import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonBack, ButtonNext, CarouselProvider } from 'pure-react-carousel'
import { appConfig } from '../../constants/appConfig'
import { Modal } from 'reactstrap'
import ImageCounter from './ImageCounter'
import Slides from './Slides'
import { useWindowSize } from '../../hooks/useWindowSize'

export default function Gallery({
  currentSlide,
  toggle,
  images,
  videos,
  imageParams,
  showCounter = true,
  influencerId,
}) {
  const [isFirstOpened, setIsFirstOpened] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(true)
  const size = useWindowSize()
  const position = useRef(null)
  const lastSlide = useRef()
  const width = size.width > 480 ? 480 : size.width - 16
  const data = [...images, ...videos.map((data) => ({ ...data, isVideo: true }))].sort(
    (a, b) => -1 * (a.isMain - b.isMain),
  )
  const currentSlideIndex = data.findIndex(({ path }) => path === currentSlide)

  const touchstart = (event) => {
    position.current = {
      startScreenY: event.changedTouches[0].screenY,
    }
  }

  const touchend = (event) => {
    const { screenY: stopScreenY } = event.changedTouches[0]
    if (Math.abs(stopScreenY - position.current.startScreenY) > 50) {
      toggle()
    }
  }

  useEffect(() => {
    if (currentSlide !== false) {
      let scrollX = window.scrollX
      let scrollY = window.scrollY
      window.onscroll = () => {
        window.scrollTo(scrollX, scrollY)
      }
      window.addEventListener('touchstart', touchstart, false)
      window.addEventListener('touchend', touchend, false)
    } else {
      window.removeEventListener('touchstart', touchstart, false)
      window.removeEventListener('touchend', touchend, false)
      window.onscroll = () => {}
      setIsFirstOpened(true)
      setIsFullscreen(false)
    }
    lastSlide.current = currentSlide
  }, [currentSlide])

  return (
    <Modal
      isOpen={currentSlide !== false}
      toggle={toggle}
      centered
      className="list-post-gallery"
      wrapClassName="list-post-gallery-background"
    >
      <div
        className="list-post-gallery-close"
        onClick={() => {
          toggle()
          setIsFirstOpened(true)
        }}
      >
        <img src={`${appConfig.cdnPath.iconsSvg}/cross-white-around.svg`} alt="" />
      </div>
      <CarouselProvider
        naturalSlideWidth={width}
        naturalSlideHeight={size.height - 50}
        totalSlides={images.length + videos.length}
        currentSlide={currentSlideIndex}
        dragStep={1}
        className={isFullscreen ? 'fullscreenSlide' : ''}
      >
        {currentSlide !== false && currentSlideIndex !== -1 && (
          <Slides
            data={data}
            imageParams={imageParams}
            isFirstOpened={isFirstOpened}
            setIsFirstOpened={setIsFirstOpened}
            setFullScreenSlide={(val) => setIsFullscreen(val)}
            influencerId={influencerId}
          />
        )}
        {!isFullscreen && data.length > 1 && (
          <>
            <ButtonBack
              onClick={() => {
                setIsFirstOpened(false)
                toggle(data?.[currentSlideIndex - 1]?.path)
              }}
            >
              <img
                src={`${appConfig.cdnPath.iconsSvg}/white-arrow-left-round1.svg`}
                alt=""
                width="32px"
                height="32px"
              />
            </ButtonBack>
            <ButtonNext
              onClick={() => {
                setIsFirstOpened(false)
                toggle(data?.[currentSlideIndex + 1]?.path)
              }}
            >
              <img
                src={`${appConfig.cdnPath.iconsSvg}/white-arrow-right-round1.svg`}
                alt=""
                width="32px"
                height="32px"
              />
            </ButtonNext>
          </>
        )}
        {!isFullscreen && showCounter && <ImageCounter />}
      </CarouselProvider>
    </Modal>
  )
}

Gallery.defaultProps = {
  showCounter: true,
}

Gallery.propTypes = {
  currentSlide: PropTypes.any,
  toggle: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  videos: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
  imageParams: PropTypes.object,
  showCounter: PropTypes.bool,
  influencerId: PropTypes.string,
}

import { createContext } from 'react'
import PropTypes from 'prop-types'

export const postModes = {
  addPost: 'addPost',
  editPost: 'editPost',
  postSettings: 'postSettings',
}

export const PostContext = createContext({
  mode: postModes.addPost,
  mediaIdToDelete: [],
  deleteMedia: () => {},
  cancelDeleteMedia: () => {},
})

PostContext.Provider.propTypes = {
  mode: PropTypes.oneOf(Object.keys(postModes)),
  mediaIdToDelete: PropTypes.arrayOf(PropTypes.string),
  deleteMedia: PropTypes.func,
  cancelDeleteMedia: PropTypes.func,
}

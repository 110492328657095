import * as DateComponent from 'react-mobile-datepicker'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { formatDate } from '../../../helpers/time'

export default function DatePicker({ date, open, onSelect, onCancel, isPopup, minDate }) {
  const [currentDate, setCurrentDate] = useState(date || new Date())
  const dateFormat = {
    date: {
      format: 'D',
      caption: 'День',
      step: 1,
    },
    month: {
      format: 'M',
      caption: 'Месяц',
      step: 1,
    },
    year: {
      format: 'YYYY',
      caption: 'Год',
      step: 1,
    },
  }

  return (
    <DateComponent
      isPopup={isPopup}
      theme="ios"
      isOpen={open}
      dateConfig={dateFormat}
      confirmText="Выбрать"
      cancelText="Отмена"
      showFormat="DD/MM/YYYY hh:mm"
      customHeader={
        <div>
          {formatDate(currentDate)}
          <div className="d-flex">
            <div className="d-flex text-center justify-content-center" style={{ flex: 1 }}>
              День
            </div>
            <div className="d-flex text-center justify-content-center" style={{ flex: 1 }}>
              Месяц
            </div>
            <div className="d-flex text-center justify-content-center" style={{ flex: 1 }}>
              Год
            </div>
          </div>
        </div>
      }
      value={currentDate}
      onSelect={onSelect}
      onCancel={onCancel}
      onChange={(date) => setCurrentDate(date)}
      min={minDate}
    />
  )
}

DatePicker.defaultProps = {
  isPopup: false,
}

DatePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  date: PropTypes.any,
  isPopup: PropTypes.bool,
  minDate: PropTypes.any,
}

import { hasSubscribe } from '../../api/auth'
import { ROUTES } from '../../constants/routes'
import { appConfig } from '../../constants/appConfig'
import { makeUrl } from '../index'

export const redirectFollowerToPostOrInfluencer = ({
  influencerNick,
  influencerId,
  subscribeInfluencerId,
  history,
  user,
}) => {
  if (subscribeInfluencerId) {
    const {
      follower: { subscriptions },
    } = user

    const alreadySubscribed = (subscriptions || []).some(
      ({ influencerId }) => influencerId === subscribeInfluencerId,
    )
    if (!alreadySubscribed) {
      history.push(`/subscribe/?influencerId=${subscribeInfluencerId}`)
    } else {
      history.push(ROUTES.MAIN)
    }
    return
  }
  if (hasSubscribe(influencerNick)) {
    history.push(
      makeUrl({
        route: ROUTES.INSIDERS_NICK,
        routeParams: { nick: influencerNick },
        params: { tabName: appConfig.tabCodes.posts },
      }),
    )
  } else {
    history.push(
      makeUrl({
        route: ROUTES.SUBSCRIBE,
        params: { influencerId },
      }),
    )
  }
}

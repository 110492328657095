import React from 'react'
import PropTypes from 'prop-types'
import PasswordMask from 'react-password-mask'
import { appConfig } from '../../../constants/appConfig'

function InputPassword({ value, onChange, onKeyDown, inputClassName, placeholder, autoFocus }) {
  return (
    <PasswordMask
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      autoFocus={autoFocus}
      showButtonContent={<img src={`${appConfig.cdnPath.iconsSvg}/eye-strike.svg`} alt="" />}
      hideButtonContent={<img src={`${appConfig.cdnPath.iconsSvg}/eye-open.svg`} alt="" />}
      className="password"
      inputClassName={`form-item auth-name ${inputClassName}`}
      buttonClassName="hide-show-button"
      useVendorStyles={false}
    />
  )
}

InputPassword.defaultProps = {
  value: null,
  onChange: () => {},
  onKeyDown: () => {},
  inputClassName: '',
  placeholder: 'Введите пароль',
  autoFocus: false,
}

InputPassword.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
}

export default InputPassword

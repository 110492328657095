import Stomp from 'stompjs'

const SUBSCRIBE = 'SUBSCRIBE'
const SEND_VIDEO = 'SEND_VIDEO'
const DISCONNECT = 'DISCONNECT'
const NEW_MESSAGE = 'NEW_MESSAGE'
const NEW_VIDEO_MESSAGE = 'NEW_VIDEO_MESSAGE'
export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE'
const NEW_SERVICE_MESSAGE = 'NEW_SERVICE_MESSAGE'
const DISCONNECTED = 'DISCONNECTED'
const CONNECTED = 'CONNECTED'
const videoPrefix = 'video'

const messageIdHeader = 'chat-message-id'
const messageTypeHeader = 'chat-message-type'
const messageSourceHeader = 'chat-message-source'

const messageTypes = {
  user: 'user',
  service: 'service',
}

let stompClient
let connectionHeaders
let connectInterval

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

const newVideoMessage = () => (stompFrame) => {
  const { body: message, headers } = stompFrame
  postMessage({ event: NEW_VIDEO_MESSAGE, payload: { message, timestamp: headers?.timestamp } })
  stompFrame.ack()
}

const notificationMessage = () => (stompFrame) => {
  const { body: message } = stompFrame
  postMessage({ event: NOTIFICATION_MESSAGE, payload: { message } })
  stompFrame.ack()
}

const subscribe = ({ followerId, token }) => {
  const decodedToken = parseJwt(token)
  decodedToken?.scope?.map((channel) => {
    const prefix = 'rabbit.read:insider/*/'
    if (channel.includes(`${prefix}video`)) {
      stompClient.subscribe(
        `/topic/${channel.replace(prefix, '')}`,
        newVideoMessage({ followerId }),
        {
          ack: 'client-individual',
          exclusive: 1,
        },
      )
    } else if (channel.includes(prefix)) {
      stompClient.subscribe(
        `/topic/${channel.replace(prefix, '')}`,
        notificationMessage({ followerId }),
        {
          ack: 'client-individual',
          exclusive: 1,
        },
      )
    }
  })
}

const clearFailoverInterval = () => {
  if (connectInterval) {
    clearInterval(connectInterval)
    connectInterval = null
  }
}

const success = (payload) => () => {
  clearFailoverInterval()
  subscribe(payload)
  postMessage({ event: CONNECTED })
}

const failover =
  ({ url, token, host, payload }) =>
  (e) => {
    console.error(e)
    clearFailoverInterval()
    postMessage({ event: DISCONNECTED })
    connectInterval = setInterval(() => {
      console.log('WorkerParams: trying to connect again after unexpected disconnect')
      stompClient = Stomp.client(url)
      stompClient.connect(
        '',
        token,
        success(payload),
        failover({ url, token, host, payload }),
        host,
      )
    }, 3000)
  }

const connect = ({ headers, payload }) => {
  clearFailoverInterval()
  const { url, host, token } = headers
  if (url && host && token) {
    if (
      stompClient?.connected &&
      connectionHeaders.url === url &&
      connectionHeaders.host === host &&
      connectionHeaders.token === token
    ) {
      console.warn('Client has already connected and headers are the same')
      return
    }
    connectionHeaders = { ...headers }
    if (stompClient?.connected) {
      stompClient.disconnect()
    }
    stompClient = stompClient || Stomp.client(url)
    const headers = {
      login: '',
      passcode: token,
      host,
      exclusive: 1,
    }
    stompClient.connect(headers, success(payload), failover({ url, token, host, payload }))
  } else {
    console.error("Unable to connect because required headers hasn't been provided")
  }
}

const disconnect = () => {
  clearFailoverInterval()
  stompClient?.disconnect()
  stompClient = null
  postMessage({ event: DISCONNECTED })
}

const sendVideo = ({ payload }, type = messageTypes.user) => {
  if (stompClient?.connected) {
    const { messageId, message } = payload
    if (messageId && message) {
      const { followerId } = payload
      stompClient.send(
        `/exchange/amq.topic/${videoPrefix}.${followerId}`,
        {
          [messageIdHeader]: messageId,
          [messageTypeHeader]: type,
          [messageSourceHeader]: 'client',
        },
        JSON.stringify(message),
      )
    } else {
      console.error("Unable to send a message because required data hasn't been provided")
    }
  } else {
    console.error('Unable to send a message because stomp client is disconnected')
  }
}

onmessage = function (e) {
  console.log('WorkerParams: Message received from main script', e.data)

  const { cmd } = e.data
  switch (cmd) {
    case SUBSCRIBE:
      return connect(e.data)
    case DISCONNECT:
      return disconnect()
    case SEND_VIDEO:
      return sendVideo(e.data)
    default:
      break
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import { Button } from '../Layout/Button/Button'

function ErrorModal({ isOpen, toggle, message }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="sm">
      <div className="py-4">
        <div className="medium-13-20 text-color-regular text-center mt-1 p-2">{message}</div>
        <div className="d-flex justify-content-between mt-4 ml-3 mr-3">
          <Button className="mr-3" onClick={toggle}>
            Закрыть
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ErrorModal

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  message: PropTypes.string,
}

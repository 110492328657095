import React, { useContext, useEffect, useReducer } from 'react'
import { Helmet } from 'react-helmet-async'
import { appConfig } from '../../constants/appConfig'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { useParams } from 'react-router-dom'
import TargetList from '../../components/Targets/TargetList'
import TargetEdit from '../../components/Targets/TargetEdit'
import { convertArrayToObject, makeUrl, simpleReducer } from '../../helpers'
import { useAllTargets } from '../../api/targets'
import { UserContext } from '../../contexts/userContext'

function TargetsPage() {
  const { targetId } = useParams()
  const { influencerId } = useContext(UserContext)

  const initialState = {
    allTargets: [],
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data: dataTargets, isSuccess: isSuccessTargets } = useAllTargets(
    { influencerId },
    { enabled: Boolean(influencerId) },
  )

  useEffect(() => {
    if (isSuccessTargets && dataTargets) {
      setState({ allTargets: convertArrayToObject(dataTargets, 'id') })
    }
  }, [dataTargets, isSuccessTargets])

  const getTitle = () => {
    if (targetId === appConfig.targets.createId) {
      return 'Новая цель'
    } else if (targetId) {
      return 'Редактирование цели'
    } else {
      return 'Цели'
    }
  }

  const getHeaderLink = () => {
    return targetId
      ? makeUrl({ route: ROUTES.PROFILE_TARGETS })
      : makeUrl({ route: ROUTES.PROFILE_SETTINGS })
  }

  return (
    <main className="h-100 d-flex flex-column">
      <Helmet htmlAttributes={{ lang: 'ru' }}>
        <meta charSet="utf-8" />
        <meta name="description" content="Insider" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <title>{appConfig.title}</title>
      </Helmet>
      <Header title={getTitle()} needRight={false} to={getHeaderLink()} />
      {targetId ? (
        <TargetEdit data={state.allTargets[targetId]} />
      ) : (
        <TargetList data={Object.values(state.allTargets)} />
      )}
    </main>
  )
}

export default TargetsPage

import React, { useEffect, useReducer, useMemo, useContext } from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { appConfig } from '../../constants/appConfig'
import { Button } from '../../components/Layout/Button/Button'
import {
  getDescription,
  setAuthorInfo,
  getCurrentUser,
  changeInfluencerName,
} from '../../api/users'
import { clearProtocolHost, makeUrl, simpleReducer } from '../../helpers'
import queryString from 'query-string'
import { pageMode as profilePageMode } from '../Profile'
import { pageMode as profileSettingsPageMode } from './settings'
import { Input } from '../../components/Layout/Input/Input'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'

const pageMode = {
  name: 'name',
}

const { socialHosts } = appConfig

function ProfileAboutPage() {
  const { influencerId } = useContext(UserContext)
  const history = useHistory()
  const initialState = {
    user: null,
    title: null,
    text: null,
    shortDescription: null,
    mode: pageMode.profile,
    name: null,
    instagram: null,
    youtube: null,
    tiktok: null,
    twitch: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const isEditedForm = useMemo(() => {
    return state.name !== state.user?.influencer?.influencerFullName
  }, [state.user, state.name])

  const loadUserData = async () => {
    if (influencerId) {
      const user = await getCurrentUser()
      const { influencerTitle, longDescription, shortDescription } = await getDescription(
        influencerId,
      )
      setState({
        user,
        title: influencerTitle,
        text: longDescription,
        shortDescription,
        name: user?.influencer?.influencerFullName,
        instagram: user?.influencer?.instagramLink,
        youtube: user?.influencer?.youTubeLink,
        tiktok: user?.influencer?.tikTokLink,
        twitch: user?.influencer?.twitchLink,
      })
    }
  }

  useEffect(() => {
    loadUserData()
  }, [influencerId])

  const renderValue = (item) => {
    return (
      <div className="value" onClick={item.onClick}>
        {item.type === 'link' && item.value ? (
          <>
            <img src={`${appConfig.cdnPath.iconsSvg}/icon-link.svg`} alt="" className="link" />
            {clearProtocolHost(item.value)}
          </>
        ) : (
          item.value
        )}
        {!(item.value || item.link) && <span className="placeholder">{item.placeholder}</span>}
      </div>
    )
  }

  const renderEdit = (item) => {
    const prefix = socialHosts[item.key] || ''

    return (
      <div className="edit">
        {prefix && <div>{prefix.replace('https://', '')}</div>}
        <Input
          autofocus
          placeholder={item.placeholder}
          onChange={(value) => handleChange(item, value)}
          value={clearProtocolHost(item.value)}
        />
      </div>
    )
  }

  const renderItem = (item) => {
    return (
      <div className="profile-about-item" key={item.key}>
        <div className="label">{item.name}</div>
        {state.mode === item.key ? renderEdit(item) : renderValue(item)}
        <img
          src={`${appConfig.cdnPath.iconsSvg}/arrow-grey-right.svg`}
          alt=""
          onClick={item.onClick}
        />
      </div>
    )
  }

  const handleLink = (route, pageOption) => {
    history.push(
      `${route}?${queryString.stringify({
        pageOption,
        backScreen: 'profileAbout',
      })}`,
    )
  }

  const handleChange = (item, value) => {
    setState({
      [item.key]: value,
    })
  }

  const handleSave = async () => {
    if (state.user.influencer.id) {
      await setAuthorInfo(state.user.influencer.id, state)
      await changeInfluencerName(state.name)
      history.push(ROUTES.PROFILE_SETTINGS)
    }
  }

  return (
    <>
      <Header title="Об авторе" needRight={false} to={ROUTES.PROFILE_SETTINGS} />
      <div className="content content-padding profile-about">
        {[
          {
            key: 'name',
            name: 'Имя',
            value: state.name,
            placeholder: 'Введите имя',
            onClick: () => setState({ mode: pageMode.name }),
          },
          {
            key: 'nickName',
            name: 'Ник',
            value: state.user?.influencer?.nickName,
          },
          {
            key: 'shortDescription',
            name: 'Описание профиля',
            value: state.shortDescription,
            placeholder: 'Введите описание',
            onClick: () => handleLink(ROUTES.PROFILE, profilePageMode.description),
          },
          {
            key: 'title',
            name: 'Заголовок',
            value: state.title,
            placeholder: 'Введите заголовок',
            onClick: () => handleLink(ROUTES.PROFILE_SETTINGS, profileSettingsPageMode.about),
          },
          {
            key: 'text',
            name: 'Текст',
            value: state.text,
            placeholder: 'Введите текст',
            onClick: () => handleLink(ROUTES.PROFILE_SETTINGS, profileSettingsPageMode.about),
          },
          {
            key: 'instagram',
            name: 'Instagram',
            type: 'link',
            value: state.instagram,
            placeholder: 'Добавить ссылку на профиль',
            onClick: () =>
              history.push(
                makeUrl({ route: ROUTES.PROFILE_SOCIAL_LINKS, routeParams: { name: 'instagram' } }),
              ),
          },
          {
            key: 'youtube',
            name: 'YouTube',
            type: 'link',
            value: state.youtube,
            placeholder: 'Добавить ссылку на профиль',
            onClick: () =>
              history.push(
                makeUrl({ route: ROUTES.PROFILE_SOCIAL_LINKS, routeParams: { name: 'youtube' } }),
              ),
          },
          {
            key: 'tiktok',
            name: 'TikTok',
            type: 'link',
            value: state.tiktok,
            placeholder: 'Добавить ссылку на профиль',
            onClick: () =>
              history.push(
                makeUrl({ route: ROUTES.PROFILE_SOCIAL_LINKS, routeParams: { name: 'tiktok' } }),
              ),
          },
          {
            key: 'twitch',
            name: 'Twitch',
            type: 'link',
            value: state.twitch,
            placeholder: 'Добавить ссылку на профиль',
            onClick: () =>
              history.push(
                makeUrl({ route: ROUTES.PROFILE_SOCIAL_LINKS, routeParams: { name: 'twitch' } }),
              ),
          },
        ].map(renderItem)}
      </div>
      <div className="content-padding pb-4">
        <Button
          className="semiBold-15-24"
          onClick={handleSave}
          color="regular"
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          disable={!isEditedForm}
        >
          Сохранить
        </Button>
      </div>
    </>
  )
}

export default ProfileAboutPage

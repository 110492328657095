import React, { useContext, useEffect, useReducer } from 'react'
import { Footer } from '../components/Footer/Footer'
import LinkWithArrow from '../components/Layout/LinkWithArrow/LinkWithArrow'
import { appConfig } from '../constants/appConfig'
import { ROUTES } from '../constants/routes'
import { getAvatars } from '../api/users'
import { simpleReducer } from '../helpers'
import { useHistory } from 'react-router-dom'
import UserAvatar from '../components/Profile/UserAvatar'
import { logout, removeToken } from '../api/auth'
import { remove } from '../api/storage'
import { ChatContext } from '../contexts/chatContext'
import { UserContext } from '../contexts/userContext'

function FollowerPage() {
  const { user, setUser } = useContext(UserContext)
  const history = useHistory()
  const initialState = {
    followerAvatarLink: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const loadData = async () => {
    try {
      const { followerAvatarLink } = await getAvatars()
      if (followerAvatarLink) {
        setState({ followerAvatarLink })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const { disconnect } = useContext(ChatContext)
  const { disconnect: paramsDisconnect } = useContext(ChatContext)

  const handleLogout = async () => {
    try {
      await logout()
      remove(appConfig.storage.keys.user)
      remove(appConfig.storage.keys.userType)
      remove(appConfig.chats.filterName)
      remove(appConfig.comments.filterName)
      remove(appConfig.storage.keys.welcomeMode)
      setUser({})
      removeToken()
      disconnect()
      paramsDisconnect()
      history.push(ROUTES.AUTH)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <header className="mb-4 follower-profile-title">Настройки</header>
      <div className="content content-padding follower-profile footer-padding">
        <div className="follower-profile-account">
          <UserAvatar size={100} src={state.followerAvatarLink} />
          <div className="info">
            <div className="title">{user?.follower?.nickName}</div>
            <div className="mail">{user?.email}</div>
            <div className="btnChange mt-2" onClick={() => history.push(ROUTES.FOLLOWER_ACCOUNT)}>
              Настройки аккаунта
            </div>
          </div>
        </div>
        <LinkWithArrow
          title="Управление подписками"
          onClick={() => history.push(ROUTES.FOLLOWER_SUBSCRIPTIONS)}
          className="follower-profile-item"
          iconElement={
            <div className="icon">
              <img src={`${appConfig.cdnPath.iconsSvg}/people-green.svg`} alt="" />
            </div>
          }
        />
        <LinkWithArrow
          title="Мой рейтинг"
          onClick={() => history.push(ROUTES.FOLLOWER_RATING)}
          className="follower-profile-item"
          iconElement={
            <div className="icon">
              <img src={`${appConfig.cdnPath.iconsSvg}/greenstar.svg`} alt="" />
            </div>
          }
        />
        <hr className="follower-profile-item" />
        <LinkWithArrow
          title="Сохраненное"
          onClick={() => history.push(ROUTES.FOLLOWER_SAVED_POSTS)}
          className="follower-profile-item"
          iconElement={
            <div className="icon">
              <img src={`${appConfig.cdnPath.iconsSvg}/flag-green.svg`} alt="" />
            </div>
          }
        />
        <LinkWithArrow
          title="Понравилось"
          onClick={() => history.push(ROUTES.FOLLOWER_LIKED_POSTS)}
          className="follower-profile-item"
          iconElement={
            <div className="icon">
              <img src={`${appConfig.cdnPath.iconsSvg}/like-green.svg`} alt="" />
            </div>
          }
        />
        <hr className="follower-profile-item" />
        {/* Скрыть пока не реализован функционал самой страницы */}
        {/*<LinkWithArrow*/}
        {/*  title="Платежная информация"*/}
        {/*  onClick={() => history.push(ROUTES.FOLLOWER_PAYMENT_INFO)}*/}
        {/*  className="follower-profile-item"*/}
        {/*  iconElement={*/}
        {/*    <div className="icon">*/}
        {/*      <img src={`${appConfig.cdnPath.iconsSvg}/card-green.svg`} alt="" />*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<LinkWithArrow*/}
        {/*  title="Способы оплаты"*/}
        {/*  onClick={() => history.push(ROUTES.FOLLOWER_PAYMENT_CARDS)}*/}
        {/*  className="follower-profile-item"*/}
        {/*  iconElement={*/}
        {/*    <div className="icon">*/}
        {/*      <img src={`${appConfig.cdnPath.iconsSvg}/card-green.svg`} alt="" />*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*/>*/}
        <LinkWithArrow
          title="Об Insider"
          onClick={() => history.push(ROUTES.ABOUT_APP)}
          className="follower-profile-item"
          iconElement={
            <div className="icon">
              <img src={`${appConfig.cdnPath.iconsSvg}/icon-i-green.svg`} alt="" />
            </div>
          }
        />
        <hr className="follower-profile-item" />
        <div
          className="list-payment-method-content follower-profile-item logout"
          onClick={handleLogout}
        >
          <div className="icon">
            <img src={`${appConfig.cdnPath.iconsSvg}/exit.svg`} alt="" />
          </div>
          <div className="semiBold-15-24 flex-grow-1 text-left">Выйти</div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default FollowerPage

import { appConfig } from '../../../../constants/appConfig'
import PropTypes from 'prop-types'
import React from 'react'

export function VideoPreview({ style, src, oldLink, onClick = () => {} }) {
  return (
    <div style={{ ...style, position: 'relative' }} onClick={onClick}>
      <picture onClick={onClick}>
        <source srcSet={src} />
        <img
          src={oldLink}
          alt=""
          className="screenshot-image"
          style={{ maxHeight: style?.maxHeight || 'none', objectFit: 'contain' }}
        />
      </picture>
      <img
        style={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }}
        src={`${appConfig.cdnPath.iconsSvg}/play-white-around.svg`}
        alt=""
      />
    </div>
  )
}

VideoPreview.propTypes = {
  style: PropTypes.any,
  src: PropTypes.string,
  oldLink: PropTypes.string,
  onClick: PropTypes.func,
}

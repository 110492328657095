import { isWebpSupported } from 'react-image-webp/dist/utils'
import queryString from 'query-string'

export function useWebpLinks({ path, params = {}, isOriginal = false }) {
  if (!path) {
    return { link: undefined }
  }
  let ext = ''
  const secureParams = { ...params }
  let o
  if (isWebpSupported) {
    o = `${path.split('/').pop()}`
    ext = isOriginal ? '' : '.webp'
  }
  if (secureParams?.md5Static) {
    secureParams.md5 = secureParams.md5Static
    delete secureParams.md5Static
  }
  return {
    link: `${path}${ext}?${queryString.stringify({ ...secureParams, ...(o && { o }) })}`,
    oldLink: `${path}?${queryString.stringify(secureParams)}`,
  }
}

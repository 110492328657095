import React, { useReducer } from 'react'
import EmptyProfile from '../components/Profile/EmptyProfile/EmptyProfile'
import DescriptionProfile from '../components/Profile/EmptyProfile/DescriptionProfile'
import { simpleReducer } from '../helpers'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { ROUTES } from '../constants/routes'
import { useHistory } from 'react-router-dom'

export const pageMode = {
  profile: 'profile',
  description: 'description',
}

const ProfilePage = ({ location }) => {
  const history = useHistory()
  const { pageOption, backScreen } = queryString.parse(location.search)
  const initialState = {
    mode: pageMode[pageOption] ? pageMode[pageOption] : pageMode.profile,
  }

  const [state, setState] = useReducer(simpleReducer, initialState)

  const handlePageMode = (mode) => setState({ mode })

  const handleDescriptionBack = () => {
    if (pageMode[pageOption]) {
      if (backScreen === 'profileAbout') {
        history.push(ROUTES.PROFILE_ABOUT)
      } else {
        handlePageMode(pageMode.profile)
      }
    } else {
      handlePageMode(pageMode.profile)
    }
  }

  const renderBody = () => {
    switch (state.mode) {
      case pageMode.profile:
        return <EmptyProfile onAddDescription={() => handlePageMode(pageMode.description)} />
      case pageMode.description:
        return <DescriptionProfile onBackLink={handleDescriptionBack} />
      default:
        return null
    }
  }

  return renderBody()
}

ProfilePage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

export default ProfilePage

import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'

import { appConfig } from '../../constants/appConfig'

function FilterValuesModal({
  values,
  onClose,
  onClickValue,
  isOpen,
  valueElement,
  title,
  hideClose,
}) {
  const renderItem = (item) => {
    return (
      <div key={item} className="values-list-item" onClick={() => onClickValue(item, values[item])}>
        {values[item]}
      </div>
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" className="filterValuesModal">
      <div className="d-flex flex-column filterValuesModalContent">
        <div className="title">
          {title}
          {!hideClose && (
            <img
              src={`${appConfig.cdnPath.iconsSvg}/cross-black-margin.svg`}
              alt=""
              onClick={onClose}
            />
          )}
        </div>
        {valueElement ? (
          valueElement
        ) : (
          <div className="values-list">{Object.keys(values || {}).map(renderItem)}</div>
        )}
      </div>
    </Modal>
  )
}

FilterValuesModal.defaultProps = {
  values: {},
  onClose: () => {},
  onClickValue: () => {},
  isOpen: false,
  hideClose: false,
}

FilterValuesModal.propTypes = {
  values: PropTypes.object,
  valueElement: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onClickValue: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  hideClose: PropTypes.bool,
}

export default FilterValuesModal

import React from 'react'
import PropsTypes from 'prop-types'
import InfoModal from '../Modal/InfoModal'
import { Register, ResetSuccess, DisableRegistration } from './Messages/'
import { errorsJsx } from '../../constants/errorsJsx'

export const modalTypes = {
  register: 'register',
  registerFinishError: 'registerFinishError',
  resetSuccess: 'resetSuccess',
  resetError: 'resetError',
  resetLimit: 'resetLimit',
  dataError: 'dataError',
  disableRegistration: 'disableRegistration',
  accountHardBanned: 'accountHardBanned',
  lockedOut: 'lockedOut',
}

function EmailModal({ isOpen, onOpened, onClose, type, email }) {
  const getContent = () => {
    switch (type) {
      case modalTypes.register:
        return <Register email={email} />
      case modalTypes.resetSuccess:
        return <ResetSuccess email={email} />
      case modalTypes.resetError:
        return errorsJsx.auth.userNotFound
      case modalTypes.resetLimit:
        return errorsJsx.auth.resetPassLimit
      case modalTypes.dataError:
        return errorsJsx.dataSendError
      case modalTypes.disableRegistration:
        return <DisableRegistration />
      case modalTypes.registerFinishError:
        return <>Мы не смогли подтвердить ваш email по полученной ссылке. Повторите регистрацию.</>
      case modalTypes.accountHardBanned:
        return errorsJsx.auth.hardBanned
      case modalTypes.lockedOut:
        return errorsJsx.auth.lockedOut
    }
  }

  return <InfoModal isOpen={isOpen} onOpened={onOpened} onClose={onClose} message={getContent()} />
}

EmailModal.defaultProps = {
  isOpen: false,
  type: '',
  email: '',
  onOpened: () => {},
  onClose: () => {},
}

EmailModal.propTypes = {
  isOpen: PropsTypes.bool,
  type: PropsTypes.oneOf([...Object.values(modalTypes), '']).isRequired,
  onOpened: PropsTypes.func.isRequired,
  onClose: PropsTypes.func.isRequired,
  email: PropsTypes.string,
}

export default EmailModal

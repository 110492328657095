import React, { useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { Header } from '../Header/Header'
import CommentsItem from './CommentsItem'
import { Paragraph, typeLines } from '../Layout/'
import CommentEditor from './CommentEditor'
import { getComments, deleteComment } from '../../api/comment'
import { useParams, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { convertArrayToObject, isWrongAnyGetParam, makeUrl, simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import { ROUTES } from '../../constants/routes'
import UserAvatar from '../Profile/UserAvatar'
import { useLogicalFlow, logicalFlows } from '../../hooks/useLogicalFlow'
import moment from 'moment'
import { useWindowSize } from '../../hooks/useWindowSize'
import { deleteCommentAdmin } from '../../api/administrator'
import { UserContext } from '../../contexts/userContext'
import CommentsFilterModal, { filters as commentsFilters } from './CommentsFilterModal'
import { find, update } from '../../api/storage'
import { nbsp } from '../../constants/typography'

export const backOption = {
  followerSaved: 'followerSaved',
  followerLiked: 'followerLiked',
}

function Comments({ influencerViewer, followerViewer }) {
  const { user, isAdmin } = useContext(UserContext)
  const history = useHistory()
  const { getFlowData, setFlowData } = useLogicalFlow()
  const bottomRef = useRef()
  const { nick } = useParams()
  const {
    postId,
    influencerId: paramInfluencerId,
    backScreen,
    targetComment,
  } = queryString.parse(window.location.search)
  const { height } = useWindowSize()

  const influencerId = useMemo(() => {
    if (influencerViewer) {
      return user?.influencer?.id
    }
    return paramInfluencerId
  }, [influencerViewer, paramInfluencerId, user?.influencer?.id])

  const initialState = {
    commentList: {},
    nav: {
      page: -1,
      perPage: appConfig.comments.countPerPage,
      total: 0,
    },
    post: null,
    cnt: null,
    cntList: {},
    isOpenFilters: false,
    activeFilter: find(appConfig.comments.filterName, commentsFilters.isRate.id),
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const clearPostCommentsCnt = (list) => {
    delete list[postId]
    setFlowData(logicalFlows.postCommentsCnt.id, { ...list })
  }

  useEffect(() => {
    getFlowData(
      logicalFlows.postCommentsCnt.id,
      (list) => {
        let cnt = null
        if (list && (list[postId] !== undefined || list[postId] !== null)) {
          if (list[postId]?.datetime) {
            const now = moment()
            const someMinutesLast = moment().add(-appConfig.monitorCacheExpireInMinutes, 'minute')
            const cntDatetime = moment(list[postId].datetime, 'DD.MM.YYYY HH:mm:ss')
            if (cntDatetime.isBetween(someMinutesLast, now)) {
              cnt = list[postId]?.cnt
            } else {
              clearPostCommentsCnt(list)
            }
          } else {
            clearPostCommentsCnt(list)
          }
        }

        setState({ cnt, cntList: list, nav: { ...state.nav, page: 0 } })
      },
      () => setState({ nav: { ...state.nav, page: 0 } }),
    )
  }, [])

  const getBackRoute = () => {
    switch (true) {
      case backScreen === backOption.followerLiked && Boolean(followerViewer):
        return makeUrl({ route: ROUTES.FOLLOWER_LIKED_POSTS })
      case backScreen === backOption.followerSaved && Boolean(followerViewer):
        return makeUrl({ route: ROUTES.FOLLOWER_SAVED_POSTS })
      case Boolean(nick && followerViewer && !backScreen):
        return makeUrl({
          route: ROUTES.INSIDERS_NICK,
          routeParams: { nick },
          params: { tabName: appConfig.tabCodes.posts },
        })
      case Boolean(influencerViewer):
        return makeUrl({ route: ROUTES.PROFILE, params: { tabName: appConfig.tabCodes.posts } })
      default:
        return 'back'
    }
  }

  const loadData = async () => {
    if (isWrongAnyGetParam({ influencerId, postId })) {
      history.push(makeUrl({ route: ROUTES.PAGE_404 }))
      return
    }

    try {
      const result = await getComments({
        influencerId,
        postId,
        pageNumber: state.nav.page,
        commentsOnPage: state.nav.perPage,
        cnt: state.cnt,
        user,
        targetComment,
        isCreateDateRequested: state.activeFilter === commentsFilters.isCreateDate.id,
      })
      setState({
        commentList: {
          ...state.commentList,
          ...convertArrayToObject(result?.data, 'id'),
        },
        nav: result?.nav,
        post: result?.post,
      })
      // bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    } catch (e) {
      console.log('e', e?.response?.statusText)
      history.push(makeUrl({ route: ROUTES.PAGE_404 }))
    }
  }

  useEffect(() => {
    if (state.nav.page >= 0) {
      loadData()
    }
  }, [state.nav.page])

  const handleClickMore = () => {
    setState({
      nav: {
        ...state.nav,
        page: state.nav.page + 1,
      },
    })
  }

  const handleClickDelete = async (commentId) => {
    try {
      const method = isAdmin ? deleteCommentAdmin : deleteComment
      await method({ postId, commentId })
      delete state.commentList[commentId]
      setState({ commentList: state.commentList })
      setFlowData(logicalFlows.postCommentsCnt.id, {
        ...state.cntList,
        [postId]: {
          cnt: state.nav.total - 1,
          datetime: moment().format('DD.MM.YYYY HH:mm:ss'),
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getCountMore = () => {
    return state.nav.total - state.nav.perPage * (state.nav.page + 1)
  }

  const handleSaveComment = (comment) => {
    setState({
      commentList: {
        ...state.commentList,
        ...{ [comment.id]: comment },
      },
    })
    setFlowData(logicalFlows.postCommentsCnt.id, {
      ...state.cntList,
      [postId]: {
        cnt: state.nav.total + 1,
        datetime: moment().format('DD.MM.YYYY HH:mm:ss'),
      },
    })
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleClickFilter = () => {
    setState({ isOpenFilters: true })
  }

  const { influencer } = user

  const isPostOwner = useMemo(() => influencer?.id === influencerId, [influencer?.id, influencerId])

  return (
    <div className="comments d-flex flex-column" style={{ height }}>
      <Header
        needRight={true}
        title={`Комментарии${nbsp.repeat(8)}`}
        to={getBackRoute()}
        needLeft={true}
        rightBlock={
          <div className="semiBold-14-20" onClick={handleClickFilter}>
            Фильтры <img src={`${appConfig.cdnPath.iconsSvg}/filter-black.svg`} />
          </div>
        }
      />
      <div className="comments-content">
        {state.post?.text && (
          <div className="comments-item pinned">
            <UserAvatar src={state.post?.avatarUrl} size={40} className="avatar mr-2" />
            <div className="main">
              <div className="d-flex justify-content-between">
                <div className="text">
                  <b>{state.post?.influencerNickName}</b>
                  <img
                    src={`${appConfig.cdnPath.iconsSvg}/profileIcons/verified.svg`}
                    alt=""
                    className="ml-1"
                    width={'20px'}
                  />
                  <Paragraph
                    value={state.post?.text}
                    etcClassName="text-color-black30"
                    typeLine={typeLines.threeLines}
                    isReplaceMails
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {Object.values(state.commentList).map((comment) => (
          <CommentsItem
            key={comment.id}
            comment={comment}
            influencerId={influencerId}
            postId={postId}
            onDelete={handleClickDelete}
            isPostOwner={isPostOwner}
            influencerNickName={state.post?.influencerNickName}
          />
        ))}
        {getCountMore() > 0 && (
          <div
            className="comments-showMore d-flex align-items-center justify-content-center"
            onClick={handleClickMore}
          >
            Показать ещё
          </div>
        )}
        <div ref={bottomRef} className="bottom-tag" />
      </div>
      <div className="comments-footer content-padding pt-2 pb-4">
        <CommentEditor postId={postId} onSave={handleSaveComment} />
      </div>
      <CommentsFilterModal
        isOpen={state.isOpenFilters}
        onClose={() => setState({ isOpenFilters: false })}
        activeFilter={state.activeFilter}
        onSetActiveFilter={(value) => {
          update(appConfig.comments.filterName, value)
          history.go(0)
        }}
      />
    </div>
  )
}

Comments.defaultProps = {
  followerViewer: null,
  influencerViewer: null,
}

Comments.propTypes = {
  followerViewer: PropTypes.object,
  influencerViewer: PropTypes.object,
}

export default Comments

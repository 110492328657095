import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InfluencerContent } from './Influencer/InfluencerContent'
import { hasSubscribe } from '../../api/auth'
import { LayoutContext } from '../../contexts/layoutContext'

export default function Influencer({ profile }) {
  const {
    nick,
    id: influencerId,
    avatarUrl,
    backgroundUrl,
    name,
    shortDescription,
    about,
    products,
    socialLinks,
    followersCount,
    subscription,
    publishedPostsCount,
  } = profile || {}

  const isSubscribed = hasSubscribe(nick)
  const { oneMonthCost } = subscription || {}
  const { setHelmetPreloadLinks } = useContext(LayoutContext)

  useEffect(() => {
    setHelmetPreloadLinks([avatarUrl, backgroundUrl])
  }, [avatarUrl, backgroundUrl, setHelmetPreloadLinks])

  return (
    <InfluencerContent
      bgUrl={backgroundUrl}
      photoUrl={avatarUrl}
      name={name}
      followersCount={followersCount}
      shortDescription={shortDescription}
      subscribed={isSubscribed}
      oneMonthCost={oneMonthCost}
      products={products}
      about={about}
      socialLinks={socialLinks}
      author={nick}
      influencerId={influencerId}
      publishedPostsCount={publishedPostsCount}
    />
  )
}

Influencer.propTypes = {
  profile: PropTypes.object,
}

import React, { useContext, useEffect, useReducer } from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import LinkWithArrow from '../../components/Layout/LinkWithArrow/LinkWithArrow'
import { useHistory } from 'react-router-dom'
import Checkbox from '../../components/Layout/Checkbox/Checkbox'
import { makeUrl, simpleReducer } from '../../helpers'
import { Button } from '../../components/Layout/Button/Button'
import { setInfluencerOptions } from '../../api/influencer'
import { Modal } from 'reactstrap'
import { UserContext } from '../../contexts/userContext'

function SettingsPrivacyPage() {
  const history = useHistory()
  const initialState = {
    isShowLikes: false,
    isShowFollowersCount: false,
    isModalOpen: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { user, setUser } = useContext(UserContext)
  useEffect(() => {
    if (user) {
      setState({
        isShowLikes: user.influencer?.isShowingLikes,
        isShowFollowersCount: user.influencer?.isShowingFollowers,
      })
    }
  }, [user])

  const handleSave = async () => {
    try {
      await setInfluencerOptions({
        isShowingFollowers: state.isShowFollowersCount,
        isShowingLikes: state.isShowLikes,
      })
      user.influencer = {
        ...user.influencer,
        isShowingFollowers: state.isShowFollowersCount,
        isShowingLikes: state.isShowLikes,
      }
      setUser(user)
      history.push(makeUrl({ route: ROUTES.PROFILE_SETTINGS }))
    } catch (e) {
      console.log(e)
      setState({ isModalOpen: true })
    }
  }

  return (
    <>
      <Header title="Приватность" needRight={false} to={ROUTES.PROFILE_SETTINGS} />
      <div className="content content-padding profile-settings-privacy">
        <LinkWithArrow
          title="Сменить пароль"
          onClick={() => history.push(ROUTES.EDIT_PASSWORD_PAGE)}
          className="profile-settings-item mb-5"
        />
        <Checkbox
          className="profile-settings-item"
          label="Отображать лайки"
          onChange={(checked) => setState({ isShowLikes: checked })}
          checked={state.isShowLikes}
        />
        <Checkbox
          className="profile-settings-item"
          label="Показывать количество подписчиков"
          onChange={(checked) => setState({ isShowFollowersCount: checked })}
          checked={state.isShowFollowersCount}
        />
      </div>
      <div className="content-padding pb-4">
        <Button
          className="semiBold-15-24"
          onClick={handleSave}
          color="regular"
          role="button"
          tabIndex={0}
        >
          Сохранить
        </Button>
      </div>
      <Modal
        isOpen={state.isModalOpen}
        centered={true}
        toggle={() => setState({ isModalOpen: false })}
        size="sm"
      >
        <div className="py-4">
          <div className="medium-13-20 text-color-regular text-center mt-1 p-2">
            Ошибка сохранения данных. Повторите попытку.
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SettingsPrivacyPage

import { createContext } from 'react'
import PropTypes from 'prop-types'

export const LayoutContext = createContext({
  helmetPreloadLinks: [],
  setHelmetPreloadLinks: () => {},
})

LayoutContext.Provider.propTypes = {
  helmetPreloadLinks: PropTypes.arrayOf(PropTypes.string),
  setHelmetPreloadLinks: PropTypes.func,
}

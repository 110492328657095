import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '../../Layout/Button/Button'
import { ROUTES } from '../../../constants/routes'
import { isIOS, makeUrl } from '../../../helpers'
import { RECOMMEND_TAB_KEY } from '../../../constants'
import { hasSubscriptions } from '../../../api/auth'
import { appConfig } from '../../../constants/appConfig'

function EmptyChats() {
  const history = useHistory()
  const hasSubscribed = hasSubscriptions()

  return (
    <div className="chat-user--empty h-100" style={{ marginBottom: isIOS() ? 0 : 70 }}>
      {/*<div className="img" /> not seen in mobile mode*/}
      <img src={`${appConfig.cdnPath.images}/emptyChats.svg`} />
      <div className="text">
        <div className="text-center">
          У тебя пока нет ни одного чата. <br />
          {!hasSubscribed && 'Подпишись на одного из инфлюенсеров, чтобы начать беседу.'}
        </div>
        {!hasSubscribed && (
          <div className="w-100">
            <Button
              className="w-100 mt-3"
              onClick={() =>
                history.push(makeUrl({ route: ROUTES.MAIN, params: { tab: RECOMMEND_TAB_KEY } }))
              }
            >
              Подписаться
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default EmptyChats

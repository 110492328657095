import React, { useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { isNumber } from 'lodash-es'
import clsx from 'clsx'
import { Input } from '../Layout/Input/Input'
import { MemoizedAudio } from './AudioPlayer/Audio'
import queryString from 'query-string'

export function AudioPlayer({
  src,
  type,
  name,
  onChangeName,
  className = '',
  needFilename = true,
  secureParams = {},
}) {
  const audioRef = useRef(null)
  const progressBar = useRef(null)
  const [buttonType, setButtonType] = useState('play')
  const [percentage, setPercentage] = useState(0)
  const [duration, setDuration] = useState('00:00')
  const [currentTime, setCurrentTime] = useState('00:00')
  const [mode, setMode] = useState('show')

  const playPauseAudio = () => {
    if (audioRef.current.paused || audioRef.current.ended) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }

  const formatSeconds = (seconds) => {
    if (seconds) {
      const date = new Date(null)
      date.setSeconds(seconds)
      const time = date.toISOString().substr(11, 8)
      if (time.indexOf('00') === 0) {
        return time.substr(3)
      }
      return time
    }
  }

  const onPlay = useCallback(() => {
    setButtonType('pause')
  }, [setButtonType])

  const onPause = useCallback(() => {
    setButtonType('play')
  }, [setButtonType])

  const onLoadedData = useCallback(() => {
    setDuration(formatSeconds(audioRef.current.duration))
  }, [setDuration])

  const onTimeUpdate = useCallback(() => {
    if (audioRef.current.duration) {
      setPercentage(Math.floor((100 / audioRef.current.duration) * audioRef.current.currentTime))
      setCurrentTime(formatSeconds(audioRef.current?.currentTime))
    }
  }, [setCurrentTime, setPercentage])

  const secureSrc = useMemo(() => {
    return `${src}?${queryString.stringify(secureParams)}`
  }, [src, secureParams])
  return (
    <div
      className={`player-audio ${className}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <MemoizedAudio
        audioRef={audioRef}
        onPlay={onPlay}
        onLoadedData={onLoadedData}
        onPause={onPause}
        onTimeUpdate={onTimeUpdate}
        src={secureSrc}
        type={type}
      />

      <div className="player-audio-controls">
        <div id="btnPlayPause" className="player-button" title="play" accessKey="P">
          {buttonType === 'play' && (
            <img
              src={`${appConfig.cdnPath.iconsSvg}/green-play2.svg`}
              width="44px"
              height="44px"
              alt=""
              onClick={playPauseAudio}
            />
          )}
          {buttonType === 'pause' && (
            <img
              src={`${appConfig.cdnPath.iconsSvg}/green-pause.svg`}
              width="44px"
              height="44px"
              alt=""
              onClick={playPauseAudio}
            />
          )}
        </div>
        <div
          className="player-audio-controls-center pb-1"
          style={{ ...(name === '' && { paddingTop: '20px' }) }}
        >
          {(!onChangeName || mode === 'show') && (
            <div
              className={clsx('medium-14-20', { filename: needFilename })}
              onClick={() => setMode('edit')}
            >
              {name || name === '' ? name : 'Композиция без названия'}
            </div>
          )}
          {onChangeName && mode === 'edit' && (
            <Input
              onChange={(text) => {
                onChangeName(text)
              }}
              value={name}
              autofocus={false}
              inputClassName="player-audio-input"
              placeholder="Введите название композиции"
            />
          )}
          <input
            type="range"
            id="seek-slider"
            max="100"
            value={isNumber(percentage) ? percentage : 0}
            ref={progressBar}
            onTouchEnd={(e) => {
              const { changedTouches } = e
              if (changedTouches.length > 0) {
                const last = changedTouches.item(changedTouches.length - 1)
                const x = last.pageX - progressBar.current.offsetLeft
                const percent = x / progressBar.current.offsetWidth
                audioRef.current.currentTime = percent * audioRef.current.duration
                setPercentage(percent)
              }
            }}
            onClick={(e) => {
              const x = e.pageX - progressBar.current.offsetLeft
              const percent = x / progressBar.current.offsetWidth
              audioRef.current.currentTime = percent * audioRef.current.duration
              setPercentage(percent)
            }}
            style={{ '--webkitProgressPercent': `${percentage}%` }}
            onChange={() => {}}
          />
          <div className="d-flex justify-content-between text-color-green">
            <div
              className="medium-12-20"
              style={{ ...(currentTime === '00:00' && { color: 'rgba(23, 148, 148, 0.5)' }) }}
            >
              {currentTime}
            </div>
            <div
              className="medium-12-20"
              style={{ ...(currentTime !== '00:00' && { color: 'rgba(23, 148, 148, 0.5)' }) }}
            >
              {duration}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AudioPlayer.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChangeName: PropTypes.func,
  className: PropTypes.string,
  needFilename: PropTypes.bool,
  secureParams: PropTypes.object,
}

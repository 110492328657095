export const dbStorages = {
  routingData: 'routingData',
}

const dbConfig = {
  name: 'InsiderDB',
  version: 1,
  objectStoresMeta: [
    {
      store: dbStorages.routingData,
      storeConfig: { keyPath: 'key' },
      storeSchema: [
        { name: 'key', keypath: 'key', options: { unique: true } },
        { name: 'prev', keypath: 'prev', options: { unique: false } },
      ],
    },
  ],
}

export default dbConfig

import React, { useState, useEffect } from 'react'
import { getCategories } from '../../api/product'
import clsx from 'clsx'
import PropTypes from 'prop-types'

export function Categories({ selected, toggleSelected }) {
  const [categories, setCategories] = useState([])
  useEffect(() => {
    if (categories.length === 0) {
      getCategories().then((list) => setCategories(list.categories))
    }
  }, [categories])

  return (
    <div className="categories">
      {categories.map(({ displayName, contentTypeKey }) => (
        <div
          key={contentTypeKey}
          onClick={() => toggleSelected(contentTypeKey)}
          className={clsx({
            'categories-item-active': (selected || []).includes(contentTypeKey),
          })}
        >
          {displayName}
        </div>
      ))}
    </div>
  )
}

Categories.propTypes = {
  selected: PropTypes.array.isRequired,
  toggleSelected: PropTypes.func.isRequired,
}

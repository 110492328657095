import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { toggleLike, toggleFavorite } from '../../api/blog'
import { makeUrl, simpleReducer } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { backOption as commentsBackScreens } from '../Comments/Comments'
import { Modal } from 'reactstrap'
import { Button } from '../Layout/Button/Button'
import { nbsp } from '../../constants/typography'
import { useLogicalFlow, logicalFlows } from '../../hooks/useLogicalFlow'
import { UserContext } from '../../contexts/userContext'
import { useIdsHash } from '../../hooks/useIdsHash'

const modalTypes = {
  authorizedAndUnsubscribed: 'authorizedAndUnsubscribed',
  notAuthorized: 'notAuthorized',
  authorizedAndNoEmail: 'authorizedAndNoEmail',
}

const favoriteIconsPermissions = logicalFlows.favoriteIconsPermissions

function FavoriteIcons({ post, influencerNick, isSubscribed }) {
  const { user, isFollower, isInfluencer, isAuthorized } = useContext(UserContext)
  const history = useHistory()
  const { setFlowData } = useLogicalFlow()
  const { idsHashData, hasPostLike, toggleLikedPosts, hasPostFavorite, toggleFavoritePosts } =
    useIdsHash()
  const matchRoute = {
    followerSaved: useRouteMatch(ROUTES.FOLLOWER_SAVED_POSTS),
    followerLiked: useRouteMatch(ROUTES.FOLLOWER_LIKED_POSTS),
  }

  const initialState = {
    countLikes: post.likesCount,
    countFavorites: post.favoritesCount,
    likesMarked: false,
    favoritesMarked: false,
    isModalOpen: false,
    modalType: null,
    clickType: null,
  }

  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (idsHashData) {
      setState({
        likesMarked: hasPostLike(post.id),
        favoritesMarked: hasPostFavorite(post.id),
      })
    }
  }, [idsHashData])

  const isFailPermissionsModal = (clickType) => {
    if (isAuthorized && post.influencerId === user?.influencer?.id) {
      return false
    } else if (isAuthorized && !user?.email) {
      setState({ isModalOpen: true, modalType: modalTypes.authorizedAndNoEmail, clickType })
      return true
    } else if (!isAuthorized) {
      setState({ isModalOpen: true, modalType: modalTypes.notAuthorized, clickType })
      return true
    } else if (isAuthorized && !isSubscribed) {
      setState({ isModalOpen: true, modalType: modalTypes.authorizedAndUnsubscribed, clickType })
      return true
    }
    return false
  }

  const handleClickLike = async () => {
    if (isFailPermissionsModal(favoriteIconsPermissions.types.like)) return
    if (isInfluencer) return

    try {
      const result = await toggleLike(post.id)
      if (result) {
        setState({
          countLikes: result.totalLikeCount,
          likesMarked: result.toggleLikeResult === appConfig.toggleIconsValues.setLike,
        })
        toggleLikedPosts(result.toggleLikeResult === appConfig.toggleIconsValues.setLike, post.id)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleClickFavorite = async () => {
    if (isFailPermissionsModal(favoriteIconsPermissions.types.favorite)) return
    if (isInfluencer) return

    try {
      const result = await toggleFavorite(post.id)
      if (result) {
        setState({
          countFavorites: result.totalFavoriteCount,
          favoritesMarked: result.toggleFavoriteResult === appConfig.toggleIconsValues.setFavorite,
        })
        toggleFavoritePosts(
          result.toggleFavoriteResult === appConfig.toggleIconsValues.setFavorite,
          post.id,
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleClickComment = () => {
    if (isFailPermissionsModal(favoriteIconsPermissions.types.comment)) return

    if (isAuthorized) {
      if (isFollower) {
        let backScreen
        if (matchRoute.followerLiked?.isExact) {
          backScreen = commentsBackScreens.followerLiked
        } else if (matchRoute.followerSaved?.isExact) {
          backScreen = commentsBackScreens.followerSaved
        }
        history.push(
          makeUrl({
            route: ROUTES.INSIDERS_NICK_COMMENTS,
            routeParams: { nick: influencerNick },
            params: {
              influencerId: post.influencerId,
              postId: post.id,
              ...(backScreen && { backScreen }),
            },
          }),
        )
      } else if (isInfluencer) {
        history.push(
          makeUrl({
            route: ROUTES.PROFILE_COMMENTS,
            params: { postId: post.id },
          }),
        )
      }
    }
  }

  const handleToggleModal = () => {
    setState({ isModalOpen: false, modalType: null })
  }

  const handleClickOk = () => {
    if (state.modalType === modalTypes.authorizedAndUnsubscribed) {
      history.push(
        makeUrl({
          route: ROUTES.SUBSCRIBE,
          params: { influencerId: post.influencerId },
        }),
      )
      return
    } else if (state.modalType === modalTypes.notAuthorized) {
      setFlowData(favoriteIconsPermissions.id, {
        isActive: true,
        postId: post.id,
        influencerNick,
        influencerId: post.influencerId,
        clickType: state.clickType,
      })
      history.push(makeUrl({ route: ROUTES.AUTH }))
      return
    } else if (state.modalType === modalTypes.authorizedAndNoEmail) {
      history.push(makeUrl({ route: ROUTES.EMAIL }))
      return
    }
    setState({ isModalOpen: false })
  }

  const handleClickCancel = () => {
    setState({ isModalOpen: false, modalType: null })
  }

  const getContentModal = () => {
    let okText, message
    okText = ''
    if (state.modalType === modalTypes.authorizedAndUnsubscribed) {
      message = 'Данная функция доступна только подписчикам'
      okText = 'Подписаться'
    } else if (state.modalType === modalTypes.notAuthorized) {
      message = 'Данная функция доступна только авторизованным пользователям с подпиской'
      okText = 'Авторизоваться'
    } else if (state.modalType === modalTypes.authorizedAndNoEmail) {
      message = 'Данная функция доступна только верифицированным пользователям с подпиской'
      okText = 'Подтвердить email'
    }

    return (
      <>
        {message}
        <div className="d-flex justify-content-between mt-3">
          <Button className="mr-3" onClick={handleClickOk}>
            {okText}
          </Button>
          <Button className="ml-3" color="grey" onClick={handleClickCancel}>
            Отмена
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="favoriteIcons mt-2">
      {post.likesCount !== undefined ? (
        <div onClick={handleClickLike}>
          <img
            src={`${appConfig.cdnPath.iconsSvg}/${
              state.likesMarked ? 'like-small-fill.svg' : 'like-grey.svg'
            }`}
            alt=""
            width="24"
            height="24"
          />
          <span>{state.countLikes > 0 && state.countLikes}</span>
        </div>
      ) : (
        <div className="empty">{nbsp}</div>
      )}
      {post.allowComments ? (
        <div onClick={handleClickComment}>
          <img src={`${appConfig.cdnPath.iconsSvg}/comment-grey.svg`} alt="" />
          <span>{post.commentCount > 0 && post.commentCount}</span>
        </div>
      ) : (
        <div className="empty">{nbsp}</div>
      )}
      <div onClick={handleClickFavorite}>
        <img
          src={`${appConfig.cdnPath.iconsSvg}/${
            state.favoritesMarked ? 'flag-green.svg' : 'flag-grey.svg'
          }`}
          alt=""
        />
        <span>{/*state.countFavorites > 0 && state.countFavorites*/}</span>
      </div>
      <Modal isOpen={state.isModalOpen} centered={true} toggle={handleToggleModal} size="sm">
        <div className="py-4">
          <div className="medium-13-20 text-color-regular text-center mt-1 p-2">
            {getContentModal()}
          </div>
        </div>
      </Modal>
    </div>
  )
}

FavoriteIcons.defaultProps = {
  post: {},
  isSubscribed: false,
}

FavoriteIcons.propTypes = {
  post: PropTypes.object.isRequired,
  influencerNick: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool,
}

export default FavoriteIcons

import React from 'react'
import { useHistory } from 'react-router-dom'
import { Header } from '../components/Header/Header'
import { ROUTES } from '../constants/routes'
import LinkWithArrow from '../components/Layout/LinkWithArrow/LinkWithArrow'
import { Footer } from '../components/Footer/Footer'

function AboutAppPage() {
  const history = useHistory()
  return (
    <>
      <Header title="Об Insider" needRight={false} to={ROUTES.FOLLOWER} />
      <div className="content content-padding menu-list">
        <LinkWithArrow
          onClick={() => history.push(ROUTES.AGREEMENT)}
          title="Пользовательское соглашение"
          className="menu-list-item"
        />
        <LinkWithArrow
          onClick={() => history.push(ROUTES.CONFIDENTIALITY)}
          title="Политика конфиденциальности"
          className="menu-list-item"
        />
        <LinkWithArrow
          onClick={() => history.push(ROUTES.OFFER_PAYMENT)}
          title="Оферта рекуррентных платежей"
          className="menu-list-item"
        />
        <LinkWithArrow
          onClick={() => history.push(ROUTES.FAQ)}
          title="Частые вопросы"
          className="menu-list-item"
        />
        <hr />
        <div className="semiBold-13-16 text-center text-color-green mt-4">Версия Insider 1.0</div>
      </div>
      <Footer />
    </>
  )
}

export default AboutAppPage

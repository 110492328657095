export const update = (key, value) => localStorage.setItem(key, JSON.stringify(value))

export const remove = (key) => localStorage.removeItem(key)

export const find = (key, initialValue = false) => {
  try {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : initialValue
  } catch (e) {
    return initialValue
  }
}

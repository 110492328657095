import * as React from 'react'
import { LogoSpinner } from '../../components/Layout'
import './ChangePassword.scss'

function ChangePassword() {
  const nickName = '{nickName}'
  return (
    <div className="template ChangePassword">
      <div className="header d-flex justify-content-center align-items-center">
        <LogoSpinner isLoading withLogo size={40} />
      </div>

      <div className="content">
        <hr className="mb-5" />
        <span className="bold-17-24">Привет {nickName}</span>
        <div className="mt-5 medium-13-20 text-color-regular">
          Мы получили запрос на смену пароля твоего аккаунта Insider.
        </div>
        <div className="mt-5 medium-13-20 text-color-regular d-flex justify-content-center  button-center">
          <a
            className="semiBold-15-24button-dark button-regular"
            target="_blank"
            rel="noreferrer"
            href="{ссылка для смены пароля}"
            type="button"
          >
            Сменить пароль
          </a>
        </div>
        <div className="mt-5 medium-13-20 text-color-regular">
          Если ты не отправлял(-а) такой запрос, пожалуйста, свяжись с нами по почте{' '}
          <a href="mailto:support@isider.space" className="text-color-green">
            support@isider.space
          </a>
          .
        </div>
        <div className="mt-5 medium-13-20 text-color-regular">
          С уважением,
          <br />
          команда Insider.
        </div>
      </div>

      <div className="footer">
        <hr className="mb-5" />
        <div className="mb-5">
          <span>ООО «Инсайдер Эпп», г. Москва, ул. Верейская, д. 29, стр. 33, эт. 3 ком. 12</span>
          <br />
          <a
            className="text-color-green"
            target="_blank"
            rel="noreferrer"
            href="{ссылка для отписки от рассылки}"
          >
            Отписаться от рассылки
          </a>
        </div>
      </div>
    </div>
  )
}
export default ChangePassword

import React, { useEffect, useReducer, useState, useCallback, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'
import { Search } from '../../components/Search/Search'
import { appConfig } from '../../constants/appConfig'
import { makeUrl, numberDeclension, simpleReducer } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { getCurrentSubscribers } from '../../api/influencer'
import { useLogicalFlow, logicalFlows } from '../../hooks/useLogicalFlow'
import { getInfluencerFullInfo } from '../../api/users'
import RandomSelect from '../../components/Profile/Influencer/FollowersList/RandomSelect'
import { find, remove, update } from '../../api/storage'
import FollowerListItem from '../../components/Profile/Influencer/FollowersList/FollowerListItem'
import { PageLoading } from '../../components/Layout/PageLoading'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useScroll } from '../../hooks/useScroll'
import { FixedSizeList as List } from 'react-window'
import { Button } from '../../components/Layout/Button/Button'
import { LogoSpinner } from '../../components/Layout'
import { UserContext } from '../../contexts/userContext'

const followersRandomString = [
  'случайный подписчик',
  'случайных подписчика',
  'случайных подписчиков',
]
const modes = {
  random: 'random',
  top: 'top',
  show: 'show',
}
export const storageKey = 'followerListParams'

function InfluencerFollowerListPage() {
  const history = useHistory()
  const location = useLocation()
  const { getFlowData, setFlowData, delFlowData } = useLogicalFlow()
  const { userType, influencerId } = useContext(UserContext)

  const initialParams = find(storageKey)

  const initialState = {
    randomCount: '',
    topPercent: '',
    mode: modes.show,
    search: '',
    searchText: '',
    isLoading: false,
    ...initialParams,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const loadData = async () => {}

  const [page, setPage] = useState(0)
  const countPerPage = 50
  const { data: influencerData, isLoading } = useQuery(
    ['influencer', influencerId],
    () => getInfluencerFullInfo(influencerId),
    {},
  )

  const { isFetching, data, fetchNextPage } = useInfiniteQuery(
    `loadFollowers-${state.randomCount}-${state.search}`,
    (key, pageParam) => {
      return getCurrentSubscribers({
        page: pageParam,
        perPage: countPerPage,
        countOfRandomSubscribers: state.randomCount,
        search: state.search,
      })
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )
  useEffect(() => {
    getFlowData(
      logicalFlows.followerList.id,
      (ldata) => {
        if (ldata?.state) {
          setState({ ...ldata.state })
          delFlowData(logicalFlows.followerList.id)
        }
      },
      () => {
        loadData()
      },
    )
  }, [])

  const handleRandomSearch = (count) => {
    if (count > 0) {
      setState({ search: '', searchText: '', mode: modes.random, randomCount: count })
      loadData(count)
    }
  }

  const handleTopSearch = (percent) => {
    if (percent > 0) {
      setState({
        search: '',
        searchText: '',
        mode: modes.top,
        topPercent: percent,
      })
    }
  }

  const handleBack = () => {
    if (state.mode === modes.show) {
      history.push(makeUrl({ route: ROUTES.PROFILE }))
    } else {
      remove(storageKey)
      setState({
        mode: modes.show,
        randomCount: '',
        search: '',
        searchText: '',
        topPercent: '',
      })
    }
  }

  const handleClickFollower = (followerNick) => {
    setFlowData(logicalFlows.followerList.id, { state })
    history.push(makeUrl({ route: ROUTES.PROFILE_FOLLOWER, routeParams: { followerNick } }))
  }

  const handleClickChat = (followerId) => {
    update(appConfig.storage.keys.backFromChats, location.pathname)
    setFlowData(logicalFlows.followerList.id, { state })
    history.push(
      makeUrl({
        route: ROUTES.PROFILE_CHATS,
        routeParams: { userType: userType.toLowerCase(), interlocutorId: followerId },
      }),
    )
  }

  const fetchNextFollowersPage = useCallback(() => {
    if (data && data[data.length - 1]?.length === countPerPage) {
      const nextPage = page + 1
      fetchNextPage({ pageParam: nextPage })
      setPage(nextPage)
    }
  }, [fetchNextPage, page, data])

  useScroll({
    onReachedBottom: () => {
      if (!isFetching) {
        fetchNextFollowersPage()
      }
    },
  })

  useEffect(() => {
    if (data?.length && (state.randomCount || state.topPercent)) {
      update(storageKey, {
        topPercent: state.topPercent,
        randomCount: state.randomCount,
        mode: state.mode,
      })
    }
  }, [data, state.mode, state.randomCount, state.topPercent])

  if (isLoading || (isFetching && !data)) {
    return <PageLoading hasFooter hasHeader />
  }

  const followers = [
    ...new Set(
      (data || [])
        .flat()
        .filter((item) => item)
        .filter(
          (follower) => !state.topPercent || follower.followerScore <= parseInt(state.topPercent),
        ),
    ),
  ]
  return (
    <>
      <Header
        title="Подписчики"
        needRight={true}
        needLeft={true}
        onBackAction={handleBack}
        rightBlock={
          <div className="d-flex align-items-center">
            <Button
              color="link"
              onClick={() => history.push(ROUTES.PROFILE_RANDOM_LIST)}
              className="mr-2"
            >
              <img src={`${appConfig.cdnPath.icons}/calendar.png`} alt="" width={20} height={20} />
            </Button>
            <div className="followers-count mr-2">{influencerData.followersCount || null}</div>
            <img src={`${appConfig.cdnPath.iconsSvg}/people-gray.svg`} alt="" />
          </div>
        }
      />
      <div className="follower-list-wrapper">
        <div className="subheader">
          <div className="content-padding">
            <Search
              value={state.searchText}
              onChange={(text) => setState({ searchText: text })}
              placeholder="Поиск по подписчикам"
              filterMode={false}
              onSearch={(search) => setState({ search })}
              onClear={() => setState({ search: '', searchText: '' })}
            />
            {state.mode === modes.show && (
              <>
                <RandomSelect
                  initialValue={state.randomCount}
                  afterInputText="случайных подписчиков"
                  onSelect={handleRandomSearch}
                />
                <RandomSelect
                  initialValue={state.topPercent}
                  beforeInputText="Выбрать топ"
                  afterInputText="% подписчиков"
                  onSelect={handleTopSearch}
                />
              </>
            )}
            {state.mode === modes.random && (
              <div className="random-select">
                Выбрано{' '}
                {`${state.randomCount} ${numberDeclension(
                  state.randomCount,
                  followersRandomString,
                )}`}
              </div>
            )}
            {state.mode === modes.top && (
              <div className="random-select">Выбран топ {state.topPercent}% подписчиков</div>
            )}
          </div>
        </div>
      </div>
      <div className="content content-padding influencer-follower-list">
        <div className="follower-list">
          <List
            height={followers.length * 60}
            itemCount={followers.length}
            itemSize={60}
            width="100%"
          >
            {({ index, style }) => (
              <div style={style}>
                <FollowerListItem
                  follower={followers[index]}
                  onClickChat={handleClickChat}
                  onClickFollower={handleClickFollower}
                />
              </div>
            )}
          </List>
          {isFetching && followers?.length > 0 && (
            <div className="my-2">
              <LogoSpinner isLoading size={25} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default InfluencerFollowerListPage

import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import { InfluencerAvatar } from '../InfluencerAvatar'
import { nbsp } from '../../../constants/typography'
import { numberDeclension, simpleReducer } from '../../../helpers'
import { Tabs } from '../Tabs'
import { find, remove } from '../../../api/storage'
import { appConfig } from '../../../constants/appConfig'
import { formatDate } from '../../../helpers/time'
import { logicalFlows, useLogicalFlow } from '../../../hooks/useLogicalFlow'
import InfluencerButtons from './InfluencerButtons'

export function InfluencerContent({
  bgUrl,
  photoUrl,
  name,
  followersCount,
  shortDescription,
  subscribed,
  oneMonthCost,
  about,
  socialLinks,
  author,
  influencerId,
  defaultTab,
  publishedPostsCount,
}) {
  const { getFlowData, setFlowData } = useLogicalFlow()
  const initialState = {
    modalTitle: '',
    modalText: '',
    modalIsOpen: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    getFlowData(logicalFlows.paymentFinish.id, (result) => {
      if (result?.type === logicalFlows.paymentFinish.types.donateSuccess) {
        setState({
          modalTitle: `Донат инфлюенсеру ${author}`,
          modalText: 'Оплата выполнена успешно',
          modalIsOpen: true,
        })
        setFlowData(logicalFlows.paymentFinish.id, null)
      }
    })
  }, [])

  useEffect(() => {
    // @TODO Перевести модалку оплаты подписки на механизм getFlowData()
    if (author) {
      let status = find(appConfig.storage.keys.status)
      if (status) {
        if (status !== appConfig.payment.statuses.charged) {
          setState({
            modalTitle: 'Ошибка оплаты',
            modalText: 'Попробуйте повторить оплату позже',
            modalIsOpen: true,
          })
        } else {
          const expirationDate = find(appConfig.storage.keys.expirationDate)
          setState({
            modalTitle: 'Подписка успешно оформлена!',
            modalText: expirationDate
              ? `Ваша подписка действует до ${formatDate(expirationDate)}`
              : '',
            modalIsOpen: true,
          })
          window.ym(81892699, 'reachGoal', 'subscription')
        }
        remove(appConfig.storage.keys.status)
        remove(appConfig.storage.keys.expirationDate)
      }
    }
  }, [author])

  return (
    <>
      <InfluencerAvatar bgUrl={bgUrl} photoUrl={photoUrl} influencerId={influencerId} />
      <div className="d-flex justify-content-between content-margin">
        <span className="d-flex bold-17-24">{name}</span>
        {followersCount !== null && followersCount >= 0 && (
          <span className="d-flex medium-13-20 text-color-regular">
            <b className="text-color-sub-title">{followersCount}</b>
            {nbsp}
            {numberDeclension(followersCount, ['подписчик', 'подписчика', 'подписчиков'])}
          </span>
        )}
      </div>
      <div className="mt-2 medium-13-20 text-color-regular content-margin">{shortDescription}</div>
      <InfluencerButtons
        influencerId={influencerId}
        subscribed={subscribed}
        oneMonthCost={oneMonthCost}
        author={author}
      />
      <Tabs
        products={[]}
        about={about}
        socialLinks={socialLinks}
        publishedPostsCount={publishedPostsCount}
        isSubscribed={subscribed === -1 ? false : subscribed}
        avatar={photoUrl}
        influencerId={influencerId}
        influencerNick={author}
        defaultTab={defaultTab}
      />
      <Modal
        isOpen={state.modalIsOpen}
        centered={true}
        toggle={() => setState({ modalIsOpen: false })}
        size="sm"
      >
        <div className="py-4">
          <div className="semiBold-15-24">{state.modalTitle}</div>
          <div className="medium-13-20 text-color-regular text-center mt-1">{state.modalText}</div>
        </div>
      </Modal>
    </>
  )
}

InfluencerContent.propTypes = {
  bgUrl: PropTypes.any,
  photoUrl: PropTypes.any,
  name: PropTypes.any,
  followersCount: PropTypes.any,
  shortDescription: PropTypes.any,
  subscribed: PropTypes.bool,
  oneMonthCost: PropTypes.any,
  onClick: PropTypes.func,
  products: PropTypes.any,
  about: PropTypes.any,
  socialLinks: PropTypes.any,
  posts: PropTypes.any,
  author: PropTypes.any,
  influencerId: PropTypes.any,
  defaultTab: PropTypes.any,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  hasMorePosts: PropTypes.bool,
  publishedPostsCount: PropTypes.number,
}

import React, { useState, lazy, Suspense, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { About } from './Tabs/About'
import clsx from 'clsx'
import { appConfig } from '../../constants/appConfig'
import { LogoSpinner } from '../Layout'
import queryString from 'query-string'
import { getFreePosts, getPosts } from '../../api/blog'
import { useInfiniteQuery } from 'react-query'
import { UserContext } from '../../contexts/userContext'
const Posts = lazy(() => import('./Tabs/Posts'))
const Marketplace = lazy(() => import('./Tabs/Marketplace'))
const tabKeys = appConfig.tabCodes

export function Tabs({
  about,
  products,
  avatar,
  influencerId,
  influencerNick,
  isSubscribed,
  socialLinks = null,
  publishedPostsCount,
}) {
  const { user } = useContext(UserContext)
  const { tabName: defaultTab, postId } = queryString.parse(window.location.search)
  const [currentTab, setCurrentTab] = useState(
    Object.values(tabKeys).includes(defaultTab) ? defaultTab : tabKeys.posts,
  )
  const countPages = Math.ceil(publishedPostsCount / appConfig.posts.countPerPage)
  const [postPage, setPostPage] = useState(countPages > 0 ? countPages - 1 : 0)
  const [currentPostId, setCurrentPostId] = useState(null)

  const getPostsData = isSubscribed ? getPosts : getFreePosts

  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    `influencerPosts-${influencerId}`,
    (key, pageParam = countPages > 0 ? countPages - 1 : 0) => {
      return getPostsData({
        influencerId,
        page: pageParam,
        user,
      })
    },
    {
      enabled: !!influencerId,
    },
  )

  const loadNextPosts = useCallback(() => {
    if (postPage > 0) {
      const pageParam = postPage - 1
      fetchNextPage({ pageParam })
      setPostPage(pageParam)
    }
  }, [fetchNextPage, postPage])

  useEffect(() => {
    // нужно чтобы было меньше 3 постов на экране чтобы принудительно подгрузить следующую страницу, иначе ничего не грузится
    if (data?.length === 1 && data[0]?.length <= 3 && countPages > 1) {
      loadNextPosts()
    }
  }, [data, countPages])

  useEffect(() => {
    if (data?.length > 0 && postId && !currentPostId) {
      const currentPost = data?.flat()?.find((post) => post.id === postId)
      if (currentPost) {
        setCurrentPostId(postId)
      } else {
        loadNextPosts()
      }
    }
  }, [data, postId, currentPostId])

  return (
    <>
      <div className="d-flex flex-row justify-content-between mt-2 content-margin" role="tablist">
        <div
          onClick={() => setCurrentTab(tabKeys.about)}
          onKeyDown={() => {}}
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', { 'tab-active': currentTab === tabKeys.about })}
          role="tab"
          aria-selected={currentTab === tabKeys.about}
        >
          Об авторе
        </div>
        <div
          onClick={() => setCurrentTab(tabKeys.posts)}
          onKeyDown={() => {}}
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', { 'tab-active': currentTab === tabKeys.posts })}
          role="tab"
          aria-selected={currentTab === tabKeys.posts}
        >
          Лента
        </div>
        <div
          onClick={() => setCurrentTab(tabKeys.marketplace)}
          onKeyDown={() => {}}
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': currentTab === tabKeys.marketplace,
          })}
          role="tab"
          aria-selected={currentTab === tabKeys.marketplace}
        >
          Маркетплейс
        </div>
      </div>
      <div className="about">
        {currentTab === tabKeys.about && (
          <About about={about} socialLinks={socialLinks} influencerNick={influencerNick} />
        )}
        {currentTab === tabKeys.posts && (
          <Suspense fallback={<LogoSpinner isLoading />}>
            <Posts
              currentPostId={currentPostId}
              posts={data}
              isSubscribed={isSubscribed}
              avatar={avatar}
              author={influencerNick}
              influencerId={influencerId}
              influencerNick={influencerNick}
              loadNextPosts={loadNextPosts}
              hasMorePosts={postPage > 0}
              isLoading={isFetching}
            />
          </Suspense>
        )}
        {currentTab === tabKeys.marketplace && (
          <Suspense fallback={<LogoSpinner isLoading />}>
            <Marketplace products={products} />
          </Suspense>
        )}
      </div>
    </>
  )
}

Tabs.defaultProps = {
  posts: undefined,
}
Tabs.propTypes = {
  about: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  products: PropTypes.array.isRequired,
  avatar: PropTypes.string.isRequired,
  influencerId: PropTypes.string.isRequired,
  influencerNick: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  socialLinks: PropTypes.any,
  publishedPostsCount: PropTypes.number,
  hasMorePosts: PropTypes.bool,
}

import { api } from './api'
import { useQuery } from 'react-query'

const DOCUMENT_KEY = 'documents'

export const getDocument = (type) => {
  return api.get({
    path: `/insider/document/${type}`,
  })
}

export const useDocuments = ({ type }, options) => {
  return useQuery([DOCUMENT_KEY, type], () => getDocument(type), options)
}

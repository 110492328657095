import React from 'react'
import { Button } from '../Layout/Button/Button'
import PropTypes from 'prop-types'

function CloseInfoForm({ onClose, message, className }) {
  return (
    <div className={`py-4 ${className}`}>
      <div className="semiBold-14-20 text-color-regular text-center mt-1 p-2">
        {message}
        <div className="d-flex justify-content-center">
          <div style={{ width: '100px' }}>
            <Button className="mt-3 semiBold-14-20" color="grey" onClick={onClose}>
              Закрыть
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

CloseInfoForm.defaultProps = {
  onClose: () => {},
  message: '',
  className: '',
}

CloseInfoForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
}

export default CloseInfoForm

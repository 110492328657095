import React, { useEffect, useState } from 'react'
import { formatSeconds } from '../../../helpers/time'
import { appConfig } from '../../../constants/appConfig'
import PropTypes from 'prop-types'

export function RemoveButton({ onClick }) {
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    const timerId = setInterval(() => setSeconds(seconds + 1), 1000)

    return () => clearInterval(timerId)
  })
  return (
    <>
      <span className="medium-12-24 text-color-regular">{formatSeconds(seconds)}</span>
      <img
        className="play-icon"
        src={`${appConfig.cdnPath.iconsSvg}/voiceDel.svg`}
        alt=""
        onClick={onClick}
      />
    </>
  )
}

RemoveButton.propTypes = {
  seconds: PropTypes.number,
  onClick: PropTypes.func,
}

import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import { getAvatars, deleteAvatar } from '../../../api/users'
import { ROUTES } from '../../../constants/routes'
import { Header } from '../../Header/Header'
import { appConfig } from '../../../constants/appConfig'
import { simpleReducer } from '../../../helpers'
import UserAvatar from '../UserAvatar'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { UserContext } from '../../../contexts/userContext'

function SelectImage({ onSelect }) {
  const { influencerId } = useContext(UserContext)
  const initialState = {
    influencerAvatar: null,
    avatars: [],
    needReload: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const size = useWindowSize()
  const loadAvatars = async () => {
    try {
      const { influencerAvatarLink } = await getAvatars()
      const defaultAvatar =
        Object.values(influencerAvatarLink)
          .filter(({ isDefault }) => isDefault)
          .pop() || Object.values(influencerAvatarLink).pop()

      setState({
        avatars: influencerAvatarLink,
        influencerAvatar: defaultAvatar?.influencerAvatarLink,
        needReload: false,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadAvatars()
  }, [])

  const getAvatar = (link) =>
    state.avatars
      .filter(({ influencerAvatarLink }) => 'https://' + influencerAvatarLink === link)
      .pop() || {}

  const selectAvatar = async () => {
    const { fileGuid } = getAvatar(state.influencerAvatar)
    if (fileGuid) {
      onSelect(fileGuid)
    }
  }

  const handleDelete = async () => {
    if (influencerId) {
      const { fileGuid } = getAvatar(state.influencerAvatar)
      if (fileGuid) {
        await deleteAvatar(influencerId, fileGuid)
        const list = state.avatars.filter((item) => item.fileGuid !== fileGuid)
        setState({ needReload: true, avatars: list })
      }
    }
  }

  useEffect(() => {
    if (state.needReload) {
      loadAvatars()
    }
  }, [state.needReload])

  return (
    <>
      <div className="layout3 select-avatar">
        <Header title="Фото профиля" needRight={false} to={ROUTES.PROFILE} isWhiteTheme />
        <div
          className="layout3-content justify-content-center align-items-center d-flex flex-column"
          style={{ backgroundColor: 'none', height: (size.height || 170) - 170 }}
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 'calc(100% - 50px)' }}
          >
            <UserAvatar
              src={state.influencerAvatar}
              round={false}
              showEmptyOnLoad={false}
              size={480}
              className="mb-3 img-fluid-height"
            />
          </div>
          <div className="avatar-list-block">
            <div className="avatar-list" style={{ height: '50px' }}>
              {state.avatars.map(({ fileGuid, influencerAvatarLink }) => (
                <div
                  key={fileGuid}
                  onClick={() => setState({ influencerAvatar: `https://${influencerAvatarLink}` })}
                  className="ml-1"
                >
                  <UserAvatar
                    src={influencerAvatarLink}
                    size={44}
                    round={false}
                    showEmptyOnLoad={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <footer className="layout3-footer flex-column">
          <img
            src={`${appConfig.cdnPath.iconsSvg}/basket.svg`}
            className="avatar-delete"
            onClick={handleDelete}
            alt=""
          />
          <div className="upload-button w-100" onClick={selectAvatar}>
            Выбрать
          </div>
        </footer>
      </div>
    </>
  )
}

SelectImage.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default SelectImage

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from '../../../Layout/Input/Input'
import { Button } from '../../../Layout/Button/Button'
import { appConfig } from '../../../../constants/appConfig'
import clsx from 'clsx'

function RandomSelect({
  initialValue,
  onSelect,
  onChange,
  beforeInputText = 'Выбрать',
  afterInputText = '',
  inputClassName,
}) {
  const [value, setValue] = useState(initialValue)
  return (
    <div className="random-select">
      <div className="d-flex align-items-center">
        {beforeInputText}
        <div className="input-wrapper">
          <Input
            type="number"
            value={value}
            placeholder=""
            onChange={(value) => {
              const clearValue = value?.replace(/([^\d])/, '')
              if (onChange) {
                onChange(clearValue)
              }
              setValue(clearValue)
            }}
            inputClassName={clsx('random-input', inputClassName)}
            hideDeleteIcon
          />
        </div>
        {afterInputText}
      </div>
      <div className="button-wrapper flex-row d-flex">
        <Button onClick={() => onSelect(value)}>
          <img
            src={`${appConfig.cdnPath.iconsSvg}/white-arrow-right.svg`}
            alt=""
            width="6px"
            height="12px"
          />
        </Button>
      </div>
    </div>
  )
}
export default RandomSelect

RandomSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  beforeInputText: PropTypes.string,
  afterInputText: PropTypes.string,
  inputClassName: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
}

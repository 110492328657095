import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Header } from '../Header/Header'
import { Button } from '../Layout/Button/Button'
import { setNickname } from '../../api/users'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { logicalFlows, useLogicalFlow } from '../../hooks/useLogicalFlow'
import { simpleReducer } from '../../helpers'
import { redirectFollowerToPostOrInfluencer } from '../../helpers/logicalFlow'
import { Modal } from 'reactstrap'
import { UserContext } from '../../contexts/userContext'
import { refresh } from '../../api/auth'

function AddName({ isEdit }) {
  const { isFollower, user, setUser } = useContext(UserContext)
  const initialState = {
    name: '',
    logicalFlowData: null,
    isOpenModal: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const history = useHistory()
  const { getFirstFlowData, setFlowData } = useLogicalFlow()

  useEffect(() => {
    if (isEdit) {
      if (isFollower) {
        setState({ name: user?.follower?.nickName || '' })
      }
    } else {
      getFirstFlowData(
        [logicalFlows.subscribe.id, logicalFlows.favoriteIconsPermissions.id],
        (result) => {
          setState({ logicalFlowData: result })
        },
      )
    }
  }, [isEdit, isFollower])

  const handleSetNick = async () => {
    try {
      await setNickname(state.name?.trim())
      let { user } = await refresh()
      setUser(user)
      if (isEdit) {
        history.push(ROUTES.FOLLOWER_ACCOUNT)
      } else {
        if (state.logicalFlowData?.isActive) {
          setFlowData(state.logicalFlowData?.key, null)
          redirectFollowerToPostOrInfluencer({
            influencerNick: state.logicalFlowData.influencerNick,
            influencerId: state.logicalFlowData.influencerId,
            subscribeInfluencerId: state.logicalFlowData.subscribe,
            history,
            user,
          })
        } else {
          history.push(ROUTES.MAIN)
        }
      }
    } catch (e) {
      console.log(e)
      setState({ isOpenModal: true })
    }
  }
  return (
    <>
      <div className="auth">
        <Header
          title="Учетная запись"
          needRight={false}
          to={isEdit ? ROUTES.FOLLOWER_ACCOUNT : ROUTES.AUTH}
        />
        <h1 className="bold-24-32 mb-4">Ваш профиль</h1>
        <span className="text-color-regular medium-13-20 mb-2">
          Пожалуйста, {isEdit ? 'введите' : 'добавьте'} ник
        </span>

        <input
          value={state.name}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSetNick()
              return
            }
            const charCode = event.key.charCodeAt(0)
            return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)
          }}
          type="text"
          className="auth-name"
          placeholder="Ник"
          autoFocus
          onChange={(e) => {
            setState({ name: e.target.value.replace(/[^A-z0-9_]/g, '') })
          }}
        />
        <div className="text-color-regular text-center pt-1 text-subscript">
          Ник состоит из латинских букв и цифр
        </div>

        <Button
          className="mt-4 semiBold-15-24"
          onClick={handleSetNick}
          disable={!state.name?.trim()}
        >
          {isEdit ? 'Сохранить' : 'Продолжить'}
        </Button>
        <Modal
          isOpen={state.isOpenModal}
          toggle={() => setState({ isOpenModal: false })}
          centered={true}
          size="sm"
        >
          <div className="py-4">
            <div className="medium-13-20 text-color-regular text-center mt-1 p-2">
              Ник уже занят. <br />
              Ник должен состоять только из латинских букв/цифр
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

AddName.defaultProps = {
  isEdit: false,
}

AddName.propTypes = {
  isEdit: PropTypes.bool,
}

export default AddName

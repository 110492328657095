import React, { useEffect, useReducer } from 'react'
import queryString from 'query-string'
import moment from 'moment'
import { ROUTES } from '../../constants/routes'
import { Footer } from '../../components/Footer/Footer'
import { Header } from '../../components/Header/Header'
import clsx from 'clsx'
import { makeUrl, simpleReducer } from '../../helpers'
import { nbsp, rub } from '../../constants/typography'
import { getSubscriptionsFollower } from '../../api/subscribe'
import { appConfig } from '../../constants/appConfig'
import { Modal } from 'reactstrap'
import { find } from '../../api/storage'
import { formatDate } from '../../helpers/time'
import { useHistory } from 'react-router-dom'
import UserAvatar from '../../components/Profile/UserAvatar'

const TAB_ACTIVE_LIST_KEY = 'active'
const TAB_INACTIVE_LIST_KEY = 'inactive'
const { periodLabels } = appConfig.subscribe

function FollowerSubscriptionsPage() {
  const history = useHistory()
  const initialState = {
    currentTab: TAB_ACTIVE_LIST_KEY,
    subscriptionList: [],
    modalIsOpen: false,
    expirationDate: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const loadData = async () => {
    const { subscriptions } = await getSubscriptionsFollower()
    let expirationDate = find(appConfig.storage.keys.expirationDate)
    if (expirationDate) {
      expirationDate = formatDate(expirationDate)
    }
    setState({
      subscriptionList: subscriptions,
      ...(expirationDate && { expirationDate, modalIsOpen: true }),
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const renderSubscriptions = () => {
    const now = moment() // test date '2021-06-20'
    if (state.currentTab === TAB_ACTIVE_LIST_KEY) {
      return state.subscriptionList
        ?.filter((item) => moment(item.expirationDate, 'DD.MM.YYYY').isAfter(now))
        .map((item, index) => renderItem(item, TAB_ACTIVE_LIST_KEY, index))
    } else {
      return state.subscriptionList
        ?.filter((item) => moment(item.expirationDate, 'DD.MM.YYYY').isBefore(now))
        .map((item, index) => renderItem(item, TAB_INACTIVE_LIST_KEY, index))
    }
  }

  const handleChangeSubscription = (item) => {
    history.push(
      `${ROUTES.FOLLOWER_SUBSCRIBE}?${queryString.stringify({
        influencerId: item.influencerId,
      })}`,
    )
  }

  const handleChangeCard = () => {
    history.push(makeUrl({ route: ROUTES.FOLLOWER_PAYMENT_CARDS }), {
      backTo: window.location.pathname,
    })
  }

  const renderDescription = (item, type) => {
    const expirationDate = formatDate(item.expirationDate)
    if (type === TAB_ACTIVE_LIST_KEY) {
      if (item.isRecurrent) {
        return (
          <>
            Следующий платеж будет списан <b>{expirationDate}</b>
          </>
        )
      } else {
        return <div className="error">Автопродление подписки выключено</div>
      }
    } else {
      return (
        <>
          Дата окончания подписки <b>{expirationDate}</b>
        </>
      )
    }
  }

  const renderItem = (item, type, index) => {
    return (
      <div key={index} className="follower-subscriptions-item">
        <div className="top">
          <UserAvatar src={item.avatarUrl} size={44} />
          <div className="info">
            <div className="title">{item.influencerNickname}</div>
            {type === TAB_ACTIVE_LIST_KEY ? (
              <div className="status mt-1">Действует до {`${formatDate(item.expirationDate)}`}</div>
            ) : (
              <div className="error mt-1">Подписка приостановлена</div>
            )}
          </div>
        </div>
        <div className="extra-info">
          <div className="description">{renderDescription(item, type)}</div>
          <div className="cost">
            {periodLabels[item.subscriptionType]} / {item.subscriptionCost} {rub}
            {nbsp}
            <span className="btnChange ml-2" onClick={() => handleChangeSubscription(item)}>
              Изменить
            </span>
          </div>
        </div>
        <div className="payment">
          {item.pan ? (
            <>
              <div className="title">Способ оплаты</div>
              <div>
                <span>Карта{` ${item.cardType || ''}`}</span>, последние цифры{' '}
                <span>{`${item.pan?.substring(item.pan?.length - 4) || ''}`}</span>
                <br />
                {type === TAB_ACTIVE_LIST_KEY && item.cardExpirationDate && (
                  <>
                    Срок действия истекает <span>{item.cardExpirationDate}</span>
                    <br />
                  </>
                )}
                <span className="btnChange" onClick={handleChangeCard}>
                  Изменить
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="mt-3">Карта не привязана</div>
              <span className="btnChange" onClick={handleChangeCard}>
                Изменить
              </span>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <Header title="Управление подписками" needRight={false} to={ROUTES.FOLLOWER} />
      <div className="d-flex flex-row justify-content-between mt-2 content-margin">
        <div
          onClick={() => setState({ currentTab: TAB_ACTIVE_LIST_KEY })}
          role="tab"
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': state.currentTab === TAB_ACTIVE_LIST_KEY,
          })}
        >
          Действующие
        </div>
        <div
          onClick={() => setState({ currentTab: TAB_INACTIVE_LIST_KEY })}
          role="tab"
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': state.currentTab === TAB_INACTIVE_LIST_KEY,
          })}
        >
          Не действующие
        </div>
      </div>
      <div className="content content-padding follower-subscriptions footer-padding">
        {renderSubscriptions()}
      </div>
      <Footer />
      <Modal
        isOpen={state.modalIsOpen}
        centered={true}
        toggle={() => setState({ modalIsOpen: false })}
        size="sm"
      >
        <div className="py-4">
          <div className="semiBold-15-24">Подписка успешно оформлена!</div>
          <div className="medium-13-20 text-color-regular text-center mt-1">
            Ваша подписка действует до {state.expirationDate}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default FollowerSubscriptionsPage

import { api } from './api'
import { appConfig } from '../constants/appConfig'
import { getPrivateParamsByUser } from './users'

export const getRecordsCount = async (influencerId) => {
  return api.get({
    path: `/insider/blog-posts/record-count/${influencerId}`,
  })
}

export const getPosts = async ({
  influencerId,
  page,
  user,
  countPerPage = appConfig.posts.countPerPage,
  ignorePins = false,
}) => {
  const { md5, e } = getPrivateParamsByUser(influencerId, user)
  const { blogPosts: posts } = await api.get({
    path: `/insider/private/influencer/${influencerId}/blog-posts/page?page=${page}&countPerPage=${countPerPage}&md5=${md5}&e=${e}${
      ignorePins ? '&ignorePins=true' : ''
    }`,
  })
  return posts
}

export const getFreePosts = async ({
  influencerId,
  page,
  countPerPage = appConfig.posts.countPerPage,
  ignorePins = false,
}) => {
  const { blogPosts: posts } = await api.get({
    path: `/insider/free-posts/page/${influencerId}/${page}/${countPerPage}${
      ignorePins ? '?ignorePins=true' : ''
    }`,
  })
  return posts
}

export const getMediaLink = async (data) => {
  return api.post({
    path: `/insider/blog-posts/media-link`,
    data,
  })
}

export const toggleLike = async (blogPostId) => {
  return api.put({
    path: `/insider/blog-posts/${blogPostId}/toggle-like`,
  })
}

export const toggleFavorite = async (blogPostId) => {
  return api.put({
    path: `/insider/blog-posts/${blogPostId}/toggle-favorite`,
  })
}

export const addPost = async (data) => {
  return api.post({
    path: `/insider/blog-posts`,
    data,
  })
}

export const finishPost = async (blogPostId, mainImageId, data) => {
  return api.put({
    path: `/insider/blog-posts/${blogPostId}/finish-edit${
      mainImageId ? `?mainImageId=${mainImageId}` : ''
    }`,
    data,
    isDirect: true,
  })
}

export const getPostById = async (postId) => {
  return api.get({
    path: `/insider/blog-posts/${postId}`,
  })
}

export const deleteEditPost = async (postId) => {
  return api.del({
    path: `/insider/blog-posts/${postId}/delete-edit`,
    isDirect: true,
  })
}

export const deleteMediaInPost = async (postId, mediaContentId) => {
  return api.del({
    path: `/insider/media/${postId}/${mediaContentId}`,
    isDirect: true,
  })
}

export const setPostTitle = async (blogPostId, title, text) => {
  return api.put({
    path: `/insider/blog-posts/${blogPostId}/title`,
    data: { title, text },
  })
}

export const setPostTeaser = async (blogPostId, teaser, allowComments, isFree) => {
  return api.put({
    path: `/insider/blog-posts/${blogPostId}/teaser`,
    data: { teaser, allowComments, isFree },
  })
}

export const setPostPublishDate = async (blogPostId, { dateTimeToUpdate }) => {
  return api.put({
    path: `/insider/blog-posts/${blogPostId}/update-publish-date`,
    data: { dateTimeToUpdate },
  })
}

export const uploadFile = (blogPostId, contentType, content) => {
  return api.post({
    path: `/insider/blog-posts/upload-file`,
    data: { blogPostId, contentType, content },
  })
}

export const getMyPosts = async (page = 0, countPerPage = appConfig.posts.countPerPage) => {
  const params = new URLSearchParams({
    page: page.toString(),
    countPerPage: countPerPage.toString(),
  })
  const { blogPosts: posts } = await api.get({
    path: `/insider/blog-posts/my-posts?${params.toString()}`,
  })
  return posts
}

export const getLikedPosts = async (countPerPage = appConfig.posts.countPerPage) => {
  const params = new URLSearchParams({ postsPerPage: countPerPage })
  return await api.get({
    path: `/insider/blog-posts/liked-posts?${params.toString()}`,
  })
}

export const getFavoritePosts = async (countPerPage = appConfig.posts.countPerPage) => {
  const params = new URLSearchParams({ postsPerPage: countPerPage })
  return await api.get({
    path: `/insider/blog-posts/favorite-posts?${params.toString()}`,
  })
}

export const togglePinned = async (blogPostId) => {
  return await api.put({
    path: `/insider/blog-posts/${blogPostId}/toggle-pinned`,
  })
}

export const deletePost = async (blogPostId) => {
  return await api.del({
    path: `/api/blog-posts/${blogPostId}`,
    isDirect: true,
  })
}

export const getLikedPostsList = async () => {
  return await api.get({
    path: `/insider/blog-posts/liked-posts-list`,
  })
}

export const getFavoritePostsList = async () => {
  return await api.get({
    path: `/insider/blog-posts/favorite-posts-list`,
  })
}

export const getLikedCommentsList = async () => {
  return await api.get({
    path: `/insider/blog-posts/liked-comments-list`,
  })
}

import React, { useReducer, useContext, useMemo } from 'react'
import { generatePath, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { appConfig } from '../../constants/appConfig'
import { simpleReducer, isLocationEqual, getUnreadMessagesCountString } from '../../helpers'
import { Modal } from 'reactstrap'
import { ChatContext } from '../../contexts/chatContext'
import { Button } from '../Layout/Button/Button'
import { UserContext } from '../../contexts/userContext'

export function Footer() {
  let matchChats = useRouteMatch(ROUTES.PROFILE_CHATS)
  let matchProfile = useRouteMatch(ROUTES.PROFILE)
  const location = useLocation()
  const history = useHistory()
  const initialState = {
    isModalOpen: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { unreadMessagesCount, unreadMessagesCountVip } = useContext(ChatContext)
  const { user, userType, isFollower, isInfluencer, isAuthorized } = useContext(UserContext)

  const existNotifications = useMemo(() => {
    if (isFollower) {
      return user?.follower?.unreadNotificationCount > 0
    }
    if (isInfluencer) {
      return user?.influencer?.unreadNotificationCount > 0
    }
  }, [
    isFollower,
    isInfluencer,
    user.follower?.unreadNotificationCount,
    user.influencer?.unreadNotificationCount,
  ])

  const unreadCount = useMemo(
    () => unreadMessagesCount + unreadMessagesCountVip,
    [unreadMessagesCountVip, unreadMessagesCount],
  )
  const emailActive = `${appConfig.cdnPath.iconsSvg}/email-green-slim.svg`
  const emailInactive = `${appConfig.cdnPath.iconsSvg}/email-grey-slim.svg`

  const notificationActive = `${appConfig.cdnPath.iconsSvg}/notification-green-slim-no-back.svg`
  const notificationInactive = `${appConfig.cdnPath.iconsSvg}/notification-grey-slim-no-back.svg`

  const homeActive = `${appConfig.cdnPath.iconsSvg}/home-green-slim-no-back.svg`
  const homeInactive = `${appConfig.cdnPath.iconsSvg}/home-grey-slim-no-back.svg`

  const settingsActive = `${appConfig.cdnPath.iconsSvg}/settings-green-slim.svg`
  const settingsInactive = `${appConfig.cdnPath.iconsSvg}/settings-grey-slim.svg`

  const getHomeIcon = () => {
    return isLocationEqual(location?.pathname, [ROUTES.MAIN, ROUTES.PROFILE])
      ? homeActive
      : homeInactive
  }

  const getChatsIcon = () => {
    return matchChats?.isExact ? emailActive : emailInactive
  }

  const getSettingsIcon = () => {
    return isLocationEqual(location?.pathname, [
      ROUTES.FOLLOWER,
      ROUTES.FOLLOWER_ACCOUNT,
      ROUTES.FOLLOWER_SUBSCRIPTIONS,
      ROUTES.FOLLOWER_RATING,
      ROUTES.FOLLOWER_RATING_INFO,
      ROUTES.FOLLOWER_RATING_SUBSCRIPTIONS,
      ROUTES.FOLLOWER_SAVED_POSTS,
      ROUTES.FOLLOWER_LIKED_POSTS,
      ROUTES.FOLLOWER_PAYMENT_CARDS,
      ROUTES.PROFILE_SETTINGS,
      ROUTES.PROFILE_INCOME,
      ROUTES.ABOUT_APP,
    ])
      ? settingsActive
      : settingsInactive
  }

  const getNotificationsIcon = () => {
    return isLocationEqual(location?.pathname, [
      ROUTES.FOLLOWER_NOTIFICATIONS,
      ROUTES.PROFILE_NOTIFICATIONS,
    ])
      ? notificationActive
      : notificationInactive
  }

  const handleHome = () => {
    if (!isAuthorized || isFollower) {
      history.push(ROUTES.MAIN)
    } else if (isInfluencer) {
      if (matchProfile?.isExact) {
        history.go(0)
      } else {
        history.push(ROUTES.PROFILE)
      }
    } else {
      history.push(ROUTES.AUTH)
    }
  }

  const handleChats = () => {
    if (!isAuthorized) {
      setState({ isModalOpen: true })
    } else {
      if (userType) {
        history.push(generatePath(ROUTES.PROFILE_CHATS, { userType: userType?.toLowerCase() }))
      } else {
        history.push(ROUTES.AUTH)
      }
    }
  }

  const handleSettings = () => {
    if (!isAuthorized) {
      history.push(ROUTES.AUTH)
    } else if (!user?.email) {
      history.push(ROUTES.EMAIL)
    } else if (isFollower) {
      history.push(ROUTES.FOLLOWER)
    } else if (isInfluencer) {
      history.push(ROUTES.PROFILE_SETTINGS)
    } else {
      history.push(ROUTES.AUTH)
    }
  }

  const handleNotifications = () => {
    if (!isAuthorized) {
      history.push(ROUTES.AUTH)
    } else if (isFollower) {
      history.push(ROUTES.FOLLOWER_NOTIFICATIONS)
    } else if (isInfluencer) {
      history.push(ROUTES.PROFILE_NOTIFICATIONS)
    } else {
      history.push(ROUTES.AUTH)
    }
  }

  const getModalContent = () => {
    return (
      <>
        Чат доступен только авторизованным пользователям
        <div className="d-flex justify-content-between mt-4">
          <Button className="mr-3" onClick={() => history.push(ROUTES.AUTH)}>
            Авторизоваться
          </Button>
          <Button className="ml-3" color="grey" onClick={handleToggleModal}>
            Отмена
          </Button>
        </div>
      </>
    )
  }

  const handleToggleModal = () => setState({ isModalOpen: false })

  return (
    <div className="footer">
      <div
        className="d-flex flex-row justify-content-between flex-grow-1 align-items-center"
        style={{ height: '56px' }}
      >
        <div>
          <img src={getHomeIcon()} alt="" onClick={handleHome} width="33" height="33" />
        </div>
        <div className="footer-icon" onClick={handleChats}>
          {unreadCount > 0 && (
            <div className="footer-icon--counter unread-messages-counter">
              {getUnreadMessagesCountString(unreadCount)}
            </div>
          )}
          <img src={getChatsIcon()} alt="" width="33" height="33" />
        </div>
        <div className="footer-icon" onClick={handleNotifications}>
          {existNotifications && (
            <img
              src={`${appConfig.cdnPath.iconsSvg}/dot-green.svg`}
              alt=""
              width="14"
              height="14"
              className="footer-icon--right"
            />
          )}
          <img src={getNotificationsIcon()} alt="" width="33" height="33" />
        </div>
        <div>
          <img src={getSettingsIcon()} alt="" onClick={handleSettings} width="33" height="33" />
        </div>
      </div>
      <Modal isOpen={state.isModalOpen} centered={true} toggle={handleToggleModal} size="sm">
        <div className="py-4">
          <div className="medium-13-20 text-color-regular text-center mt-1 p-2">
            {getModalContent()}
          </div>
        </div>
      </Modal>
    </div>
  )
}

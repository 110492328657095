import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { uniqBy } from 'lodash-es/array'

import Message from './Message'
import { ChatContext } from '../../../contexts/chatContext'
import { formatMessageTimeForChat } from '../../../helpers/time'
import { getChatMessages } from '../../../api/chat'
import { PageLoading } from '../../Layout/PageLoading'
import { useScroll } from '../../../hooks/useScroll'
import { getChatKey, simpleReducer } from '../../../helpers'
import { LogoSpinner } from '../../Layout'
import { VIP_CHAT_TYPE } from '../../../constants'
import { appConfig } from '../../../constants/appConfig'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { queryClient } from '../../../api/api'
import { UserContext } from '../../../contexts/userContext'

function Messages({ chat, onSetUnread, disableRead = false }) {
  const { currentTypeId, user } = useContext(UserContext)
  const messagesContainer = useRef(null)

  const initialState = {
    messages: {},
    page: {},
    loadedPages: {},
    needScrollToBottom: {},
    lastMessageId: {},
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { height: windowHeight } = useWindowSize()
  const {
    refreshHistory,
    setRefreshed,
    newMessages,
    reduceUnreadCount,
    updateIsRead: updateIsReadNewMessages,
    markAllRead,
  } = useContext(ChatContext)

  const maxPage = useMemo(
    () => Math.trunc((chat.messageQty - 1) / appConfig.chats.countPerPage),
    [chat],
  )
  const chatKey = useMemo(() => getChatKey(chat), [chat])
  const messages = useMemo(() => state.messages[chatKey] || [], [state.messages, chatKey])
  const page = useMemo(
    () => (typeof state.page[chatKey] === 'undefined' ? maxPage : state.page[chatKey]),
    [state.page, chatKey, maxPage],
  )
  const loadedPages = useMemo(() => state.loadedPages[chatKey] || [], [state.loadedPages, chatKey])

  const needScrollToBottom = useMemo(
    () => state.needScrollToBottom[chatKey] || false,
    [state.needScrollToBottom, chatKey],
  )
  const unreadMessages = useMemo(
    () =>
      [...messages, ...(newMessages?.[chatKey] || [])]?.filter(
        (message) => !message.isRead && message.recipientId === currentTypeId,
      ) || [],
    [messages, newMessages, chatKey, currentTypeId],
  )
  const isFirstPageLoaded = useMemo(
    () => page === maxPage && messages.length > 0,
    [page, maxPage, messages],
  )
  const lastMessageId = useMemo(
    () => state.lastMessageId[chatKey] || null,
    [state.lastMessageId, chatKey],
  )
  /**
   * группируем сообщения по дате для корректного вывода
   */
  const groups = useMemo(() => {
    const messagesArray = uniqBy(
      [...(messages || []), ...(newMessages?.[chatKey] || [])],
      'externalId',
    )

    return messagesArray.reduce((result, item) => {
      const date = formatMessageTimeForChat(item.createDate)
      return {
        ...result,
        [date]: [...(result[date] || []), item],
      }
    }, {})
  }, [chatKey, messages, newMessages])

  const set = useCallback(
    (key, value) => {
      if (chatKey) {
        if (JSON.stringify(value) === JSON.stringify(state[key][chatKey])) {
          return null
        }
        setState({
          [key]: {
            ...state[key],
            [chatKey]: value,
          },
        })
      }
    },
    [chatKey, state],
  )

  const { isLoading, isError, data } = useQuery(
    ['loadMessages', user?.id, currentTypeId, chatKey, page, loadedPages],
    () => getChatMessages({ chatId: chat.chatId, page }),
    {
      enabled: Boolean(user?.id && chatKey && page >= 0 && !loadedPages.includes(page)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const addMessages = useCallback(
    (newData) => {
      if (!loadedPages.includes(page)) {
        const messagesCopy = uniqBy([...newData.slice().reverse(), ...messages], 'externalId')
        if (messagesCopy.length !== messages.length) {
          set('messages', messagesCopy)
          set('loadedPages', [...loadedPages, page])
        }
      }
    },
    [set, messages, loadedPages, page],
  )

  const updateIsRead = useCallback(
    (messagesIds) => {
      const messagesCopy = [...messages]
      messagesIds.forEach((messageId) => {
        const messageIndex = messagesCopy.findIndex((message) => message.externalId === messageId)
        if (messageIndex > -1 && !messagesCopy[messageIndex].isRead) {
          messagesCopy[messageIndex].isRead = true
        }
      })
      updateIsReadNewMessages(chatKey, messagesIds)
      set('messages', messagesCopy)
    },
    [messages, set, updateIsReadNewMessages, chatKey],
  )

  const loadMessages = useCallback(() => {
    if (!isLoading && page > 0) {
      set('page', Math.max(page - 1, 0))
    }
  }, [isLoading, page, set])

  const { scrollToBottom } = useScroll({
    onReachedTop: loadMessages,
    ref: messagesContainer,
  })

  useEffect(() => {
    if (!disableRead && unreadMessages.filter((message) => !message.isRead).length) {
      markAllRead({
        chatKey,
        chatId: chat.chatId,
        senderId: chat?.abonent?.idProfile,
        recipientId: currentTypeId,
        vip: chat?.chatType === VIP_CHAT_TYPE,
      })
      onSetUnread(chatKey, 0)
      updateIsRead(unreadMessages.map((message) => message.externalId))
      reduceUnreadCount(unreadMessages.length, chat.chatType)
    }
  }, [
    chat,
    chatKey,
    disableRead,
    markAllRead,
    onSetUnread,
    reduceUnreadCount,
    unreadMessages,
    updateIsRead,
    currentTypeId,
  ])

  useLayoutEffect(() => {
    if (isFirstPageLoaded) {
      scrollToBottom()
    }
  }, [isFirstPageLoaded, scrollToBottom])

  useEffect(() => {
    if (isFirstPageLoaded) {
      const height = messagesContainer.current?.clientHeight
      if (windowHeight > height) {
        loadMessages()
        set('needScrollToBottom', messages.length)
      }
    }
  }, [set, isFirstPageLoaded, messages, loadMessages, windowHeight])

  useEffect(() => {
    Object.keys(newMessages || {}).forEach((chatId) => {
      const unread =
        [...(state.messages[chatId] || []), ...(newMessages?.[chatId] || [])]?.filter(
          (message) => !message.isRead && message.recipientId === currentTypeId,
        ) || []
      if (unread.length > 0) {
        onSetUnread(chatId, unread.length)
      }
    })
  }, [newMessages, currentTypeId, state.messages, onSetUnread])

  useEffect(() => {
    if (isError) {
      console.log('Ошибка получения сообщений')
    }
    if (data) {
      addMessages(data)
    }
  }, [data, isError, addMessages, loadedPages, page, set])

  useLayoutEffect(() => {
    if (needScrollToBottom && needScrollToBottom < messages.length) {
      scrollToBottom()
      set('needScrollToBottom', false)
    }
  }, [needScrollToBottom, messages, set, scrollToBottom])

  useEffect(() => {
    if (newMessages?.[chatKey]?.length > 0) {
      set('lastMessageId', newMessages[chatKey]?.[newMessages[chatKey].length - 1]?.externalId)
    }
  }, [set, newMessages, chatKey])

  useEffect(() => {
    if (lastMessageId) {
      scrollToBottom()
    }
  }, [lastMessageId, scrollToBottom])

  useEffect(() => {
    //рефреш истории сообщений
    if (refreshHistory && chatKey === refreshHistory) {
      queryClient.removeQueries(['loadMessages'])
      set('messages', [])
      set('loadedPages', [])
      set('page', maxPage)
      setRefreshed()
    }
  }, [refreshHistory, chatKey, set, setRefreshed, maxPage])

  useLayoutEffect(() => {
    scrollToBottom()
  }, [chatKey, scrollToBottom])

  if (isLoading && !messages?.length) {
    return <PageLoading hasHeader hasFooter />
  }

  return (
    <>
      {isLoading && messages?.length > 0 && (
        <div className="my-2">
          <LogoSpinner isLoading size={25} />
        </div>
      )}
      <div className="messages content-padding" ref={messagesContainer}>
        {Object.keys(groups)?.map((date) => (
          <div className="messages-group" key={date}>
            <div className="messages-timeline">{date}</div>

            {groups[date].map((message) => (
              <Message
                id={message.externalId}
                key={message.externalId}
                mediaItemsData={message.mediaItemsData}
                text={message.content}
                time={message.createDate}
                isRead={message.isRead || false}
                isMe={message.senderId === currentTypeId}
                interlocutor={chat.abonent}
                paymentSum={message.paymentSum}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  )
}

export default Messages

Messages.propTypes = {
  chat: PropTypes.shape({
    chatId: PropTypes.string,
    chatType: PropTypes.string,
    messageQty: PropTypes.number,
    abonent: PropTypes.shape({
      idProfile: PropTypes.string,
      nickName: PropTypes.string,
      avatarLink: PropTypes.string,
    }),
  }),
  onSetUnread: PropTypes.func.isRequired,
  disableRead: PropTypes.bool,
}

import * as React from 'react'
import Influencer from '../../components/Profile/Influencer'
import { useHistory, useParams } from 'react-router-dom'
import { getInfluencerByNick } from '../../api/influencer'
import { useQuery } from 'react-query'
import { PageLoading } from '../../components/Layout/PageLoading'
import { ROUTES } from '../../constants/routes'
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'

// markup
const InsiderPage = () => {
  const { nick: currentNick } = useParams()
  const history = useHistory()
  const { isLoading, isError, data } = useQuery(['loadInfluencer', currentNick], () =>
    getInfluencerByNick(currentNick),
  )

  if (!isLoading && !data) {
    history.push(ROUTES.PAGE_404)
    return null
  }

  return (
    <>
      <Header title="Профиль автора" to={location.state?.backTo || ROUTES.MAIN} needRight={false} />
      <div className="content footer-padding">
        {isLoading ? (
          <PageLoading hasFooter hasHeader />
        ) : (
          <Influencer profile={data} isError={isError} />
        )}
      </div>
      <Footer />
    </>
  )
}

export default InsiderPage

import React from 'react'
import AddName from '../components/addName/addName'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

// markup
const AddNamePage = () => {
  const matchEditName = useRouteMatch(ROUTES.EDIT_NAME)

  /*useEffect(() => {
    const { follower, influencer } = JSON.parse(find(appConfig.storage.keys.user)) || {
      follower: false,
      influencer: false,
    }
    if (!follower && !influencer) {
      navigate(ROUTES.AUTH)
    }
    if (follower?.nickName || influencer?.nickName) {
      navigate(ROUTES.MAIN)
    }
  })*/
  return <AddName isEdit={matchEditName?.isExact} />
}

export default AddNamePage

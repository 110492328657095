import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../../constants/appConfig'

export const types = {
  marketplace: 'marketplace',
  post: 'post',
}

function TabContentEmpty({ type }) {
  const getTitle = () => {
    switch (type) {
      case types.marketplace:
        return 'Маркетплейс скоро откроется...'
      case types.post:
        return 'Постов пока нет'
      default:
        return ''
    }
  }

  const getMessage = () => {
    switch (type) {
      case types.marketplace:
        return 'Загляни чуть попозже'
      case types.post:
        return 'Возвращайся через некоторое время'
      default:
        return ''
    }
  }

  const getImage = () => {
    switch (type) {
      case types.marketplace:
        return 'marketplace-empty.svg'
      case types.post:
        return 'post-list-empty.svg'
      default:
        return ''
    }
  }

  return (
    <div className="tab-content-empty">
      <div className="info">
        <span>{getTitle()}</span>
        <span>{getMessage()}</span>
      </div>
      <img src={`${appConfig.cdnPath.images}/${getImage()}`} alt="" />
    </div>
  )
}

TabContentEmpty.propTypes = {
  type: PropTypes.oneOf(Object.values(types)).isRequired,
}

export default TabContentEmpty

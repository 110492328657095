import * as React from 'react'
import { find } from '../api/storage'
import { ROUTES } from '../constants/routes'
import UserAvatar from '../components/Profile/UserAvatar'
import ChatTab from '../components/Profile/Chats/ChatTab'
import { REGULAR_CHAT_TYPE, VIP_CHAT_TYPE } from '../constants'
import { appConfig } from '../constants/appConfig'
import Messages from '../components/Profile/Chats/Messages'
import MessageForm from '../components/Profile/Chats/MessageForm'

// markup
const TestPage = () => {
  const blackArrowLeft = `${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`
  const points = `${appConfig.cdnPath.iconsSvg}/points.svg`

  return (
    <div className="chat-user d-flex flex-column h-100">
      <div className="chat-user--header">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex">
            <div
              onClick={() => {
                const backLink = find('backFromChats') || ROUTES.PROFILE_CHATS
                history.push(backLink)
              }}
              className="d-flex justify-content-center"
            >
              <img className="chat-user--back mr-3" src={blackArrowLeft} alt="back" />
            </div>
            <div className="mr-2">
              <UserAvatar size={44} src={''} />
            </div>
            <div className="chat-item__message d-flex align-items-center">
              <div className="chat-item__name">test</div>
              {/*<div className="chat-item__message--text">Не в сети</div>*/}
            </div>
          </div>
          <div onClick={() => {}} className="d-flex justify-content-center">
            <img className="chat-user--back mr-3" src={points} alt="actions" />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <ChatTab type={REGULAR_CHAT_TYPE} title="Обычный чат" />
          <ChatTab type={VIP_CHAT_TYPE} title="Премиум чат" />
        </div>
      </div>
      <Messages chat={[]} />
      <div className="chat-user--footer">
        <MessageForm
          onSend={() => {}}
          chat={{ chatId: 222, chatType: 'Normal', abonent: {} }}
          onMessageFocus={() => {}}
          onMessageBlur={() => {}}
        />
      </div>
    </div>
  )
}

export default TestPage

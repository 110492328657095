import React from 'react'
import PropTypes from 'prop-types'

function Checkbox({ className, classLabel, label, onChange, checked }) {
  const handleChange = (e) => {
    onChange(e.target.checked)
  }
  return (
    <div className={`checkbox ${className}`}>
      <div className={`label ${classLabel}`}>{label}</div>
      <div>
        <label className="switch">
          <input type="checkbox" onChange={handleChange} checked={checked} />
          <span className="slider round" />
        </label>
      </div>
    </div>
  )
}

Checkbox.defaultProps = {
  className: '',
  classLabel: '',
  label: '',
  onChange: () => {},
  checked: false,
}

Checkbox.propTypes = {
  className: PropTypes.string,
  classLabel: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

export default Checkbox

import { api, queryClient } from './api'
import { useInfiniteQuery, useMutation } from 'react-query'
import { appConfig } from '../constants/appConfig'

export const NOTIFICATION_INFLUENCER_KEY = 'notificationsInfluencer'
export const NOTIFICATION_FOLLOWER_KEY = 'notificationsFollower'

export const getInfluencerNotifications = ({
  page = 0,
  perPage = appConfig.notifications.perPage,
}) => {
  const params = new URLSearchParams({ page, perPage })
  return api.get({
    path: `/insider/notifications/influencer?${params.toString()}`,
  })
}

export const getFollowerNotifications = ({
  page = 0,
  perPage = appConfig.notifications.perPage,
}) => {
  const params = new URLSearchParams({ page, perPage })
  return api.get({
    path: `/insider/notifications/follower?${params.toString()}`,
  })
}

export const useNotifications = ({ userId, type }, params) => {
  if (!Object.values(appConfig.userTypes).includes(type)) {
    throw new Error(
      `Неверный тип пользователя, ожидается: ${Object.values(appConfig.userTypes).join(' | ')}`,
    )
  }
  return useInfiniteQuery(
    [
      `${
        type === appConfig.userTypes.influencer
          ? NOTIFICATION_INFLUENCER_KEY
          : NOTIFICATION_FOLLOWER_KEY
      }-
      ${type}-
      ${userId}`,
    ],
    (key, pageParam) =>
      type === appConfig.userTypes.influencer
        ? getInfluencerNotifications({ page: pageParam })
        : getFollowerNotifications({ page: pageParam }),
    params,
  )
}

export const getNotificationToken = () => {
  return api.get({
    path: `/insider/notifications/token`,
  })
}

export const influencerSetReadNotifications = () => {
  return api.get({
    path: `/insider/notifications/influencer/set-read`,
  })
}

export const followerSetReadNotifications = () => {
  return api.get({
    path: `/insider/notifications/follower/set-read`,
  })
}

export const useMutateSetRead = (options) => {
  return useMutation(({ type }) => {
    if (!Object.values(appConfig.userTypes).includes(type)) {
      throw new Error(
        `Неверный тип пользователя, ожидается: ${Object.values(appConfig.userTypes).join(' | ')}`,
      )
    }
    if (type === appConfig.userTypes.influencer) {
      return influencerSetReadNotifications()
    } else {
      return followerSetReadNotifications()
    }
  }, options)
}

export const notificationSetRead = (notificationId) => {
  return api.get({
    path: `/insider/notifications/set-read/${notificationId}`,
  })
}

export const useMutateNotificationSetRead = (options) => {
  return useMutation((notificationId) => notificationSetRead(notificationId), {
    ...options,
    onSuccess: (data) => {
      queryClient.refetchQueries(NOTIFICATION_FOLLOWER_KEY)
      queryClient.refetchQueries(NOTIFICATION_INFLUENCER_KEY)
      options?.onSuccess(data)
    },
  })
}

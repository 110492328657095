import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { formatTime } from '../../../helpers/time'

import ReadIcon from '../../../images/read.svg'
import UnReadIcon from '../../../images/unRead.svg'
import UserAvatar from '../UserAvatar'
import { nbsp, rub } from '../../../constants/typography'
import { Link } from 'react-router-dom'
import { getInterlocutorProfileLink } from '../../../helpers'
import { AudioPlayer } from '../../Player/AudioPlayer'
import Gallery from '../../Post/Gallery'
import { isString } from 'lodash-es'
import { UserContext } from '../../../contexts/userContext'

function Message({ id, text, mediaItemsData, time, isMe, isRead, interlocutor, paymentSum = 0 }) {
  const { isInfluencer } = useContext(UserContext)
  const interlocutorLink = getInterlocutorProfileLink(interlocutor?.nickName, isInfluencer)
  const [openedImg, setOpenedImg] = useState(false)
  const hasAudio = mediaItemsData
    ?.filter((item) => item)
    .some(({ contentType }) => {
      return !contentType?.includes('image')
    })
  return (
    <div id={id} className={clsx('messages-item d-flex', { 'justify-content-end': isMe })}>
      {!isMe && (
        <Link
          to={{
            pathname: interlocutorLink,
            state: {
              backTo: window.location.pathname,
            },
          }}
        >
          <UserAvatar size={44} className="chat-item__avatar mr-2" src={interlocutor?.avatarLink} />
        </Link>
      )}
      <div
        className={clsx('messages-item__body d-flex flex-column justify-content-center', {
          inbox: !isMe,
        })}
        style={{ ...(hasAudio && { width: '100%', backgroundColor: 'transparent', padding: '0' }) }}
      >
        {paymentSum > 0 && (
          <div className="messages-item__body-payment">
            {paymentSum}
            {nbsp}
            {rub}
          </div>
        )}
        {text}
        {mediaItemsData
          ?.filter((item) => item)
          .map(({ downloadUrl, path, contentType, axisXSize, axisYSize }, id) => {
            const isImage = contentType?.includes('image')
            if (isImage) {
              const maxWidth = 240

              //вычисляем статические размеры картинки (нужно для верного скролла вниз)
              const ratio = axisXSize > maxWidth ? maxWidth / axisXSize : 1
              const imageSize = {
                width: axisXSize ? ratio * axisXSize : maxWidth,
                height: axisYSize ? ratio * axisYSize : 'auto',
              }

              return (
                <>
                  {/*  Если у картинки нет заданных размеров, оборачиваем ее в блок с фиксированной высотой */}
                  <div style={{ height: axisYSize ? 'auto' : window.innerHeight * 0.45 }}>
                    <img
                      src={`//${downloadUrl}`}
                      alt=""
                      key={id}
                      style={{ maxWidth }}
                      onClick={() => setOpenedImg(path)}
                      {...imageSize}
                    />
                  </div>
                  <Gallery
                    videos={[]}
                    currentSlide={openedImg}
                    toggle={(id = false) => {
                      if (isString(id) || id === false) setOpenedImg(id)
                    }}
                    images={[{ id: 'img', path, isMain: true }]}
                    showCounter={false}
                  />
                </>
              )
            }
            return (
              <AudioPlayer
                src={`//${downloadUrl}`}
                type={contentType}
                name=""
                needFilename={false}
              />
            )
          })}
      </div>
      <div className="messages-item__time-block d-flex align-items-center">
        <div className="messages-item__time">{formatTime(time)}</div>
        {isMe && (
          <div className="messages-item__icon">
            {isRead ? <img src={ReadIcon} alt="" /> : <img src={UnReadIcon} alt="" />}
          </div>
        )}
      </div>
    </div>
  )
}

export default Message

Message.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  isMe: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  paymentSum: PropTypes.number,
  interlocutor: PropTypes.shape({
    avatarLink: PropTypes.string,
    nickName: PropTypes.string,
  }),
  mediaItemsData: PropTypes.array,
}

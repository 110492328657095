import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { rub } from '../../constants/typography'

const eventTypes = appConfig.notifications.types

function NotificationTypeMessage({ type, onClickSource, description }) {
  const eventLabels = useMemo(
    () => ({
      [eventTypes.eventNewPost]: (
        <>
          В ленте опубликован новый <span onClick={onClickSource}>пост</span>
        </>
      ),
      [eventTypes.eventNewComment]: (
        <>
          Ответил на ваш <span onClick={onClickSource}>комментарий</span> в посте
        </>
      ),
      [eventTypes.eventSubscriptionExpired]: (
        <>
          Не удалось продлить <span onClick={onClickSource}>подписку</span>
        </>
      ),
      [eventTypes.eventBoughtVipChat]: (
        <>
          Купил подписку на премиум <span onClick={onClickSource}>чат</span>
        </>
      ),
      [eventTypes.eventDonateTargetReached]: (
        <>
          Цель <span onClick={onClickSource}>{description}</span> достигнута
        </>
      ),
      [eventTypes.eventPriceChanged]: (
        <>
          Стоимость подписки изменилась. Теперь подписка стоит{' '}
          <span onClick={onClickSource}>
            {description} {rub}
          </span>
        </>
      ),
      [eventTypes.eventPromo]: <>Запустил новую акцию</>,
    }),
    [onClickSource],
  )

  return eventLabels[type] || null
}

NotificationTypeMessage.defaultProps = {
  onClickSource: () => {},
}

NotificationTypeMessage.propTypes = {
  onClickSource: PropTypes.func.isRequired,
  type: PropTypes.oneOf([Object.values(eventTypes)]),
  description: PropTypes.string,
}

export default NotificationTypeMessage

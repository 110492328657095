import React from 'react'
import PropTypes from 'prop-types'

function ResetSuccess({ email }) {
  return (
    <>
      <div className="bold-16-24 mb-3 text-color-sub-title">Восстановление пароля</div>
      Вам на почту <span className="text-color-green">{email}</span> было отправлено письмо с
      ссылкой восстановления пароля.
      <div className="text-color-sub-title mt-3">Пожалуйста, проверяйте папку спам!</div>
    </>
  )
}

ResetSuccess.propTypes = {
  email: PropTypes.string,
}

export default ResetSuccess

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import UserAvatar from '../../Profile/UserAvatar'

export function Influencer({ avatar, author, teaser, onClick = () => {}, ...props }) {
  const { className } = props
  return (
    <div
      className={clsx('list-influencer', className)}
      onClick={onClick}
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <UserAvatar src={avatar} size={64} />
      <div className="list-influencer-content">
        <div className="bold-17-24">{author}</div>
        <div className="list-influencer-teaser">{teaser}</div>
      </div>
    </div>
  )
}

Influencer.propTypes = {
  avatar: PropTypes.string,
  author: PropTypes.string.isRequired,
  teaser: PropTypes.string,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

import axios from 'axios'
import { QueryCache, QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  cache: new QueryCache(),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const apiAxios = axios.create()

const getHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
})

export const apiUrl = ''
const get = ({ path, data, isDirect }) => makeRequest({ method: 'GET', path, data, isDirect })
const post = ({ path, data, validateStatus, isDirect }) =>
  makeRequest({ method: 'POST', path, data, validateStatus, isDirect })
const put = ({ path, data, isDirect }) => makeRequest({ method: 'PUT', path, data, isDirect })
const del = ({ path, data, isDirect }) => makeRequest({ method: 'DELETE', path, data, isDirect })

const makeRequest = ({ method, path, data, validateStatus, isDirect = false }) =>
  apiAxios
    .request({
      url: `${
        isDirect && process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : apiUrl
      }${path}`,
      method,
      data,
      ...(validateStatus && { validateStatus }),
      headers: getHeaders(),
      withCredentials: true,
    })
    .then((res) => {
      if (Object.keys(res?.data || {}).length > 0) {
        if (typeof res.data === 'string') {
          return res.data
        }
        if (Array.isArray(res.data)) {
          return [...res.data]
        }
        return {
          ...res.data,
        }
      }
    })
    .catch((e) => {
      // throw new Error(e?.response?.data?.message || 'api error')
      throw e
    })

export const api = {
  get,
  post,
  put,
  del,
}

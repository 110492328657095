import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { appConfig } from '../../../constants/appConfig'
import { REGULAR_CHAT_TYPE, VIP_CHAT_TYPE } from '../../../constants'
import ChatTab from './ChatTab'
import Messages from './Messages'
import { getChats } from '../../../api/chat'
import UserAvatar from '../UserAvatar'
import MessageForm from './MessageForm'
import { ChatContext } from '../../../contexts/chatContext'
import { find } from '../../../api/storage'
import { ROUTES } from '../../../constants/routes'
import { Button } from '../../Layout/Button/Button'
import { rub } from '../../../constants/typography'
import { getInterlocutorProfileLink, makeUrl, simpleReducer, getChatKey } from '../../../helpers'
import { pay } from '../../../api/payment'
import { formatDateTime, isDateTimeExpired } from '../../../helpers/time'
import { logicalFlows, useLogicalFlow } from '../../../hooks/useLogicalFlow'
import ChatExpiredMessage from './ChatExpiredMessage'
import VipChatExpiredPlaceholder from './VipChatExpiredPlaceholder'
import CheckboxAgreement from '../../Layout/Checkbox/CheckboxAgreement'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { errorsJsx } from '../../../constants/errorsJsx'
import InfoModal from '../../Modal/InfoModal'
import { apiCodes } from '../../../constants/apiCodes'
import { UserContext } from '../../../contexts/userContext'
import { getAuthIp } from '../../../api/auth'

const blackArrowLeft = `${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`
// const points = `${appConfig.cdnPath.iconsSvg}/points.svg`

function Chat() {
  const history = useHistory()
  const { getFlowData, delFlowData } = useLogicalFlow()
  const { userType, interlocutorId } = useParams()
  const chatType = new URLSearchParams(useLocation().search).get('type') || REGULAR_CHAT_TYPE
  const [chats, setChats] = useState({})
  const initialState = {
    isModalOpen: false,
    isModalBackendOpen: false,
    modalContent: '',
    isAgree: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { send, isConnecting, isConnected } = useContext(ChatContext)
  const { isFollower, isInfluencer, user, isAuthorized } = useContext(UserContext)
  const { height } = useWindowSize()

  const { isError, data } = useQuery(
    ['loadChats', user?.id, userType, interlocutorId],
    () => getChats({ userType, interlocutorId }),
    {
      enabled: Boolean(userType) && Boolean(interlocutorId) && Boolean(user?.id),
    },
  )

  const setUnread = useCallback(
    (chatKey, count) => {
      const chat = Object.values(chats).find((chat) => getChatKey(chat) === chatKey)
      if (chat && chat.unreadQty !== count) {
        chat.unreadQty = count
        setChats({
          ...chats,
          [chat.chatType]: chat,
        })
      }
    },
    [chats],
  )

  useEffect(() => {
    if (!isAuthorized) {
      history.push(ROUTES.AUTH)
    }
    if (![REGULAR_CHAT_TYPE, VIP_CHAT_TYPE].includes(chatType)) {
      history.push(ROUTES.PAGE_404)
    }
  }, [chatType, history, isAuthorized])

  useEffect(() => {
    getFlowData(logicalFlows.paymentFinish.id, (result) => {
      if (result?.type === logicalFlows.paymentFinish.types.vipChatSuccess) {
        setState({
          modalContent: getMessageSuccess(result?.expirationDate),
          isModalOpen: true,
        })
        delFlowData(logicalFlows.paymentFinish.id)
      }
    })
    /**
     * Запрет обновления страницы на pull-down
     */
    document.body.classList.add('prevent-scroll')
    return () => document.body.classList.remove('prevent-scroll')
  }, [])

  useEffect(() => {
    if (isError) {
      console.log('Ошибка получения чатов')
    }
    if (data?.data?.length) {
      setChats(
        data.data.reduce((result, chat) => {
          return {
            ...result,
            [chat.chatType]: chat,
          }
        }, {}),
      )
    }
  }, [data, isError])

  const chat = useMemo(() => chats[chatType] || {}, [chats, chatType])

  const isExpired = useMemo(() => isDateTimeExpired(chat?.expirationDate), [chat])

  const checkBackend = () => {
    getAuthIp()
      .then(() => {
        handlePayChat()
      })
      .catch(() => {
        setState({ isModalBackendOpen: true })
      })
  }

  const handlePayChat = async () => {
    try {
      const result = await pay({
        influencerId: interlocutorId,
        paymentType: appConfig.payment.types.vipChat,
        amount: chat.chatCost,
        returnUrl: makeUrl({ route: ROUTES.PAY_FINISH, isAbsolute: true }),
        isAgree: state.isAgree,
      })

      if (result?.redirectUrl) {
        window.ym(81892699, 'reachGoal', 'premium_chat_start', {
          order_price: chat.chatCost || 0,
          currency: 'RUB',
        })
        window.location = result?.redirectUrl
      }
    } catch (e) {
      let message = errorsJsx.payment.fail
      if (e.response?.status === 400 && e.response?.data === apiCodes.paymentFailAgreement) {
        message = errorsJsx.payment.failAgreement
      }
      setState({ isModalOpen: true, modalContent: message })
    }
  }

  const getMessageSuccess = (payExpirationDate) => (
    <>
      <div className="semiBold-15-24">Оплата выполнена успешно</div>
      <div className="medium-13-20 text-color-regular text-center mt-1">
        Премиум чат доступен до {formatDateTime(payExpirationDate)}
      </div>
    </>
  )
  const interlocutorLink = getInterlocutorProfileLink(chat?.abonent?.nickName, isInfluencer)

  return (
    <div className="chat chat-user d-flex flex-column" style={{ height }}>
      <div className="chat-user--header">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex">
            <div
              onClick={() => {
                const backLink =
                  find(appConfig.storage.keys.backFromChats) ||
                  makeUrl({ route: ROUTES.PROFILE_CHATS, routeParams: { userType } })
                history.push(backLink)
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <img className="chat-user--back mr-3" src={blackArrowLeft} alt="back" />
            </div>
            <div className="mr-2">
              <Link
                to={{
                  pathname: interlocutorLink,
                  state: {
                    backTo: window.location.pathname,
                  },
                }}
              >
                <UserAvatar size={44} src={chat?.abonent?.avatarLink} />
              </Link>
            </div>
            <div className="chat-item__message d-flex align-items-center">
              <div className="chat-item__name">{chat?.abonent?.nickName}</div>
              {/*<div className="chat-item__message--text">Не в сети</div>*/}
            </div>
          </div>
          {/*<div onClick={() => {}} className="d-flex justify-content-center">
            <img className="chat-user--back mr-3" src={points} alt="actions" />
          </div>*/}
        </div>
        <div className="d-flex flex-row justify-content-between">
          <ChatTab
            type={REGULAR_CHAT_TYPE}
            title="Обычный чат"
            unreadChatCountVip={chats[VIP_CHAT_TYPE]?.unreadQty || 0}
            unreadChatCount={chats[REGULAR_CHAT_TYPE]?.unreadQty || 0}
          />
          <ChatTab
            type={VIP_CHAT_TYPE}
            title="Премиум чат"
            unreadChatCountVip={chats[VIP_CHAT_TYPE]?.unreadQty || 0}
            unreadChatCount={chats[REGULAR_CHAT_TYPE]?.unreadQty || 0}
          />
        </div>
        {!isConnecting && !isConnected && (
          <div className="text-danger text-center">Соединение с чатом потеряно :(</div>
        )}
        {isExpired && chat.messageQty > 0 && <ChatExpiredMessage chat={chat} />}
      </div>
      {isExpired && isFollower && chatType === VIP_CHAT_TYPE && chat.messageQty === 0 ? (
        <VipChatExpiredPlaceholder chat={chat} />
      ) : (
        <Messages chat={chat} onSetUnread={setUnread} disableRead={isExpired && isFollower} />
      )}

      {isExpired && chatType === VIP_CHAT_TYPE && isFollower ? (
        <div className="chat-user--footer">
          <CheckboxAgreement
            className="mb-3 mt-2 content-padding"
            onChange={(checked) => setState({ isAgree: checked })}
            checked={state.isAgree}
          />
          <div className="button">
            <Button onClick={checkBackend} disable={!state.isAgree}>
              {`${chat.messageQty > 0 ? 'Возобновить' : 'Открыть'} доступ за ${
                chat.chatCost
              } ${rub}`}
            </Button>
          </div>
        </div>
      ) : (
        <MessageForm onSend={send} chat={chat} disabled={isExpired && isFollower} />
      )}
      <InfoModal
        isOpen={state.isModalOpen}
        onClose={() => setState({ isModalOpen: false })}
        message={state.modalContent}
      />
      <InfoModal
        isOpen={state.isModalBackendOpen}
        onClose={() => setState({ isModalBackendOpen: false })}
        message={errorsJsx.backendPingError}
      />
    </div>
  )
}

export default Chat

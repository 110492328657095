import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { layout } from '../../../helpers/ImageContainer'
import { Horizontal } from './Post/Horizontal'
import { Vertical } from './Post/Vertical'
import FavoriteIcons from '../../Main/FavoriteIcons'
import 'pure-react-carousel/dist/react-carousel.es.css'
import Gallery from '../../Post/Gallery'
import { Paragraph } from '../../Layout'
import { appConfig } from '../../../constants/appConfig'
import { download } from '../../../api/download'
import { AudioPlayer } from '../../Player/AudioPlayer'
import { PostHeader } from '../TabsEditable/PostHeader'
import { isString } from 'lodash-es'
import queryString from 'query-string'
export function AuthorizedPost({
  post,
  avatar,
  author,
  influencerId,
  influencerNick,
  secureParams,
  isSubscribed = false,
  onPressActions = false,
}) {
  const { images, files, audios, videos, text = '' } = post
  const [openedImg, setOpenedImg] = useState(false)
  const media = [...images, ...videos.map((data) => ({ ...data, isVideo: true }))]
  const main = media.filter(({ isMain }) => isMain).pop() || media.pop()
  const secondaryImages = [...media.filter(({ isMain }) => !isMain)]

  const imagesList = main
    ? [
        main.axisXSize / main.axisYSize,
        ...secondaryImages.map(({ axisXSize, axisYSize }) => axisXSize / axisYSize),
      ]
    : []
  const { orientation, count, split } = layout(imagesList)

  const staticSecureParams = { e: secureParams?.e, md5: secureParams?.md5Static }
  return (
    <>
      <div className="list-post mb-4" id={post?.id}>
        <PostHeader
          author={author}
          avatarUrl={avatar}
          onPressActions={onPressActions}
          post={post}
        />
        <Paragraph
          className="list-post-teaser medium-15-24 text-pre-line text-color-sub-title"
          value={text}
          isReplaceMails
        />
        {main && orientation === 'vertical' && (
          <Horizontal
            main={main}
            secondaryImages={secondaryImages}
            count={count}
            influencerId={influencerId}
            split={split}
            openGallery={(img) => setOpenedImg(img)}
            imageParams={secureParams}
          />
        )}
        {main && orientation === 'horizontal' && (
          <Vertical
            main={main}
            secondaryImages={secondaryImages}
            count={count}
            influencerId={influencerId}
            split={split}
            openGallery={(img) => setOpenedImg(img)}
            imageParams={secureParams}
          />
        )}
        {audios && (
          <>
            {audios.map(({ description, path, contentType }, index) => (
              <div className="content-margin mt-3" key={`audio-${index}`}>
                <AudioPlayer
                  name={description}
                  src={`${appConfig.cdnPath.insider}${path}`}
                  type={contentType}
                  secureParams={staticSecureParams}
                />
              </div>
            ))}
          </>
        )}
        {files && (
          <>
            {files.map(({ description, path, contentType }, index) => (
              <div
                className="file content-margin mt-3"
                key={`file-${index}`}
                onClick={() => {
                  const ext = contentType.split('/').pop()
                  if (ext === 'pdf') {
                    window.open(
                      `${appConfig.cdnPath.insider}${path}?${queryString.stringify(
                        staticSecureParams || {},
                      )}`,
                    )
                  } else {
                    download(
                      `${appConfig.cdnPath.insider}${path}?${queryString.stringify(
                        staticSecureParams || {},
                      )}`,
                      description || `Без имени.${ext}`,
                    )
                  }
                }}
              >
                <img
                  src={`${appConfig.cdnPath.iconsSvg}/file-green-crop.svg`}
                  alt=""
                  width="26px"
                  height="36px"
                />
                <div className="w-100 mr-3">
                  <div className="semiBold-14-20 text-left filename">
                    {description || 'Без имени'}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        <FavoriteIcons post={post} influencerNick={influencerNick} isSubscribed={isSubscribed} />
      </div>
      {main && (
        <Gallery
          currentSlide={openedImg}
          toggle={(id = false) => {
            if (isString(id) || id === false) setOpenedImg(id)
          }}
          images={images}
          videos={videos}
          influencerId={influencerId}
          imageParams={secureParams}
        />
      )}
    </>
  )
}

AuthorizedPost.propTypes = {
  post: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  influencerId: PropTypes.string.isRequired,
  influencerNick: PropTypes.string.isRequired,
  secureParams: PropTypes.object,
  isSubscribed: PropTypes.bool,
  onPressActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

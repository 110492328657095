import { api } from './api'
import { useQuery } from 'react-query'

export const getSubscriptions = ({ userId }) => {
  return api.get({
    path: `/insider/subscriptions/influencer/${userId}`,
  })
}

export const getSubscriptionsFollower = () => {
  return api.get({
    path: `/insider/subscriptions/follower`,
  })
}

export const useFollowerSubscriptions = (userId, params) => {
  return useQuery(['followerSubscriptions', userId], getSubscriptionsFollower, params)
}

export const subscribe = (influencerId, subscriptionTypeKey, bankCardId, isAgree) => {
  return api.post({
    path: '/insider/subscriptions/subscribe',
    data: {
      influencerId,
      subscriptionTypeKey,
      ...(bankCardId && { bankCardId }),
      AgreementChecked: isAgree,
    },
  })
}

export const changeSubscriptionPrice = (data) => {
  return api.put({
    path: '/insider/subscriptions/influencer/change-price',
    data,
    isDirect: true,
  })
}

export const changeSubscriptionType = (subscriptionId, data) => {
  return api.put({
    path: `/insider/subscriptions/${subscriptionId}/change-type`,
    data,
  })
}

export const setSubscriptionAutoRenew = (influencerId, desiredState) => {
  const params = new URLSearchParams({ desiredState })
  return api.put({
    path: `/insider/subscriptions/${influencerId}/toggle-autorenew?${params.toString()}`,
  })
}

import ReactHlsPlayer from 'react-hls-player'
import { makeSecureLink } from '../../../helpers'
import PropTypes from 'prop-types'
import React from 'react'

function Video({
  path,
  securityParams,
  playerRef,
  onLoadedData,
  onPause,
  onPlay,
  onTimeUpdate,
  onEnded,
  fullScreen,
  maxWidth,
  style,
  height,
  autoPlay,
  src,
}) {
  return (
    <ReactHlsPlayer
      poster={`//${makeSecureLink({ path: path, params: securityParams || {} })}`}
      playerRef={playerRef}
      onLoadedData={onLoadedData}
      onPause={onPause}
      onPlay={onPlay}
      onTimeUpdate={onTimeUpdate}
      onEnded={onEnded}
      style={
        !fullScreen
          ? {
              maxWidth: maxWidth,
              alignSelf: 'center',
              ...(style && style),
            }
          : { height: height }
      }
      src={src || `//${makeSecureLink({ path: path, params: securityParams || {} })}`}
      autoPlay={autoPlay}
      playsInline={true}
    />
  )
}

export const MemoizedVideo = React.memo(Video)
Video.propTypes = {
  path: PropTypes.any,
  securityParams: PropTypes.any,
  playerRef: PropTypes.any,
  onLoadedData: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onEnded: PropTypes.func,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.number,
  style: PropTypes.any,
  height: PropTypes.number,
  src: PropTypes.string,
  autoPlay: PropTypes.bool,
}

import { useContext, useMemo } from 'react'
import { UserContext } from '../contexts/userContext'
import { apiAxios } from '../api/api'
import { getPrivateParamsByUser } from '../api/users'
import { remove } from '../api/storage'
import { appConfig } from '../constants/appConfig'
import Cookies from 'js-cookie'
import { ensureAuth } from '../api/auth'
import { useIdsHash } from '../hooks/useIdsHash'

const WithAxios = ({ children }) => {
  const { setUser } = useContext(UserContext)
  const { checkHashData } = useIdsHash()
  let refreshRequest = null

  async function requestValidUserData() {
    if (refreshRequest === null) {
      refreshRequest = ensureAuth()
    }
    return await refreshRequest
  }

  useMemo(() => {
    apiAxios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        console.error('API error:', error)
        const request = error.config
        if (error.response && [401].includes(error.response.status) && !request._retry) {
          request._retry = true
          if (!['/insider/auth/login/social', '/insider/auth/refresh'].includes(request.url)) {
            const { user } = await requestValidUserData()
            setUser(user)
            if (user) {
              checkHashData({
                likedPostsHash: user?.follower?.likedPostsHash,
                savedPostsHash: user?.follower?.savedPostsHash,
                likedCommentsHash: user?.follower?.likedCommentsHash,
                userId: user?.id,
              })
            }
            return apiAxios(request)
          }
        }
        if (error.response && [403, 410].includes(error.response.status) && !request._retry) {
          if (request.url !== '/insider/auth/refresh') {
            const { user } = await requestValidUserData()
            const urlObject = new URL(request.url, window.location)
            const id = urlObject.pathname.match('influencer/([A-z0-9-]*)/')?.pop()
            if (id) {
              const { md5, e } = getPrivateParamsByUser(id, user)
              urlObject.searchParams.set('md5', md5)
              urlObject.searchParams.set('e', e)
              request.url = urlObject.pathname + urlObject.search + urlObject.hash
              setUser(user)
              return apiAxios(request)
            }
          }
        }
        if (error.response && request.url === '/insider/auth/refresh') {
          remove(appConfig.storage.keys.user)
          Cookies.remove('App.Dat')
          window.location.href = '/auth/'
          return null
        }
        return Promise.reject(error)
      },
    )
  }, [setUser])

  return children
}

export default WithAxios

import React, { useContext, useEffect, useReducer } from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { Footer } from '../../components/Footer/Footer'
import { simpleReducer } from '../../helpers'
import { getAvatars } from '../../api/users'
import LinkWithArrow from '../../components/Layout/LinkWithArrow/LinkWithArrow'
import { useHistory } from 'react-router-dom'
import UserAvatar from '../../components/Profile/UserAvatar'
import { UserContext } from '../../contexts/userContext'

function AccountPage() {
  const history = useHistory()
  const initialState = {
    followerAvatarLink: null,
  }
  const { user } = useContext(UserContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const loadData = async () => {
    try {
      const { followerAvatarLink } = await getAvatars()
      return {
        user,
        ...(followerAvatarLink && { followerAvatarLink: 'https://' + followerAvatarLink }),
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadData().then((data) => setState(data))
  }, [])

  const handleEditNick = () => {
    history.push(ROUTES.EDIT_NAME)
  }

  return (
    <>
      <Header title="Настройки аккаунта" needRight={false} to={ROUTES.FOLLOWER} />
      <div className="content content-padding follower-profile">
        <div className="follower-profile-account">
          <UserAvatar size={100} src={state.followerAvatarLink} />
          <div className="info">
            <div className="title">
              {user?.follower?.nickName} <span onClick={handleEditNick}>Изм.</span>
            </div>
            <div
              className="btnChange mt-2 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: ROUTES.PROFILE_ADD_AVATAR,
                  state: { backTo: ROUTES.FOLLOWER_ACCOUNT },
                })
              }
            >
              Новое фото
            </div>
          </div>
        </div>
        <LinkWithArrow
          title="Сменить пароль"
          onClick={() => history.push(ROUTES.EDIT_PASSWORD_PAGE)}
          className="follower-profile-item"
        />
        <hr />
      </div>
      <Footer />
    </>
  )
}

export default AccountPage

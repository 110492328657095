import { api } from './api'
import { find, update } from './storage'
import { appConfig } from '../constants/appConfig'
import { clearProtocolHost, getWithSinglePrefix } from '../helpers'

export const setNickname = async (nickname) => {
  return await api.put({
    path: '/insider/users/current/nickname',
    data: {
      nickname,
    },
  })
}

export const getCurrentUser = () => {
  return api.get({
    path: '/insider/users/current',
  })
}

export const getDescription = (influencerId) => {
  return api.get({ path: `/insider/users/influencers/${influencerId}/description` })
}

export const setDescription = ({ shortDescription, longDescription, influencerTitle }) => {
  return api.put({
    path: `/insider/users/current/influencers/description`,
    data: {
      shortDescription,
      longDescription,
      influencerTitle,
    },
    isDirect: true,
  })
}

export const setAuthorInfo = (influencerId, { instagram, youtube, tiktok, twitch }) => {
  const { socialHosts } = appConfig
  const instagramLink = getWithSinglePrefix(clearProtocolHost(instagram), socialHosts.instagram)
  const youTubeLink = getWithSinglePrefix(clearProtocolHost(youtube), socialHosts.youtube)
  const tikTokLink = getWithSinglePrefix(clearProtocolHost(tiktok), socialHosts.tiktok)
  const twitchLink = getWithSinglePrefix(clearProtocolHost(twitch), socialHosts.twitch)
  return api.put({
    path: `/insider/users/influencers/${influencerId}/author-info`,
    data: {
      influencerId,
      instagramLink,
      youTubeLink,
      tikTokLink,
      twitchLink,
    },
    isDirect: true,
  })
}

export const getAvatars = () => {
  return api.get({
    path: '/insider/users/current/avatars',
  })
}

export const setDefaultAvatar = ({ avatarFileGuid, axisXOffset = 0 }) => {
  return api.put({
    path: '/insider/users/current/avatars/update-default',
    data: {
      avatarFileGuid,
      axisXOffset,
    },
  })
}

export const getBackground = () => {
  return api.get({
    path: '/insider/users/current/background',
  })
}

export const getInfluencerFullInfo = (influencerId) => {
  return api
    .get({
      path: `/insider/users/influencers/${influencerId}/full-info`,
    })
    .then(
      ({
        id,
        avatarUrl,
        backgroundUrl,
        followersSubscribed,
        influencerFullName,
        influencerLongDescription,
        influencerShortDescription,
        influencerTitle,
        instagramLink,
        nickName,
        tikTokLink,
        twitchLink,
        youTubeLink,
        subscription,
        publishedPostsCount,
      }) => ({
        id,
        avatarUrl: avatarUrl ? `//${avatarUrl}` : avatarUrl,
        backgroundUrl: backgroundUrl ? `//${backgroundUrl}` : backgroundUrl,
        name: influencerFullName,
        influencerFullName,
        influencerNick: nickName,
        shortDescription: influencerShortDescription,
        about: {
          title: influencerTitle,
          text: influencerLongDescription || '',
        },
        followersCount: followersSubscribed || 0,
        socialLinks: {
          instagramLink,
          tikTokLink,
          twitchLink,
          youTubeLink,
        },
        subscription,
        publishedPostsCount,
      }),
    )
}

export const deleteAvatar = (influencerId, avatarId) => {
  return api.del({
    path: `/insider/users/influencers/${influencerId}/avatar/${avatarId}`,
  })
}

export const changeInfluencerName = (name) => {
  return api.put({
    path: `/insider/users/current/influencers/change-name?name=${name}`,
    isDirect: true,
  })
}

export const changePassword = (password) => {
  return api.put({
    path: `/insider/users/current/change-password`,
    data: { password },
  })
}

export const getUserType = () => find(appConfig.storage.keys.userType, '')

export const setUserType = (type) => update(appConfig.storage.keys.userType, type)

export const getUser = () => find(appConfig.storage.keys.user, {})

export const setUser = (data) => update(appConfig.storage.keys.user, data)

export const getPrivateParamsByUser = (id, user) => {
  const { follower } = user
  const {
    md5,
    md5_static: md5Static,
    exp: e,
  } = (follower?.subscriptions || []).filter(({ influencerId }) => influencerId === id).pop() || {}
  return md5 ? { md5, md5Static, e } : {}
}

export const updateUserWelcomeMessage = (message) => {
  return api.put({
    path: `/insider/users/current/welcome-message`,
    data: { welcomeMessage: message },
    isDirect: true,
  })
}

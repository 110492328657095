import React from 'react'
import PropTypes from 'prop-types'
import SocialLinks from '../../Main/SocialLinks'

export function About({ about: { title, text }, socialLinks = null, influencerNick }) {
  const final = text?.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  return (
    <>
      <div className="bold-17-24 mb-3 content-margin">{title}</div>
      <div
        className="text-color-regular content-margin medium-15-24 text-break text-pre-line"
        dangerouslySetInnerHTML={{ __html: final }}
      />
      <SocialLinks links={socialLinks} influencerNick={influencerNick} />
    </>
  )
}

About.propTypes = {
  about: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  socialLinks: PropTypes.any,
  influencerNick: PropTypes.string,
}

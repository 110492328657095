import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

function Facebook({ authHandler }) {
  const redirectUrl = window.location.origin + '/auth/'
  useEffect(() => {
    const { code, isVk } = queryString.parse(window.location.search)
    if (code) {
      if (!isVk) {
        authHandler({
          token: code,
          socialId: 'https://cdn.insider.improvity.ru/auth/',
          provider: 'Facebook',
          redirectUrl,
        })
      }
    }
  }, [])
  return (
    <button
      onClick={() => {
        window.location.href = `https://www.facebook.com/v11.0/dialog/oauth?${queryString.stringify(
          {
            client_id: 2788520744741738,
            redirect_uri: redirectUrl,
            scope: 'email, public_profile',
          },
        )}`
      }}
      className="auth-line-fb semiBold-15-24 "
    >
      Facebook
    </button>
  )
}

export default Facebook

Facebook.propTypes = {
  authHandler: PropTypes.func.isRequired,
}

import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import TextArea from '../../Layout/TextArea/TextArea'
import { appConfig } from '../../../constants/appConfig'
import clsx from 'clsx'
import { ROUTES } from '../../../constants/routes'
import { handleFile } from '../../../api/upload'
import {
  deleteEditPost,
  deleteMediaInPost,
  getPostById,
  setPostPublishDate,
} from '../../../api/blog'
import DateTimePicker from '../../Layout/DateTimePicker/DateTimePicker'
import { formatDateTime, isDateExpired } from '../../../helpers/time'
import { Modal } from 'reactstrap'
import { AudioLine, CancelAddPostModal, FileLine, ImageLine, VideoLine } from './addPostParts/'
import { UploadMaterialModal } from '../../Upload/'
import { nbsp } from '../../../constants/typography'
import { useHistory } from 'react-router-dom'
import { isIOS, simpleReducer } from '../../../helpers'
import ConfirmModal from '../../Modal/ConfirmModal'
import { logicalFlows, useLogicalFlow } from '../../../hooks/useLogicalFlow'
import { PostContext, postModes } from '../../../contexts/postContext'
import { getPrivateParamsByUser } from '../../../api/users'
import { UserContext } from '../../../contexts/userContext'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useScroll } from '../../../hooks/useScroll'

function AddPost({
  onReturnBack,
  savePost,
  changePostData,
  post,
  mainImageId,
  setMainImageId,
  descriptionList,
  updateDescription,
  combineAllDesc,
  isLoading,
  onDeleteMedia,
  postEditCacheData,
  isNewPost,
}) {
  const { id, text, images, videos, audios, files, publishDate } = post
  const { user } = useContext(UserContext)
  const { mode } = useContext(PostContext)
  const { setFlowData } = useLogicalFlow()
  const history = useHistory()
  const initialState = {
    deleteMaterialId: null,
    isDeleteMaterialOpen: false,
    isSelectUploadTypeOpen: false,
    isConfirmBackOpen: false,
    isDatepickerShow: false,
    isVideoWarningShow: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const updateAfterUpload = async () => {
    const postAfterUpload = await getPostById(id)
    changePostData({
      ...postAfterUpload,
      allowComments: post.allowComments,
      isFree: post.isFree,
      text: post.text,
      teaser: post.teaser,
    })
    combineAllDesc(postAfterUpload)
    setState({ isSelectUploadTypeOpen: false, isDatepickerShow: false })
  }

  const upload = async ({ file, callback = () => {}, onProgress = () => {}, type = null }) =>
    await handleFile({
      file,
      data: { blogPostId: id },
      callback: (data) => {
        updateAfterUpload().then(() => callback(data))
      },
      onProgress,
      isSetDescription: true,
      type,
    })

  const isDisabledButton = useMemo(() => {
    return (
      !text &&
      (images || []).length === 0 &&
      (videos || []).length === 0 &&
      (audios || []).length === 0 &&
      (files || []).length === 0
    )
  }, [text, images, videos, audios, files])

  const handleDelete = (id) => {
    if (mode !== postModes.editPost) {
      setState({ isDeleteMaterialOpen: true, deleteMaterialId: id })
    }
  }

  const secureParams = useMemo(() => {
    const { md5Static: md5, e } = getPrivateParamsByUser(user.influencer.id, user)
    return { md5, e, w: 480 }
  }, [user])
  const { height } = useWindowSize()
  const [isFocused, setIsFocused] = useState()
  const style = isFocused ? { maxHeight: isIOS() ? height - 397 : height - 200 } : {}
  const { scrollToBottom } = useScroll({})

  useEffect(() => {
    if (isFocused === false) {
      scrollToBottom()
    }
  }, [isFocused])
  return (
    <div>
      <header className="mb-4">
        <a
          className="pr-2"
          onClick={() => {
            if (!isLoading) {
              setState({ isConfirmBackOpen: true })
            }
          }}
        >
          <img
            src={`${appConfig.cdnPath.iconsSvg}/cancel-black.svg`}
            width="24px"
            height="24px"
            alt=""
          />
        </a>
        <div className="flex-grow-1 text-center bold-17-24">
          {mode === postModes.editPost ? 'Редактирование поста' : 'Новый пост'}
        </div>
        <img
          src={`${appConfig.cdnPath.iconsSvg}/arrow-up-fill-green1.svg`}
          width="24px"
          height="24px"
          alt=""
          className={clsx({ disabled: isDisabledButton })}
          onClick={() => {
            if (!isDisabledButton && !isLoading) {
              savePost()
            }
          }}
        />
      </header>
      <div className="content content-padding footer-padding">
        <TextArea
          onChange={(text) => changePostData({ text })}
          value={text}
          placeholder="Текст поста"
          showCounterLimit
          maxlength={appConfig.posts.maxPostTextLength}
          textareaClassName="addPostTextArea"
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          style={style}
        />
        <div className="mt-2">
          {(images || []).map(({ path, id }) => {
            return (
              <ImageLine
                key={id}
                value={id}
                mainImageId={mainImageId}
                onChange={(id) => {
                  setMainImageId(id)
                }}
                onDelete={() => handleDelete(id)}
                path={path}
                secureParams={secureParams}
              />
            )
          })}
          {(videos || []).map(({ id, screenshotUrl }) => {
            return (
              <VideoLine
                key={id}
                value={id}
                mainImageId={mainImageId}
                onChange={(id) => {
                  setMainImageId(id)
                }}
                onClick={() => setState({ isVideoWarningShow: true })}
                onDelete={() => setState({ isDeleteMaterialOpen: true, deleteMaterialId: id })}
                updateAfterUpload={updateAfterUpload}
                screenshotUrl={screenshotUrl}
                secureParams={secureParams}
              />
            )
          })}
          <Modal
            isOpen={state.isVideoWarningShow}
            toggle={() => setState({ isVideoWarningShow: false })}
            centered
            size="sm"
          >
            <div className="py-4">
              <div className="medium-13-20 text-color-regular text-center mt-1">
                Видео будет доступно для просмотра после сохранения поста
              </div>
            </div>
          </Modal>
          {(audios || []).map(({ id, path, contentType }) => (
            <AudioLine
              key={id}
              contentType={contentType}
              path={path}
              id={id}
              description={descriptionList[id]}
              updateDescription={(desc) => {
                updateDescription(id, desc)
              }}
              onDelete={() => handleDelete(id)}
              secureParams={secureParams}
            />
          ))}
          {(files || []).map(({ id }) => {
            return (
              <FileLine
                key={id}
                onChange={(desc) => updateDescription(id, desc)}
                descriptionList={descriptionList}
                id={id}
                onDelete={() => handleDelete(id)}
                secureParams={secureParams}
              />
            )
          })}
        </div>
      </div>
      <div className="footer">
        <div
          className="d-flex flex-row justify-content-between flex-grow-1 align-items-center"
          style={{ height: '56px' }}
        >
          <img
            src={`${appConfig.cdnPath.iconsSvg}/plus-around-green2.svg`}
            width="24px"
            height="24px"
            alt=""
            role="button"
            onClick={() => setState({ isSelectUploadTypeOpen: true })}
          />
          <div className="flex-grow-1 pl-3 ">
            <span
              onClick={() => setState({ isDatepickerShow: true })}
              className="border rounded px-2 semiBold-15-20"
            >
              {publishDate && !isDateExpired(publishDate) ? formatDateTime(publishDate) : 'Сейчас'}
              {nbsp}
              <img
                src={`${appConfig.cdnPath.iconsSvg}/arrow-down-black.svg`}
                width="10px"
                height="20px"
                alt=""
              />
            </span>
            <DateTimePicker
              isPopup
              open={state.isDatepickerShow}
              onSelect={async (date) => {
                await setPostPublishDate(id, { dateTimeToUpdate: date.toISOString() })
                await updateAfterUpload()
              }}
              onCancel={() => setState({ isDatepickerShow: false })}
              minDate={new Date()}
            />
          </div>
          <img
            src={`${appConfig.cdnPath.iconsSvg}/settings-grey.svg`}
            width="24px"
            height="24px"
            alt=""
            role="button"
            onClick={onReturnBack}
          />
        </div>
      </div>
      <CancelAddPostModal
        open={state.isConfirmBackOpen}
        toggle={() => setState({ isConfirmBackOpen: false })}
        onClick={async () => {
          if (isNewPost) {
            await deleteEditPost(id)
          }
          delete postEditCacheData?.[id]
          setFlowData(logicalFlows.postEditData.id, { list: postEditCacheData })
          history.push(`${ROUTES.PROFILE}?tabName=${appConfig.tabCodes.posts}`)
        }}
      />

      <ConfirmModal
        isOpen={state.isDeleteMaterialOpen}
        onCancel={() => setState({ isDeleteMaterialOpen: false, deleteMaterialId: null })}
        onOk={async () => {
          if (state.deleteMaterialId) {
            setState({ isDeleteMaterialOpen: false, deleteMaterialId: null })
            onDeleteMedia()
            await deleteMediaInPost(id, state.deleteMaterialId)
            setFlowData(logicalFlows.postEditData.id, {
              list: {
                ...postEditCacheData,
                [post.id]: {
                  allowComments: post.allowComments,
                  isFree: post.isFree,
                  publishDate: post.publishDate,
                  text: post.text,
                  teaser: post.teaser,
                },
              },
            })
            history.go(0)
          }
        }}
        message="Удалить материал?"
      />

      <UploadMaterialModal
        open={state.isSelectUploadTypeOpen}
        toggle={() => setState({ isSelectUploadTypeOpen: false })}
        uploadMethod={upload}
        onCancel={() => setState({ isSelectUploadTypeOpen: false })}
      />
    </div>
  )
}

AddPost.defaultProps = {
  onReturnBack: () => {},
}

AddPost.propTypes = {
  onReturnBack: PropTypes.func,
  post: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    videos: PropTypes.array.isRequired,
    audios: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    publishDate: PropTypes.string,
    allowComments: PropTypes.bool,
    isFree: PropTypes.bool,
    teaser: PropTypes.string,
  }).isRequired,
  savePost: PropTypes.func.isRequired,
  changePostData: PropTypes.func.isRequired,
  setMainImageId: PropTypes.func.isRequired,
  mainImageId: PropTypes.string,
  descriptionList: PropTypes.object,
  updateDescription: PropTypes.func.isRequired,
  combineAllDesc: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDeleteMedia: PropTypes.func,
  postEditCacheData: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.object]),
  isNewPost: PropTypes.bool,
}

export default AddPost

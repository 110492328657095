import React from 'react'

function LogoWelcome() {
  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5352 30.947C20.1377 31.0325 19.8632 31.4073 19.9268 31.8084L20.3349 34.3818C20.3986 34.7834 20.7769 35.0588 21.1759 34.9782C26.9064 33.8195 31.9393 29.8487 34.1897 23.9928C35.7252 19.9973 35.7102 15.7881 34.4394 12.0269C34.3094 11.6419 33.8818 11.453 33.5021 11.5986L31.0689 12.5315C30.689 12.6772 30.5013 13.1024 30.6265 13.4892C31.5503 16.3408 31.5398 19.5151 30.3807 22.5312C28.6712 26.9794 24.8746 30.0136 20.5352 30.947Z"
        fill="#171C1C"
      />
      <path
        d="M20.5352 30.947C20.1377 31.0325 19.8632 31.4073 19.9268 31.8084L20.3349 34.3818C20.3986 34.7834 20.7769 35.0588 21.1759 34.9782C26.9064 33.8195 31.9393 29.8487 34.1897 23.9928C35.7252 19.9973 35.7102 15.7881 34.4394 12.0269C34.3094 11.6419 33.8818 11.453 33.5021 11.5986L31.0689 12.5315C30.689 12.6772 30.5013 13.1024 30.6265 13.4892C31.5503 16.3408 31.5398 19.5151 30.3807 22.5312C28.6712 26.9794 24.8746 30.0136 20.5352 30.947Z"
        fill="url(#paint0_linear_1370:75)"
      />
      <path
        d="M20.5352 30.947C20.1377 31.0325 19.8632 31.4073 19.9268 31.8084L20.3349 34.3818C20.3986 34.7834 20.7769 35.0588 21.1759 34.9782C26.9064 33.8195 31.9393 29.8487 34.1897 23.9928C35.7252 19.9973 35.7102 15.7881 34.4394 12.0269C34.3094 11.6419 33.8818 11.453 33.5021 11.5986L31.0689 12.5315C30.689 12.6772 30.5013 13.1024 30.6265 13.4892C31.5503 16.3408 31.5398 19.5151 30.3807 22.5312C28.6712 26.9794 24.8746 30.0136 20.5352 30.947Z"
        fill="#179494"
        fillOpacity="0.4"
      />
      <path
        d="M27.7763 8.55313C28.049 8.85505 28.5118 8.9055 28.8282 8.64958L30.8538 7.01111C31.1698 6.75552 31.2199 6.29145 30.9514 5.98652C29.1243 3.91208 26.7816 2.23663 24.0201 1.17783C16.9273 -1.54175 9.16191 0.608189 4.41644 5.9856C4.14744 6.29042 4.19732 6.75491 4.51347 7.01062L6.53933 8.64924C6.85557 8.90503 7.31824 8.85455 7.59114 8.55304C11.2509 4.5095 17.1578 2.91066 22.5579 4.98119C24.6254 5.77393 26.3878 7.01603 27.7763 8.55313Z"
        fill="#171C1C"
      />
      <path
        d="M27.7763 8.55313C28.049 8.85505 28.5118 8.9055 28.8282 8.64958L30.8538 7.01111C31.1698 6.75552 31.2199 6.29145 30.9514 5.98652C29.1243 3.91208 26.7816 2.23663 24.0201 1.17783C16.9273 -1.54175 9.16191 0.608189 4.41644 5.9856C4.14744 6.29042 4.19732 6.75491 4.51347 7.01062L6.53933 8.64924C6.85557 8.90503 7.31824 8.85455 7.59114 8.55304C11.2509 4.5095 17.1578 2.91066 22.5579 4.98119C24.6254 5.77393 26.3878 7.01603 27.7763 8.55313Z"
        fill="url(#paint1_linear_1370:75)"
      />
      <path
        d="M27.7763 8.55313C28.049 8.85505 28.5118 8.9055 28.8282 8.64958L30.8538 7.01111C31.1698 6.75552 31.2199 6.29145 30.9514 5.98652C29.1243 3.91208 26.7816 2.23663 24.0201 1.17783C16.9273 -1.54175 9.16191 0.608189 4.41644 5.9856C4.14744 6.29042 4.19732 6.75491 4.51347 7.01062L6.53933 8.64924C6.85557 8.90503 7.31824 8.85455 7.59114 8.55304C11.2509 4.5095 17.1578 2.91066 22.5579 4.98119C24.6254 5.77393 26.3878 7.01603 27.7763 8.55313Z"
        fill="#179494"
        fillOpacity="0.4"
      />
      <path
        d="M4.74187 13.4894C4.8672 13.1028 4.67959 12.6774 4.29977 12.5318L1.86679 11.599C1.48697 11.4533 1.05902 11.6432 0.928909 12.0282C-2.08688 20.953 2.46334 30.744 11.3485 34.1507C12.2874 34.5107 13.2381 34.7854 14.192 34.9786C14.591 35.0594 14.9692 34.7839 15.0329 34.3822L15.441 31.8087C15.5046 31.4077 15.2303 31.0329 14.8329 30.9473C14.1545 30.8012 13.4786 30.6012 12.8105 30.345C6.02926 27.7449 2.53046 20.3108 4.74187 13.4894Z"
        fill="#171C1C"
      />
      <path
        d="M4.74187 13.4894C4.8672 13.1028 4.67959 12.6774 4.29977 12.5318L1.86679 11.599C1.48697 11.4533 1.05902 11.6432 0.928909 12.0282C-2.08688 20.953 2.46334 30.744 11.3485 34.1507C12.2874 34.5107 13.2381 34.7854 14.192 34.9786C14.591 35.0594 14.9692 34.7839 15.0329 34.3822L15.441 31.8087C15.5046 31.4077 15.2303 31.0329 14.8329 30.9473C14.1545 30.8012 13.4786 30.6012 12.8105 30.345C6.02926 27.7449 2.53046 20.3108 4.74187 13.4894Z"
        fill="url(#paint2_linear_1370:75)"
      />
      <path
        d="M4.74187 13.4894C4.8672 13.1028 4.67959 12.6774 4.29977 12.5318L1.86679 11.599C1.48697 11.4533 1.05902 11.6432 0.928909 12.0282C-2.08688 20.953 2.46334 30.744 11.3485 34.1507C12.2874 34.5107 13.2381 34.7854 14.192 34.9786C14.591 35.0594 14.9692 34.7839 15.0329 34.3822L15.441 31.8087C15.5046 31.4077 15.2303 31.0329 14.8329 30.9473C14.1545 30.8012 13.4786 30.6012 12.8105 30.345C6.02926 27.7449 2.53046 20.3108 4.74187 13.4894Z"
        fill="#179494"
        fillOpacity="0.4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1370:75"
          x1="38.4069"
          y1="16.7605"
          x2="-0.929213"
          y2="25.0482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69B4B4" />
          <stop offset="0.239583" stopColor="#7EDCDC" />
          <stop offset="0.463542" stopColor="#96F9F9" />
          <stop offset="0.744792" stopColor="#77DDDD" />
          <stop offset="0.984375" stopColor="#6EBCBC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1370:75"
          x1="38.4069"
          y1="16.7605"
          x2="-0.929213"
          y2="25.0482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69B4B4" />
          <stop offset="0.239583" stopColor="#7EDCDC" />
          <stop offset="0.463542" stopColor="#96F9F9" />
          <stop offset="0.744792" stopColor="#77DDDD" />
          <stop offset="0.984375" stopColor="#6EBCBC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1370:75"
          x1="38.4069"
          y1="16.7605"
          x2="-0.929213"
          y2="25.0482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#69B4B4" />
          <stop offset="0.239583" stopColor="#7EDCDC" />
          <stop offset="0.463542" stopColor="#96F9F9" />
          <stop offset="0.744792" stopColor="#77DDDD" />
          <stop offset="0.984375" stopColor="#6EBCBC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoWelcome

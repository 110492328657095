import { NOTIFICATION_MESSAGE } from '../stomp/paramsWorker'

const cardTypes = {
  visa: 'Visa',
  mastercard: 'Mastercard',
  mir: 'Mir',
  americanExpress: 'AmericanExpress',
  unionPay: 'UnionPay',
}

const periodTypes = {
  oneMonthKey: 'OneMonthKey',
  threeMonthKey: 'ThreeMonthsKey',
  twelveMonthsKey: 'TwelveMonthsKey',
}

const filters = {
  income: 'income',
  periodFrom: 'periodFrom',
  periodTo: 'periodTo',
}

const incomeTypes = {
  // product: 'product',
  donate: 'Donate',
  subscription: 'Subscription',
  premium: 'VipChat',
}

const postActions = {
  pinn: 'pinn',
  edit: 'edit',
  delete: 'delete',
}

export const appConfig = {
  postActions,
  subscribe: {
    types: periodTypes,
    periodLabels: {
      [periodTypes.oneMonthKey]: '1 мес',
      [periodTypes.threeMonthKey]: '3 мес',
      [periodTypes.twelveMonthsKey]: '12 мес',
    },
    periodMonthValue: {
      [periodTypes.oneMonthKey]: 1,
      [periodTypes.threeMonthKey]: 3,
      [periodTypes.twelveMonthsKey]: 12,
    },
    firstLimit: 100000,
  },
  userTypes: {
    influencer: 'Influencer',
    follower: 'Follower',
  },
  userState: {
    noEmailReceived: 'NoEmailReceived',
  },
  cdnPath: {
    root: 'https://insider-static.cdnvideo.ru',
    insider: 'https://insider-static.cdnvideo.ru/insider',
    insiderWithoutProtocol: 'insider-static.cdnvideo.ru/insider',
    media: 'https://insider-static.cdnvideo.ru/insider/media',
    images: 'https://insider-static.cdnvideo.ru/insider/images',
    icons: 'https://insider-static.cdnvideo.ru/insider/images/icons',
    iconsSocial: 'https://insider-static.cdnvideo.ru/insider/images/social',
    iconsSocialSvg: 'https://insider-static.cdnvideo.ru/insider/images/iconssvg/social',
    iconsSvg: 'https://insider-static.cdnvideo.ru/insider/images/iconssvg',
  },
  payment: {
    cardTypeValues: cardTypes,
    cardTypes: {
      [cardTypes.visa]: { icon: 'visa.svg' },
      [cardTypes.mastercard]: { icon: 'master-card.svg' },
      [cardTypes.mir]: { icon: 'mir.svg' },
      [cardTypes.americanExpress]: { icon: 'some-card.svg' },
      [cardTypes.unionPay]: { icon: 'some-card.svg' },
    },
    types: {
      donate: 'Donate',
      vipChat: 'VipChat',
      link: 'Link',
    },
    statuses: {
      charged: 'Charged',
    },
    donateMinAmount: 10,
  },
  media: {
    blurPostfix: '_blr',
    mediaTypeDestinations: {
      chat: 'Chat',
      welcomeMedia: 'WelcomeMedia',
      screenshot: 'Screenshot',
      background: 'Background',
      influencer: 'Influencer',
      follower: 'Follower',
    },
  },
  socialHosts: {
    instagram: 'https://instagram.com/',
    youtube: 'https://youtube.com/',
    tiktok: 'https://tiktok.com/',
    twitch: 'https://twitch.tv/',
  },
  posts: {
    countPerPage: 10,
    maxPostTextLength: 3000,
  },
  notifications: {
    types: {
      eventNewPost: 'NewPost',
      eventNewComment: 'NewComment',
      eventSubscriptionExpired: 'SubscriptionExpired',
      eventBoughtVipChat: 'BoughtVipChat',
      eventDonateTargetReached: 'DonateTargetReached',
      eventPriceChanged: 'PriceChanged',
      eventPromo: 'Promotion',
    },
    perPage: 10,
  },
  income: {
    types: incomeTypes,
    labels: {
      // [incomeTypes.product]: 'Продукт',
      [incomeTypes.donate]: 'Донат',
      [incomeTypes.subscription]: 'Подиска',
      [incomeTypes.premium]: 'Премиум чат',
    },
  },
  filters: {
    values: filters,
    labels: {
      [filters.income]: 'Доходы от',
      [filters.periodFrom]: 'Период от',
      [filters.periodTo]: 'Период до',
    },
  },
  tabCodes: {
    about: 'about',
    posts: 'posts',
    marketplace: 'marketplace',
  },
  comments: {
    countPerPage: 10,
    filterName: 'commentsFilter',
  },
  chats: {
    countPerPage: 20,
    footerIconCounterRange: 99,
    filterName: 'chatsFilter',
  },
  toggleIconsValues: {
    setLike: 'SetLike',
    removeLike: 'RemoveLike',
    setFavorite: 'SetFavorite',
    removeFavorite: 'RemoveFavorite',
  },
  stomp: {
    commands: {
      subscribe: 'SUBSCRIBE',
      send: 'SEND',
      sendVideo: 'SEND_VIDEO',
      sendServiceMessage: 'SEND_SERVICE_MESSAGE',
      disconnect: 'DISCONNECT',
    },
    events: {
      newMessage: 'NEW_MESSAGE',
      newVideoMessage: 'NEW_VIDEO_MESSAGE',
      notificationMessage: NOTIFICATION_MESSAGE,
      newServiceMessage: 'NEW_SERVICE_MESSAGE',
      disconnected: 'DISCONNECTED',
      connected: 'CONNECTED',
      sent: 'SENT',
    },
    config: {
      host: 'insider',
      url: `wss://${window.location.host}/chat`,
    },
    flags: {
      delivered: 'delivered',
      read: 'read',
    },
  },
  storage: {
    keys: {
      user: 'user',
      userType: 'userType',
      message: 'message',
      expirationDate: 'expirationDate',
      status: 'status',
      backFromChats: 'backFromChats',
      welcomeMode: 'welcomeMode',
    },
  },
  tokenKeys: {
    subscriptions: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata',
    emailAddress: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    isPromo: 'IsPromotion',
    notificationId: 'NotificationId',
    promotionId: 'PromotionId',
  },
  title: 'Insider',
  mailSupport: 'support@insider.space',
  cdnImagesSizeRange: 3, // размер картинки с CDN запросим в N раз больше, а при показе уменьшим через css - так качество лучше
  monitorCacheExpireInMinutes: 2,
  passwordLength: {
    min: 6,
    max: 24,
  },
  targets: {
    createId: 'create',
    activeLimit: 5,
    targetNameLength: 50,
  },
  dateFormats: {
    shortDate: 'DD.MM.YYYY',
    apiDateTime: 'YYYY-MM-DDT00:00:00.0000',
    apiDateTimeAllDay: 'YYYY-MM-DDT23:59:59.0000',
  },
  documentTypes: {
    confidentiality: 'Confidentiality',
    offerPayment: 'OfferPayment',
    agreement: 'Agreement',
    offerPromo: 'OfferPromo',
  },
  isPingBackend: false,
  cryptoSecret: 'SecretKey80tkFBilpFjDWvRRcDnaeGyrKdDTBbLS',
}

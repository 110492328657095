import { api } from './api'

export const getCategories = async () => {
  return await api.get({
    path: '/insider/products/categories',
  })
}

export const selectCategories = async ({ influencerId, categories }) => {
  return await api.post({
    path: '/insider/products/categories',
    data: {
      influencerId,
      categories,
    },
    isDirect: true,
  })
}

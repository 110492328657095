import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import UserAvatar from './UserAvatar'
import { appConfig } from '../../constants/appConfig'
import { useWebpLinks } from '../../hooks/useWebpLinks'
import { getProfileIconUrl } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { UserContext } from '../../contexts/userContext'

const photoIcon = `${appConfig.cdnPath.iconsSvg}/photo-green.svg`
const emptyBackground = `${appConfig.cdnPath.iconsSvg}/emptycover.svg`

export function InfluencerAvatar({
  bgUrl,
  photoUrl,
  onEditAvatar = null,
  onEditBackground = null,
  influencerId = false,
}) {
  const { isFollower, user } = useContext(UserContext)
  const history = useHistory()
  let bgSrc = bgUrl
  if (!bgSrc?.includes('//')) {
    bgSrc = `//${bgSrc}`
  }
  const { link: background } = useWebpLinks({
    path: bgSrc,
    params: { h: 168 * appConfig.cdnImagesSizeRange },
  })

  const subscription = useMemo(
    () =>
      isFollower
        ? user?.follower?.subscriptions?.find(
            ({ influencerId: id, isActive }) => influencerId === id && isActive,
          ) || null
        : null,
    [influencerId, isFollower, user?.follower?.subscriptions],
  )

  return (
    <div
      style={{
        backgroundImage: `url(${bgUrl ? background : emptyBackground})`,
      }}
      className="content-margin avatarEmptyCover"
    >
      <UserAvatar src={photoUrl} size={136} className="user-avatar" />
      {subscription && (
        <div
          className="follower-score cursor-pointer badges"
          onClick={() => history.push(ROUTES.FOLLOWER_RATING_SUBSCRIPTIONS)}
        >
          Вы в Топ{' '}
          {subscription.followerTop
            ? subscription.followerTop
            : `${Math.ceil(subscription.followerScore)}%`}
          <img
            className="ml-1"
            src={
              subscription.followerTop
                ? `${appConfig.cdnPath.iconsSvg}/profileIcons/20x20/top.svg`
                : getProfileIconUrl(subscription.followerMonths)
            }
            alt=""
            width="14px"
          />
        </div>
      )}
      {onEditAvatar && (
        <img className="edit-icon edit-avatar" src={photoIcon} alt="" onClick={onEditAvatar} />
      )}
      {onEditBackground && (
        <img
          className="edit-icon edit-background"
          src={photoIcon}
          alt=""
          onClick={onEditBackground}
        />
      )}
    </div>
  )
}

InfluencerAvatar.propTypes = {
  bgUrl: PropTypes.string,
  photoUrl: PropTypes.string,
  onEditAvatar: PropTypes.func,
  onEditBackground: PropTypes.func,
  influencerId: PropTypes.string,
}

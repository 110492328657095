import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { payFinish } from '../api/payment'
import { appConfig } from '../constants/appConfig'
import { makeUrl } from '../helpers'
import { ROUTES } from '../constants/routes'
import { VIP_CHAT_TYPE } from '../constants'
import { useLogicalFlow, logicalFlows } from '../hooks/useLogicalFlow'
import { UserContext } from '../contexts/userContext'
import { refresh } from '../api/auth'

function PayFinish() {
  const history = useHistory()
  const location = useLocation()
  const { setFlowData } = useLogicalFlow()
  const { order_id: orderId } = queryString.parse(location.search)
  const { user, setUser } = useContext(UserContext)

  const loadData = async () => {
    try {
      const result = await payFinish(orderId)
      switch (result?.paymentType) {
        case appConfig.payment.types.donate:
          finishDonate(result)
          break
        case appConfig.payment.types.vipChat:
          finishVipChat(result)
          break
        case appConfig.payment.types.link:
          history.push(makeUrl({ route: ROUTES.FOLLOWER_PAYMENT_CARDS }))
          break
        default:
          history.push(makeUrl({ route: ROUTES.MAIN }))
      }
    } catch (e) {
      console.log(e)
      history.push(makeUrl({ route: ROUTES.MAIN }))
    }
  }

  const finishDonate = (data) => {
    if (data?.paymentStatus === appConfig.payment.statuses.charged) {
      if (data?.nickName) {
        setFlowData(logicalFlows.paymentFinish.id, {
          type: logicalFlows.paymentFinish.types.donateSuccess,
        })
        const { followerScore, followerTop, influencerId } = data || {}
        if (user?.follower) {
          const { subscriptions } = user.follower
          setUser({
            ...user,
            follower: {
              ...user.follower,
              subscriptions: subscriptions?.map((item) => {
                return {
                  ...item,
                  ...(item?.influencerId === influencerId && { followerScore, followerTop }),
                }
              }),
            },
          })
        }
        window.ym(81892699, 'reachGoal', 'donate')
        history.push(
          makeUrl({ route: ROUTES.INSIDERS_NICK, routeParams: { nick: data?.nickName } }),
        )
      }
    }
  }

  const finishVipChat = async (data) => {
    if (data?.paymentStatus === appConfig.payment.statuses.charged) {
      let { user } = await refresh()
      setUser(user)
      setFlowData(logicalFlows.paymentFinish.id, {
        type: logicalFlows.paymentFinish.types.vipChatSuccess,
        expirationDate: data?.expirationDate,
      })
      window.ym(81892699, 'reachGoal', 'premium_chat')
      history.push(
        makeUrl({
          route: ROUTES.PROFILE_CHATS,
          routeParams: {
            userType: appConfig.userTypes.follower.toLowerCase(),
            interlocutorId: data?.influencerId,
          },
          params: { type: VIP_CHAT_TYPE },
        }),
      )
    }
  }

  useEffect(() => {
    if (orderId) {
      loadData()
    } else {
      history.push(ROUTES.PAGE_404)
    }
  }, [orderId])

  return null
}

export default PayFinish

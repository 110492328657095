import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'

const filterKeys = appConfig.filters.values
const filterLabels = appConfig.filters.labels

function FilterList({ className, values, onClick, onClear }) {
  const existsValue = (id) => {
    return Object.keys(values || {}).includes(id)
  }

  const renderItem = (filterKey) => {
    return (
      <div
        key={filterKey}
        className={`filters-item ${existsValue(filterKey) ? 'active' : ''}`}
        onClick={() => onClick(filterKey)}
      >
        {existsValue(filterKey) ? values[filterKey].label : filterLabels[filterKey]}
        {existsValue(filterKey) ? (
          <img
            src={`${appConfig.cdnPath.iconsSvg}/cross-white-margin.svg`}
            alt=""
            onClick={(e) => {
              e.stopPropagation()
              onClear(filterKey)
            }}
          />
        ) : (
          <img src={`${appConfig.cdnPath.iconsSvg}/arrow-down-green.svg`} alt="" />
        )}
      </div>
    )
  }
  return <div className={`filters ${className}`}>{Object.values(filterKeys).map(renderItem)}</div>
}

FilterList.defaultProps = {
  className: '',
  values: {},
  onClick: () => {},
  onClear: () => {},
}

FilterList.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
}

export default FilterList

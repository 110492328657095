import React from 'react'
import PropTypes from 'prop-types'
import { Influencer } from './Influencer'
import { useHistory } from 'react-router-dom'

export function Recommends({ influencers, search }) {
  const history = useHistory()
  let currentInfluencers = influencers || []
  if (search) {
    currentInfluencers = currentInfluencers.filter(
      ({ influencerNickname, influencerShortDescription }) =>
        influencerNickname?.toLowerCase()?.includes(search?.toLowerCase()) ||
        influencerShortDescription?.toLowerCase()?.includes(search?.toLowerCase()),
    )
  }
  return currentInfluencers.map(
    ({ id, influencerNickname, influencerShortDescription, influencerAvatarLink }) => (
      <Influencer
        key={id}
        author={influencerNickname}
        teaser={influencerShortDescription}
        avatar={influencerAvatarLink}
        id={id}
        onClick={() => {
          history.push(`/insiders/${influencerNickname}/`)
        }}
        className="mb-4 content-margin"
      />
    ),
  )
}

Recommends.propTypes = {
  influencers: PropTypes.arrayOf(
    PropTypes.shape({
      influencerNickname: PropTypes.string,
      influencerShortDescription: PropTypes.string,
      influencerAvatarLink: PropTypes.string,
    }),
  ),
  search: PropTypes.string,
}

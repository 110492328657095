import * as React from 'react'
import queryString from 'query-string'
import { getOrder } from '../api/payment'
import { update } from '../api/storage'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { ROUTES } from '../constants/routes'
import { appConfig } from '../constants/appConfig'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../contexts/userContext'
import { refresh } from '../api/auth'

// markup
const PrPage = ({ location }) => {
  const history = useHistory()
  const { setUser } = useContext(UserContext)
  useEffect(() => {
    const { order_id: orderId } = queryString.parse(location.search)
    if (orderId) {
      getOrder(orderId)
        .then(async (data) => {
          const { nickName, paymentStatus, expirationDate } = data
          if (paymentStatus === appConfig.payment.statuses.charged) {
            let { user } = await refresh()
            setUser(user)
            update(appConfig.storage.keys.expirationDate, expirationDate)
          }
          if (nickName) {
            update(appConfig.storage.keys.status, paymentStatus)
            history.push(`/insiders/${nickName}/`)
          } else {
            history.push(ROUTES.MAIN)
          }
        })
        .catch(() => {
          history.push(ROUTES.MAIN)
        })
    } else {
      history.push(ROUTES.PAGE_404)
    }
  }, [])
  return <></>
}

export default PrPage

PrPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as SorryLogo } from '../../images/sorry.svg'

const NoInternetConnection = ({ children }) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true)

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true)
  })

  window.addEventListener('offline', () => {
    setOnline(false)
  })

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return children
  } else {
    return (
      <main className="layout">
        <header className="layout3-header justify-content-end align-items-start">
          <a onClick={() => window.location.reload()} className="green-link semiBold-15-24">
            Обновить
          </a>
        </header>
        <div className="d-flex flex-column justify-content-around flex-grow-1">
          <div className="text-center">
            <SorryLogo width="279px" height="279px" />
          </div>
          <div
            className="content-padding justify-content-center flex-column pb-5"
            style={{ background: 'none' }}
          >
            <h1 className="bold-24-32 text-center w-100">Отсутствует интернет-соединение.</h1>
            <div className="text-color-regular medium-15-24 w-100 text-center">
              Пожалуйста повторите попытку позже
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default NoInternetConnection

NoInternetConnection.propTypes = {
  children: PropTypes.node,
}

import React, { useEffect, useReducer } from 'react'
import { navigate } from '@reach/router'
import { Header } from '../components/Header/Header'
import { Button } from '../components/Layout/Button/Button'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { useDocuments } from '../api/document'
import { appConfig } from '../constants/appConfig'
import { simpleReducer } from '../helpers'

function AgreementPage() {
  const matchRoute = {
    agreement: useRouteMatch(ROUTES.AGREEMENT),
    confidentiality: useRouteMatch(ROUTES.CONFIDENTIALITY),
    offerPayment: useRouteMatch(ROUTES.OFFER_PAYMENT),
  }

  const initialState = { type: null, title: '' }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data: dataDocument } = useDocuments(
    { type: state.type },
    { enabled: Boolean(state.type) },
  )

  useEffect(() => {
    switch (true) {
      case matchRoute?.agreement?.isExact:
        setState({ type: appConfig.documentTypes.agreement, title: 'Пользовательское соглашение' })
        return
      case matchRoute?.confidentiality?.isExact:
        setState({
          type: appConfig.documentTypes.confidentiality,
          title: 'Политика конфиденциальности',
        })
        return
      case matchRoute?.offerPayment?.isExact:
        setState({
          type: appConfig.documentTypes.offerPayment,
          title: 'Оферта рекуррентных платежей',
        })
        return
    }
  }, [])

  return (
    <>
      <Header title={state.title} needRight={false} to="back" />
      <div
        className="content-margin agreement"
        dangerouslySetInnerHTML={{ __html: dataDocument?.text }}
      />
      <div className="mb-3 button">
        <Button onClick={() => navigate(-1)}>Закрыть</Button>
      </div>
    </>
  )
}

export default AgreementPage

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

function Vk({ authHandler }) {
  const redirectUrl = `${location.protocol}//${location.hostname}${
    location.port ? ':' + location.port : ''
  }/auth/?isVk=1`
  useEffect(() => {
    const { code, isVk } = queryString.parse(window.location.search)
    if (code) {
      if (isVk) {
        authHandler({
          token: code,
          socialId: 'https://cdn.insider.improvity.ru/auth/',
          provider: 'Vk',
          redirectUrl,
        })
      }
    }
  }, [])

  const clientID = '7788030'

  const handleClick = () => {
    window.location.href = `https://oauth.vk.com/authorize?response_type=code&client_id=${clientID}&scope=email&redirect_uri=${redirectUrl}`
  }

  return (
    <button onClick={handleClick} className="auth-line-vk semiBold-15-24">
      Вконтакте
    </button>
  )
}

export default Vk

Vk.propTypes = {
  authHandler: PropTypes.func.isRequired,
}

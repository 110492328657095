import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../Layout/Button/Button'
import { rub } from '../../../constants/typography'
import { makeUrl } from '../../../helpers'
import { ROUTES } from '../../../constants/routes'
import { update } from '../../../api/storage'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { appConfig } from '../../../constants/appConfig'
import { UserContext } from '../../../contexts/userContext'

function InfluencerButtons({ subscribed, oneMonthCost, author, influencerId }) {
  const history = useHistory()
  const location = useLocation()
  const { influencerId: currentInfluencerId } = useContext(UserContext)

  /**
   * Текущему пользователю при просмотре своего же профиля кнопки не показываем
   */
  if (influencerId === currentInfluencerId) {
    return null
  }

  return (
    <>
      {(!subscribed || subscribed === -1) && oneMonthCost ? (
        <div className="mt-3 mb-4">
          <Button
            className="content-margin"
            onClick={() => {
              history.push(`/subscribe/?influencerId=${influencerId}`)
            }}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            Подписаться за {oneMonthCost} {rub}
          </Button>
        </div>
      ) : (
        <div className="content-margin mt-3 mb-3 d-flex">
          <Button
            className="mr-1"
            color="dark"
            onClick={() =>
              history.push(
                makeUrl({
                  route: ROUTES.INSIDERS_NICK_DONATE,
                  routeParams: { nick: author },
                  params: { influencerId },
                }),
              )
            }
          >
            Поддержать
          </Button>
          <Button
            className="ml-1"
            color="dark"
            onClick={() => {
              update(appConfig.storage.keys.backFromChats, location.pathname)
              history.push(
                generatePath(ROUTES.PROFILE_CHATS, {
                  userType: appConfig.userTypes.follower.toLowerCase(),
                  interlocutorId: influencerId,
                }),
              )
            }}
          >
            Сообщение
          </Button>
        </div>
      )}
    </>
  )
}

export default InfluencerButtons

InfluencerButtons.propTypes = {
  subscribed: PropTypes.bool,
  oneMonthCost: PropTypes.any,
  author: PropTypes.any,
  influencerId: PropTypes.any,
}

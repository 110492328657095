import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { About } from './TabsEditable/About'
import { Posts } from './TabsEditable/Posts'
import { Marketplace } from './TabsEditable/Marketplace'
import clsx from 'clsx'
import { appConfig } from '../../constants/appConfig'
import { getMyPosts } from '../../api/blog'
import { useInfiniteQuery } from 'react-query'

export function TabsEditable({
  about,
  socialLinks = null,
  avatar,
  author,
  influencerId,
  defaultTab,
  publishedPostsCount,
}) {
  const [currentTab, setCurrentTab] = useState(appConfig.tabCodes.about)

  useEffect(() => {
    if (Object.values(appConfig.tabCodes || {}).includes(defaultTab)) {
      setCurrentTab(defaultTab)
    }
  }, [defaultTab])

  const countPages = Math.ceil(publishedPostsCount / appConfig.posts.countPerPage)
  const [postPage, setPostPage] = useState(countPages > 0 ? countPages - 1 : 0)

  const { data, fetchNextPage } = useInfiniteQuery(
    'influencerPosts',
    (key, pageParam = countPages > 0 ? countPages - 1 : 0) => {
      return getMyPosts(pageParam)
    },
    {
      enabled: !!influencerId,
    },
  )

  const loadNextPosts = () => {
    if (postPage > 0) {
      const nextPost = postPage - 1
      fetchNextPage({ id: influencerId, pageParam: nextPost })
      setPostPage(nextPost)
    }
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-between mt-3 content-margin">
        <div
          onClick={() => setCurrentTab(appConfig.tabCodes.about)}
          onKeyDown={() => {}}
          role="tab"
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': currentTab === appConfig.tabCodes.about,
          })}
        >
          Об авторе
        </div>
        <div
          onClick={() => setCurrentTab(appConfig.tabCodes.posts)}
          onKeyDown={() => {}}
          role="tab"
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': currentTab === appConfig.tabCodes.posts,
          })}
        >
          Лента
        </div>
        <div
          onClick={() => setCurrentTab(appConfig.tabCodes.marketplace)}
          onKeyDown={() => {}}
          role="tab"
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': currentTab === appConfig.tabCodes.marketplace,
          })}
        >
          Маркетплейс
        </div>
      </div>
      <div className="about">
        {currentTab === appConfig.tabCodes.about && (
          <About about={about} socialLinks={socialLinks} influencerNick={author} />
        )}
        {currentTab === appConfig.tabCodes.posts && (
          <Posts
            posts={data}
            avatar={avatar}
            author={author}
            influencerId={influencerId}
            loadNextPosts={loadNextPosts}
            hasMorePosts={postPage > 0}
          />
        )}
        {currentTab === appConfig.tabCodes.marketplace && <Marketplace products={[]} />}
      </div>
    </>
  )
}

TabsEditable.propTypes = {
  about: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  avatar: PropTypes.string,
  author: PropTypes.string,
  influencerId: PropTypes.string.isRequired,
  socialLinks: PropTypes.any,
  defaultTab: PropTypes.string,
  publishedPostsCount: PropTypes.number,
}

import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { Modal } from 'reactstrap'
import { appConfig } from '../../constants/appConfig'
import { Button } from '../Layout/Button/Button'
import { makeUrl, simpleReducer } from '../../helpers'
import { useMutateNotificationSetRead } from '../../api/notification'
import { UserContext } from '../../contexts/userContext'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { setSubscriptionAutoRenew } from '../../api/subscribe'
import { rub } from '../../constants/typography'
import { getPromotion } from '../../api/promotion'

const notificationTypes = appConfig.notifications.types

const okButtonText = {
  [notificationTypes.eventSubscriptionExpired]: 'Продлить подписку',
  [notificationTypes.eventPriceChanged]: 'Остаться подписчиком',
  [notificationTypes.eventPromo]: `Активировать подписку за 1 ${rub}`,
}

const cancelButtonText = {
  [notificationTypes.eventSubscriptionExpired]: 'Больше не интересно',
  [notificationTypes.eventPriceChanged]: 'Дорого. Откажусь',
  [notificationTypes.eventPromo]: 'Мне это неинтересно',
}

function SubscriptionNotifyModal() {
  const history = useHistory()
  const location = useLocation()
  const { user, isFollower, setUser } = useContext(UserContext)

  const initialState = {
    isOpen: false,
    notificationData: null,
    type: notificationTypes.eventPromo,
    promoData: location.state?.promoData,
  }

  const [state, setState] = useReducer(simpleReducer, initialState)
  const { mutate: setNotificationViewed } = useMutateNotificationSetRead()

  const removeNotificationAlert = (notificationId) => {
    const tmpUser = { ...user }
    tmpUser.follower.unreadAlerts = user?.follower.unreadAlerts?.filter(
      (item) => item.id !== notificationId,
    )
    tmpUser.follower.unreadNotificationCount =
      user?.follower.unreadNotificationCount > 0 ? user?.follower.unreadNotificationCount - 1 : 0
    setUser(tmpUser)
  }

  useEffect(() => {
    const first = state.promoData?.notificationId
      ? { ...state.promoData, id: state.promoData.notificationId }
      : user?.follower?.unreadAlerts?.filter((item) =>
          [
            notificationTypes.eventPriceChanged,
            notificationTypes.eventSubscriptionExpired,
            notificationTypes.eventPromo,
          ].includes(item.notificationType),
        )[0]
    if (isFollower && first) {
      const data = {
        notificationData: first,
        isOpen: true,
        type: first.notificationType,
        promoData: null,
      }

      if (
        first.id &&
        !first.notificationId &&
        first.notificationType === notificationTypes.eventPromo
      ) {
        getPromotion()
          .then((promotionData) => {
            if (!promotionData?.length) {
              setNotificationViewed(first.id)
              removeNotificationAlert(first.id)
              setState({ isOpen: false })
            } else {
              setState(data)
            }
          })
          .catch(() => setState(data))
      } else {
        setState(data)
      }
    }
  }, [user?.follower?.unreadAlerts, isFollower])

  const handleCancel = () => {
    setNotificationViewed(state.notificationData.id)
    removeNotificationAlert(state.notificationData.id)
    setState({ isOpen: false })
    if (location.state?.promoData) {
      history.push(makeUrl({ route: ROUTES.MAIN }))
    }
  }

  const handleOk = async () => {
    setNotificationViewed(state.notificationData.id)
    removeNotificationAlert(state.notificationData.id)
    setState({ isOpen: false })
    if (state.notificationData.notificationType === notificationTypes.eventSubscriptionExpired) {
      history.push(
        makeUrl({
          route: ROUTES.FOLLOWER_SUBSCRIBE,
          params: {
            influencerId: state.notificationData?.sourceId,
          },
        }),
      )
    }
    if (state.notificationData.notificationType === notificationTypes.eventPromo) {
      history.push(
        makeUrl({
          route: ROUTES.SUBSCRIBE,
          params: {
            influencerId: state.notificationData?.sourceId,
          },
        }),
      )
    }
    if (state.notificationData.notificationType === notificationTypes.eventPriceChanged) {
      await setSubscriptionAutoRenew(state.notificationData?.sourceId, true)
    }
  }

  const handleInfo = () => history.push(makeUrl({ route: ROUTES.OFFER_PAYMENT }))

  const messages = useMemo(
    () => ({
      [notificationTypes.eventSubscriptionExpired]: (
        <>
          Нам не удалось продлить твою подписку на{' '}
          <span className="text-color-green">{state.notificationData?.senderNickName}</span>. <br />
          Как поступим?
        </>
      ),
      [notificationTypes.eventPriceChanged]: (
        <>
          Стоимость подписки на профиль{' '}
          <span className="text-color-green">{state.notificationData?.senderNickName}</span>{' '}
          изменилась. Теперь подписка стоит{' '}
          <span className="text-color-green">{state.notificationData?.description} рублей</span>.{' '}
          <br />
          Как поступим?
        </>
      ),
      [notificationTypes.eventPromo]: (
        <div className="text-center medium-14-20">
          <img src={`${appConfig.cdnPath.images}/one-rub-head.svg`} />
          <div className="bold-16-24 my-3">Привет!</div>
          <div className="mb-2">
            Видим, что ты купил курс Михаила Литвина и поэтому, как и обещали, дарим тебе месячную{' '}
            <span className="text-color-green">подписку за 1 рубль</span>!
          </div>
          <div className="mb-2">
            Если у тебя всё ещё нет подписки, по истечении подарочного месяца она автоматически
            продлится по стандартной стоимости автора.
          </div>
          <div className="mb-2">
            Если у тебя уже есть подписка, она автоматически продлится на еще один месяц.
          </div>
          <div className="mb-4">Как поступим?</div>
        </div>
      ),
    }),
    [state.notificationData],
  )

  return (
    <Modal isOpen={state.isOpen} centered={true} toggle={() => {}} size="lg">
      <div className="d-flex flex-column">
        {[notificationTypes.eventSubscriptionExpired, notificationTypes.eventPriceChanged].includes(
          state.type,
        ) && <img src={`${appConfig.cdnPath.iconsSvg}/pattern/notify.svg`} alt="" />}
        <div className="content-padding bold-17-24 text-center">{messages[state.type] || null}</div>
        <div className="content-margin mt-3 mb-3 text-center semiBold-13-20">
          <Button className="mb-3 semiBold-14-20" onClick={handleOk}>
            {okButtonText[state.type]}
          </Button>
          <Button
            className="mt-3 semiBold-14-20 text-color-green"
            color="grey"
            onClick={handleCancel}
          >
            {cancelButtonText[state.type]}
          </Button>
          {state.type === notificationTypes.eventPromo && (
            <div className="mt-4 bold-12-20 text-color-green" onClick={handleInfo}>
              Оферта акционного предложения
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default SubscriptionNotifyModal

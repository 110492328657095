import React, { useEffect, useReducer } from 'react'
import { Input } from '../Layout/Input/Input'
import { makeUrl, simpleReducer } from '../../helpers'
import { rub } from '../../constants/typography'
import { Button } from '../Layout/Button/Button'
import { errorsJsx } from '../../constants/errorsJsx'
import { useHistory, useParams } from 'react-router-dom'
import { appConfig } from '../../constants/appConfig'
import { useMutateTarget } from '../../api/targets'
import { ROUTES } from '../../constants/routes'
import PropTypes from 'prop-types'

function TargetEdit({ data }) {
  const { targetId } = useParams()
  const history = useHistory()

  const initialState = {
    cost: data?.amount || '',
    title: data?.targetName || '',
    errorCost: null,
    error: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    setState({
      cost: data?.amount || '',
      title: data?.targetName || '',
      errorCost: null,
      error: null,
    })
  }, [data])

  const { mutate: mutateTarget, isSuccess: isSuccessMutate } = useMutateTarget()

  useEffect(() => {
    if (isSuccessMutate) {
      history.push(makeUrl({ route: ROUTES.PROFILE_TARGETS }))
    }
  }, [isSuccessMutate])

  const handleChangeInput = (value) => {
    const digits = value?.replace(rub, '').replace(/([^\d])/, '')
    setState({
      cost: digits ? parseInt(digits) : digits,
      errorCost: digits > 0 ? null : errorsJsx.targets.failCost,
    })
  }

  const handleChangeText = (value) => {
    if (value.trim().length > appConfig.targets.targetNameLength) {
      setState({
        error: errorsJsx.targets.failLengthTitle,
      })
    } else {
      setState({
        title: value,
        error: null,
      })
    }
  }

  const handleSave = () => {
    if (state.title?.trim().length === 0 || !state.cost) {
      setState({
        errorCost: !state.cost ? errorsJsx.targets.failCost : null,
        error: state.title?.trim().length === 0 ? errorsJsx.targets.failTitle : null,
      })
    } else if (state.title?.trim().length > 0 && state.cost > 0) {
      mutateTarget({
        data: {
          ...(targetId && targetId !== appConfig.targets.createId && { id: targetId }),
          targetName: state.title.trim(),
          amount: state.cost,
        },
      })
    }
  }

  return (
    <>
      <div className="content content-padding target-edit">
        <Input
          onChange={handleChangeInput}
          value={state.cost}
          autofocus={true}
          placeholder="Сумма"
          type="number"
          prefix={rub}
        />
        {state.errorCost && <div className="text-danger">{state.errorCost}</div>}
        <div className="description">
          Введите сумму, которую хотите собрать с помощью этой цели.
        </div>
        <div className="mt-4">
          <Input
            onChange={handleChangeText}
            value={state.title}
            inputClassName="pr-3"
            placeholder="Название цели"
            maxlength={appConfig.targets.targetNameLength}
            showCounterLimit
          />
        </div>
        {state.error && <div className="text-danger">{state.error}</div>}
      </div>
      <div className="mb-5 button">
        <Button onClick={handleSave}>
          {targetId === appConfig.targets.createId ? 'Добавить' : 'Сохранить'}
        </Button>
      </div>
    </>
  )
}

TargetEdit.propTypes = {
  data: PropTypes.object,
}

export default TargetEdit

import { mb } from '../../constants/typography'

export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1])
  let length = bstr.length
  const u8arr = new Uint8Array(length)
  while (length--) {
    u8arr[length] = bstr.charCodeAt(length)
  }
  return new File([u8arr], filename, { type: mime })
}

export const formatSizeMb = (size, postfix = mb) => {
  const val = ((size || 0) / (1024 * 1024)).toFixed(2)
  return `${val} ${postfix}`.trim()
}

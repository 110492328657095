class Container {
  vertical = false

  constructor(vertical) {
    this.vertical = vertical
    this.images = []
    this.MAX_RATIO = 6
  }
  add(image) {
    if (this.vertical) {
      image = 1 / image
    }

    const sum = this.images.reduce(function (a, b) {
      return a + b
    }, 0)
    if (sum + image <= this.MAX_RATIO) {
      this.images.push(image)
      return true
    } else {
      return false
    }
  }

  second_dimension() {
    return this.images.reduce(function (a, b) {
      return a + b
    }, 0)
  }

  count() {
    return this.images.length
  }
}

const horizontal_layout = (images) => {
  const [heroImage, ...secondaryImages] = images
  const vertical_secondary_container = new Container(true)
  for (let image of secondaryImages) {
    const res = vertical_secondary_container.add(image)
    if (!res) {
      break
    }
  }
  const split = vertical_secondary_container.count()
    ? 1 / heroImage / vertical_secondary_container.second_dimension()
    : 0
  return {
    orientation: 'horizontal',
    count: vertical_secondary_container.count() + 1,
    sd: vertical_secondary_container.second_dimension(),
    split: split,
  }
}

const vertical_layout = (images) => {
  const [heroImage, ...secondaryImages] = images
  const horizontal_secondary_container = new Container(false)
  for (let image of secondaryImages) {
    const res = horizontal_secondary_container.add(image)
    if (!res) {
      break
    }
  }

  const split = horizontal_secondary_container.count()
    ? heroImage / horizontal_secondary_container.second_dimension()
    : 0

  return {
    orientation: 'vertical',
    count: horizontal_secondary_container.count() + 1,
    sd: horizontal_secondary_container.second_dimension(),
    split: split,
  }
}

export const layout = (images) => {
  const hl = horizontal_layout([...images])
  const vl = vertical_layout([...images])
  if (hl['split'] > 1 || (vl['split'] < 1 && vl['split'] > hl['split'])) {
    return vl
  } else {
    return hl
  }
}

import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation, useHistory } from 'react-router-dom'

import { Header } from '../../../components/Header/Header'
import { Button } from '../../../components/Layout/Button/Button'

import { isWrongAnyGetParam, makeUrl, simpleReducer } from '../../../helpers'
import CheckboxAgreement from '../../../components/Layout/Checkbox/CheckboxAgreement'
import { rub } from '../../../constants/typography'
import { Input } from '../../../components/Layout/Input/Input'
import { pay } from '../../../api/payment'
import { appConfig } from '../../../constants/appConfig'
import { ROUTES } from '../../../constants/routes'
import { logicalFlows, useLogicalFlow } from '../../../hooks/useLogicalFlow'
import InfoModal from '../../../components/Modal/InfoModal'
import { errorsJsx } from '../../../constants/errorsJsx'
import { apiCodes } from '../../../constants/apiCodes'
import TargetSelect from '../../../components/Targets/TargetSelect'
import { getAuthIp } from '../../../api/auth'

const ERROR_MIN_SUM = `Сумма доната должна быть от ${appConfig.payment.donateMinAmount} ${rub}`

const InsiderDonate = () => {
  const history = useHistory()
  const { nick: currentNick } = useParams()
  const location = useLocation()
  const { setFlowData } = useLogicalFlow()
  const influencerId = new URLSearchParams(location.search)?.get('influencerId')

  const initialState = {
    amount: appConfig.payment.donateMinAmount,
    comment: '',
    isAgree: false,
    isModalOpen: false,
    isModalBackendOpen: false,
    modalContent: '',
    error: '',
    currentTarget: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (isWrongAnyGetParam({ influencerId })) {
      history.push(ROUTES.PAGE_404)
    }
  }, [influencerId])

  const checkBackend = () => {
    getAuthIp()
      .then(() => {
        handlePay()
      })
      .catch(() => {
        setState({ isModalBackendOpen: true })
      })
  }

  const handlePay = async () => {
    if (state.amount < appConfig.payment.donateMinAmount) {
      setState({ error: ERROR_MIN_SUM })
      return
    }

    try {
      const result = await pay({
        influencerId,
        paymentType: appConfig.payment.types.donate,
        amount: state.amount,
        paymentDetail: state.comment,
        returnUrl: makeUrl({ route: ROUTES.PAY_FINISH, isAbsolute: true }),
        isAgree: state.isAgree,
        donateTargetId: state.currentTarget?.id,
      })

      if (result?.redirectUrl) {
        window.ym(81892699, 'reachGoal', 'donate_start', {
          order_price: state.amount || 0,
          currency: 'RUB',
        })
        window.location = result?.redirectUrl
      }
      if (result?.paymentStatus === appConfig.payment.statuses.charged) {
        setFlowData(logicalFlows.paymentFinish.id, {
          type: logicalFlows.paymentFinish.types.donateSuccess,
        })
        history.push(makeUrl({ route: ROUTES.INSIDERS_NICK, routeParams: { nick: currentNick } }))
      }
    } catch (e) {
      let message = errorsJsx.payment.fail
      if (e.response?.status === 400 && e.response?.data === apiCodes.paymentFailAgreement) {
        message = errorsJsx.payment.failAgreement
      }
      setState({ isModalOpen: true, modalContent: message })
    }
  }

  const getCheckboxAgree = () => {
    return (
      <CheckboxAgreement
        className="mb-3"
        onChange={(checked) => setState({ isAgree: checked })}
        checked={state.isAgree}
      />
    )
  }

  const handleCommentChange = (value) => {
    setState({ comment: value })
  }
  const handleChangeInput = (value) => {
    const digits = value?.replace(rub, '').replace(/([^\d])/, '')
    setState({
      amount: digits ? parseInt(digits) : digits,
      error: digits && parseInt(digits) < appConfig.payment.donateMinAmount && ERROR_MIN_SUM,
    })
  }

  const handleSelectTarget = (target) => {
    setState({ currentTarget: target })
  }

  return (
    <>
      <Header title={`Донат ${currentNick}`} needRight={false} to="back" />
      <div className="subscribe mt-3">
        <span className="bold-17-24">Сумма доната</span>
        <Input
          type="number"
          onChange={handleChangeInput}
          value={state.amount}
          // autofocus={true}
          inputClassName="pr-3"
          placeholder="Введите сумму доната"
          prefix={rub}
        />
        {state.error ? (
          <div className="text-danger">{state.error}</div>
        ) : (
          <div className="mt-3 medium-13-20 text-color-regular">
            Введите сумму доната, минимум {appConfig.payment.donateMinAmount} {rub}
          </div>
        )}
        <TargetSelect influencerId={influencerId} onSelect={handleSelectTarget} />
        <div className="mt-4" />
        <span className="bold-17-24">Комментарий к донату</span>
        <div className="mt-3 medium-13-20 text-color-regular">
          <Input
            placeholder="Ваш комментарий"
            value={state.comment}
            onChange={handleCommentChange}
            inputClassName="mb-3"
          />
        </div>
        {/*<hr width="100%" size="2" className="mt-3" />*/}
      </div>
      <div className="cards">
        {getCheckboxAgree()}
        <hr width="100%" size="2" className="mt-3" />
      </div>
      <div className="mb-5 button">
        <Button onClick={checkBackend} disable={!state.isAgree}>
          Оплатить
        </Button>
      </div>
      <InfoModal
        isOpen={state.isModalOpen}
        onClose={() => setState({ isModalOpen: false })}
        message={state.modalContent}
      />
      <InfoModal
        isOpen={state.isModalBackendOpen}
        onClose={() => setState({ isModalBackendOpen: false })}
        message={errorsJsx.backendPingError}
      />
    </>
  )
}

InsiderDonate.defaultProps = {}

InsiderDonate.propTypes = {
  influencerId: PropTypes.string,
}

export default InsiderDonate

import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import { appConfig } from '../../constants/appConfig'
import Radio from '../Layout/Radio/Radio'

export const filters = {
  isCreateDate: {
    id: 'isCreateDate',
    name: 'Сначала новые',
  },
  isRate: {
    id: 'isRate',
    name: 'Сначала популярные',
  },
}

function CommentsFilterModal({ isOpen, onClose, activeFilter, onSetActiveFilter }) {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true} size="sm">
      <div className="comments-filters-modal p-4">
        <img
          onClick={onClose}
          className="comments-filters-modal--close cursor-pointer"
          src={`${appConfig.cdnPath.iconsSvg}/cancel-black.svg`}
          width="24px"
          height="24px"
          alt=""
        />
        <div className="semiBold-17-24 text-left mb-4">Комментарии</div>
        {Object.values(filters).map((filter) => (
          <Radio
            key={filter.id}
            name="commentsFilter"
            value={filter.id}
            label={filter.name}
            checked={filter.id === activeFilter}
            onChange={(value) => {
              onSetActiveFilter(value)
            }}
            className="mb-3"
          />
        ))}
      </div>
    </Modal>
  )
}

export default CommentsFilterModal

CommentsFilterModal.defaultProps = {
  activeFilter: filters.isRate.id,
}

CommentsFilterModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  activeFilter: PropTypes.string,
  onSetActiveFilter: PropTypes.func,
}

import React from 'react'
import SelectCategory from '../components/Category/SelectCategory'
import { ROUTES } from '../constants/routes'
import { useHistory } from 'react-router-dom'

const SelectCategoryPage = () => {
  const history = useHistory()
  const handleSave = () => {
    history.push(ROUTES.SET_SUBSCRIPTION_AMOUNT)
  }
  return <SelectCategory withProgressBar onAfterSave={handleSave} />
}

export default SelectCategoryPage

import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Layout/Button/Button'

function ConfirmForm({ onOk, onCancel, okLabel, cancelLabel, message, className }) {
  return (
    <div className={`py-4 ${className}`}>
      <div className="medium-13-20 text-color-regular text-center mt-1 p-2">{message}</div>
      <div className="d-flex justify-content-between mt-4 ml-3 mr-3">
        <Button className="mr-3" onClick={onOk} color="regular">
          {okLabel}
        </Button>
        <Button className="ml-3" color="grey" onClick={onCancel}>
          {cancelLabel}
        </Button>
      </div>
    </div>
  )
}

ConfirmForm.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  okLabel: 'Да',
  cancelLabel: 'Отмена',
  message: '',
  className: '',
}

ConfirmForm.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
}

export default ConfirmForm

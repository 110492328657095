import React, { useEffect } from 'react'
import './custom.scss'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import Home from './screens/Home'
import AuthPage from './screens/Auth'
import EmailPage from './screens/Email'
import RegisterFinish from './screens/RegisterFinish'
import AddNamePage from './screens/AddName'
import AgreementPage from './screens/Agreement'
import FollowerPage from './screens/Follower'
import MainPage from './screens/Main'
import PrPage from './screens/pr'
import PayFinish from './screens/PayFinish'
import ProfilePage from './screens/Profile'
import SelectCategoryPage from './screens/SelectCategory'
import SelectProfilePage from './screens/SelectProfile'
import SetPasswordPage from './screens/SetPassword'
import SetSubscriptionAmountPage from './screens/SetSubscriptionAmount'
import SubscribePage from './screens/Subscribe'

import FollowerSubscribePage from './screens/follower/subscribe'
import RatingPage from './screens/follower/rating'
import FollowerSubscriptionsPage from './screens/follower/subscriptions'
import FollowerPaymentInfoPage from './screens/follower/paymentInfo'
import FollowerPaymentHistoryPage from './screens/follower/paymentHistory'
import FollowerPaymentCardsPage from './screens/follower/paymentCards'
import FollowerFavoritePostsPage from './screens/follower/favoritePosts'
import InsiderPage from './screens/insiders/Insider'
import InsiderCommentsPage from './screens/insiders/insider/Comments'
import InsiderDonatePage from './screens/insiders/insider/Donate'
import ProfileAboutPage from './screens/profile/about'
import ProfileAddAvatarPage from './screens/profile/addAvatar'
import ProfileAddBackgroundPage from './screens/profile/addBackground'
import ProfileAddPostPage from './screens/profile/addPost'
import ProfileCategoryPage from './screens/profile/category'
import ProfileCommentsPage from './screens/profile/comments'
import ProfileSelectAvatarPage from './screens/profile/selectAvatar'
import ProfileSetSubscriptionAmountPage from './screens/profile/setSubscriptionAmount'
import ProfileSettingsPage from './screens/profile/settings'
import ProfileSocialLinks from './screens/profile/socialLinks'
import TargetsPage from './screens/profile/Targets'
import InfluencerFollowerPage from './screens/profile/follower'
import InfluencerFollowerListPage, {
  storageKey as followersListKey,
} from './screens/profile/followerList'
import RandomFollowersPage, {
  storageKey as randomFollowerKey,
} from './screens/profile/randomFollowers'
import ChatProfilePage from './screens/profile/chats'
import NotificationsPage from './screens/Notifications'
import IncomePage from './screens/profile/income'
import AccountPage from './screens/follower/account'
import SettingsChatsPage from './screens/profile/settingsChats'
import SettingsPrivacyPage from './screens/profile/SettingsPrivacyPage'
import TestPage from './screens/test'
import NoMatchPage from './screens/NoMatch'
import AboutAppPage from './screens/AboutApp'
import FaqPage from './screens/Faq'
import ChangePassword from './mailTemplates/changePassword/ChangePassword'
import { HelmetProvider } from 'react-helmet-async'

import './sass/app.scss'
import { ROUTES } from './constants/routes'
import dbConfig from './constants/dbConfig'
import { initDB } from 'react-indexed-db'
import { remove } from './api/storage'
import UserProvider from './providers/UserProvider'
import ChatProvider from './providers/ChatProvider'
import WithAxios from './components/WithAxios'
import LayoutProvider from './providers/LayoutProvider'
import NoInternetConnection from './components/Layout/NoInternetConnection'

initDB(dbConfig)

function App() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.document.addEventListener(
      'touchmove',
      (e) => {
        if (e.scale !== 1) {
          e.preventDefault()
        }
      },
      { passive: false },
    )
  }

  /**
   * При перезагрузке страницы удаляем из storage данные о подписчиках
   */
  useEffect(() => {
    const removeParams = () => {
      remove(followersListKey)
      remove(randomFollowerKey)
    }
    window.addEventListener('beforeunload', removeParams)
    return () => {
      window.removeEventListener('beforeunload', removeParams)
    }
  }, [])

  return (
    <HelmetProvider>
      <UserProvider>
        <WithAxios>
          <ChatProvider>
            <NoInternetConnection>
              <BrowserRouter>
                <LayoutProvider>
                  <Switch>
                    <Route path={ROUTES.ROOT} exact component={Home} />
                    <Route path={ROUTES.ADD_NAME} exact component={AddNamePage} />
                    <Route path={ROUTES.EDIT_NAME} exact component={AddNamePage} />
                    <Route path={ROUTES.AGREEMENT} exact component={AgreementPage} />
                    <Route path={ROUTES.CONFIDENTIALITY} exact component={AgreementPage} />
                    <Route path={ROUTES.OFFER_PAYMENT} exact component={AgreementPage} />
                    <Route path={ROUTES.ABOUT_APP} exact component={AboutAppPage} />
                    <Route path={ROUTES.FAQ} exact component={FaqPage} />
                    <Route path={ROUTES.AUTH} exact component={AuthPage} />
                    <Route path={ROUTES.EMAIL} exact component={EmailPage} />
                    <Route path={ROUTES.REGISTER_FINISH} exact component={RegisterFinish} />
                    <Route path={ROUTES.FOLLOWER} exact component={FollowerPage} />
                    <Route
                      path={ROUTES.FOLLOWER_PAYMENT_INFO}
                      exact
                      component={FollowerPaymentInfoPage}
                    />
                    <Route
                      path={ROUTES.FOLLOWER_PAYMENT_HISTORY}
                      exact
                      component={FollowerPaymentHistoryPage}
                    />
                    <Route
                      path={ROUTES.FOLLOWER_PAYMENT_CARDS}
                      exact
                      component={FollowerPaymentCardsPage}
                    />
                    <Route
                      path={ROUTES.FOLLOWER_LIKED_POSTS}
                      exact
                      component={FollowerFavoritePostsPage}
                    />
                    <Route
                      path={ROUTES.FOLLOWER_SAVED_POSTS}
                      exact
                      component={FollowerFavoritePostsPage}
                    />
                    <Route path={ROUTES.MAIN} exact component={MainPage} />
                    <Route path={ROUTES.PR} exact component={PrPage} />
                    <Route path={ROUTES.PAY_FINISH} exact component={PayFinish} />
                    <Route path={ROUTES.PROFILE} exact component={ProfilePage} />
                    <Route path={ROUTES.SELECT_CATEGORY} exact component={SelectCategoryPage} />
                    <Route path={ROUTES.SELECT_PROFILE} exact component={SelectProfilePage} />
                    <Route path={ROUTES.SET_PASSWORD_PAGE} exact component={SetPasswordPage} />
                    <Route path={ROUTES.EDIT_PASSWORD_PAGE} exact component={SetPasswordPage} />
                    <Route
                      path={ROUTES.SET_SUBSCRIPTION_AMOUNT}
                      exact
                      component={SetSubscriptionAmountPage}
                    />
                    <Route path={ROUTES.SUBSCRIBE} exact component={SubscribePage} />
                    <Route
                      path={ROUTES.FOLLOWER_SUBSCRIBE}
                      exact
                      component={FollowerSubscribePage}
                    />

                    <Route
                      path={ROUTES.FOLLOWER_SUBSCRIPTIONS}
                      exact
                      component={FollowerSubscriptionsPage}
                    />
                    <Route path={ROUTES.FOLLOWER_NOTIFICATIONS} component={NotificationsPage} />
                    <Route path={ROUTES.FOLLOWER_ACCOUNT} component={AccountPage} />
                    <Route path={ROUTES.INSIDERS_NICK} exact component={InsiderPage} />
                    <Route
                      path={ROUTES.INSIDERS_NICK_COMMENTS}
                      exact
                      component={InsiderCommentsPage}
                    />
                    <Route path={ROUTES.INSIDERS_NICK_DONATE} exact component={InsiderDonatePage} />
                    <Route path={ROUTES.PROFILE_ABOUT} exact component={ProfileAboutPage} />
                    <Route
                      path={ROUTES.PROFILE_ADD_AVATAR}
                      exact
                      component={ProfileAddAvatarPage}
                    />
                    <Route
                      path={ROUTES.PROFILE_ADD_BACKGROUND}
                      exact
                      component={ProfileAddBackgroundPage}
                    />
                    <Route path={ROUTES.PROFILE_ADD_POST} exact component={ProfileAddPostPage} />
                    <Route path={ROUTES.PROFILE_CATEGORY} exact component={ProfileCategoryPage} />
                    <Route path={ROUTES.PROFILE_COMMENTS} exact component={ProfileCommentsPage} />
                    <Route
                      path={ROUTES.PROFILE_SELECT_AVATAR}
                      exact
                      component={ProfileSelectAvatarPage}
                    />
                    <Route
                      path={ROUTES.PROFILE_SUBSCRIPTION_AMOUNT}
                      exact
                      component={ProfileSetSubscriptionAmountPage}
                    />
                    <Route path={ROUTES.PROFILE_SETTINGS} exact component={ProfileSettingsPage} />
                    <Route path={ROUTES.PROFILE_CHATS} component={ChatProfilePage} />
                    <Route path={ROUTES.PROFILE_SOCIAL_LINKS} component={ProfileSocialLinks} />
                    <Route path={ROUTES.PROFILE_NOTIFICATIONS} component={NotificationsPage} />
                    <Route path={ROUTES.PROFILE_INCOME} component={IncomePage} />
                    <Route path={ROUTES.PROFILE_TARGETS} component={TargetsPage} />
                    <Route path={ROUTES.PROFILE_FOLLOWER} component={InfluencerFollowerPage} />
                    <Route
                      path={ROUTES.PROFILE_FOLLOWER_LIST}
                      component={InfluencerFollowerListPage}
                    />
                    <Route path={ROUTES.PROFILE_RANDOM_LIST} component={RandomFollowersPage} />
                    <Route path={ROUTES.PROFILE_SETTINGS_CHATS} component={SettingsChatsPage} />
                    <Route path={ROUTES.PROFILE_SETTINGS_PRIVACY} component={SettingsPrivacyPage} />
                    <Route path={ROUTES.PAGE_404} component={NoMatchPage} status={404} />
                    <Route path={ROUTES.TEMPLATES_CHANGE_PASSWORD} component={ChangePassword} />
                    <Route path={ROUTES.FOLLOWER_RATING} component={RatingPage} />
                    <Route path="/test" component={TestPage} />
                    <Route path="*" component={NoMatchPage} status={404} />
                  </Switch>
                </LayoutProvider>
              </BrowserRouter>
            </NoInternetConnection>
          </ChatProvider>
        </WithAxios>
      </UserProvider>
    </HelmetProvider>
  )
}

export default App

import React from 'react'
import PropTypes from 'prop-types'
import { ROUTES } from '../../../constants/routes'
import { useHistory } from 'react-router-dom'

function CheckboxAgreement({ className, classLabel, onChange, checked }) {
  const history = useHistory()
  const handleChange = (e) => {
    onChange(e.target.checked)
  }

  const handleOfferPayment = () => history.push(ROUTES.OFFER_PAYMENT)

  const handleConfidentiality = () => history.push(ROUTES.CONFIDENTIALITY)

  return (
    <div className={`checkboxAgreement ${className}`}>
      <div>
        <label className="switch">
          <input type="checkbox" onChange={handleChange} checked={checked} />
          <span className="square">
            <span className="fill" />
          </span>
        </label>
      </div>
      <div className={`label ${classLabel}`}>
        Я согласен(-сна) с условиями{' '}
        <span onClick={handleOfferPayment}>оферты рекуррентных платежей</span> и{' '}
        <span onClick={handleConfidentiality}>политикой обработки персональных данных</span>
      </div>
    </div>
  )
}

CheckboxAgreement.defaultProps = {
  className: '',
  classLabel: '',
  onChange: () => {},
  checked: false,
}

CheckboxAgreement.propTypes = {
  className: PropTypes.string,
  classLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

export default CheckboxAgreement

import React, { useContext, useEffect, useReducer } from 'react'
import { Header } from '../../components/Header/Header'
import { InfluencerAvatar } from '../../components/Profile/InfluencerAvatar'
import { appConfig } from '../../constants/appConfig'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button } from '../../components/Layout/Button/Button'
import { Footer } from '../../components/Footer/Footer'
import { formatPrice, makeUrl, simpleReducer } from '../../helpers'
import { formatDate } from '../../helpers/time'
// import { mock } from '../../mock/profile/mock'
const mock = []
import { getSubscriberByNick } from '../../api/influencer'
import { ROUTES } from '../../constants/routes'
import { update } from '../../api/storage'
import { UserContext } from '../../contexts/userContext'

const typeLabels = appConfig.income.labels

function InfluencerFollowerPage() {
  const { followerNick } = useParams()
  const history = useHistory()
  const location = useLocation()
  const initialState = {
    subscriberData: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { userType } = useContext(UserContext)

  const loadData = async () => {
    try {
      const result = await getSubscriberByNick(followerNick)
      setState({ subscriberData: result })
    } catch (e) {
      if (e?.response?.status === 400) {
        history.push(ROUTES.PAGE_404)
      }
    }
  }

  const handleClickChat = () => {
    if (state.subscriberData?.followerId) {
      update(appConfig.storage.keys.backFromChats, location.pathname)
      history.push(
        makeUrl({
          route: ROUTES.PROFILE_CHATS,
          routeParams: {
            userType: userType.toLowerCase(),
            interlocutorId: state.subscriberData?.followerId,
          },
        }),
      )
    }
  }

  useEffect(() => {
    loadData()
  }, [followerNick])

  const renderIncomeItem = (item) => {
    return (
      <div key={item.key} className="income-list-item">
        <div className="d-flex justify-content-between mb-2">
          <div className="amount">+ {formatPrice(item.amount)}</div>
          <div className="date">{formatDate(item.date)}</div>
        </div>
        <div className="type">{typeLabels[item.type]}</div>
        {item.title && <div className="title">{item.title}</div>}
      </div>
    )
  }

  return (
    <>
      <Header
        title="Профиль подписчика"
        needRight
        to={location.state?.backTo || makeUrl({ route: ROUTES.PROFILE_FOLLOWER_LIST })}
      />
      <div className="content content-padding influencer-follower-profile">
        <InfluencerAvatar photoUrl={state.subscriberData?.avatarUrl} />
        <div className="nickName">{state.subscriberData?.nickname}</div>
        <div className="subscribeDate">
          Подписка действует до {formatDate(state.subscriberData?.subscriptionExpirationDate)}
        </div>
        <Button color="dark" onClick={handleClickChat}>
          Сообщение
        </Button>
        <hr className="mt-4 mb-3" />
        {mock.length > 0 && (
          <>
            {' '}
            <div className="subTitle">Покупки у вас</div>
            <div className="income-list">{mock.map(renderIncomeItem)}</div>
          </>
        )}
      </div>
      <Footer />
    </>
  )
}

export default InfluencerFollowerPage

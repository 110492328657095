import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { navigate } from '@reach/router'
import { appConfig } from '../../constants/appConfig'
import { ROUTES } from '../../constants/routes'
import clsx from 'clsx'

export function Header({
  title,
  needRight = true,
  needLeft = true,
  to = ROUTES.AUTH,
  onBackAction = false,
  onForwardAction = false,
  isWhiteTheme = false,
  disableForwardButton = false,
  rightBlock = null,
}) {
  const blackArrowLeft = `${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`
  const points = `${appConfig.cdnPath.iconsSvg}/points.svg`
  const greyArrowLeft = `${appConfig.cdnPath.iconsSvg}/grey-arrow-left.svg`
  const titleColorClass = isWhiteTheme ? ' text-color-white ' : ''

  const renderArrow = () => {
    if (typeof onBackAction === 'function') {
      return (
        <a className="pr-2" onClick={onBackAction} aria-label="Back">
          <img
            src={isWhiteTheme ? greyArrowLeft : blackArrowLeft}
            width="16px"
            height="14px"
            alt=""
            aria-label="Назад"
          />
        </a>
      )
    } else if (to !== 'back') {
      return (
        <Link className="pr-2" to={to} aria-label="Back">
          <img
            src={isWhiteTheme ? greyArrowLeft : blackArrowLeft}
            width="16px"
            height="14px"
            alt=""
          />
        </Link>
      )
    } else if (to === 'back') {
      return (
        <a className="pr-2" onClick={() => navigate(-1)}>
          <img
            src={isWhiteTheme ? greyArrowLeft : blackArrowLeft}
            width="16px"
            height="14px"
            alt=""
          />
        </a>
      )
    }
  }

  return (
    <header className="pb-4">
      {needLeft && renderArrow()}
      <div className={`flex-grow-1 text-center bold-17-24 ${titleColorClass}`}>{title}</div>
      {typeof onForwardAction === 'function' && (
        <div
          className={clsx(
            'text-color-green semiBold-15-24',
            disableForwardButton ? 'button-text-regular-disable' : '',
          )}
          onClick={disableForwardButton ? () => {} : onForwardAction}
          role="button"
        >
          Готово
        </div>
      )}
      {needRight && (
        <div className="right-block">
          {rightBlock || <img src={points} width="16px" height="4px" alt="" />}
        </div>
      )}
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  needRight: PropTypes.bool,
  needLeft: PropTypes.bool,
  to: PropTypes.string,
  onBackAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onForwardAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  disableForwardButton: PropTypes.bool,
  isWhiteTheme: PropTypes.bool,
  rightBlock: PropTypes.any,
}

import * as React from 'react'
import { useContext, useEffect, useMemo, useReducer } from 'react'
import { Input } from '../components/Layout/Input/Input'
import { Button } from '../components/Layout/Button/Button'
import InputPassword from '../components/Layout/InputPassword/InputPassword'
import { emailLogin, getAuthData, loginReset, setAuthData } from '../api/auth'
import { authRedirects, simpleReducer } from '../helpers'
import { ROUTES } from '../constants/routes'
import { useHistory } from 'react-router-dom'
import { logicalFlows, useLogicalFlow } from '../hooks/useLogicalFlow'
import { appConfig } from '../constants/appConfig'
import { Header } from '../components/Header/Header'
import EmailModal, { modalTypes } from '../components/Auth/EmailModal'
import Checkbox from '../components/Layout/Checkbox/Checkbox'
import { apiCodes } from '../constants/apiCodes'
import { errorsJsx } from '../constants/errorsJsx'
import { UserContext } from '../contexts/userContext'
import { useIdsHash } from '../hooks/useIdsHash'
import { find, remove, update } from '../api/storage'
import { modes as authScreenMode } from './Auth'

const validator = require('email-validator')

const { min: MIN_PASS_LENGTH, max: MAX_PASS_LENGTH } = appConfig.passwordLength

const modes = {
  checkRegisterByEmail: 'checkRegisterByEmail',
  registerByEmail: 'registerByEmail',
  loginByEmail: 'loginByEmail',
  auth: 'loginByEmail',
  register: 'registerByEmail',
}

const EmailPage = () => {
  const history = useHistory()
  const { getFirstFlowData, setFlowData } = useLogicalFlow()
  const initialState = {
    email: '',
    password: '',
    rememberMe: false,
    emailError: '',
    passwordError: '',
    isModalOpen: false,
    modalType: '',
    logicalFlowData: null,
    isDisableButton: false,
    isDisableLostPass: false,
    pageMode: modes[find(appConfig.storage.keys.welcomeMode, modes.checkRegisterByEmail)],
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { setUser, setUserType } = useContext(UserContext)
  const { checkHashData } = useIdsHash()

  useEffect(() => {
    const authData = getAuthData()
    if (Object.keys(authData).length) {
      setState({
        email: authData.email,
        password: authData.password,
        rememberMe: authData.rememberMe,
      })
    }
  }, [])

  useEffect(() => {
    getFirstFlowData(
      [logicalFlows.subscribe.id, logicalFlows.favoriteIconsPermissions.id],
      (data) => {
        const { key, ...result } = data
        setState({
          logicalFlowData: {
            ...result,
            resetFlowData: () => {
              if (key) {
                setFlowData(key, null)
              }
            },
          },
        })
      },
    )
  }, [])

  const handleLoginError = (error) => {
    let stateParams = {}
    switch (error) {
      case apiCodes.passwordRequired:
        stateParams = { emailError: errorsJsx.auth.userDuplicate }
        break
      case apiCodes.emailConfirmRequestSent:
        stateParams = {
          isModalOpen: true,
          modalType: modalTypes.register,
        }
        break
      case apiCodes.accountHardBanned:
        stateParams = {
          isModalOpen: true,
          modalType: modalTypes.accountHardBanned,
        }
        break
      case apiCodes.lockedOut:
        stateParams = {
          isModalOpen: true,
          modalType: modalTypes.lockedOut,
        }
        break
      default:
        break
    }
    if (Object.keys(stateParams).length) {
      setState({
        ...stateParams,
        isDisableButton: false,
        isDisableLostPass: false,
      })
    } else {
      setState({
        passwordError: errorsJsx.auth.failLoginOrPass,
        emailError: errorsJsx.auth.failLoginOrPass,
        isDisableLostPass: false,
      })
    }
  }

  const checkRegisterEmail = async () => {
    const emailValid = validator.validate(state.email)
    if (emailValid) {
      try {
        const {
          state: { id },
        } = await emailLogin(state.email, null, null, state.pageMode === modes.registerByEmail)
        handleLoginError(id)
      } catch (e) {
        if (e.response?.status === 404) {
          setState({
            pageMode: modes.registerByEmail,
            isDisableButton: false,
            isDisableLostPass: false,
          })
        } else if (e.response?.data?.state?.id) {
          handleLoginError(e.response?.data?.state?.id)
        }
      }
    } else {
      setState({
        emailError: errorsJsx.auth.inputValidMail,
        isDisableButton: false,
        isDisableLostPass: false,
      })
    }
  }

  const loginByEmailAndPass = async () => {
    const emailValid = validator.validate(state.email)
    const passValid =
      state.password.length >= MIN_PASS_LENGTH &&
      state.password.length <= MAX_PASS_LENGTH &&
      !/^ *$/.test(state.password)

    if (emailValid && passValid) {
      try {
        const authData = await emailLogin(state.email, state.password)
        if (authData?.state?.id) {
          return handleLoginError(authData?.state?.id)
        }
        setAuthData({ email: state.email, password: state.password, rememberMe: state.rememberMe })
        checkHashData({
          likedPostsHash: authData?.user?.follower?.likedPostsHash,
          savedPostsHash: authData?.user?.follower?.savedPostsHash,
          likedCommentsHash: authData?.user?.follower?.likedCommentsHash,
          userId: authData?.user?.id,
        })
        authRedirects({
          authData,
          history,
          logicalFlowData: state.logicalFlowData,
          setUser,
          setUserType,
        })
      } catch (e) {
        handleLoginError(e.response?.data?.state?.id)
      }
    } else {
      setState({
        isDisableButton: false,
        isDisableLostPass: false,
        emailError: !emailValid ? errorsJsx.auth.inputValidMail : '',
        passwordError: !passValid ? errorsJsx.auth.failPassLength : '',
      })
    }
  }

  const sendReset = async () => {
    const emailValid = validator.validate(state.email)
    if (emailValid) {
      setFlowData(logicalFlows.favoriteIconsPermissions.id, null)
      try {
        await loginReset(state.email)
        setState({
          isModalOpen: true,
          modalType: modalTypes.resetSuccess,
          isDisableButton: false,
          isDisableLostPass: false,
        })
      } catch (e) {
        let errorType = modalTypes.dataError
        if (e?.response?.status === 404) {
          errorType = modalTypes.resetError
        } else if (
          e?.response?.status === 400 &&
          e?.response?.data === apiCodes.resetAttemptsUsed
        ) {
          errorType = modalTypes.resetLimit
        }
        setState({
          isModalOpen: true,
          modalType: errorType,
          isDisableButton: false,
          isDisableLostPass: false,
        })
      }
    } else {
      setState({
        emailError: errorsJsx.auth.inputValidMail,
        isDisableButton: false,
        isDisableLostPass: false,
      })
    }
  }

  const handleCloseModal = () => {
    if (state.modalType === modalTypes.register) {
      setState({ isModalOpen: false })
      remove(appConfig.storage.keys.welcomeMode)
      history.push(ROUTES.AUTH)
    } else if (
      state.modalType === modalTypes.resetSuccess ||
      state.modalType === modalTypes.disableRegistration
    ) {
      setState({ isModalOpen: false })
      remove(appConfig.storage.keys.welcomeMode)
      history.push(ROUTES.AUTH)
    } else {
      setState({ isModalOpen: false })
    }
  }

  const handleButtonClick = (action) => {
    setState({ isDisableButton: true, isDisableLostPass: true })
    action()
  }

  const isDisableByForm = useMemo(() => {
    return (
      (state.pageMode === modes.registerByEmail && !state.email) ||
      (state.pageMode === modes.loginByEmail && (!state.email || !state.password))
    )
  }, [state.pageMode, state.email, state.password])

  return (
    <>
      <div className="auth">
        <Header
          needRight={false}
          title={
            [modes.checkRegisterByEmail, modes.registerByEmail].includes(state.pageMode)
              ? 'Регистрация через почту'
              : 'Авторизация через почту'
          }
          to={ROUTES.AUTH}
        />
        <h1 className="bold-24-32 mt-5">Почта</h1>
        <Input
          type="email"
          onKeyPress={(event) => {
            if (event.key === 'Enter' && state.pageMode === modes.loginByEmail) {
              loginByEmailAndPass()
            }
          }}
          value={state.email}
          onChange={(newEmail) => {
            setState({ email: newEmail.replace(' ', ''), emailError: '', isDisableButton: false })
          }}
          placeholder="Введите email"
          autofocus
          iconClassName="deleteicon-green"
          inputClassName={`medium-14-20 ${state.emailError !== '' ? 'border-bottom-error' : ''}`}
        />
        <div className="text-color-error medium-12-16 mt-2">{state.emailError}</div>

        {state.pageMode === modes.loginByEmail && (
          <>
            <h1 className="bold-24-32 mt-5">Пароль</h1>
            <InputPassword
              value={state.password}
              onChange={(newPass) => {
                setState({
                  password: newPass.target.value,
                  passwordError: '',
                  isDisableButton: false,
                })
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && state.pageMode === modes.loginByEmail) {
                  loginByEmailAndPass()
                }
              }}
              inputClassName={`medium-14-20 ${
                state.passwordError !== '' ? 'border-bottom-error' : ''
              }`}
            />
            <div className="text-color-error medium-12-16 mt-2">{state.passwordError}</div>
          </>
        )}

        {state.pageMode === modes.loginByEmail && (
          <>
            <div className="d-flex mt-5">
              <Checkbox
                className="w-100"
                onChange={() => setState({ rememberMe: !state.rememberMe })}
                checked={state.rememberMe}
                label="Запомнить меня"
              />
            </div>
            <div className="d-flex mt-2">
              <span
                className="text-color-green semiBold-14-20"
                onClick={state.isDisableLostPass ? () => {} : () => handleButtonClick(sendReset)}
              >
                Забыли пароль?
              </span>
            </div>
          </>
        )}

        <div className="pt-4">
          <Button
            onClick={() =>
              [modes.checkRegisterByEmail, modes.registerByEmail].includes(state.pageMode)
                ? handleButtonClick(checkRegisterEmail)
                : handleButtonClick(loginByEmailAndPass)
            }
            className="mt-5 semiBold-14-20"
            disable={state.isDisableButton || state.isModalOpen || isDisableByForm}
          >
            {state.pageMode === modes.checkRegisterByEmail
              ? 'Продолжить'
              : state.pageMode === modes.registerByEmail
              ? 'Зарегистрироваться'
              : 'Войти'}
          </Button>
        </div>
        <div className="d-flex justify-content-center mt-5 pb-4">
          <span
            className="text-color-green semiBold-14-20"
            onClick={() => {
              setState({
                pageMode: [modes.checkRegisterByEmail, modes.registerByEmail].includes(
                  state.pageMode,
                )
                  ? modes.loginByEmail
                  : modes.registerByEmail,
                emailError: '',
                passwordError: '',
                isDisableButton: false,
                isDisableLostPass: false,
              })
              update(
                appConfig.storage.keys.welcomeMode,
                modes.registerByEmail === state.pageMode
                  ? authScreenMode.auth
                  : authScreenMode.register,
              )
            }}
          >
            {[modes.checkRegisterByEmail, modes.registerByEmail].includes(state.pageMode)
              ? 'У меня уже есть аккаунт'
              : 'У меня еще нет аккаунта'}
          </span>
        </div>
      </div>

      <EmailModal
        isOpen={state.isModalOpen}
        type={state.modalType}
        onOpened={() => setState({ isDisableButton: false })}
        onClose={handleCloseModal}
        email={state.email}
      />
    </>
  )
}

export default EmailPage

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import clsx from 'clsx'
import { useWindowSize } from '../../hooks/useWindowSize'

const CropImage = ({ image, onCrop, isAvatar = false }) => {
  const cropperRef = useRef(null)
  const size = useWindowSize()

  const getCropBoxAvatarParams = (imageData) => {
    const cropBoxSide = Math.min(imageData.height, imageData.width)
    return {
      width: cropBoxSide,
      height: cropBoxSide,
      left: imageData.width / 2 - cropBoxSide / 2,
      top: imageData.height / 2 - cropBoxSide / 2,
    }
  }
  const getCropBoxBackgroundParams = (imageData) => {
    const backgroundHeight = 168 //фиксированная высота подложки
    const backgroundMargins = imageData.width * 0.042 //отступы 4.2%
    return {
      width: imageData.width,
      height: (backgroundHeight * imageData.width) / (imageData.width - backgroundMargins),
      left: 0,
      top: imageData.height / 2 - backgroundHeight / 2,
    }
  }

  const setCropBoxParams = () => {
    const cropper = cropperRef?.current?.cropper
    const imageData = cropper?.getImageData()

    if (imageData?.height && imageData?.width) {
      if (isAvatar) {
        cropper.setCropBoxData(getCropBoxAvatarParams(imageData))
      } else {
        cropper.setCropBoxData(getCropBoxBackgroundParams(imageData))
      }
    }
  }

  const handleCrop = () => {
    const cropper = cropperRef?.current?.cropper
    onCrop(cropper?.getCroppedCanvas()?.toDataURL())
  }

  return (
    <div
      className={clsx('layout3-content d-flex justify-content-center align-items-center', {
        'avatar-container': isAvatar,
      })}
      style={{ height: (size.height || 130) - 130 }}
    >
      {image && (
        <Cropper
          viewMode={1}
          ref={cropperRef}
          src={image}
          guides={false}
          highlight={false}
          background={false}
          zoomable={false}
          center={false}
          responsive={false}
          movable={false}
          rotatable={false}
          scalable={false}
          cropBoxResizable={false}
          ready={() => {
            setCropBoxParams()
            handleCrop()
          }}
          cropstart={(e) => {
            if (e.detail.action !== 'all') {
              /**
               * https://github.com/fengyuanchen/cropperjs#cropstart-1
               * разрешаем только двигать изначальный cropBox, нельзя изменять его размер и создавать новые
               */
              e.preventDefault()
            }
          }}
          cropend={handleCrop}
        />
      )}
    </div>
  )
}
CropImage.propTypes = {
  image: PropTypes.string.isRequired,
  onCrop: PropTypes.func.isRequired,
  isAvatar: PropTypes.bool,
}

export default CropImage

import { useCallback, useEffect } from 'react'
import { throttle } from 'lodash-es'

const scrollDelta = 50

export function useScroll({
  ref = null,
  onScroll = null,
  onReachedTop = null,
  onReachedBottom = null,
}) {
  const scrollTarget = ref?.current || window
  const throttleScroll = throttle(() => {
    const currentScroll = scrollTarget.scrollY || scrollTarget.scrollTop
    if (onScroll) {
      onScroll(currentScroll)
    }
    if (onReachedTop && currentScroll <= scrollDelta) {
      onReachedTop()
    }
    if (onReachedBottom) {
      const maxScroll = scrollTarget.scrollHeight || document.body.scrollHeight
      if (currentScroll + scrollTarget.innerHeight + scrollDelta >= maxScroll) {
        onReachedBottom()
      }
    }
  }, 500)

  const infiniteScroll = useCallback(() => {
    scrollTarget?.addEventListener('scroll', throttleScroll)
  }, [scrollTarget, throttleScroll])

  const removeScroll = useCallback(() => {
    scrollTarget?.removeEventListener('scroll', throttleScroll)
  }, [scrollTarget, throttleScroll])

  const scrollToTop = useCallback(() => {
    scrollTarget?.scrollTo(0, 0)
  }, [scrollTarget])

  const scrollToBottom = useCallback(() => {
    scrollTarget?.scrollTo(0, scrollTarget.scrollHeight || document.body.scrollHeight)
  }, [scrollTarget])

  const scrollToY = useCallback(
    (y) => {
      scrollTarget.scrollTo(0, y)
    },
    [scrollTarget],
  )

  useEffect(() => {
    if (onScroll || onReachedTop || onReachedBottom) {
      infiniteScroll()
      return () => removeScroll()
    }
  }, [infiniteScroll, onReachedBottom, onReachedTop, onScroll, removeScroll])

  return {
    scrollToTop,
    scrollToBottom,
    scrollToY,
  }
}

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextArea from '../../Layout/TextArea/TextArea'
import { Button } from '../../Layout/Button/Button'
import { getDescription, setDescription } from '../../../api/users'
import { appConfig } from '../../../constants/appConfig'
import { UserContext } from '../../../contexts/userContext'

function SetAbout({ onSelectProfile }) {
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const { user, influencerId } = useContext(UserContext)

  const setData = async () => {
    const { influencer } = user
    if (influencer) {
      await setDescription({ longDescription: text, influencerTitle: title })
      onSelectProfile()
    } else {
      console.log('user is not influencer')
    }
  }
  const loadDesc = async () => {
    if (influencerId) {
      const { influencerTitle, longDescription } = await getDescription(influencerId)
      if (influencerTitle) {
        setTitle(influencerTitle)
      }
      if (longDescription) {
        setText(longDescription)
      }
    }
  }
  useEffect(() => {
    loadDesc()
  }, [influencerId])

  return (
    <>
      <header className="mb-4">
        <a
          className="pr-2"
          onClick={() => {
            onSelectProfile()
          }}
        >
          {' '}
          <img
            src={`${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`}
            width="16px"
            height="14px"
            alt=""
          />
        </a>
        <div className="flex-grow-1 text-center bold-17-24">Об авторе</div>
      </header>
      <div className="content content-padding profile-about">
        <TextArea
          onChange={setTitle}
          value={title}
          autofocus
          placeholder="Заголовок"
          className="mt-2 mb-5"
          showCounterLimit
          maxlength={50}
        />
        <TextArea
          onChange={setText}
          value={text}
          placeholder="Текст"
          showCounterLimit
          maxlength={1000}
        />
      </div>
      <div className="content-padding pb-4">
        <Button
          className="semiBold-15-24"
          onClick={setData}
          color="regular"
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          disable={title.length === 0}
        >
          Сохранить
        </Button>
      </div>
    </>
  )
}

SetAbout.defaultProps = {
  onSelectProfile: () => {},
}

SetAbout.propTypes = {
  onSelectProfile: PropTypes.func,
}

export default SetAbout

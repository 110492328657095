import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import clsx from 'clsx'
import { appConfig } from '../../constants/appConfig'

export function Search({
  value,
  placeholder = 'Найти',
  onChange,
  onSearch = null,
  onClear = null,
  filterMode = true,
}) {
  const handleChange = (e) => {
    const query = e.target.value
    onChange(query)
  }

  const handleClear = () => {
    if (onClear) {
      onClear()
    }
  }

  return (
    <div className="search">
      <div className="search-input">
        <input
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={clsx({ filter: filterMode })}
        />
        {onClear && value?.length > 0 && (
          <div className="search-input-clear" onClick={handleClear}>
            <img src={`${appConfig.cdnPath.iconsSvg}/cross-white-around.svg`} alt="" />
          </div>
        )}
      </div>
      {!filterMode && (
        <Button disabled={!value?.length} onClick={() => onSearch(value)} outline={false}>
          <img src={`${appConfig.cdnPath.iconsSvg}/search-grey.svg`} alt="" />
        </Button>
      )}
    </div>
  )
}

Search.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  filterMode: PropTypes.bool,
}

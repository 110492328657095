import { createContext } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../constants/appConfig'

export const UserContext = createContext({
  user: {},
  userType: null,
  currentTypeId: null,
  influencerId: null,
  followerId: null,
  isFollower: false,
  isInfluencer: false,
  isAdmin: false,
  isAuthorized: false,
  setUser: () => {},
  setUserType: () => {},
  idsHash: {},
  setIdsHash: () => {},
})

UserContext.Provider.propTypes = {
  user: PropTypes.shape({}),
  userType: PropTypes.oneOf(Object.values(appConfig.userTypes)),
  currentTypeId: PropTypes.string,
  influencerId: PropTypes.string,
  followerId: PropTypes.string,
  isFollower: PropTypes.bool,
  isInfluencer: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  setUser: PropTypes.func,
  setUserType: PropTypes.func,
  idsHash: PropTypes.shape({}),
  setIdsHash: PropTypes.func,
}

import { api } from './api'

export const deletePostAdmin = async (blogPostId) => {
  return await api.del({
    path: `/insider/administrator/${blogPostId}/delete`,
    isDirect: true,
  })
}

export const deleteCommentAdmin = async ({ postId, commentId }) => {
  return await api.del({
    path: `/insider/administrator/comments/${postId}/delete`,
    isDirect: true,
    data: {
      commentId,
    },
  })
}

import React, { useReducer } from 'react'
import ProfileSettings from '../../components/Profile/ProfileSettings/ProfileSettings'
import SetAbout from '../../components/Profile/ProfileSettings/SetAbout'
import DescriptionProfile from '../../components/Profile/EmptyProfile/DescriptionProfile'
import { simpleReducer } from '../../helpers'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'

export const pageMode = {
  settings: 'settings',
  about: 'about',
  donats: 'donats',
  subscriptions: 'subscriptions',
  categories: 'categories',
}

// markup
const ProfileSettingsPage = ({ location }) => {
  const history = useHistory()
  const { pageOption, backScreen } = queryString.parse(location.search)

  const initialState = {
    mode: pageMode[pageOption] ? pageMode[pageOption] : pageMode.settings,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handlePageMode = (mode) => setState({ mode })

  const handleSetAboutBackLink = () => {
    if (pageMode[pageOption]) {
      if (backScreen === 'profile') {
        history.push(ROUTES.PROFILE)
      } else if (backScreen === 'profileAbout') {
        history.push(ROUTES.PROFILE_ABOUT)
      } else {
        history.push(ROUTES.PROFILE)
      }
    } else {
      handlePageMode(pageMode.settings)
    }
  }

  const renderBody = () => {
    switch (state.mode) {
      case pageMode.settings:
        return <ProfileSettings onSelectAbout={() => history.push(ROUTES.PROFILE_ABOUT)} />
      case pageMode.about:
        return <SetAbout onSelectProfile={handleSetAboutBackLink} />
      case pageMode.subscriptions:
        return <DescriptionProfile />
      default:
        return null
    }
  }

  return renderBody()
}

ProfileSettingsPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

export default ProfileSettingsPage

import { api } from './api'
import { useQuery } from 'react-query'

const ORDER_KEY = 'order'
const CARDS_KEY = 'cardsList'

export const getOrder = (orderId) => {
  return api.post({
    path: `/insider/subscriptions/finish/${orderId}`,
  })
}

export const getCards = () => {
  return api.get({
    path: '/insider/pay/cards/current',
  })
}

export const deleteCard = (cardId) => {
  return api.put({
    path: `/insider/subscriptions/${cardId}/delete`,
  })
}

export const pay = ({
  influencerId,
  paymentType,
  paymentDetail = null,
  amount = null,
  bankCardId = null,
  returnUrl = null,
  isAgree,
  donateTargetId = null,
}) => {
  return api.post({
    path: `/api/pay`,
    data: {
      ...(influencerId && { influencerId }),
      paymentType,
      ...(amount && { amount }),
      ...(bankCardId && { bankCardId }),
      ...(returnUrl && { returnUrl }),
      ...(paymentDetail && { paymentDetail }),
      ...(donateTargetId && { donateTargetId }),
      AgreementChecked: isAgree,
    },
  })
}

export const payFinish = (orderId) => {
  return api.post({
    path: `/api/pay/finish/${orderId}`,
  })
}

/*export function useOrderQuery(id) {
  return useQuery(id && [ORDER_KEY, id], () => getOrder(id))
}*/

export const useCardList = (params) => {
  return useQuery([CARDS_KEY], getCards, params)
}

import React from 'react'
import PropTypes from 'prop-types'
import { ROUTES } from '../../../constants/routes'
import queryString from 'query-string'
import { pageMode as profileSettingsPageMode } from '../../../screens/profile/settings'
import SocialLinks from '../../Main/SocialLinks'
import { appConfig } from '../../../constants/appConfig'
import { useHistory } from 'react-router-dom'

export function About({ about: { title, text }, socialLinks = null, influencerNick }) {
  const history = useHistory()
  const final = text?.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

  const handleEditDescription = () => {
    history.push(
      `${ROUTES.PROFILE_SETTINGS}?${queryString.stringify({
        pageOption: profileSettingsPageMode.about,
        backScreen: 'profile',
      })}`,
    )
  }
  return (
    <>
      {!(title || text) ? (
        <div className="text-center pt-4">
          <img src={`${appConfig.cdnPath.iconsSvg}/pages.svg`} width="98px" height="98px" alt="" />
          <div className="medium-15-24 mb-3 mt-4 content-margin">
            Добавьте немного информации о себе.
          </div>
          <div
            className="text-color-green content-margin semiBold-15-24"
            onClick={handleEditDescription}
          >
            Добавить информацию
          </div>
        </div>
      ) : (
        <>
          <div className="bold-17-24 mb-3 content-margin">{title}</div>
          <div
            className="text-color-regular content-margin medium-15-24 text-break text-pre-line"
            dangerouslySetInnerHTML={{ __html: final }}
          />
          <SocialLinks links={socialLinks} influencerNick={influencerNick} />
          <div
            className="my-3 semiBold-13-20 text-color-regular content-margin text-color-green pointer"
            onClick={handleEditDescription}
          >
            Редактировать
          </div>
        </>
      )}
    </>
  )
}

About.propTypes = {
  about: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  socialLinks: PropTypes.any,
  influencerNick: PropTypes.string,
}

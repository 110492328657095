import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useWebpLinks } from '../../../../hooks/useWebpLinks'
import { VideoPlayer } from '../../../Player/VideoPlayer'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { VideoPreview } from './VideoPreview'
import InfoModal from '../../../Modal/InfoModal'
import { errorsJsx } from '../../../../constants/errorsJsx'
import { getAuthIp } from '../../../../api/auth'
export function PostPreview({
  image,
  path,
  isVideo,
  style,
  imageClassName,
  isMain,
  params = { w: 480 },
  hasSecondary,
  onClick = () => {},
  influencerId,
}) {
  const [isVideoStarted, setVideoStarted] = useState(false)
  const [isModalBackendOpen, setIsModalBackendOpen] = useState(false)
  const { width, height } = useWindowSize()
  const { link: img, oldLink } = useWebpLinks({
    path: `//${path}`,
    params,
  })
  if (!path) {
    return null
  }

  const checkBackend = (action) => {
    getAuthIp()
      .then(() => {
        action()
      })
      .catch(() => {
        setIsModalBackendOpen(true)
      })
  }

  if (isVideo && !path) {
    return (
      <div
        className={clsx(`screenshot-no-image`, { 'screenshot-main': isMain })}
        style={style}
        onClick={onClick}
      />
    )
  }
  if (!isVideo) {
    return (
      <picture onClick={onClick}>
        <source srcSet={img} type="image/webp" />
        <source srcSet={oldLink} type={image?.contentType} />
        <img src={oldLink} alt="" style={style} className={imageClassName} />
      </picture>
    )
  }

  if (isMain && !hasSecondary) {
    const currWidth = width < 480 ? width * 0.916 : 439
    const style = image?.axisXSize
      ? {
          width: currWidth,
          height: (currWidth * image.axisYSize) / image.axisXSize,
          maxHeight: isVideoStarted ? height - 56 : (currWidth * image.axisYSize) / image.axisXSize,
        }
      : {}
    if (!isVideoStarted) {
      return (
        <>
          <VideoPreview
            style={style}
            src={img}
            onClick={() => checkBackend(() => setVideoStarted(true))}
          />
          <InfoModal
            isOpen={isModalBackendOpen}
            onClose={() => setIsModalBackendOpen(false)}
            message={errorsJsx.backendPingError}
          />
        </>
      )
    }
    return (
      <VideoPlayer
        screenshotUrl={path}
        id={image?.id}
        style={style}
        securityParams={params}
        autoplay={image?.id}
        isActiveSlide={true}
        influencerId={influencerId}
        size={{ axisXSize: image.axisXSize, axisYSize: image.axisYSize }}
      />
    )
  }

  return (
    <>
      <VideoPreview
        style={style}
        src={img}
        oldLink={oldLink}
        onClick={() => checkBackend(onClick)}
      />
      <InfoModal
        isOpen={isModalBackendOpen}
        onClose={() => setIsModalBackendOpen(false)}
        message={errorsJsx.backendPingError}
      />
    </>
  )
}

PostPreview.propTypes = {
  image: PropTypes.object,
  path: PropTypes.string,
  isVideo: PropTypes.bool,
  isMain: PropTypes.bool,
  style: PropTypes.any,
  imageClassName: PropTypes.string,
  params: PropTypes.object,
  hasSecondary: PropTypes.bool,
  onClick: PropTypes.func,
  influencerId: PropTypes.string,
}

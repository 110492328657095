import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { nbsp } from '../../constants/typography'
import { makeUrl, numberDeclension } from '../../helpers'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import moment from 'moment'

const eventGroups = {
  newSubscriber: 'recentSubscriptionCount',
  newSale: 'newSale',
  newDonate: 'recentDonationCount',
}

const eventGroupIcons = {
  [eventGroups.newSubscriber]: 'profile-plus-green.svg',
  [eventGroups.newSale]: 'bag-green.svg',
  [eventGroups.newDonate]: 'donate-green.svg',
}

function NotificationStatistics({ statData }) {
  const history = useHistory()

  const handleDonate = () => {
    history.push(makeUrl({ route: ROUTES.PROFILE_INCOME }), {
      filterInitList: {
        [appConfig.filters.values.income]: appConfig.income.types.donate,
        [appConfig.filters.values.periodFrom]: moment().format(appConfig.dateFormats.apiDateTime),
        [appConfig.filters.values.periodTo]: moment().format(
          appConfig.dateFormats.apiDateTimeAllDay,
        ),
      },
    })
  }

  const getGroupContent = (statKey) => {
    const count = statData[statKey] || 0
    switch (statKey) {
      case eventGroups.newSubscriber:
        return (
          <>
            У вас{nbsp}
            <span>{count}</span>
            {nbsp}{' '}
            {numberDeclension(count, ['новый подписчик', 'новых подписчика', 'новых подписчиков'])}
          </>
        )
      case eventGroups.newSale:
        return (
          <>
            У вас{nbsp}
            <span>{count}</span>
            {nbsp}
            {numberDeclension(count, ['новая покупка', 'новых покупки', 'новых покупок'])} в
            маркетплейсе
          </>
        )
      case eventGroups.newDonate:
        return (
          <>
            У вас{nbsp}
            <span onClick={handleDonate}>{count}</span>
            {nbsp}
            {numberDeclension(count, ['новый донат', 'новых доната', 'новых донатов'])}
          </>
        )
    }
  }

  const renderGroupItem = (statKey) => {
    return (
      <div key={statKey} className="notifications-group-item">
        <img src={`${appConfig.cdnPath.iconsSvg}/${eventGroupIcons[statKey]}`} alt="" />
        {getGroupContent(statKey)}
      </div>
    )
  }

  return Object.keys(statData || {}).map(renderGroupItem)
}

NotificationStatistics.propTypes = {
  statData: PropTypes.object,
}

export default NotificationStatistics

import * as React from 'react'
import { setDefaultAvatar } from '../../api/users'
import SelectImage from '../../components/Profile/EmptyProfile/SelectImage'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../../contexts/userContext'

// markup
const SelectAvatarPage = () => {
  const { influencerId } = useContext(UserContext)
  const history = useHistory()
  const selectAvatar = async (avatarFileGuid, axisXOffset) => {
    if (avatarFileGuid) {
      if (influencerId) {
        await setDefaultAvatar({ avatarFileGuid, axisXOffset })
        history.push(ROUTES.PROFILE)
      }
    }
  }

  return <SelectImage onSelect={selectAvatar} />
}

export default SelectAvatarPage

import React from 'react'
import { Modal } from 'reactstrap'
import PropTypes from 'prop-types'
import CloseInfoForm from './CloseInfoForm'

function InfoModal({ isOpen, onOpened, onClose, message }) {
  return (
    <Modal isOpen={isOpen} centered={true} toggle={() => {}} size="sm" onOpened={onOpened}>
      <CloseInfoForm message={message} onClose={onClose} />
    </Modal>
  )
}

InfoModal.defaultProps = {
  isOpen: false,
  onOpened: () => {},
  onClose: () => {},
  message: '',
}

InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onOpened: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default InfoModal

import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { makeInstagramLink, makeMailLink, simpleReducer } from '../../helpers'
import { ellipsis, nbsp, newLine } from '../../constants/typography'

export const typeLines = {
  fiveLines: 'fiveLines',
  threeLines: 'threeLines',
}

const lineOptions = {
  [typeLines.fiveLines]: {
    charsInAllLines: 200,
    countLines: 5,
  },
  [typeLines.threeLines]: {
    charsInAllLines: 80,
    countLines: 3,
  },
}

function Paragraph({
  value,
  className = '',
  etcClassName,
  typeLine = typeLines.fiveLines,
  isReplaceMails = false,
}) {
  const divideToBlocks = (text) => {
    let stringArr = []
    const { charsInAllLines, countLines } = lineOptions[typeLine]
    const blocks = text?.split(newLine)
    blocks.forEach((block, index) => {
      const partString = stringArr.join(newLine)
      if (partString.length < charsInAllLines && index < countLines) {
        stringArr.push(block)
      }
    })
    return divideText(stringArr.join(newLine))
  }

  const divideText = (text) => {
    const partText = text?.substring(0, lineOptions[typeLine].charsInAllLines)
    if (partText?.length < text?.length) {
      const words = partText?.split(' ')
      words.pop()
      return words.join(' ')
    }
    return text
  }

  const initialState = {
    text: divideToBlocks(value),
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleEtcClick = () => {
    setState({ text: value })
  }

  const makeTextLinks = (text) => {
    return makeMailLink(makeInstagramLink(text))
  }

  return (
    <div className={`paragraph ${className}`}>
      {isReplaceMails ? (
        <div className="d-inline" dangerouslySetInnerHTML={{ __html: makeTextLinks(state.text) }} />
      ) : (
        state.text
      )}

      {state.text?.length < value?.length && (
        <>
          {nbsp}
          {ellipsis}
          <span onClick={handleEtcClick} className={etcClassName}>
            еще
          </span>
        </>
      )}
    </div>
  )
}

Paragraph.propTypes = {
  className: PropTypes.string,
  etcClassName: PropTypes.string,
  value: PropTypes.string,
  typeLine: PropTypes.oneOf(Object.values(typeLines)),
  isReplaceMails: PropTypes.bool,
}

export { Paragraph }

import React, { useContext, useEffect, useReducer } from 'react'
import { socialAuth } from '../api/auth'
import { useHistory } from 'react-router-dom'
import { logicalFlows, useLogicalFlow } from '../hooks/useLogicalFlow'
import { authRedirects, simpleReducer } from '../helpers'
import { useMutation } from 'react-query'
import { UserContext } from '../contexts/userContext'
import { useIdsHash } from '../hooks/useIdsHash'
import { errorsJsx } from '../constants/errorsJsx'
import InfoModal from '../components/Modal/InfoModal'
import { apiCodes } from '../constants/apiCodes'
import SelectButtons from '../components/Auth/SelectButtons'
import Welcome from '../components/Auth/Welcome'
import { find, remove, update } from '../api/storage'
import { appConfig } from '../constants/appConfig'

export const modes = {
  auth: 'auth',
  register: 'register',
}

// markup
const AuthPage = () => {
  const history = useHistory()
  const { getFirstFlowData, setFlowData } = useLogicalFlow()
  const { setUser, setUserType } = useContext(UserContext)
  const { checkHashData } = useIdsHash()

  const initialState = {
    isOpenModal: false,
    isWelcome: Boolean(!find(appConfig.storage.keys.welcomeMode)),
    mode: find(appConfig.storage.keys.welcomeMode, ''),
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const socialMutationAuth = useMutation(({ token, socialId, provider, redirectUrl }) =>
    socialAuth({ token, socialId, provider, redirectUrl }),
  )

  if (socialMutationAuth.isSuccess) {
    if (socialMutationAuth.data) {
      checkHashData({
        likedPostsHash: socialMutationAuth.data?.user?.follower?.likedPostsHash,
        savedPostsHash: socialMutationAuth.data?.user?.follower?.savedPostsHash,
        likedCommentsHash: socialMutationAuth.data?.user?.follower?.likedCommentsHash,
        userId: socialMutationAuth.data?.user?.id,
      })
    }
    getFirstFlowData(
      [logicalFlows.subscribe.id, logicalFlows.favoriteIconsPermissions.id],
      (data) => {
        const { key, ...result } = data
        authRedirects({
          authData: socialMutationAuth.data,
          history,
          isSocial: true,
          logicalFlowData: { ...result, resetFlowData: () => setFlowData(key, null) },
          setUserType,
          setUser,
        })
      },
    )
  }

  useEffect(() => {
    if (socialMutationAuth.isError) {
      if (socialMutationAuth.error?.response?.data?.state?.id === apiCodes.accountHardBanned) {
        setState({ isOpenModal: true })
      }
      socialMutationAuth.reset()
    }
  }, [socialMutationAuth.isError, socialMutationAuth.error])

  useEffect(() => {
    if (Object.values(modes).includes(state.mode)) {
      update(appConfig.storage.keys.welcomeMode, state.mode)
    } else {
      remove(appConfig.storage.keys.welcomeMode)
    }
  }, [state.mode])

  return (
    <div className="auth pb-5">
      {state.isWelcome ? (
        <Welcome onSelect={(mode) => setState({ mode, isWelcome: false })} />
      ) : (
        <SelectButtons
          authHandler={(data) => {
            if (socialMutationAuth.isIdle) {
              socialMutationAuth.mutate(data)
            }
          }}
          mode={state.mode}
          backAction={() => setState({ mode: '', isWelcome: true })}
        />
      )}
      <InfoModal
        isOpen={state.isOpenModal}
        onClose={() => setState({ isOpenModal: false })}
        message={errorsJsx.auth.hardBanned}
      />
    </div>
  )
}

export default AuthPage

import React from 'react'
import PropTypes from 'prop-types'
import UserAvatar from '../../UserAvatar'
import { appConfig } from '../../../../constants/appConfig'
import clsx from 'clsx'
import { getProfileIconUrl } from '../../../../helpers'

function FollowerListItem({ follower, onClickFollower, onClickChat }) {
  return (
    <div key={follower.nickname} className="follower-list-item">
      <div onClick={() => onClickFollower(follower.nickname)}>
        <UserAvatar
          src={follower.avatarUrl}
          className={clsx('mr-2', { 'user-avatar-vip': follower.hadVipChat })}
          size={44}
        />
      </div>
      <div className="item-content">
        <div>
          <div
            className={clsx('bold-16-20 d-flex align-items-center', {
              'text-color-green': follower.hadVipChat,
            })}
            onClick={() => onClickFollower(follower.nickname)}
          >
            {follower.nickname}
            {follower.subscribedMonths > 0 && (
              <img
                className="ml-1"
                src={
                  follower.followerTop
                    ? `${appConfig.cdnPath.iconsSvg}/profileIcons/20x20/top.svg`
                    : getProfileIconUrl(follower.subscribedMonths)
                }
                alt=""
                width={20}
              />
            )}
          </div>
          <div className="medium-14-20 text-color-grey">
            Входит в топ{' '}
            {follower.followerTop ? follower.followerTop : `${Math.ceil(follower.followerScore)}%`}
          </div>
        </div>
        <img
          onClick={() => onClickChat(follower.followerId)}
          src={`${appConfig.cdnPath.iconsSvg}/say-balloon.svg`}
          alt=""
        />
      </div>
    </div>
  )
}
export default FollowerListItem

FollowerListItem.propTypes = {
  follower: PropTypes.shape({
    followerId: PropTypes.string,
    avatarUrl: PropTypes.string,
    nickname: PropTypes.string,
    hadVipChat: PropTypes.bool,
    followerTop: PropTypes.number,
    followerScore: PropTypes.number,
    subscribedMonths: PropTypes.number,
  }).isRequired,
  onClickFollower: PropTypes.func.isRequired,
  onClickChat: PropTypes.func.isRequired,
}

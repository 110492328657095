import React from 'react'
import PropTypes from 'prop-types'
import { nbsp } from '../../constants/typography'

function CardsItem({ item, onDelete }) {
  return (
    <div className="follower-payment-cards-item">
      <div className="cardInfo">
        <b>Карта {item.cardType},</b> последние цифры{nbsp}
        <b>{`${item.pan?.substring(item.pan?.length - 4) || ''}`}</b>
      </div>
      <div className="cardExp">
        Срок действия истекает <b>{item.expirationDate}</b>
      </div>
      {/*<div className="error">На карте недостаточно средств</div>*/}
      <div className="delete" onClick={onDelete}>
        Удалить
      </div>
    </div>
  )
}

export default CardsItem

CardsItem.propTypes = {
  item: PropTypes.shape({
    cardType: PropTypes.string,
    pan: PropTypes.string,
    expirationDate: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
}

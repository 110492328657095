import { useIndexedDB } from 'react-indexed-db'
import { dbStorages } from '../constants/dbConfig'

//Не переименовывать! у пользователей может уже бить установлено
export const logicalFlows = {
  favoriteIconsPermissions: {
    id: 'favoriteIconsPermissions',
    types: {
      like: 'like',
      favorite: 'favorite',
      comment: 'comment',
    },
  },
  mainSubscribeRecommend: {
    id: 'mainSubscribeRecommend',
    types: {
      subscribe: 'subscribe',
      recommend: 'recommend',
    },
  },
  paymentFinish: {
    id: 'paymentFinish',
    types: {
      donateSuccess: 'donateSuccess',
      vipChatSuccess: 'vipChatSuccess',
    },
  },
  followerList: {
    id: 'followerList',
  },
  followerRandomList: {
    id: 'followerRandomList',
  },
  subscribe: {
    id: 'followerSubscribe',
  },
  postCommentsCnt: {
    id: 'postCommentsCnt',
  },
  postEditData: {
    id: 'postEditData',
  },
  profileTabName: {
    id: 'profileTabName',
  },
  idsHash: {
    id: 'idsHash',
    types: {
      likedPostsList: 'likedPostsList',
      favoritePostsList: 'favoritePostsList',
      likedCommentsList: 'likedCommentsList',
    },
  },
}

export function useLogicalFlow() {
  const { update, getByIndex, deleteRecord } = useIndexedDB(dbStorages.routingData)

  const setFlowData = (key, data) => {
    update({ ...data, key }).catch((error) => console.log(error))
  }

  const getFlowData = async (key, onSuccess, onFail) => {
    try {
      const result = await getByIndex('key', key)
      onSuccess(result)
    } catch (e) {
      console.log(e)
      onFail()
    }
  }

  const getFirstFlowData = async (keys, onSuccess, onFail) => {
    try {
      for (const key of keys) {
        const result = await getByIndex('key', key)
        if (result) {
          onSuccess({ ...result, key })
          return
        }
      }
      onSuccess({})
    } catch (e) {
      console.log(e)
      if (onFail) {
        onFail()
      }
    }
  }

  const delFlowData = async (key) => {
    await deleteRecord(key)
  }

  return {
    setFlowData,
    getFlowData,
    getFirstFlowData,
    delFlowData,
  }
}

import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { Subscribes } from './Tabs/Subscribes'
import { Recommends } from './Tabs/Recommends'
import clsx from 'clsx'
import { Search } from '../Search/Search'
import { logicalFlows, useLogicalFlow } from '../../hooks/useLogicalFlow'
import { simpleReducer } from '../../helpers'
import { SUBSCRIBE_TAB_KEY, RECOMMEND_TAB_KEY } from '../../constants'

export function Tabs({ influencers }) {
  const { /*temporary getFlowData,*/ setFlowData } = useLogicalFlow()
  //temporary const location = useLocation()
  //temporary const paramTab = useMemo(() => new URLSearchParams(location.search).get('tab'), [location])
  const initialState = {
    currentTab: RECOMMEND_TAB_KEY, //temporary paramTab || SUBSCRIBE_TAB_KEY,
    search: '',
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  // useEffect(() => {
  //   if (!paramTab) {
  //     getFlowData(logicalFlows.mainSubscribeRecommend.id, (result) => {
  //       temporary setState({ currentTab: result?.tabName || SUBSCRIBE_TAB_KEY })
  //     })
  //   }
  // }, [paramTab])

  const handleChangeTab = (name) => {
    setFlowData(logicalFlows.mainSubscribeRecommend.id, {
      tabName: name,
    })
    setState({ currentTab: name })
  }

  return (
    <>
      <div className="content-margin mt-2">
        <Search className="" value={state.search} onChange={(text) => setState({ search: text })} />
      </div>
      <div className="d-flex flex-row justify-content-between mt-2 content-margin" role="tablist">
        <div
          onClick={() => handleChangeTab(SUBSCRIBE_TAB_KEY)}
          onKeyDown={() => {}}
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': state.currentTab === SUBSCRIBE_TAB_KEY,
          })}
          role="tab"
          aria-selected={state.currentTab === SUBSCRIBE_TAB_KEY}
        >
          Подписки
        </div>
        <div
          onClick={() => handleChangeTab(RECOMMEND_TAB_KEY)}
          onKeyDown={() => {}}
          tabIndex={0}
          className={clsx('semiBold-15-24 tab', {
            'tab-active': state.currentTab === RECOMMEND_TAB_KEY,
          })}
          role="tab"
          aria-selected={state.currentTab === RECOMMEND_TAB_KEY}
        >
          Рекомендации
        </div>
      </div>
      <div className="about">
        {state.currentTab === SUBSCRIBE_TAB_KEY && (
          <Subscribes
            influencers={influencers}
            findHandler={() => handleChangeTab(RECOMMEND_TAB_KEY)}
            search={state.search}
          />
        )}
        {state.currentTab === RECOMMEND_TAB_KEY && (
          <Recommends influencers={influencers} search={state.search} />
        )}
      </div>
    </>
  )
}

Tabs.propTypes = {
  influencers: PropTypes.array,
}

import Radio from '../../../Layout/Radio/Radio'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { appConfig } from '../../../../constants/appConfig'
import DeleteMediaIcon from './DeleteMediaIcon'
import { PostContext } from '../../../../contexts/postContext'
import { nbsp } from '../../../../constants/typography'
import { Uploader } from '../../../Upload/Uploader'
import { handleFile } from '../../../../api/upload'
import { useWebpLinks } from '../../../../hooks/useWebpLinks'

export function VideoLine({
  value,
  mainImageId,
  onChange,
  screenshotUrl,
  onClick,
  onDelete,
  updateAfterUpload,
  secureParams,
}) {
  const { mediaIdToDelete } = useContext(PostContext)
  const uploadMethod = async ({ file, callback = () => {}, onProgress = () => {} }) =>
    await handleFile({
      file,
      data: {
        mediaTypeDestination: appConfig.media.mediaTypeDestinations.screenshot,
        mediaContentId: value,
      },
      callback,
      onProgress,
    })
  const { link: img, oldLink } = useWebpLinks({
    path: `//${screenshotUrl}`,
    params: secureParams,
  })
  return (
    <div className="mb-3">
      <div className="d-flex justify-content-between mb-2">
        <Radio
          name="media"
          value={value}
          label={value === mainImageId ? 'Основной материал' : 'Сделать основным'}
          checked={value === mainImageId}
          onChange={onChange}
        />
        <Uploader
          title={
            <span>
              <img
                src={`${appConfig.cdnPath.iconsSvg}/plus-around-green2.svg`}
                width="24px"
                height="24px"
                alt=""
              />
              {nbsp}Скриншот
            </span>
          }
          className="text-color-green semiBold-15-24"
          uploadMethod={uploadMethod}
          loaderText="Загрузка..."
          accept=".jpg, .jpeg, .png"
          onFinishUpload={updateAfterUpload}
        />
        <DeleteMediaIcon id={value} onDelete={onDelete} />
      </div>
      <div
        style={{ position: 'relative' }}
        role="button"
        onClick={onClick}
        className={clsx({ disabled: mediaIdToDelete.includes(value) })}
      >
        <picture>
          <source srcSet={img} type="image/webp" />
          <source srcSet={oldLink} />
          <img src={oldLink} alt="" className="screenshot-image" />
        </picture>
        <img
          style={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }}
          src={`${appConfig.cdnPath.iconsSvg}/play-white-around.svg`}
          alt=""
        />
      </div>
    </div>
  )
}

VideoLine.defaultProps = {
  onChange: () => {},
  onClick: () => {},
  onDelete: () => {},
}

VideoLine.propTypes = {
  value: PropTypes.any,
  mainImageId: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  screenshotUrl: PropTypes.any,
  updateAfterUpload: PropTypes.func,
  secureParams: PropTypes.object,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, CustomInput } from 'reactstrap'
import { Button } from '../../Layout/Button/Button'
import { appConfig } from '../../../constants/appConfig'

const filters = [
  {
    id: 'isNotRead',
    name: 'Непрочитанные',
  },
  {
    id: 'isRead',
    name: 'Прочитанные',
  },
  {
    id: 'isResponded',
    name: 'Ответил',
  },
  {
    id: 'isDonated',
    name: 'С донатом',
  },
]

function ChatsFilterModal({ isOpen, onClose, activeFilters, onSetActiveFilters }) {
  const [active, setActive] = useState(activeFilters)
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true} size="sm">
      <div className="chat-filters-modal p-4">
        <img
          onClick={onClose}
          className="chat-filters-modal--close cursor-pointer"
          src={`${appConfig.cdnPath.iconsSvg}/cancel-black.svg`}
          width="24px"
          height="24px"
          alt=""
        />
        <div className="semiBold-17-24 text-left mb-4">Фильтры</div>
        {filters.map((filter) => (
          <div key={filter.id}>
            <CustomInput
              id={filter.id}
              type="checkbox"
              label={filter.name}
              checked={active.includes(filter.id)}
              onChange={(e) => {
                const activeFiltersCopy = [...active]
                if (e.target.checked) {
                  activeFiltersCopy.push(filter.id)
                } else {
                  activeFiltersCopy.splice(
                    active.findIndex((item) => item === filter.id),
                    1,
                  )
                }
                setActive(activeFiltersCopy)
              }}
            />
          </div>
        ))}
        <Button
          color="grey"
          className="w-50 text-color-green semiBold-14-20"
          onClick={() => {
            onSetActiveFilters(active)
            onClose()
          }}
        >
          Применить
        </Button>
      </div>
    </Modal>
  )
}

export default ChatsFilterModal

ChatsFilterModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  activeFilters: PropTypes.arrayOf(PropTypes.string),
  onSetActiveFilters: PropTypes.func,
}

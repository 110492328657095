import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { appConfig } from '../../constants/appConfig'
import { dotMiddle } from '../../constants/typography'
import { makeUrl, simpleReducer } from '../../helpers'
import { formatPostTime } from '../../helpers/time'
import UserAvatar from '../Profile/UserAvatar'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import {
  useMutateNotificationSetRead,
  useMutateSetRead,
  useNotifications,
} from '../../api/notification'
import NotificationTypeMessage from './NotificationTypeMessage'
import { VIP_CHAT_TYPE } from '../../constants'
import NotificationStatistics from './NotificationStatistics'
import { UserContext } from '../../contexts/userContext'
import { PageLoading } from '../Layout/PageLoading'

const eventTypes = appConfig.notifications.types

function Notifications() {
  const history = useHistory()
  const matchProfile = useRouteMatch(ROUTES.PROFILE_NOTIFICATIONS)
  const isInfluencerNotifications = useMemo(() => {
    return matchProfile?.isExact
  }, [matchProfile])
  const { user, setUser } = useContext(UserContext)

  const initialState = {
    influencerStatistics: null,
    nav: {
      page: 0,
      total: 0,
      perPage: appConfig.notifications.perPage,
    },
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: notificationsData,
    isFetching,
    refetch: refetchNotifications,
    fetchNextPage,
  } = useNotifications({
    userId: user?.id,
    type: isInfluencerNotifications ? appConfig.userTypes.influencer : appConfig.userTypes.follower,
  })

  const onSuccessAllViewed = async () => {
    setState(initialState)
    await refetchNotifications()
    setUserUnreadNotificationCount(0)
  }

  const onSuccessOneViewed = (data) => {
    if (data?.unreadCount >= 0) {
      setUserUnreadNotificationCount(data?.unreadCount)
    } else {
      const count = newList.length > 0 ? newList.length - 1 : 0
      setUserUnreadNotificationCount(count)
    }
  }

  const { mutate: setAllViewed } = useMutateSetRead({ onSuccess: onSuccessAllViewed })
  const { mutate: setNotificationViewed } = useMutateNotificationSetRead({
    onSuccess: onSuccessOneViewed,
  })

  const newList = useMemo(() => {
    return (
      notificationsData
        ?.map(({ notifications }) => notifications)
        ?.flat()
        ?.filter((item) => !item.isViewed) || []
    )
  }, [notificationsData])

  const viewedList = useMemo(() => {
    return (
      notificationsData
        ?.map(({ notifications }) => notifications)
        ?.flat()
        ?.filter((item) => item.isViewed) || []
    )
  }, [notificationsData])

  useEffect(() => {
    if (notificationsData) {
      setState({
        influencerStatistics: notificationsData[notificationsData.length - 1]?.influencerStatistics,
        nav: notificationsData[notificationsData.length - 1]?.nav,
      })
    }
  }, [notificationsData])

  const handleClickNotification = (notification) => {
    if (!notification.isViewed) {
      setNotificationViewed(notification.id)
    }
    let routeData, locationState
    switch (notification.notificationType) {
      case eventTypes.eventNewPost:
        routeData = {
          route: ROUTES.INSIDERS_NICK,
          routeParams: { nick: notification.senderNickName },
          params: { postId: notification.sourceId },
        }
        break
      case eventTypes.eventNewComment:
        routeData = {
          route: ROUTES.INSIDERS_NICK_COMMENTS,
          routeParams: { nick: notification.influencerNickName },
          params: {
            influencerId: notification.influencerId,
            postId: notification.blogPostId,
            targetComment: notification.sourceId,
          },
        }
        break
      case eventTypes.eventSubscriptionExpired:
        routeData = {
          route: ROUTES.FOLLOWER_SUBSCRIBE,
          params: { influencerId: notification.sourceId },
        }
        break
      case eventTypes.eventBoughtVipChat:
        routeData = {
          route: ROUTES.PROFILE_CHATS,
          routeParams: {
            userType: appConfig.userTypes.influencer.toLowerCase(),
            interlocutorId: notification.sourceId,
          },
          params: { type: VIP_CHAT_TYPE },
        }
        break
      case eventTypes.eventDonateTargetReached:
        routeData = isInfluencerNotifications
          ? {
              route: ROUTES.PROFILE_TARGETS,
            }
          : {
              route: ROUTES.INSIDERS_NICK,
              routeParams: { nick: notification.senderNickName },
            }
        locationState = { showClosed: true }
        break
      case eventTypes.eventPriceChanged:
        routeData = {
          route: ROUTES.FOLLOWER_SUBSCRIPTIONS,
        }
        break
    }

    if (routeData) {
      history.push(makeUrl(routeData), locationState)
    }
  }

  const handleViewAll = () => {
    setAllViewed({
      type: isInfluencerNotifications
        ? appConfig.userTypes.influencer
        : appConfig.userTypes.follower,
    })
  }

  const handleClickMore = () => {
    const newPage = state?.nav?.page + 1
    setState({ nav: { ...state.nav, page: newPage } })
    fetchNextPage({ pageParam: newPage })
  }

  const getCountMore = () => {
    return state?.nav?.total - state?.nav?.perPage * (state?.nav?.page + 1)
  }

  const renderItem = (item) => {
    return (
      <div key={item.id} className="notifications-item">
        <UserAvatar src={item.senderAvatarUrl} size={44} className="avatar" />
        <div className="block">
          <div className="author">
            {item.senderNickName}
            <span style={{ whiteSpace: 'nowrap' }}>
              {dotMiddle} {item.createDate ? formatPostTime(item.createDate) : ''}
            </span>
          </div>
          <div className="body">
            <NotificationTypeMessage
              type={item.notificationType}
              onClickSource={() => handleClickNotification(item)}
              description={item.description}
            />
          </div>
        </div>
      </div>
    )
  }

  const setUserUnreadNotificationCount = (count) => {
    const updatedUser = { ...user }
    if (isInfluencerNotifications) {
      updatedUser.influencer.unreadNotificationCount = count
    } else {
      updatedUser.follower.unreadNotificationCount = count
    }
    setUser(updatedUser)
  }

  if (isFetching) {
    return <PageLoading hasFooter hasHeader />
  }
  return (
    <div className="content notifications footer-padding">
      {isInfluencerNotifications && (
        <div className="pinned">
          <div className="d-flex justify-content-between">
            <div className="subTitle">За сегодня</div>
            <div className="subTitle">
              Закрепленные
              <img src={`${appConfig.cdnPath.iconsSvg}/pin-grey.svg`} alt="" />
            </div>
          </div>
          <NotificationStatistics statData={state.influencerStatistics} />
        </div>
      )}
      {newList.length > 0 && (
        <>
          <div className="d-flex justify-content-between">
            <div className="subTitle">Новые</div>
            <div className="subTitle text-color-green" onClick={handleViewAll}>
              Прочесть все
            </div>
          </div>
          {newList.map(renderItem)}
          <hr />
        </>
      )}
      {viewedList.length > 0 && (
        <>
          <div className="subTitle">Просмотренные</div>
          {viewedList.map(renderItem)}
        </>
      )}
      {getCountMore() > 0 && (
        <div
          className="d-flex align-items-center justify-content-center text-color-regular mb-3"
          onClick={handleClickMore}
        >
          Показать ещё
        </div>
      )}
      {newList.length === 0 && viewedList.length === 0 && (
        <>
          {isInfluencerNotifications && <hr />}
          <div className="subTitle">Нет уведомлений</div>
        </>
      )}
    </div>
  )
}

export default Notifications

import React from 'react'
import { appConfig } from './appConfig'

const { min: MIN_PASS_LENGTH, max: MAX_PASS_LENGTH } = appConfig.passwordLength

export const errorsJsx = {
  payment: {
    fail: (
      <>
        Не удалось произвести оплату.
        <br /> Повторите попытку позже.
      </>
    ),
    failAgreement: <>Необходимо дать согласие на условия оферты</>,
  },
  dataSendError: <>Ошибка передачи данных</>,
  backendPingError: (
    <>
      На сайте проводятся технические работы.
      <br /> Выполните операцию позже.
    </>
  ),
  auth: {
    userNotFound: <>Пользователь с таким email не найден</>,
    userDuplicate: <>Такой адрес электронной почты уже зарегистрирован</>,
    inputValidMail: <>Введите валидный email</>,
    failLoginOrPass: <>Неверный адрес электронной почты или пароль</>,
    failPassLength: (
      <>
        Пароль может содержать от {MIN_PASS_LENGTH} - {MAX_PASS_LENGTH} символов
      </>
    ),
    inputNickname: <>Заполните Имя профиля</>,
    duplicateNick: <>Такое имя уже занято</>,
    resetPassLimit: <>На сегодня попытки восстановления пароля исчерпаны</>,
    hardBanned: (
      <>
        Ваш аккаунт заблокирован. Свяжитесь с командой поддержки сайта{' '}
        <span className="text-color-green">{appConfig.mailSupport}</span>
      </>
    ),
    lockedOut: (
      <>
        Ваш аккаунт временно заблокирован.
        <br /> Повторите попытку позже или свяжитесь с командой поддержки сайта{' '}
        <span className="text-color-green">{appConfig.mailSupport}</span>
      </>
    ),
  },
  targets: {
    failTitle: <>Введите название цели</>,
    failLengthTitle: <>Название должно быть короче 50 символов</>,
    failCost: <>Введите положительное число</>,
  },
}

import React, { useContext, useState } from 'react'
import AddImage from '../../components/Profile/EmptyProfile/AddImage'
import { ROUTES } from '../../constants/routes'
import { handleFile } from '../../api/upload'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'
import { appConfig } from '../../constants/appConfig'

const AddAvatarPage = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { userType } = useContext(UserContext)

  const uploadBack = async (file) => {
    if (userType) {
      setIsLoading(true)
      await handleFile({
        file,
        data: {
          mediaTypeDestination: appConfig.media.mediaTypeDestinations.background,
          axisXOffset: 0,
        },
        callback: () => {
          setIsLoading(false)
          history.push(ROUTES.PROFILE)
        },
      })
    }
  }
  return <AddImage upload={uploadBack} title="Подложка для фото профиля" isLoading={isLoading} />
}

export default AddAvatarPage

import { Modal } from 'reactstrap'
import PropTypes from 'prop-types'
import React from 'react'
import ConfirmForm from './ConfirmForm'

function ConfirmModal({ isOpen, toggle, onOk, onCancel, okLabel, cancelLabel, message }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="sm">
      <ConfirmForm
        onOk={onOk}
        onCancel={onCancel}
        okLabel={okLabel}
        cancelLabel={cancelLabel}
        message={message}
      />
    </Modal>
  )
}

ConfirmModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  onOk: () => {},
  onCancel: () => {},
  okLabel: 'Да',
  cancelLabel: 'Отмена',
  message: '',
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  message: PropTypes.string,
}

export default ConfirmModal

import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { appConfig } from '../constants/appConfig'
import { StompChatContext } from '../stomp/StompChatContext'
import { nanoid } from 'nanoid'
import { simpleReducer } from '../helpers'
import { getUnreadCount } from '../api/chat'
import { useQuery } from 'react-query'
import { REGULAR_CHAT_TYPE, VIP_CHAT_TYPE } from '../constants'
import { UserContext } from '../contexts/userContext'

let chatWorker
let timeout

export function useChat({ userType, influencerId, followerId, token, version = '' }) {
  const { currentTypeId, user, setUser } = useContext(UserContext)

  const { events, commands, flags } = appConfig.stomp
  const initialState = {
    isConnecting: true,
    isConnected: false,
    newMessages: {},
    unreadMessagesCount: {},
    lastMessage: null,
    lastReadMessageId: null,
    workerQueue: {},
    token: null,
    refreshHistory: false,
    e: undefined,
    md5: undefined,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  if (!chatWorker) {
    chatWorker = new Worker('/static/chatWorker.js')
  }

  const unreadMessagesCount = useMemo(
    () => state.unreadMessagesCount[userType]?.[REGULAR_CHAT_TYPE],
    [userType, state.unreadMessagesCount],
  )
  const unreadMessagesCountVip = useMemo(
    () => state.unreadMessagesCount[userType]?.[VIP_CHAT_TYPE],
    [userType, state.unreadMessagesCount],
  )

  const { data } = useQuery(['unreadCount'], () => getUnreadCount(), {
    enabled: Boolean(currentTypeId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  /**
   * Приводит то, что пришло из кролика, к контракту бэка
   * (Иначе едет кукуха)
   */
  const prepareMessageData = (message, content) => {
    /**
     * Как content приходит из кролика:
     * SEND: message: {content: "Привет"}
     * NEW_MESSAGE: message: "{\"content\":\"Привет\"}"
     * NEW_SERVICE_MESSAGE: message: "{\"read\":\"2021-06-23T09:16:14.084Z\"}
     * NEW_SERVICE_MESSAGE: message: "{\"Refresh\":\"\"}
     */
    const abonentId = message?.from === currentTypeId ? message?.to : message?.from
    return {
      content: content?.content || content?.Content, //TODO: Убрать как Дима начнет передавать все с маленькой
      externalId: message?.messageId,
      chatKey: `${abonentId}${message?.vip ? '-vip' : ''}`, //TODO: переделать этот кошмар или упросить Диму слать мне таки id чата
      senderId: message?.from,
      recipientId: message?.to,
      createDate: new Date(),
      isRead: false,
      paymentSum: content?.paymentSum || content?.PaymentSum || 0, //TODO: Убрать как Дима начнет передавать все с маленькой
      mediaItems: content.mediaItems || [],
      mediaItemsData: content.mediaItemsData || [],
    }
  }
  /**
   * Отправляем сообщения в кролик только если мы подключены. Если нет - ставим в очередь, отправляем после подключения
   */
  const postWorkerMessage = useCallback(
    (data = {}) => {
      if (state.isConnected) {
        chatWorker.postMessage(data)
      } else if (!state.workerQueue[data.cmd]) {
        setState({
          workerQueue: {
            ...state.workerQueue,
            [data.cmd]: data,
          },
        })
      }
    },
    [state.isConnected, state.workerQueue],
  )

  const reduceUnreadCount = useCallback(
    (count, chatType) => {
      setState({
        unreadMessagesCount: {
          ...state.unreadMessagesCount,
          [userType]: {
            ...state.unreadMessagesCount[userType],
            [chatType]: Math.max(state.unreadMessagesCount[userType][chatType] - count, 0),
          },
        },
      })
    },
    [state.unreadMessagesCount, userType],
  )

  const addNewMessage = useCallback(
    (messageData) => {
      const newMessagesCopy = { ...state.newMessages }
      if (!newMessagesCopy[messageData.chatKey]) {
        newMessagesCopy[messageData.chatKey] = []
      }
      if (
        !newMessagesCopy[messageData.chatKey].find(
          (message) => message.externalId === messageData.externalId,
        )
      ) {
        newMessagesCopy[messageData.chatKey].push(messageData)
        setState({ newMessages: newMessagesCopy })
      }
    },
    [state.newMessages],
  )

  const disconnect = useCallback(
    () => chatWorker.postMessage({ cmd: commands.disconnect }),
    [commands.disconnect],
  )

  const send = useCallback(
    ({ content, from, to, vip, chatId, mediaItems = [], mediaItemsData = [] }) => {
      const messageId = nanoid()
      postWorkerMessage({
        cmd: commands.send,
        payload: {
          from,
          to,
          vip,
          version,
          messageId,
          message: {
            content,
            mediaItems,
            mediaItemsData,
          },
          chatId,
        },
      })
      addNewMessage(
        prepareMessageData(
          {
            messageId,
            from,
            to,
            vip,
          },
          {
            content,
            mediaItems,
            mediaItemsData,
          },
        ),
      )
    },
    [addNewMessage, commands.send, postWorkerMessage, version],
  )

  const setFlag = useCallback(
    (flag) =>
      ({ messageId, chatId, from, to, vip }) => {
        postWorkerMessage({
          cmd: commands.sendServiceMessage,
          payload: {
            from: to,
            to: from,
            vip,
            messageId,
            chatId,
            version,
            message: { [flag]: new Date() },
          },
        })
      },
    [postWorkerMessage, commands.sendServiceMessage, version],
  )

  const updateIsRead = useCallback(
    (chatKey, messagesIds) => {
      const newMessagesCopy = { ...state.newMessages }
      if (newMessagesCopy[chatKey]) {
        let ids = messagesIds
        /**
         * Если пришла * в id, значит надо прочитать все непрочитанные
         */
        if (messagesIds[0] === '*') {
          ids = newMessagesCopy[chatKey]
            .filter((message) => !message.isRead)
            .map((message) => message.externalId)
        }
        ids.forEach((messageId) => {
          const messageIndex = newMessagesCopy[chatKey].findIndex(
            (message) => message.externalId === messageId,
          )
          if (messageIndex > -1 && !newMessagesCopy[chatKey][messageIndex].isRead) {
            console.log('READ', newMessagesCopy[chatKey][messageIndex].content)
            newMessagesCopy[chatKey][messageIndex].isRead = true
          }
        })
        setState({
          newMessages: newMessagesCopy,
          lastReadMessageId: ids[ids.length - 1],
        })
      }
    },
    [state.newMessages],
  )

  const markAllRead = useCallback(
    ({ chatId, chatKey, senderId, recipientId, vip }) => {
      setFlag(flags.read)({
        messageId: '*',
        from: senderId,
        to: recipientId,
        vip,
        chatId,
      })

      updateIsRead(chatKey, ['*'])
    },
    [setFlag, flags.read, updateIsRead],
  )

  const requestNewSignature = useCallback(
    ({ influencerId }) => {
      const messageId = nanoid()
      const followerId = user?.follower?.id
      postWorkerMessage({
        cmd: commands.sendVideo,
        payload: {
          messageId,
          message: {
            influencerId,
          },
          followerId,
        },
      })
    },
    [commands.sendVideo, postWorkerMessage],
  )

  chatWorker.onmessage = useCallback(
    (e) => {
      console.log('Main thread: Message received from chat-worker', e.data)
      const message = e.data.payload
      const content = message?.message ? JSON.parse(message?.message) : ''
      const messageData = prepareMessageData(message, content)
      const payload = e.data.payload
      const { Exp, Md5: md5 } = content || {}
      switch (e.data.event) {
        case events.newMessage:
          if (message.from && message.to && message.messageId) {
            /*TODO: как-нибудь нормально это сделать*/
            let type = 'influencer'
            if (message.to === followerId) {
              type = 'follower'
            }
            addNewMessage(messageData)
            setFlag(flags.delivered)(message)
            setState({
              lastMessage: messageData,
              unreadMessagesCount: {
                ...state.unreadMessagesCount,
                [type]: {
                  ...state.unreadMessagesCount[type],
                  [message.vip ? VIP_CHAT_TYPE : REGULAR_CHAT_TYPE]:
                    state.unreadMessagesCount[type][
                      message.vip ? VIP_CHAT_TYPE : REGULAR_CHAT_TYPE
                    ] + 1,
                },
              },
            })
          }
          break
        case events.newVideoMessage:
          if (e.data.event === events.newVideoMessage && md5) {
            navigator.serviceWorker.ready.then((registration) => {
              if (!payload.timestamp || Number(payload.timestamp) < Number(Exp)) {
                registration.active.postMessage(
                  JSON.stringify({
                    e: Exp,
                    md5,
                    type: 'videoParams',
                  }),
                )
              }
              if (timeout) {
                clearTimeout(timeout)
                timeout = undefined
              }
              timeout = setTimeout(() => {
                registration.active.postMessage(
                  JSON.stringify({
                    e: null,
                    md5: null,
                    type: 'videoParams',
                  }),
                )
              }, 20000)
            })
          }
          break
        case events.newServiceMessage:
          if (content?.read) {
            updateIsRead(messageData.chatKey, [message.messageId])
          }
          if (typeof content?.Refresh !== 'undefined' || typeof content?.refresh !== 'undefined') {
            setState({ refreshHistory: message.from, newMessages: [] })
          }
          break
        case events.connected:
          setState({ isConnected: true, isConnecting: false })
          break
        case events.disconnected:
          setState({ isConnected: false, isConnecting: true })
          break
        default:
          console.log('Received unusual message from chatWorker', e.data)
      }
    },
    [
      events.newMessage,
      events.newServiceMessage,
      events.newVideoMessage,
      events.connected,
      events.disconnected,
      followerId,
      addNewMessage,
      setFlag,
      flags.delivered,
      state.unreadMessagesCount,
      updateIsRead,
    ],
  )

  const { config } = useContext(StompChatContext)

  useEffect(() => {
    if (state.isConnected && state.workerQueue.length) {
      /**
       * Отправляем не отправленные сообщения и чистим очередь
       */
      Object.keys(state.workerQueue).forEach((command) => {
        chatWorker.postMessage(state.workerQueue[command])
      })
      setState({
        workerQueue: [],
      })
    }
  }, [state.isConnected, state.workerQueue])

  useEffect(() => {
    if (!token) {
      setState({ token: null })
    }
  }, [token])

  useEffect(() => {
    if (token && token !== state.token) {
      chatWorker.postMessage({
        cmd: commands.subscribe,
        headers: { ...config, token },
        payload: { influencerId, followerId, token },
      })
      setState({ token })
    }
  }, [influencerId, followerId, token, config, commands.subscribe, state.token])

  useEffect(() => {
    if (data) {
      setUser({
        ...user,
        follower: {
          ...user.follower,
          unreadChatMessages: data.followerUnread,
          unreadChatMessagesVip: data.followerUnreadVip,
        },
        influencer: {
          ...user.influencer,
          unreadChatMessages: data.influencerUnread,
          unreadChatMessagesVip: data.influencerUnreadVip,
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (currentTypeId) {
      setState({
        unreadMessagesCount: {
          follower: {
            [REGULAR_CHAT_TYPE]: user?.follower?.unreadChatMessages,
            [VIP_CHAT_TYPE]: user?.follower?.unreadChatMessagesVip,
          },
          ...(influencerId && {
            influencer: {
              [REGULAR_CHAT_TYPE]: user?.influencer?.unreadChatMessages,
              [VIP_CHAT_TYPE]: user?.influencer?.unreadChatMessagesVip,
            },
          }),
        },
      })
    }
  }, [
    currentTypeId,
    influencerId,
    user?.follower?.unreadChatMessages,
    user?.follower?.unreadChatMessagesVip,
    user?.influencer?.unreadChatMessages,
    user?.influencer?.unreadChatMessagesVip,
  ])

  return {
    disconnect,
    send,
    isConnecting: state.isConnecting,
    isConnected: state.isConnected,
    markAsRead: setFlag(flags.read),
    markAllRead,
    newMessages: state.newMessages,
    unreadMessagesCount,
    unreadMessagesCountVip,
    lastMessage: state.lastMessage,
    lastReadMessageId: state.lastReadMessageId,
    reduceUnreadCount,
    updateIsRead,
    refreshHistory: state.refreshHistory,
    setRefreshed: () => setState({ refreshHistory: false }),
    requestNewSignature,
    e: state.e,
    md5: state.md5,
  }
}

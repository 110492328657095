import React from 'react'
import { Header } from '../../Header/Header'
import { useQuery } from 'react-query'
import { getSubscriptionsFollower } from '../../../api/subscribe'
import { PageLoading } from '../../Layout/PageLoading'
import UserAvatar from '../../Profile/UserAvatar'
import { formatDate } from '../../../helpers/time'
import { RatingProgress } from './RatingProgress'
import { appConfig } from '../../../constants/appConfig'
import { nbsp } from '../../../constants/typography'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import moment from 'moment'
import queryString from 'query-string'
import { HashLink as Link } from 'react-router-hash-link'

export function RatingSubscriptions() {
  const { isLoading, data } = useQuery(['subscriptionsFollower'], () => getSubscriptionsFollower())
  const history = useHistory()
  const now = new Date()
  return (
    <div className="footer-padding">
      <Header
        needRight={false}
        needLeft={true}
        title="Рейтинг по инфлюенсерам"
        onBackAction={() => history.push(ROUTES.FOLLOWER_RATING)}
      />
      {isLoading && <PageLoading hasHeader hasFooter />}
      <div className="content-margin">
        {!isLoading &&
          data?.subscriptions.map((item) => {
            const isExpire = moment(item.expirationDate, 'DD.MM.YYYY').isBefore(now)
            return (
              <div key={item.influencerId} className="follower-subscriptions-item">
                <div className="top mb-3">
                  <div onClick={() => history.push(`/insiders/${item.influencerNickname}/`)}>
                    <UserAvatar src={item.avatarUrl} size={44} />
                  </div>
                  <div className="info ml-1">
                    <div
                      className="title"
                      onClick={() => history.push(`/insiders/${item.influencerNickname}/`)}
                    >
                      {item.influencerNickname}
                    </div>
                    <div className="status mt-1">
                      {!isExpire && (
                        <>
                          Подписка действует до{nbsp}
                          <span className="text-color-sub-title semiBold-14-20">
                            {`${formatDate(item.expirationDate)}`}
                          </span>
                        </>
                      )}
                      {isExpire && (
                        <>
                          <span className="error mt-1">
                            Подписка не активна{' '}
                            <span
                              onClick={() => {
                                history.push(
                                  `${ROUTES.FOLLOWER_SUBSCRIBE}?${queryString.stringify({
                                    influencerId: item.influencerId,
                                  })}`,
                                )
                              }}
                              className="text-color-green medium-14-20 ml-2"
                            >
                              Оформить
                            </span>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bold-14-20 text-color-sub-title justify-content-end mb-1">
                    Активность в профиле:{nbsp}
                    <Link to={ROUTES.FOLLOWER_RATING_INFO + '#rating'}>
                      <img
                        src={`${appConfig.cdnPath.iconsSvg}/question.svg`}
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </Link>
                  </div>
                  <ul style={{ paddingLeft: '24px' }}>
                    <li className="text-color-regular medium-14-20">
                      Количество набранных баллов:{' '}
                      <span className="text-color-sub-title semiBold-14-20">
                        {item.userScore.toFixed(2)}
                      </span>
                    </li>
                    <li className="text-color-regular medium-14-20">
                      Ты входишь в Топ
                      <span className="text-color-sub-title semiBold-14-20">
                        {item.userScoreTop ? ` ${item.userScoreTop}` : ` ${item.userScorePart}%`}{' '}
                      </span>
                      подписчиков
                    </li>
                  </ul>
                  <div className="bold-14-20 text-color-sub-title mb-1 justify-content-end">
                    Достижения в профиле:{nbsp}
                    <Link to={ROUTES.FOLLOWER_RATING_INFO + '#rank-system'}>
                      <img
                        src={`${appConfig.cdnPath.iconsSvg}/question.svg`}
                        alt=""
                        width="20px"
                        height="20px"
                      />
                    </Link>
                  </div>
                  <RatingProgress item={item} />
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

function HrTitle({ title, className, onClick }) {
  return (
    <div className={`hr-title ${className}`}>
      <div className="hr-title-line" />
      <span className="cursor-pointer" onClick={onClick}>
        {title}
      </span>
    </div>
  )
}

HrTitle.defaultProps = {
  className: '',
  title: '',
  onClick: () => {},
}

HrTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export { HrTitle }

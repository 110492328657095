import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../../constants/appConfig'

function LinkWithArrow({ title, onClick, className, iconElement }) {
  return (
    <div className={`list-payment-method-content ${className}`} onClick={onClick}>
      {iconElement !== null && iconElement}
      <div className="semiBold-15-24 flex-grow-1 text-left">{title}</div>
      <img
        src={`${appConfig.cdnPath.iconsSvg}/black-arrow-right.svg`}
        alt=""
        width="6px"
        height="12px"
      />
    </div>
  )
}

LinkWithArrow.defaultProps = {
  className: '',
  iconElement: null,
}

LinkWithArrow.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconElement: PropTypes.any,
}

export default LinkWithArrow

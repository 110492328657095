import React, { useReducer, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { LogoSpinner } from '../Layout'
import ErrorModal from '../Modal/ErrorModal'
import clsx from 'clsx'
import { simpleReducer } from '../../helpers'
import { formatSizeMb } from '../../helpers/file'
import { mb } from '../../constants/typography'
import { uploadFileTypes } from '../../constants/files'

export function Uploader({
  type,
  title,
  accept,
  uploadMethod,
  className,
  onUploading = () => {},
  onFinishUpload = () => {},
  disabled = false,
  maxFileSize = false,
  loaderText,
}) {
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState('')
  const initialState = {
    fileData: null,
    progressData: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const uploadRef = useRef()

  const changeHandler = async (event) => {
    const file = event.target.files[0]
    setState({ fileData: file })
    if (maxFileSize && formatSizeMb(file?.size, '') > maxFileSize) {
      setError(`Файл превышает допустимый размер (${maxFileSize} ${mb})`)
      setState(initialState)
      return
    }

    setInProgress(true)
    onUploading()
    try {
      await uploadMethod({
        file,
        callback: (data) => {
          setInProgress(false)
          onFinishUpload(data)
        },
        onProgress: (file, progressData) => {
          setState({ fileData: file, progressData })
        },
        type,
      })
    } catch (e) {
      setError('Произошла ошибка загрузки файла. Попробуйте ещё раз.')
      setInProgress(false)
      setState(initialState)
      onFinishUpload()
    }
  }

  if (inProgress) {
    return (
      <div className="d-flex justify-content-center">
        <span className="mr-2" style={{ lineHeight: '24px' }}>
          {loaderText || 'Выполняется загрузка'}
        </span>
        <span className="mr-2" style={{ lineHeight: '24px' }}>
          {formatSizeMb(state.progressData?.uploadedSize, '') || 0} /{' '}
          {formatSizeMb(state.fileData?.size || 0)}
        </span>
        <LogoSpinner isLoading />
      </div>
    )
  }
  return (
    <div>
      <input
        ref={uploadRef}
        type="file"
        name="file"
        onChange={changeHandler}
        accept={accept}
        hidden
      />
      <div
        className={clsx(className, { disabled })}
        onClick={() => {
          if (!disabled) {
            uploadRef.current.click()
          }
        }}
        role="button"
      >
        {title}
      </div>
      <ErrorModal toggle={() => setError('')} isOpen={Boolean(error)} message={error} />
    </div>
  )
}

Uploader.propTypes = {
  type: PropTypes.oneOf(Object.values(uploadFileTypes)),
  title: PropTypes.any.isRequired,
  accept: PropTypes.string.isRequired,
  uploadMethod: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxFileSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onUploading: PropTypes.func,
  onFinishUpload: PropTypes.func,
  loaderText: PropTypes.string,
}

import * as React from 'react'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Button } from '../Layout/Button/Button'
import { Categories } from '../Product/Categories'
import { selectCategories } from '../../api/product'
import { HeaderProgress } from '../Header/HeaderProgress'
import { ROUTES } from '../../constants/routes'
import { Header } from '../Header/Header'
import { getCurrentUser } from '../../api/users'
import { UserContext } from '../../contexts/userContext'

// markup
const SelectCategory = ({ withProgressBar, buttonName, onAfterSave }) => {
  const { influencerId } = useContext(UserContext)
  const [selected, setSelected] = useState([])
  const toggleSelected = (id) => {
    const newSelected = selected.includes(id) ? selected.filter((i) => i !== id) : [...selected, id]
    setSelected(newSelected)
  }

  const loadUser = async () => {
    const user = await getCurrentUser()
    const selectedCategories = Object.values(user?.influencer?.categories || {}).map(
      (item) => item.contentTypeKey,
    )
    setSelected(selectedCategories)
  }

  useEffect(() => {
    loadUser()
  }, [])

  return (
    <>
      {withProgressBar && <HeaderProgress all={2} progress={1} />}
      {!withProgressBar && (
        <Header title="Выберите категории" needRight={false} to={ROUTES.PROFILE_SETTINGS} />
      )}
      <div className="content content-padding">
        {withProgressBar && <div className="bold-17-24 mb-3">Выберите категории</div>}
        <div className="medium-13-20 mb-3 text-color-regular">
          Выберите от одной до трех категорий, по которым вы хотите появляться в рекомендациях для
          подписчиков
        </div>
        <Categories selected={selected} toggleSelected={toggleSelected} />
      </div>
      <div className="content-padding" style={{ height: '59px' }}>
        <Button
          className="semiBold-15-24"
          disable={(selected || []).length === 0 || (selected || []).length > 3}
          onClick={() => {
            selectCategories({ influencerId, categories: selected }).then(onAfterSave)
          }}
        >
          {buttonName}
        </Button>
      </div>
    </>
  )
}

SelectCategory.defaultProps = {
  withProgressBar: false,
  buttonName: 'Далее',
  onAfterSave: () => {},
}

SelectCategory.propTypes = {
  withProgressBar: PropTypes.bool,
  buttonName: PropTypes.string,
  onAfterSave: PropTypes.func.isRequired,
}

export default SelectCategory

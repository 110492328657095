import React from 'react'
import PropTypes from 'prop-types'
import { Influencer } from './Influencer'
import { getSubscriptions } from '../../../api/auth'
import { useHistory } from 'react-router-dom'

export function Subscribes({ influencers, findHandler = () => {}, search }) {
  const history = useHistory()
  const subscriptions = getSubscriptions()
  let currentInfluencers = (influencers || []).filter(({ influencerNickname }) =>
    subscriptions.includes(influencerNickname),
  )
  if (typeof influencers === 'undefined') {
    return null
  }
  if (currentInfluencers.length === 0) {
    return (
      <div className="mt-5 content-margin medium-15-24 text-center">
        Подписок пока нет.
        <div className="text-color-green" onClick={findHandler} role="button">
          Найти инфлюенсеров
        </div>
      </div>
    )
  }
  if (search) {
    currentInfluencers = currentInfluencers.filter(
      ({ influencerNickname, influencerShortDescription }) =>
        influencerNickname?.toLowerCase()?.includes(search?.toLowerCase()) ||
        influencerShortDescription?.toLowerCase()?.includes(search?.toLowerCase()),
    )
  }
  return currentInfluencers.map(
    ({ id, influencerNickname, influencerShortDescription, influencerAvatarLink }) => (
      <Influencer
        key={id}
        author={influencerNickname}
        teaser={influencerShortDescription}
        avatar={influencerAvatarLink}
        id={id}
        onClick={() => {
          history.push(`/insiders/${influencerNickname}/`)
        }}
        className="mb-4 content-margin"
      />
    ),
  )
}

Subscribes.propTypes = {
  influencers: PropTypes.arrayOf(
    PropTypes.shape({
      influencerNickname: PropTypes.string,
      influencerShortDescription: PropTypes.string,
      influencerAvatarLink: PropTypes.string,
    }),
  ),
  search: PropTypes.string,
  findHandler: PropTypes.func,
}

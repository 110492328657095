import { api } from './api'
import { useQuery } from 'react-query'
import { uploadFileTypes } from '../constants/files'
const CryptoJS = require('crypto-js')
const VIDEO_KEY = 'video'
const CHUNK_SIZE = 20971409

export const fetchTaskById = (id) => api.get({ path: `/insider/media/upload-status?id=${id}` })

export function useTaskQuery(id) {
  return useQuery(id && [VIDEO_KEY, id], () => fetchTaskById(id))
}

export async function handleFile({
  file,
  data,
  callback = () => {},
  onProgress = () => {},
  isSetDescription = false,
  type = null,
}) {
  const filename = file.name
  const contentType = file.type
  const fileExtension =
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename

  const { cdnAuthToken, uploadUrl, uploadId } = await startUpload({ fileExtension, ...data })

  const SHA256 = await upload(file, cdnAuthToken, uploadUrl, onProgress)

  const { mediaContentId, downloadUrl, axisXSize, axisYSize, path } = await stopUpload({
    uploadId,
    sha256: SHA256.finalize().toString(),
    ...(data.blogPostId && { blogPostId: data.blogPostId }),
    ...(data.axisXOffset !== undefined && { axisXOffset: data.axisXOffset }),
    ...(data.mediaTypeDestination && { mediaTypeDestination: data.mediaTypeDestination }),
    ...(data.mediaContentId && { mediaContentId: data.mediaContentId }),
    ...(type && type === uploadFileTypes.file && { isSavedAsFile: true }),
  })

  if (isSetDescription && mediaContentId) {
    await setFileDescription(mediaContentId, filename)
  }

  if (callback) {
    callback({ mediaContentId, downloadUrl, contentType, axisXSize, axisYSize, path })
  }
}

function setFileDescription(mediaContentId, description) {
  return api.put({
    path: `/insider/media/${mediaContentId}/description`,
    data: { description },
  })
}

function startUpload(data) {
  return api.post({
    path: '/insider/media/start-upload',
    isDirect: true,
    data,
  })
}

function stopUpload(data) {
  return api.post({
    path: '/insider/media/stop-upload',
    isDirect: true,
    data,
  })
}

const fetchWithRetry = async (url, opts, tries = 3) => {
  const errs = []

  for (let i = 0; i < tries; i++) {
    try {
      return await fetch(url, opts)
    } catch (err) {
      errs.push(err)
    }
  }

  throw errs
}

async function readFilePart(file, offset, index, SHA256, onProgress) {
  const partial = file.slice(offset, offset + CHUNK_SIZE)

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.size = CHUNK_SIZE
    reader.offset = offset
    reader.index = index
    reader.onerror = reject
    reader.onprogress = (evt) => {
      onProgress(file, { uploadedSize: offset + evt.loaded })
    }
    reader.onload = function (evt) {
      onReadFile(reader, file, evt, (data) => {
        const wordBuffer = CryptoJS.lib.WordArray.create(data)
        SHA256.update(wordBuffer)
      })
      resolve({
        result: reader.result,
        contentLength: evt.total.toString(),
      })
    }
    reader.readAsArrayBuffer(partial)
  })
}

async function upload(file, token, link, onProgress = () => {}) {
  let offset = 0
  let index = 0
  const SHA256 = CryptoJS.algo.SHA256.create()

  if (file.size === 0) {
    return SHA256
  }

  while (offset < file.size) {
    const { result: body, contentLength } = await readFilePart(
      file,
      offset,
      index,
      SHA256,
      onProgress,
    )
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)
    headers.append('Content-Type', 'application/octet-stream')
    headers.append('Content-Length', contentLength)
    const options = {
      method: 'PUT',
      headers,
      body,
    }
    await fetchWithRetry(`${link}&uploadPartNum=${index + 1}`, options)
    offset += CHUNK_SIZE
    index += 1
  }
  return SHA256
}

let lastOffset = 0

// memory reordering
const previous = []

function onReadFile(reader, file, evt, callbackProgress) {
  let readerOffset = reader.offset || 0
  if (lastOffset !== readerOffset) {
    previous.push({ offset: reader.offset, size: reader.size, result: reader.result })
    return
  }

  function parseResult(offset, size, result) {
    lastOffset = offset + size
    callbackProgress(result)
    if (offset + size >= file.size) {
      lastOffset = 0
    }
  }
  parseResult(reader.offset, reader.size, reader.result)

  // resolve previous buffered
  let buffered = [{}]
  while (buffered.length > 0) {
    buffered = previous.filter(function (item) {
      return item.offset === lastOffset
    })
    buffered.forEach(function (item) {
      parseResult(item.offset, item.size, item.result)
      previous.remove(item)
    })
  }
}

Array.prototype.remove =
  Array.prototype.remove ||
  function (val) {
    let i = this.length
    while (i--) {
      if (this[i] === val) {
        this.splice(i, 1)
      }
    }
  }

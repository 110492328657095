import React from 'react'
import { nbsp, rub } from '../../constants/typography'
import { useHistory } from 'react-router-dom'
import { makeUrl } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export const targetModes = {
  editable: 'editable',
  show: 'show',
}

function TargetItem({ targetData, mode, onClose, onSelect }) {
  const history = useHistory()

  const handleClickEdit = (target) => {
    history.push(
      makeUrl({
        route: ROUTES.PROFILE_TARGETS,
        routeParams: { targetId: target.id },
      }),
    )
  }

  const renderTitle = () => (
    <div
      className={clsx('title ', {
        'mt-0 black': mode === targetModes.show,
      })}
    >
      {targetData.targetName}
    </div>
  )

  const renderAmount = () => (
    <div
      className={clsx('amount', {
        'text-right': mode === targetModes.show,
      })}
    >
      <b>
        {targetData.donated} из {targetData.amount} {rub}
      </b>
      {nbsp}
      собрано
    </div>
  )

  return (
    <div
      className={clsx('targets-list-item', {
        closed: targetData.closed,
        'pb-1': mode === targetModes.show,
      })}
      onClick={() => onSelect(targetData)}
    >
      {mode === targetModes.show ? renderTitle() : renderAmount()}
      <progress max={targetData.amount} value={targetData.donated} id="progress-bar" />
      {mode === targetModes.show ? renderAmount() : renderTitle()}

      {mode === targetModes.editable && !targetData.closed && (
        <div className="bottom-block">
          <div className="edit-button" onClick={() => handleClickEdit(targetData)}>
            Редактировать
          </div>
          <div className="edit-button" onClick={() => onClose(targetData)}>
            Закрыть цель
          </div>
        </div>
      )}
    </div>
  )
}

TargetItem.defaultProps = {
  mode: targetModes.editable,
  onClose: () => {},
  onSelect: () => {},
}

TargetItem.propTypes = {
  targetData: PropTypes.object,
  mode: PropTypes.oneOf(Object.values(targetModes)),
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default TargetItem

import React, { useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'reactstrap'
import { addComment } from '../../api/comment'
import { appConfig } from '../../constants/appConfig'
import { simpleReducer } from '../../helpers'
import { formatDateTime, isDateTimeExpired } from '../../helpers/time'
import BottomTextArea from '../Layout/TextArea/BottomTextArea'
import { UserContext } from '../../contexts/userContext'

function CommentEditor({ postId, parentCommentId, replyToCommentId, value, onSave }) {
  const editorRef = useRef()
  const { user, userType, isFollower } = useContext(UserContext)

  const initialState = {
    content: value,
    isModalOpen: false,
    isDisableButton: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (parentCommentId) {
      editorRef?.current?.focus()
    }
  }, [parentCommentId])

  const handleAdd = async () => {
    try {
      if (!parentCommentId) {
        editorRef?.current?.focus()
      }
      const newComment = await addComment({
        postId,
        content: state.content,
        parentCommentId,
        replyToCommentId,
        userType,
      })
      setState({ content: '', isDisableButton: false })
      onSave(newComment)
    } catch (e) {
      console.log(e)
      setState({ isModalOpen: true, isDisableButton: false })
    }
  }

  const handleChange = (content) => {
    setState({ content })
  }

  const isCommentEmpty = useMemo(() => {
    const trimmedContent = state.content?.trim() || ''
    return !trimmedContent.length || trimmedContent === value?.trim()
  }, [state.content, value])

  const handleButtonClick = (action) => {
    setState({ isDisableButton: true })
    action()
  }

  const bannedUntil = useMemo(() => user?.follower?.bannedUntil, [user])

  const isBannedUser = useMemo(() => {
    return Boolean(isFollower && bannedUntil && !isDateTimeExpired(bannedUntil))
  }, [bannedUntil, isFollower])

  return (
    <>
      <div className="comment-editor">
        {isBannedUser ? (
          <div className="text-center flex-grow-1">
            Добавление комментариев заблокировано
            <br />
            до {formatDateTime(bannedUntil)}
          </div>
        ) : (
          <>
            <BottomTextArea
              ref={editorRef}
              placeholder="Комментарий ..."
              value={state.content}
              onChange={handleChange}
              onEnter={() => {
                if (!isCommentEmpty && !state.isDisableButton) {
                  handleButtonClick(handleAdd)
                }
              }}
            />
            <Button
              color="link"
              disabled={isCommentEmpty || state.isDisableButton}
              className="comment-editor--button d-flex align-items-center justify-content-center"
              onClick={() => handleButtonClick(handleAdd)}
            >
              <img src={`${appConfig.cdnPath.iconsSvg}/arrow-up-fill-green1.svg`} alt="" />
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={state.isModalOpen}
        centered
        toggle={() => setState({ isModalOpen: false })}
        size="sm"
      >
        <div className="py-4">
          <div className="medium-13-20 text-color-regular text-center mt-1 p-2">
            Не удалось сохранить.
          </div>
        </div>
      </Modal>
    </>
  )
}

CommentEditor.defaultProps = {
  value: '',
  onSave: () => {},
}

CommentEditor.propTypes = {
  postId: PropTypes.string.isRequired,
  parentCommentId: PropTypes.string,
  replyToCommentId: PropTypes.string,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
}

export default CommentEditor

import React from 'react'
import PropTypes from 'prop-types'
import TextArea from '../../Layout/TextArea/TextArea'
import { appConfig } from '../../../constants/appConfig'
import Checkbox from '../../Layout/Checkbox/Checkbox'

function PostSettings({
  onReturnBack,
  post: { isFree, allowComments, teaser },
  changePostData,
  focusTeaser,
}) {
  return (
    <>
      <header className="mb-4">
        <a
          className="pr-2"
          onClick={() => {
            onReturnBack()
          }}
        >
          <img
            src={`${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`}
            width="16px"
            height="14px"
            alt=""
            onClick={onReturnBack}
            role="button"
          />
        </a>
        <div className="flex-grow-1 text-center bold-17-24">Настройки к посту</div>
        <div className="text-color-green semiBold-15-24" onClick={onReturnBack} role="button">
          Готово
        </div>
      </header>
      <div className="content content-padding pt-5">
        <Checkbox
          label="Отключить комментарии"
          className="mb-5"
          onChange={(checked) => changePostData({ allowComments: !checked })}
          checked={!allowComments}
        />
        <Checkbox
          label="Открыт без подписки"
          className="mb-4"
          onChange={(isFree) => changePostData({ isFree })}
          checked={isFree}
        />
        <hr className="mb-5" />
        <div className="bold-17-24 mb-2">Тизер к посту</div>
        <div className="medium-13-20 text-color-regular mb-3">
          Тизер показывается только для пользователей, не имеющих подписку к вашему профилю.
        </div>
        <TextArea
          onChange={(teaser) => changePostData({ teaser })}
          value={teaser}
          placeholder="Напишите тизер к посту"
          showCounterLimit
          autofocus={focusTeaser}
          maxlength={100}
          textareaClassName="addPostTextArea"
        />
      </div>
    </>
  )
}

PostSettings.defaultProps = {
  onReturnBack: () => {},
  focusTeaser: false,
}

PostSettings.propTypes = {
  onReturnBack: PropTypes.func,
  post: PropTypes.shape({
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    isFree: PropTypes.bool.isRequired,
    allowComments: PropTypes.bool.isRequired,
  }).isRequired,
  changePostData: PropTypes.func.isRequired,
  focusTeaser: PropTypes.bool,
  isNewPost: PropTypes.bool,
}

export default PostSettings

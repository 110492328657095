import UserAvatar from '../UserAvatar'
import React from 'react'
import PropTypes from 'prop-types'
import { formatPostTime } from '../../../helpers/time'
import { appConfig } from '../../../constants/appConfig'

export function PostHeader({ post, avatarUrl, author, isPrivate = false, onPressActions = false }) {
  return (
    <div className="list-post-header">
      <UserAvatar src={avatarUrl} className="list-post-header-image" size={44} />
      <div className="list-post-header-content">
        <div className="list-post-header-top">
          <div className="bold-17-24">{author}</div>
          {isPrivate && <img src={`${appConfig.cdnPath.iconsSvg}/lock-grey.svg`} alt="" />}
          {typeof onPressActions === 'function' && (
            <img
              src={`${appConfig.cdnPath.iconsSvg}/points.svg`}
              className="options"
              onClick={onPressActions}
            />
          )}
        </div>
        <div className="list-post-header-top">
          <div className="text-color-regular">{formatPostTime(post?.publishDate)}</div>
          {post?.isPinned && (
            <div className="medium-13-20 text-color-regular">
              <img src={`${appConfig.cdnPath.iconsSvg}/clip-grey.svg`} className="mr-1" />
              Закреплено
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
PostHeader.propTypes = {
  post: PropTypes.object.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  onPressActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

import * as React from 'react'
import { useContext, useEffect, useReducer } from 'react'
import { Button } from '../components/Layout/Button/Button'
import InputPassword from '../components/Layout/InputPassword/InputPassword'
import { emailLogin, parseJwt } from '../api/auth'
import queryString from 'query-string'
import { authRedirects, makeUrl, simpleReducer } from '../helpers'
import { ROUTES } from '../constants/routes'
import { useHistory } from 'react-router-dom'
import { setNickname } from '../api/users'
import { Header } from '../components/Header/Header'
import { appConfig } from '../constants/appConfig'
import { Input } from '../components/Layout/Input/Input'
import EmailModal, { modalTypes } from '../components/Auth/EmailModal'
import { errorsJsx } from '../constants/errorsJsx'
import { UserContext } from '../contexts/userContext'
import { modes as authScreenModes } from '../screens/Auth'
import { update } from '../api/storage'
import { getPromotion } from '../api/promotion'
import moment from 'moment'

const { min: MIN_PASS_LENGTH, max: MAX_PASS_LENGTH } = appConfig.passwordLength
const { tokenKeys } = appConfig

const RegisterFinish = () => {
  const history = useHistory()
  const { token } = queryString.parse(window.location.search)
  const initialState = {
    nickname: '',
    nicknameError: '',
    password: '',
    passwordError: '',
    isModalOpen: false,
    isDisableButton: false,
    isTokenProcess: true,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { setUserType, setUser, isAuthorized, user } = useContext(UserContext)
  useEffect(() => {
    if (!token) {
      history.push(ROUTES.PAGE_404)
    }
  }, [history, token])

  useEffect(() => {
    if (token) {
      let tokenData
      try {
        tokenData = parseJwt(token)
      } catch (e) {
        console.error(e)
        history.push(ROUTES.PAGE_404)
        return
      }

      if (tokenData && tokenData[tokenKeys.isPromo] && tokenData[tokenKeys.emailAddress]) {
        if (tokenData[tokenKeys.notificationId] && !isAuthorized) {
          update(appConfig.storage.keys.welcomeMode, authScreenModes.auth)
          history.push(makeUrl({ route: ROUTES.AUTH }))
        } else if (
          tokenData[tokenKeys.notificationId] &&
          isAuthorized &&
          tokenData[tokenKeys.emailAddress] !== user.email
        ) {
          update(appConfig.storage.keys.welcomeMode, authScreenModes.auth)
          history.push(makeUrl({ route: ROUTES.AUTH }))
        } else if (
          tokenData[tokenKeys.notificationId] &&
          isAuthorized &&
          tokenData[tokenKeys.emailAddress] === user.email
        ) {
          getPromotion().then((promoData) => {
            let locationState = promoData && {
              promoData: promoData.filter(
                (item) => item.promotionId === tokenData[tokenKeys.promotionId],
              )[0],
            }
            if (locationState?.promoData?.end) {
              const now = moment()
              const promoEnd = moment.utc(locationState.promoData.end, 'DD.MM.YYYY HH:mm:ss')
              if (promoEnd.local().isBefore(now)) {
                locationState = null
              }
            }
            history.push(makeUrl({ route: ROUTES.MAIN }), locationState)
          })
        } else if (isAuthorized && tokenData[tokenKeys.emailAddress] === user.email) {
          history.push(makeUrl({ route: ROUTES.MAIN }))
        } else {
          setState({ isTokenProcess: false })
        }
      } else {
        setState({ isTokenProcess: false })
      }
    }
  }, [history, token, isAuthorized, user])

  const sendNickAndPass = async () => {
    const passValid =
      state.password.length >= MIN_PASS_LENGTH &&
      state.password.length <= MAX_PASS_LENGTH &&
      !/^ *$/.test(state.password)

    if (state.nickname.trim().length > 0 && passValid) {
      try {
        const authData = await emailLogin(null, state.password, token)
        await setNickname(state.nickname.trim())
        authData.user.follower.nickName = state.nickname.trim()
        authRedirects({ authData, history, setUser, setUserType })
        return
      } catch (e) {
        if (
          e.response?.config?.url === '/insider/users/current/nickname' &&
          e.response?.status === 400
        ) {
          setState({ nicknameError: errorsJsx.auth.duplicateNick, isDisableButton: false })
        } else {
          setState({ isModalOpen: true, isDisableButton: false })
        }
      }
    } else {
      setState({
        nicknameError: state.nickname.trim().length === 0 ? errorsJsx.auth.inputNickname : '',
        passwordError: !passValid ? errorsJsx.auth.failPassLength : '',
        isDisableButton: false,
      })
    }
  }

  const handleToggleModal = () => {
    setState({ isModalOpen: false })
    history.push(ROUTES.AUTH)
  }

  const handleButtonClick = (action) => {
    setState({ isDisableButton: true })
    action()
  }

  if (state.isTokenProcess) {
    return null
  }
  return (
    <>
      <Header title="Завершение регистрации" needRight={false} to="back" needLeft={false} />
      <div className="content content-padding footer-padding">
        <h1 className="bold-24-32 mt-3">Имя профиля</h1>
        <Input
          onKeyPress={(event) => {
            const charCode = event.key.charCodeAt(0)
            return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)
          }}
          value={state.nickname}
          onChange={(value) => {
            setState({ nickname: value.replace(/[^A-z0-9_]/g, '') })
          }}
          placeholder="Придумайте имя профиля"
          iconClassName="deleteicon-green"
          inputClassName={`medium-14-20 ${
            state.nicknameError !== '' ? 'border-bottom-error test-name-id' : 'test-name-id'
          }`}
          autofocus={false}
        />
        <div className="text-color-error medium-12-16">{state.nicknameError}</div>
        <div className="text-color-regular pt-2 medium-12-16">Только латинские буквы и цифры</div>
        <h1 className="bold-24-32 mt-3">Пароль</h1>
        <InputPassword
          value={state.password}
          onChange={(newPass) => {
            setState({ password: newPass.target.value, passwordError: '' })
          }}
          placeholder="Придумайте пароль"
          inputClassName={`medium-14-20 ${state.passwordError !== '' ? 'border-bottom-error' : ''}`}
        />
        <div className="text-color-error medium-12-16 mt-2">{state.passwordError}</div>
        <div className="pt-5">
          <Button
            onClick={() => handleButtonClick(sendNickAndPass)}
            className="mt-5 mb-5 semiBold-14-20"
            disable={state.isDisableButton}
          >
            Готово
          </Button>
        </div>
      </div>
      <EmailModal
        isOpen={state.isModalOpen}
        onClose={handleToggleModal}
        type={modalTypes.registerFinishError}
      />
    </>
  )
}

export default RegisterFinish

export const uploadFileTypes = {
  photo: 'photo',
  video: 'video',
  audio: 'audio',
  file: 'file',
}

export const uploadChatFileTypes = {
  photo: 'photo',
}

export const uploadFileTypesInfo = [
  {
    id: uploadFileTypes.photo,
    name: 'Фото',
    accept: '.jpg, .jpeg, .png',
  },
  {
    id: uploadFileTypes.video,
    name: 'Видео',
    accept: '.mp4',
  },
  {
    id: uploadFileTypes.audio,
    name: 'Аудио',
    accept: '.mp3',
  },
  {
    id: uploadFileTypes.file,
    name: 'Файл',
    accept: '.*',
  },
]

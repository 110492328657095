import React from 'react'
import { Header } from '../../Header/Header'
import LinkWithArrow from '../../Layout/LinkWithArrow/LinkWithArrow'
import { ROUTES } from '../../../constants/routes'
import { useHistory } from 'react-router-dom'

export function RatingMain() {
  const history = useHistory()
  return (
    <>
      <Header needRight={false} needLeft={true} title="Moй рейтинг" to={ROUTES.FOLLOWER} />
      <div className="content-padding">
        <LinkWithArrow
          title="Рейтинг по инфлюенсерам"
          onClick={() => history.push(ROUTES.FOLLOWER_RATING_SUBSCRIPTIONS)}
          className="follower-profile-item"
        />
        <LinkWithArrow
          title="Что такое рейтинг?"
          onClick={() => history.push(ROUTES.FOLLOWER_RATING_INFO)}
          className="follower-profile-item"
        />
        <hr className="follower-profile-item" />
      </div>
    </>
  )
}

import { api } from './api'
import { appConfig } from '../constants/appConfig'
import { getPrivateParamsByUser } from './users'

export const getComments = ({
  influencerId,
  postId,
  pageNumber = 0,
  commentsOnPage = appConfig.comments.countPerPage,
  parentCommentId = null,
  cnt = null,
  isCreateDateRequested = null,
  user,
  targetComment,
}) => {
  const { md5, e } = getPrivateParamsByUser(influencerId, user)
  const params = new URLSearchParams({
    page: pageNumber,
    countPerPage: commentsOnPage,
    md5,
    e,
    ...(parentCommentId && { parentCommentId }),
    ...(targetComment && { targetComment }),
    ...(isCreateDateRequested && { isCreateDateRequested }),
    ...(cnt !== null && cnt !== undefined && { cnt }),
  })
  return api.get({
    path: `/insider/private/influencer/${influencerId}/blog-posts/${postId}/comments?${params.toString()}`,
  })
}

export const toggleCommentLike = (commentId) => {
  return api.put({
    path: `/insider/comments/${commentId}/like`,
  })
}

export const addComment = ({
  postId,
  content,
  parentCommentId = null,
  replyToCommentId = null,
  userType,
}) => {
  return api.post({
    path: `/insider/comments/blogpost/${postId}/add`,
    data: {
      content,
      ...(parentCommentId && { parentComment: parentCommentId }),
      ...(replyToCommentId && { replyTo: replyToCommentId }),
      authorMode: userType,
    },
  })
}

export const deleteComment = ({ postId, commentId }) => {
  return api.del({
    path: `/insider/comments/blogpost/${postId}/delete`,
    data: {
      commentId,
    },
    isDirect: true,
  })
}

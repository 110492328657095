import { makeUrl } from '../../../helpers'
import { ROUTES } from '../../../constants/routes'
import { nbsp } from '../../../constants/typography'
import { appConfig } from '../../../constants/appConfig'
import React from 'react'
import * as PropTypes from 'prop-types'

function VipChatExpiredPlaceholder({ chat }) {
  return (
    <div className="d-flex flex-column justify-content-around flex-grow-1 chat-expired">
      <div className="d-flex flex-column align-content-center justify-content-center">
        <ul className="semiBold-17-24 text-left">
          <li className="">Получи доступ на 72 часа</li>
          <li className="mt-4">Веди личную переписку</li>
          <li className="mt-4">
            <span
              className="text-color-green"
              onClick={() =>
                history.push(
                  makeUrl({
                    route: ROUTES.INSIDERS_NICK,
                    routeParams: { nick: chat?.abonent?.nickName },
                  }),
                )
              }
            >
              {chat?.abonent?.nickName}
            </span>
            {nbsp}
            гарантирует ответ
          </li>
        </ul>
        <div className="chat-lock">
          <img
            src={`${appConfig.cdnPath.iconsSvg}/lock-grey.svg`}
            width="66px"
            height="84px"
            alt="lock"
          />
          <span className="medium-15-20 text-color-regular text-center">
            Вы пока что не можете
            <br />
            писать в этот чат
          </span>
        </div>
      </div>
    </div>
  )
}

export default VipChatExpiredPlaceholder

VipChatExpiredPlaceholder.propTypes = {
  chat: PropTypes.object,
}

import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import { UserContext } from '../contexts/userContext'
import { Main } from '../components/Follower/Main'

const MainPage = () => {
  const history = useHistory()
  const { isInfluencer } = useContext(UserContext)

  useEffect(() => {
    //Если инфлюенсер попал на эту страницу, бросим его на профайл
    if (isInfluencer) {
      history.push(ROUTES.PROFILE)
    }
  }, [history, isInfluencer])

  return <Main />
}

export default MainPage

import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, generatePath } from 'react-router-dom'

import { formatMessageTime } from '../../../helpers/time'
import { ROUTES } from '../../../constants/routes'
import ReadIcon from '../../../images/read.svg'
import UnReadIcon from '../../../images/unRead.svg'
import UserAvatar from '../UserAvatar'
import { update } from '../../../api/storage'
import { makeUrl, getUnreadMessagesCountString, getProfileIconUrl } from '../../../helpers'
import { appConfig } from '../../../constants/appConfig'
import { nbsp, rub } from '../../../constants/typography'
import { UserContext } from '../../../contexts/userContext'

function ChatItem({ chat }) {
  const { userType, currentTypeId } = useContext(UserContext)
  const history = useHistory()
  const {
    chatType,
    abonent,
    lastMessage = {},
    unreadQty = {},
    subscribedMonths,
    followerTop,
  } = chat
  const { isRead, createDate, content = '', senderId, paymentSum = 0, mediaItems } = lastMessage

  const isMe = useMemo(() => senderId === currentTypeId, [currentTypeId, senderId])

  return (
    <div
      className="content chat-item"
      onClick={() => {
        const type = userType.toLowerCase()
        update(
          appConfig.storage.keys.backFromChats,
          makeUrl({
            route: ROUTES.PROFILE_CHATS,
            routeParams: { userType: type },
            params: {
              type: chatType,
            },
          }),
        )
        history.push({
          pathname: generatePath(ROUTES.PROFILE_CHATS, {
            userType: type,
            interlocutorId: abonent.idProfile,
          }),
          search: `?type=${chatType}`,
        })
      }}
    >
      <div className="row d-flex h-100 justify-content-between align-items-center">
        <div className="col-10 d-flex">
          <div className="mr-2">
            <UserAvatar size={44} src={abonent?.avatarLink} />
          </div>
          <div className="chat-item__message">
            <div className="chat-item__name d-flex align-items-center">
              {abonent?.nickName}
              {subscribedMonths > 0 && (
                <img
                  className="ml-1"
                  src={
                    followerTop
                      ? `${appConfig.cdnPath.iconsSvg}/profileIcons/20x20/top.svg`
                      : getProfileIconUrl(subscribedMonths)
                  }
                  alt=""
                  width={20}
                />
              )}
            </div>
            <div className="chat-item__message--text">
              {isMe && `Вы: `}
              {paymentSum > 0 && (
                <span className="chat-item__message--text-payment">
                  {paymentSum}
                  {nbsp}
                  {rub}
                </span>
              )}
              {mediaItems?.length > 0 && <span className="text-color-green">Медиасообщение </span>}
              {content}
            </div>
          </div>
        </div>
        <div className="col-2 d-flex flex-column justify-content-center align-items-end">
          {unreadQty > 0 ? (
            <div className="chat-item__unread d-flex align-items-center justify-content-center">
              {getUnreadMessagesCountString(unreadQty)}
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
          <div className="chat-item__time-block d-flex align-items-center">
            {isMe && (
              <div className="chat-item__isme mr-1 mt-1">
                {isRead ? <img src={ReadIcon} alt="" /> : <img src={UnReadIcon} alt="" />}
              </div>
            )}
            <div className="chat-item__time">{createDate ? formatMessageTime(createDate) : ''}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

ChatItem.propTypes = {
  chat: PropTypes.shape({
    chatId: PropTypes.string.isRequired,
    chatType: PropTypes.string.isRequired,
    abonent: PropTypes.shape({
      idProfile: PropTypes.string.isRequired,
      nickName: PropTypes.string.isRequired,
      avatarLink: PropTypes.string,
    }).isRequired,
    lastMessage: PropTypes.shape({
      content: PropTypes.string,
      isRead: PropTypes.bool.isRequired,
      createDate: PropTypes.string.isRequired,
    }).isRequired,
    isWriting: PropTypes.bool,
    unreadQty: PropTypes.number,
    subscribedMonths: PropTypes.number,
    followerTop: PropTypes.number,
  }),
}

export default ChatItem

import { appConfig } from '../constants/appConfig'
import { chunk, toArray } from 'lodash-es'
import { nbsp, rub } from '../constants/typography'
import { generatePath } from 'react-router-dom'
import { isWebpSupported } from 'react-image-webp/dist/utils'
import queryString from 'query-string'
import { VIP_CHAT_TYPE } from '../constants'
import { remove, update } from '../api/storage'
import { ROUTES } from '../constants/routes'
import { redirectFollowerToPostOrInfluencer } from './logicalFlow'

let canWebp = -1
export const authRedirects = async ({
  authData,
  history,
  isSocial = false,
  logicalFlowData = null,
  setUserType,
  setUser,
}) => {
  const { follower } = authData?.user || {}
  const { influencer } = authData?.user || { influencer: false }
  if (influencer) {
    setUserType(appConfig.userTypes.influencer)
  } else {
    setUserType(appConfig.userTypes.follower)
  }
  remove(appConfig.storage.keys.message) //удаляем сообщение, если вдруг осталось от другого аккаунта
  remove(appConfig.comments.filterName) //чистим фильтры на комментах
  remove(appConfig.chats.filterName) //чистим фильтры на чатах
  if (isSocial && authData?.state?.id === appConfig.userState.noEmailReceived) {
    setUser(authData.user)
    update(appConfig.storage.keys.message, appConfig.userState.noEmailReceived)
    if (logicalFlowData?.isActive) {
      logicalFlowData?.resetFlowData()
    }
    history.push(ROUTES.MAIN)
    return null
  }
  if (follower?.nickName && !influencer) {
    setUser(authData.user)
    if (logicalFlowData?.isActive) {
      logicalFlowData?.resetFlowData()
      redirectFollowerToPostOrInfluencer({
        influencerNick: logicalFlowData.influencerNick,
        influencerId: logicalFlowData.influencerId,
        subscribeInfluencerId: logicalFlowData.subscribe,
        history,
        user: authData.user,
      })
    } else {
      history.push(ROUTES.MAIN)
    }
    return null
  }
  if ((influencer && !influencer?.nickName) || !follower?.nickName) {
    setUser(authData.user)
    window.ym(81892699, 'reachGoal', 'registration')
    history.push(ROUTES.MAIN)
  }
  if (influencer && influencer?.nickName) {
    setUser(authData.user)
    if (logicalFlowData?.isActive) {
      setUserType(appConfig.userTypes.follower)
      logicalFlowData?.resetFlowData()
      redirectFollowerToPostOrInfluencer({
        influencerNick: logicalFlowData.influencerNick,
        influencerId: logicalFlowData.influencerId,
        subscribeInfluencerId: logicalFlowData.subscribe,
        history,
      })
    } else {
      history.push(ROUTES.PROFILE)
    }
  }
}

export function simpleReducer(state, value) {
  return {
    ...state,
    ...value,
  }
}

export const getWithSinglePrefix = (value, prefix) =>
  value ? `${prefix}${value?.replace(prefix, '')}` : value

export const clearProtocolHost = (value) => {
  let result
  try {
    const urlData = new URL(value)
    result = value
      ?.replace(urlData.origin, '')
      .replace(/^\//, '')
      .replace(urlData.host, '')
      .replace(/^\//, '')
  } catch (e) {
    result = value
  }
  return result
}

/**
 * @param {number} number
 * @param {array} [words = ["яблоко", "яблока", "яблок"]]
 * @return {string}
 */
export const numberDeclension = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]

export const formatPrice = (price) => {
  const digits = toArray(price?.toString())?.reverse()
  if (digits?.length > 0) {
    let decs = chunk(digits, 3)
    decs = decs.map((item) => item.reverse().join('')).reverse()
    return `${decs.join(' ')}${nbsp}${rub}`
  }
  return price
}

/**
 * Group by prop
 * @param list []
 * @param prop string
 * @returns {*}
 */
/* eslint-disable indent */
export function groupBy(list, prop, defaultGroupName = 'none') {
  return Array.isArray(list)
    ? list.reduce((groups, item) => {
        const groupName = item[prop] || defaultGroupName
        const group = groups[groupName] || []
        group.push(item)
        groups[groupName] = group
        return groups
      }, {})
    : {}
}
/* eslint-enable indent */

export const isLocationEqual = (locationStr, routes) => {
  return locationStr && routes?.includes(locationStr)
}

export const convertArrayToObject = (list, keyName) => {
  const object = {}
  list?.forEach((item) => {
    if (item && item[keyName]) {
      object[item[keyName]] = { ...item }
    }
  })
  return object
}

export const makeUrl = ({ route, routeParams = {}, params = {}, isAbsolute = false }) => {
  const stringParams = new URLSearchParams(params).toString()
  const host = isAbsolute ? window.location.origin : ''
  return host + generatePath(route, routeParams) + (stringParams ? `?${stringParams}` : '')
}

export const makeSecureLink = ({ path, params, isImage = true }) => {
  let ext = ''
  if (canWebp === -1) {
    canWebp = isWebpSupported()
  }
  const secureParams = { ...params }
  if (isImage && path && canWebp) {
    secureParams.o = `${path.split('/').pop()}`
    ext = '.webp'
  }
  if (secureParams?.md5Static) {
    secureParams.md5 = secureParams.md5Static
    delete secureParams.md5Static
  }
  return `${path}${ext}?${queryString.stringify(secureParams)}`
}

export const getUnreadMessagesCountString = (count) =>
  count <= appConfig.chats.footerIconCounterRange
    ? count
    : `${appConfig.chats.footerIconCounterRange}+`

export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

export const getInterlocutorProfileLink = (nickName, isInfluencer) =>
  !nickName
    ? ''
    : isInfluencer
    ? makeUrl({
        route: ROUTES.PROFILE_FOLLOWER,
        routeParams: { followerNick: nickName },
      })
    : makeUrl({ route: ROUTES.INSIDERS_NICK, routeParams: { nick: nickName } })

export const isWrongAnyGetParam = (params) => {
  return Object.values(params || {}).some(
    (value) =>
      value === undefined ||
      value === null ||
      value === 'undefined' ||
      value === 'null' ||
      value === '',
  )
}

export const getChatKey = (chat) =>
  `${chat?.abonent?.idProfile}${chat?.chatType === VIP_CHAT_TYPE ? '-vip' : ''}`

function getProfileIcon(months) {
  if (months > 2 && months < 6) {
    return '3'
  }
  if (months >= 6 && months < 9) {
    return '6'
  }
  if (months >= 9 && months < 12) {
    return '9'
  }
  if (months >= 12) {
    return '12'
  }
  return months.toString()
}

export const getProfileIconUrl = (subscribedMonths) =>
  `${appConfig.cdnPath.iconsSvg}/profileIcons/20x20/${getProfileIcon(subscribedMonths)}.svg`

export const makeMailLink = (text) => {
  const regex = /([a-zA-Zа-яА-Я0-9._-]+@[a-zA-Zа-яА-Я0-9._-]+\.[a-zA-Zа-яА-Я0-9._-]+)/gi
  return text?.replace(regex, `<a href="mailto:$1">$1</a>`)
}

export const makeInstagramLink = (text) => {
  const regex = /((http(s)?:\/\/)?(www.)?instagram.com[/a-zA-z0-9._-]+)/gi
  return text
    ?.replace(regex, `<a href="$1" target="_blank">$1</a>`)
    .replace(/href="instagram.com/gi, 'href="https://instagram.com')
    .replace(/href="www.instagram.com/gi, 'href="https://www.instagram.com')
}

export function arrayUniqValues(list = []) {
  return list.filter((item, pos) => list.indexOf(item) === pos)
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min //Максимум и минимум включаются
}

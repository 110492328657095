import React, { useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import { simpleReducer } from '../../../helpers'
import PostActions from './PostActions'
import ConfirmForm from '../ConfirmForm'
import { PageLoading } from '../../Layout/PageLoading'
import { deletePost, togglePinned } from '../../../api/blog'
import CloseInfoForm from '../CloseInfoForm'
import { useHistory } from 'react-router-dom'
import { errorsJsx } from '../../../constants/errorsJsx'
import { useLogicalFlow, logicalFlows } from '../../../hooks/useLogicalFlow'
import { appConfig } from '../../../constants/appConfig'
import { deletePostAdmin } from '../../../api/administrator'
import { UserContext } from '../../../contexts/userContext'

const modes = {
  actions: 'actions',
  confirm: 'confirm',
  loading: 'loading',
  error: 'error',
}

function PostActionsModal({
  isOpen,
  onOpened,
  onClose,
  post,
  actions = [appConfig.postActions.pinn, appConfig.postActions.edit, appConfig.postActions.delete],
}) {
  const history = useHistory()
  const { setFlowData } = useLogicalFlow()
  const { isAdmin } = useContext(UserContext)
  const initialState = {
    mode: modes.actions,
    processAction: '',
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleActionDelete = () => setState({ mode: modes.confirm })

  const handleActionPinned = async () => {
    setState({ mode: modes.loading })
    try {
      await togglePinned(post.id)
      setFlowData(logicalFlows.profileTabName.id, { tabName: appConfig.tabCodes.posts })
      onClose()
      history.go(0)
      return
    } catch (e) {
      setState({ mode: modes.error })
    }
  }

  const handleCancelConfirm = () => setState({ mode: modes.actions })

  const handleDelete = async () => {
    setState({ mode: modes.loading })
    try {
      if (isAdmin) {
        await deletePostAdmin(post.id)
      } else {
        await deletePost(post.id)
      }
      setFlowData(logicalFlows.profileTabName.id, { tabName: appConfig.tabCodes.posts })
      onClose()
      history.go(0)
      return
    } catch (e) {
      setState({ mode: modes.error })
    }
  }

  const getContent = () => {
    switch (state.mode) {
      case modes.actions:
        return (
          <PostActions
            onClose={onClose}
            onActionDelete={handleActionDelete}
            onActionPinned={handleActionPinned}
            post={post}
            actions={actions}
          />
        )
      case modes.confirm:
        return (
          <ConfirmForm
            onOk={handleDelete}
            onCancel={handleCancelConfirm}
            message="Удалить пост?"
            className="post-actions-confirm modal-white-panel"
          />
        )
      case modes.loading:
        return <PageLoading hasFooter hasHeader className="mt-5 mb-5" />
      case modes.error:
        return (
          <CloseInfoForm
            onClose={() => setState({ mode: modes.actions })}
            className="modal-white-panel"
            message={errorsJsx.dataSendError}
          />
        )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={() => {}}
      size="sm"
      onOpened={onOpened}
      modalClassName="modal-bottom"
    >
      <div className="py-4">{getContent()}</div>
    </Modal>
  )
}

PostActionsModal.defaultProps = {
  isOpen: false,
  onOpened: () => {},
  onClose: () => {},
}

PostActionsModal.propTypes = {
  post: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpened: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.array,
}

export default PostActionsModal

import React, { useReducer, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { HrTitle, Paragraph, typeLines } from '../Layout'
import { simpleReducer, convertArrayToObject, getProfileIconUrl, makeUrl } from '../../helpers'
import CommentEditor from './CommentEditor'
import { toggleCommentLike, getComments, deleteComment } from '../../api/comment'
import { formatDateTimeLastDays } from '../../helpers/time'
import UserAvatar from '../Profile/UserAvatar'
import ConfirmModal from '../Modal/ConfirmModal'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { UserContext } from '../../contexts/userContext'
import { useIdsHash } from '../../hooks/useIdsHash'

const modes = {
  show: 'show',
  edit: 'edit',
}

function CommentsItem({
  influencerId,
  postId,
  comment,
  onDelete,
  onSaveReply,
  isPostOwner,
  influencerNickName,
}) {
  // const bottomRef = useRef()
  const history = useHistory()
  const { user, isInfluencer, isAdmin } = useContext(UserContext)
  const { idsHashData, hasCommentLike, toggleLikedComments } = useIdsHash()
  const initialState = {
    mode: modes.show,
    editValue: '',
    countLike: comment.likeCount,
    likesMarked: false,
    subList: {},
    subListNew: {},
    nav: {
      page: -1,
      perPage: appConfig.comments.countPerPage,
      total: 0,
    },
    isModalOpen: false,
  }

  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (idsHashData) {
      setState({ likesMarked: hasCommentLike(comment.id) })
    }
  }, [idsHashData])

  useEffect(() => {
    if (comment.influencerComments?.length > 0) {
      const influencerList = convertArrayToObject(comment.influencerComments, 'id')
      setState({
        subList: {
          ...state.subList,
          ...influencerList,
        },
      })
    }
  }, [comment])

  // const scrollBottom = () => bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

  const loadData = async (page) => {
    try {
      const result = await getComments({
        influencerId,
        postId,
        pageNumber: page,
        commentsOnPage: state.nav.perPage,
        parentCommentId: comment.id,
        user,
      })
      const partList = convertArrayToObject(result?.data, 'id')
      Object.keys(partList).forEach((commentId) => {
        const subListNewIds = Object.keys(state.subListNew)
        if (subListNewIds.includes(commentId)) {
          delete state.subListNew[commentId]
        }
      })
      setState({
        subList: {
          ...state.subList,
          ...partList,
        },
        subListNew: state.subListNew,
        nav: result?.nav,
      })
      // scrollBottom()
    } catch (e) {
      console.log(e)
    }
  }

  const handleReplyClick = () => {
    setState({ mode: modes.edit, editValue: `${comment.userNickName}, ` })
  }

  const handleClickLike = async () => {
    try {
      const result = await toggleCommentLike(comment.id)
      if (result) {
        setState({
          countLike: result.totalLikeCount,
          likesMarked: result.toggleLikeResult === appConfig.toggleIconsValues.setLike,
        })
        toggleLikedComments(
          result.toggleLikeResult === appConfig.toggleIconsValues.setLike,
          comment.id,
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleClickMore = async () => {
    await loadData(state.nav.page + 1)
  }

  const handleClickDelete = () => {
    setState({ isModalOpen: true })
  }

  const handleDeleteReply = async (deleteId) => {
    const delComment = state.subList[deleteId] || state.subListNew[deleteId]
    if (delComment) {
      try {
        await deleteComment({ postId, commentId: deleteId })
        delete state.subList[deleteId]
        delete state.subListNew[deleteId]
        setState({ subList: state.subList, subListNew: state.subListNew })
      } catch (e) {
        console.log(e)
      }
    }
  }

  const getCountMore = () => {
    if (state.nav.page >= 0) {
      return state.nav.total - state.nav.perPage * (state.nav.page + 1)
    }
    return comment.subCommentCount - comment.influencerComments?.length
  }

  const saveCommentItem = (comment) => {
    setState({
      mode: modes.show,
      subListNew: {
        ...state.subListNew,
        [comment.id]: comment,
      },
    })
  }

  const handleSaveEditor = (savedComment) => {
    if (!comment.parentCommentId) {
      saveCommentItem(savedComment)
    } else {
      setState({ mode: modes.show })
      onSaveReply(savedComment)
    }
  }

  const handleSaveReply = (comment) => {
    saveCommentItem(comment)
  }

  const handleClickUser = () => {
    if (isPostOwner && comment.authorUserId !== user?.id && isInfluencer) {
      history.push(
        makeUrl({
          route: ROUTES.PROFILE_FOLLOWER,
          routeParams: { followerNick: comment.userNickName },
        }),
        { backTo: window.location.pathname + window.location.search },
      )
    }
  }

  return (
    <>
      <div className="comments-item">
        <UserAvatar
          src={comment.userAvatar}
          size={40}
          className={clsx(`avatar mr-2`, {
            replyMargin: comment.parentCommentId,
            'user-avatar-vip': comment.userHadVip,
          })}
          onClick={handleClickUser}
        />
        <div className="main">
          <div className="d-flex justify-content-between">
            <div className="text">
              <div className="d-inline-flex align-items-center">
                <b className={clsx({ 'text-color-green': comment.userHadVip })}>
                  {comment.userNickName}
                </b>
                {influencerNickName === comment.userNickName && (
                  <img
                    src={`${appConfig.cdnPath.iconsSvg}/profileIcons/verified.svg`}
                    alt=""
                    className="ml-1"
                    width={20}
                  />
                )}
                {influencerNickName !== comment.userNickName &&
                  comment.userSubscribedMonths > 0 && (
                    <img
                      className="ml-1"
                      src={
                        comment.userTop
                          ? `${appConfig.cdnPath.iconsSvg}/profileIcons/20x20/top.svg`
                          : getProfileIconUrl(comment.userSubscribedMonths)
                      }
                      alt=""
                      width={20}
                    />
                  )}
              </div>
              <Paragraph
                value={comment.content}
                etcClassName="text-color-black30"
                typeLine={typeLines.threeLines}
              />
            </div>
          </div>
          <div className="toolbar mt-1">
            <div className="date">{formatDateTimeLastDays(comment.createDate)}</div>
            <div className="d-flex align-items-center">
              {state.mode === modes.show ? (
                <div className="btn-reply font-weight-600" onClick={handleReplyClick}>
                  Ответить
                </div>
              ) : (
                <div style={{ width: '60px' }} />
              )}
              <div className="self-like">
                {(isPostOwner || user?.id === comment.authorUserId || isAdmin) && (
                  <img
                    src={`${appConfig.cdnPath.iconsSvg}/basket-grey-dark.svg`}
                    onClick={handleClickDelete}
                    alt=""
                  />
                )}
              </div>
              <div className="comment-like" onClick={handleClickLike}>
                <img
                  src={`${appConfig.cdnPath.iconsSvg}/${
                    state.likesMarked ? 'like-small-fill.svg' : 'like-small.svg'
                  }`}
                  alt=""
                />
                {state.countLike > 0 && <span className="ml-2">{state.countLike}</span>}
              </div>
            </div>
          </div>
          {state.mode === modes.edit && (
            <div className="mt-2">
              <CommentEditor
                postId={postId}
                value={state.editValue}
                parentCommentId={comment.parentCommentId || comment.id}
                replyToCommentId={comment.id}
                onSave={handleSaveEditor}
              />
            </div>
          )}
        </div>
      </div>
      {Object.values(state.subList).map((comment) => (
        <CommentsItem
          key={comment.id}
          comment={comment}
          influencerId={influencerId}
          postId={postId}
          onDelete={handleDeleteReply}
          onSaveReply={handleSaveReply}
          isPostOwner={isPostOwner}
          influencerNickName={influencerNickName}
        />
      ))}
      {getCountMore() > 0 && (
        <HrTitle
          title={`Посмотреть ответы (${getCountMore()})`}
          className="comments-hr"
          onClick={handleClickMore}
        />
      )}
      {Object.values(state.subListNew).map((comment) => (
        <CommentsItem
          key={comment.id}
          comment={comment}
          influencerId={influencerId}
          postId={postId}
          onDelete={handleDeleteReply}
          onSaveReply={handleSaveReply}
          isPostOwner={isPostOwner}
          influencerNickName={influencerNickName}
        />
      ))}
      {/*<div ref={bottomRef} />*/}
      <ConfirmModal
        isOpen={state.isModalOpen}
        onOk={() => onDelete(comment.id)}
        onCancel={() => setState({ isModalOpen: false })}
        message="Удалить комментарий?"
      />
    </>
  )
}

CommentsItem.defaultProps = {
  onDelete: () => {},
  onSaveReply: () => {},
}

CommentsItem.propTypes = {
  influencerId: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onSaveReply: PropTypes.func.isRequired,
  isPostOwner: PropTypes.bool.isRequired,
  influencerNickName: PropTypes.string.isRequired,
}

export default CommentsItem

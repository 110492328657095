import PropTypes from 'prop-types'
import React from 'react'

function Audio({ audioRef, onPlay, onLoadedData, onPause, onTimeUpdate, src, type }) {
  return (
    <audio
      controls
      controlsList="nodownload"
      ref={audioRef}
      onPlay={onPlay}
      onLoadedData={onLoadedData}
      onPause={onPause}
      onTimeUpdate={onTimeUpdate}
      src={src}
    >
      <source src={src} type={type} />
    </audio>
  )
}

export const MemoizedAudio = React.memo(Audio)

Audio.propTypes = {
  audioRef: PropTypes.any,
  onPlay: PropTypes.func,
  onLoadedData: PropTypes.func,
  onPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  src: PropTypes.any,
  type: PropTypes.any,
}

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../Layout/Button/Button'
import { appConfig } from '../../../constants/appConfig'
import FavoriteIcons from '../../Main/FavoriteIcons'
import { isWebpSupported } from 'react-image-webp/dist/utils'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { PostHeader } from '../TabsEditable/PostHeader'

export function BlurredPost({ post, avatar, author, influencerNick, isSubscribed = false }) {
  const history = useHistory()
  const { title, teaser = '', influencerId, images, videos } = post
  const windowSize = useWindowSize()

  const postImage = useMemo(() => {
    const main = [...images, ...videos]?.filter((item) => item.isMain)[0]
    if (main && main.blurUrl) {
      const params = { w: 480 }
      const ratio = main.axisXSize / main.axisYSize
      let ext = ''
      if (isWebpSupported) {
        params.o = main.blurUrl.split('/').pop()
        ext = '.webp'
      }
      return {
        ...main,
        fileUrl: `${main.blurUrl}${ext}?${queryString.stringify(params)}`,
        ratio: ratio < 1 ? ratio : 1,
      }
    }
    return null
  }, [images, videos])

  return (
    <>
      <div className="list-post mb-4" id={post?.id}>
        <PostHeader author={author} avatarUrl={avatar} post={post} isPrivate />
        <div className="list-post-teaser semiBold-15-24 text-left">{title}</div>
        <div className="list-post-teaser medium-15-24 text-pre-line text-color-sub-title">
          {teaser}
        </div>
        <div
          className="list-post-blurred"
          style={
            postImage
              ? {
                  backgroundImage: `url('//${postImage.fileUrl}')`,
                  height: `calc(100vw / ${postImage.ratio})`,
                  maxHeight: `${windowSize.width > 480 ? 480 : windowSize.width}px`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }
              : {
                  backgroundImage: `url('${appConfig.cdnPath.images}/default-blur.png')`,
                  height: '100vw',
                  maxHeight: '480px',
                }
          }
        >
          <div className="list-post-lock">
            <img src={`${appConfig.cdnPath.iconsSvg}/lock-white.svg`} alt="" />
            <span className="medium-13-20 text-center">
              Доступно только
              <br />
              подписчикам
            </span>
          </div>
          <div className="list-post-icons">
            {post?.imagesCount > 0 && (
              <>
                <img src={`${appConfig.cdnPath.iconsSvg}/icon-pic.svg`} alt="" />
                <span>{post.imagesCount}</span>
              </>
            )}
            {post?.filesCount > 0 && (
              <>
                <img src={`${appConfig.cdnPath.iconsSvg}/icon-file.svg`} alt="" />
                <span>{post.filesCount}</span>
              </>
            )}
            {post?.videoCount > 0 && (
              <>
                <img src={`${appConfig.cdnPath.iconsSvg}/icon-video.svg`} alt="" />
                <span>{post.videoCount}</span>
              </>
            )}
            {post?.audioCount > 0 && (
              <>
                <img src={`${appConfig.cdnPath.iconsSvg}/icon-music.svg`} alt="" />
                <span>{post.audioCount}</span>
              </>
            )}
          </div>

          <div className="list-post-button">
            <Button
              onClick={() => {
                history.push(`/subscribe/?influencerId=${influencerId}`)
              }}
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
            >
              Подписаться
            </Button>
          </div>
        </div>
        <FavoriteIcons post={post} influencerNick={influencerNick} isSubscribed={isSubscribed} />
      </div>
    </>
  )
}

BlurredPost.propTypes = {
  author: PropTypes.string.isRequired,
  influencerNick: PropTypes.string.isRequired,
  post: PropTypes.shape({
    id: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        isMain: PropTypes.bool,
        blurUrl: PropTypes.string,
      }),
    ),
    videos: PropTypes.array,
    title: PropTypes.string,
    teaser: PropTypes.string,
    influencerId: PropTypes.string,
    imagesCount: PropTypes.number,
    videoCount: PropTypes.number,
    audioCount: PropTypes.number,
    filesCount: PropTypes.number,
    publishDate: PropTypes.string,
  }).isRequired,
  avatar: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool,
}

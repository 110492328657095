import * as React from 'react'
import { Link } from 'react-router-dom'
import { appConfig } from '../constants/appConfig'
import { ROUTES } from '../constants/routes'
import { useContext } from 'react'
import { UserContext } from '../contexts/userContext'

// markup
const NoMatch = () => {
  const { isFollower, isInfluencer, isAuthorized } = useContext(UserContext)
  const getMainLink = () => {
    if (!isAuthorized || isFollower) {
      return ROUTES.MAIN
    } else if (isInfluencer) {
      return ROUTES.PROFILE
    }
    return ''
  }

  return (
    <main className="layout">
      <header className="layout3-header justify-content-end align-items-start">
        <Link to={getMainLink()} className="green-link semiBold-15-24">
          На главную
        </Link>
      </header>
      <div className="d-flex flex-column justify-content-around flex-grow-1">
        <div className="text-center">
          <img src={`${appConfig.cdnPath.images}/sorry.svg`} alt="" width="279px" height="279px" />
        </div>
        <div
          className="content-padding justify-content-center flex-column pb-5"
          style={{ background: 'none' }}
        >
          <h1 className="bold-24-32 text-center w-100">Упс! Такой страницы нет.</h1>
          <div className="text-color-regular medium-15-24 w-100 text-center">
            Возможно, ошибка в адресе. Попробуй вернуться на главную
          </div>
        </div>
      </div>
    </main>
  )
}

export default NoMatch

import React, { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import TextareaAutosize from 'react-textarea-autosize'
import { isIOS } from '../../../helpers'
import throttle from 'lodash-es/throttle'

const initialFieldHeight = 36

// eslint-disable-next-line react/display-name
const BottomTextArea = forwardRef(
  ({ value, onChange, placeholder, onEnter, disabled = false }, ref) => {
    const [fieldHeight, setFieldHeight] = useState(initialFieldHeight)
    const [offsetTop, setOffsetTop] = useState(0)
    const pageHeight = useRef(window.visualViewport?.height)
    const resizeHandler = () => {
      if (pageHeight.current && pageHeight.current > window.visualViewport?.height) {
        let delta = pageHeight.current - window.visualViewport?.height
        if (delta > 150) {
          if (navigator.userAgent.indexOf('iPhone OS 15') !== -1 && pageHeight.current > 652) {
            delta += 42
          }
          setOffsetTop(delta)
          window.scrollTo(0, delta)
        }
      }
      pageHeight.current = window.visualViewport?.height
    }
    useEffect(() => {
      const iOS = isIOS()
      if (iOS) {
        window.visualViewport?.addEventListener('resize', resizeHandler)
        return () => {
          window.visualViewport?.removeEventListener('resize', resizeHandler)
        }
      }
    }, [])

    useEffect(() => {
      window.onscroll = throttle(() => {
        if (offsetTop > 0 && window.pageYOffset > offsetTop) {
          window.scrollTo(0, offsetTop)
        }
      }, 100)
      return () => {
        window.onscroll = () => {}
      }
    }, [offsetTop])

    return (
      <TextareaAutosize
        onHeightChange={setFieldHeight}
        maxRows={5}
        ref={ref}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        className={clsx({ disabled: disabled })}
        onChange={(e) => onChange(e.target.value)}
        onFocus={(e) => {
          const value = e.target.value
          e.target.value = ''
          e.target.value = value
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onEnter()
          }
        }}
        style={{
          borderRadius: fieldHeight === initialFieldHeight ? 50 : 15,
          fontSize: '16px',
        }}
      />
    )
  },
)

export default BottomTextArea

BottomTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  ref: PropTypes.any,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
}

import React, { useEffect, useReducer } from 'react'
import { ROUTES } from '../../constants/routes'
import { Header } from '../../components/Header/Header'
import { Button } from '../../components/Layout/Button/Button'
import { Input } from '../../components/Layout/Input/Input'
import { makeUrl, simpleReducer } from '../../helpers'
import { getCurrentUser, updateUserWelcomeMessage } from '../../api/users'
import { setChatPrice } from '../../api/chat'
import { rub } from '../../constants/typography'
import WelcomeMediaBlock from '../../components/Main/WelcomeMediaBlock'
import { useHistory } from 'react-router-dom'

const ERROR_COST = 'Стоимость премиум-чата должна быть больше 0 '

const SettingsChatsPage = () => {
  const history = useHistory()
  const initialState = {
    user: null,
    chatCost: null,
    chatHello: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const loadData = async () => {
    const user = await getCurrentUser()
    setState({
      user,
      chatCost: user?.influencer?.chatCost > 0 ? user?.influencer?.chatCost : null,
      chatHello:
        user?.influencer?.welcomeText === undefined
          ? 'Привет! Добро пожаловать в мой чат!'
          : user?.influencer?.welcomeText,
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (state.chatCost !== null && state.chatCost !== '') {
      setState({ error: isValidCost(state.chatCost) ? null : ERROR_COST })
    }
  }, [state.chatCost])

  const handleSave = async () => {
    if (state.error === null && isValidCost(state.chatCost)) {
      await setChatPrice(state.chatCost)
      await updateUserWelcomeMessage(state.chatHello)
      history.push(makeUrl({ route: ROUTES.PROFILE_SETTINGS }))
    }
  }

  const isValidCost = (value) => value > 0

  const handleChangeInput = (value) => {
    const digits = value?.replace(rub, '').replaceAll(/([^\d])/g, '')
    setState({
      chatCost: digits ? parseInt(digits) : digits,
    })
  }

  return (
    <>
      <Header title="Управление чатами" needRight={false} to={ROUTES.PROFILE_SETTINGS} />
      <div className="content content-padding footer-padding settings-chats">
        <div className="title">Установи стоимость премиум-чата</div>
        <div className="text mb-3">
          Старайся объективно оценивать стоимость, чтобы не отпугнуть своих фанатов.
        </div>
        <div className="text mb-5">
          Ты всегда можешь изменить стоимость подписки в настройках профиля или в настройках
          приложения.
        </div>
        <hr />
        <div className="subTitle">Стоимость, которую видят твои подписчики</div>
        <Input
          type="number"
          onChange={handleChangeInput}
          value={state.chatCost}
          autofocus={true}
          inputClassName="pr-3"
          placeholder="Введите стоимость"
          prefix={rub}
        />
        {state.error && <div className="text-danger">{state.error}</div>}

        <div className="subTitle mt-5">Приветственное сообщение</div>
        <div className="text">
          Создай приветственное сообщение. Оно будет автоматически отправляться всем новым
          подписчикам.
        </div>
        <Input
          onChange={(chatHello) => setState({ chatHello })}
          value={state.chatHello}
          inputClassName="pl-3 pr-3"
          placeholder="Введите сообщение"
        />

        <WelcomeMediaBlock className="mt-5 mb-5" user={state.user} />

        <Button
          onClick={handleSave}
          className="my-2 semiBold-15-24 mt-5"
          disable={state.error || !isValidCost(state.chatCost)}
        >
          Сохранить
        </Button>
      </div>
    </>
  )
}

export default SettingsChatsPage

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../Layout/Button/Button'
import TextArea from '../../Layout/TextArea/TextArea'
import { getDescription, setDescription } from '../../../api/users'
import { Header } from '../../Header/Header'
import { UserContext } from '../../../contexts/userContext'

function DescriptionProfile({ onBackLink }) {
  const { influencerId, isInfluencer } = useContext(UserContext)
  const [desc, setDesc] = useState('')
  const loadDesc = async () => {
    if (influencerId) {
      const { shortDescription } = await getDescription(influencerId)
      if (shortDescription) {
        setDesc(shortDescription)
      }
    }
  }
  useEffect(() => {
    loadDesc()
  }, [])

  return (
    <>
      <Header title="Описание профиля" needRight={false} onBackAction={onBackLink} />
      <div className="content content-padding mb-3">
        <TextArea
          onChange={setDesc}
          value={desc}
          autofocus
          placeholder="Опишите, чем вы занимаетесь"
          showCounterLimit
          maxlength={100}
        />
      </div>
      <div className="content-padding pb-4">
        <Button
          className="semiBold-15-24"
          onClick={async () => {
            if (isInfluencer) {
              await setDescription({ shortDescription: desc })
              onBackLink()
            } else {
              console.log('user is not influencer')
            }
          }}
          color="regular"
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          disable={desc.length === 0}
        >
          Сохранить
        </Button>
      </div>
    </>
  )
}

DescriptionProfile.defaultProps = {
  onBackLink: () => {},
}

DescriptionProfile.propTypes = {
  onBackLink: PropTypes.func,
}

export default DescriptionProfile

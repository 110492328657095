import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

const TARGETS_ALL_KEY = 'targetsAll'
const TARGETS_KEY = 'targets'

export const createDonateTarget = ({ data }) => {
  return api.post({
    path: `/insider/donate-targets`,
    data: data,
    isDirect: true,
  })
}

export const editDonateTarget = ({ data }) => {
  return api.put({
    path: `/insider/donate-targets`,
    data: data,
    isDirect: true,
  })
}

const editOrCreateTarget = ({ data }) => {
  return data?.id ? editDonateTarget({ data }) : createDonateTarget({ data })
}

export const useMutateTarget = (options) => {
  return useMutation(editOrCreateTarget, {
    ...options,
    onSuccess: () => {
      queryClient.refetchQueries(TARGETS_ALL_KEY)
    },
  })
}

export const getAllTargets = () => {
  return api.get({
    path: `/insider/donate-targets/all`,
  })
}

export const useAllTargets = ({ influencerId }, options) => {
  return useQuery([TARGETS_ALL_KEY, influencerId], getAllTargets, options)
}

export const getInfluencerTargets = (influencerId) => {
  const params = new URLSearchParams({ influencerId })
  return api.get({
    path: `/insider/donate-targets?${params.toString()}`,
  })
}

export const useInfluencerTargets = ({ influencerId }, options) => {
  return useQuery([TARGETS_KEY, influencerId], () => getInfluencerTargets(influencerId), options)
}

import React, { useContext, useEffect, useReducer } from 'react'
import Comments from '../../components/Comments/Comments'
import { makeUrl, simpleReducer } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'

function InfluencerCommentsPage() {
  const history = useHistory()
  const initialState = {
    influencer: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { isFollower, user, isAuthorized } = useContext(UserContext)

  useEffect(() => {
    if (!isAuthorized || isFollower) {
      history.push(makeUrl({ route: ROUTES.PAGE_404 }))
      return
    }

    if (user?.influencer?.id) {
      setState({ influencer: user.influencer })
    }
  }, [history, isAuthorized, isFollower, user.influencer])

  return state.influencer ? <Comments influencerViewer={state.influencer} /> : null
}

export default InfluencerCommentsPage

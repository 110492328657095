import React from 'react'
import PropTypes from 'prop-types'

function Radio({ className, classLabel, label, onChange, value, name, checked }) {
  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <div className={`radio ${className}`}>
      <input type="radio" value={value} name={name} onChange={handleChange} checked={checked} />
      <div className={`label ${classLabel}`}>{label}</div>
    </div>
  )
}

Radio.defaultProps = {
  className: '',
  classLabel: '',
  label: '',
  onChange: () => {},
}

Radio.propTypes = {
  className: PropTypes.string,
  classLabel: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default Radio

import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { appConfig } from '../../../../constants/appConfig'
import { Input } from '../../../Layout/Input/Input'
import DeleteMediaIcon from './DeleteMediaIcon'
import { PostContext } from '../../../../contexts/postContext'

const MODE_SHOW = 'show'
const MODE_EDIT = 'edit'
export function FileLine({ onChange, descriptionList, id, onDelete }) {
  const [mode, setMode] = useState(MODE_SHOW)
  const { mediaIdToDelete } = useContext(PostContext)
  return (
    <div className={clsx('file file-line mb-2', { disabled: mediaIdToDelete.includes(id) })}>
      <img
        src={`${appConfig.cdnPath.iconsSvg}/file-green-crop.svg`}
        alt=""
        width="26px"
        height="36px"
      />
      {mode === MODE_EDIT && (
        <Input
          onChange={onChange}
          autofocus={false}
          value={descriptionList[id]}
          placeholder="Введите название файла"
        />
      )}
      {mode === MODE_SHOW && (
        <div
          onClick={() => setMode(MODE_EDIT)}
          role="button"
          className="semiBold-14-20 text-left filename"
        >
          {descriptionList[id] || 'файл без названия'}
        </div>
      )}
      <DeleteMediaIcon id={id} onDelete={onDelete} />
    </div>
  )
}

FileLine.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
}

FileLine.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  descriptionList: PropTypes.any,
  id: PropTypes.any,
}

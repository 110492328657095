import * as React from 'react'
import { useEffect } from 'react'
import { ROUTES } from '../constants/routes'
import { useHistory } from 'react-router-dom'

export default function Home() {
  const history = useHistory()

  useEffect(() => {
    history.push(ROUTES.MAIN)
  }, [history])

  return (
    <div className="auth">
      <div className="auth-header" />
      <div className="auth-content" />
    </div>
  )
}

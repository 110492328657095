import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Footer } from '../../components/Footer/Footer'
import Chats from '../../components/Profile/Chats/Chats'
import Chat from '../../components/Profile/Chats/Chat'

export const pageMode = {
  settings: 'settings',
  about: 'about',
  donats: 'donats',
  subscriptions: 'subscriptions',
  categories: 'categories',
}

const ChatProfilePage = () => {
  const { interlocutorId } = useParams()
  return (
    <>
      {interlocutorId ? <Chat /> : <Chats />}
      {!interlocutorId && <Footer />}
    </>
  )
}

ChatProfilePage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

export default ChatProfilePage

import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { REGULAR_CHAT_TYPE, VIP_CHAT_TYPE } from '../../../constants'
import { ChatContext } from '../../../contexts/chatContext'
import { getUnreadMessagesCountString } from '../../../helpers'

function ChatTab({ title, type, unreadChatCount, unreadChatCountVip }) {
  const history = useHistory()
  const { userType, interlocutorId } = useParams()
  const chatType = new URLSearchParams(useLocation().search).get('type') || REGULAR_CHAT_TYPE
  const { unreadMessagesCount, unreadMessagesCountVip } = useContext(ChatContext)

  const unreadCount = useMemo(
    () =>
      !interlocutorId
        ? type === VIP_CHAT_TYPE
          ? unreadMessagesCountVip
          : unreadMessagesCount
        : type === VIP_CHAT_TYPE
        ? unreadChatCountVip
        : unreadChatCount,
    [
      interlocutorId,
      type,
      unreadMessagesCountVip,
      unreadMessagesCount,
      unreadChatCountVip,
      unreadChatCount,
    ],
  )

  return (
    <div
      onClick={() =>
        history.push({
          pathname: generatePath(ROUTES.PROFILE_CHATS, {
            userType,
            interlocutorId,
          }),
          search: `?type=${type}`,
        })
      }
      onKeyDown={() => {}}
      role="tab"
      tabIndex={0}
      className={clsx('semiBold-15-24 tab mb-0', {
        'tab-active': type === chatType,
      })}
    >
      <div className="tab-title">
        {unreadCount > 0 && (
          <div className="tab-title--counter unread-messages-counter">
            {getUnreadMessagesCountString(unreadCount)}
          </div>
        )}
        {title}
      </div>
    </div>
  )
}

export default ChatTab

ChatTab.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  unreadChatCount: PropTypes.number,
  unreadChatCountVip: PropTypes.number,
}

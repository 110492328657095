import React, { useContext, useState } from 'react'
import AddImage from '../../components/Profile/EmptyProfile/AddImage'
import { handleFile } from '../../api/upload'
import { useHistory, useLocation } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'
import { appConfig } from '../../constants/appConfig'

const AddAvatarPage = () => {
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const { userType: profileType } = useContext(UserContext)

  const uploadAvatar = async (file) => {
    if (profileType) {
      setIsLoading(true)
      await handleFile({
        file,
        data: {
          mediaTypeDestination: appConfig.media.mediaTypeDestinations[profileType.toLowerCase()],
        },
        callback: () => {
          setIsLoading(false)
          if (location?.state?.backTo) {
            history.push(location.state.backTo)
          } else {
            history.goBack()
          }
        },
      })
    }
  }
  return (
    <AddImage upload={uploadAvatar} isAvatar={true} title="Фото профиля" isLoading={isLoading} />
  )
}

export default AddAvatarPage

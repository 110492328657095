import React from 'react'
import PropTypes from 'prop-types'
import { LogoSpinner } from './LogoSpinner'

function PageLoading({ hasHeader, hasFooter, className = '' }) {
  return (
    <div
      className={`page-loader ${hasHeader ? 'hasHeader' : ''} ${
        hasFooter ? 'hasFooter' : ''
      } ${className} `}
    >
      <LogoSpinner isLoading size={32} />
    </div>
  )
}
export { PageLoading }

PageLoading.propTypes = {
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
  className: PropTypes.string,
}

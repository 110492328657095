import React, { useReducer, useState, useCallback, useEffect, useContext, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'
import { Search } from '../../components/Search/Search'
import { appConfig } from '../../constants/appConfig'
import { makeUrl, simpleReducer } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { getCurrentSubscribers } from '../../api/influencer'
import { getInfluencerFullInfo } from '../../api/users'
import RandomSelect from '../../components/Profile/Influencer/FollowersList/RandomSelect'
import { find, update } from '../../api/storage'
import FollowerListItem from '../../components/Profile/Influencer/FollowersList/FollowerListItem'
import { PageLoading } from '../../components/Layout/PageLoading'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useScroll } from '../../hooks/useScroll'
import { FixedSizeList as List } from 'react-window'
import { Input } from '../../components/Layout/Input/Input'
import DatePicker from '../../components/Layout/DatePicker/DatePicker'
import { formatDate } from '../../helpers/time'
import { nbsp } from '../../constants/typography'
import { logicalFlows, useLogicalFlow } from '../../hooks/useLogicalFlow'
import moment from 'moment'
import clsx from 'clsx'
import { UserContext } from '../../contexts/userContext'

export const storageKey = 'randomFollowersParams'

function RandomFollowersPage() {
  const history = useHistory()
  const location = useLocation()
  const initialParams = find(storageKey)
  const { userType, influencerId } = useContext(UserContext)

  const initialState = {
    isQueryActive: false,
    minMonths: undefined,
    randomCount: '',
    search: '',
    searchText: '',
    isDatepickerShow: false,
    errorMonth: false,
    errorCount: false,
    ...initialParams,
    dateFrom: initialParams?.dateFrom ? new Date(initialParams?.dateFrom) : new Date(),
    dateTo: initialParams?.dateTo ? new Date(initialParams?.dateTo) : new Date(),
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { getFlowData, setFlowData, delFlowData } = useLogicalFlow()
  const [page, setPage] = useState(0)
  const countPerPage = 50
  const { data: influencerData, isLoading } = useQuery(
    ['influencer', influencerId],
    () => getInfluencerFullInfo(influencerId),
    {},
  )
  useEffect(() => {
    getFlowData(
      logicalFlows.followerRandomList.id,
      (ldata) => {
        if (ldata?.state) {
          setState({ ...ldata.state })
          delFlowData(logicalFlows.followerRandomList.id)
        }
      },
      () => {},
    )
  }, [])

  const dateFromCache = useMemo(() => {
    return moment(state.dateFrom).format(appConfig.dateFormats.apiDateTime)
  }, [state.dateFrom])

  const dateToCache = useMemo(() => {
    return moment(state.dateTo).format(appConfig.dateFormats.apiDateTimeAllDay)
  }, [state.dateTo])

  const { isFetching, data, fetchNextPage, refetch } = useInfiniteQuery(
    `loadFollowers-${state.randomCount}-${state.minMonths}-${state.search}-${dateFromCache}-${dateToCache}`,
    (key, pageParam) => {
      const dateFrom = moment(state.dateFrom).format(appConfig.dateFormats.apiDateTime)
      const dateTo = moment(state.dateTo).format(appConfig.dateFormats.apiDateTimeAllDay)
      return getCurrentSubscribers({
        page: pageParam,
        perPage: countPerPage,
        countOfRandomSubscribers: parseInt(state.randomCount),
        dateFrom,
        dateTo,
        minMonths: parseInt(state.minMonths),
        search: state.search,
      })
    },
    {
      enabled: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const handleRandomSearch = (count) => {
    if (!state.minMonths || !count) {
      setState({
        errorMonth: !state.minMonths,
        errorCount: !count,
      })
    } else {
      const isQueryActive = state.dateFrom && state.dateTo && state.minMonths && count
      setState({
        errorMonth: false,
        errorCount: false,
        randomCount: count,
        isQueryActive,
      })
      if (isQueryActive) {
        refetch()
      }
    }
  }

  const handleBack = () => {
    history.push(makeUrl({ route: ROUTES.PROFILE_FOLLOWER_LIST }))
  }

  const handleClickFollower = (followerNick) => {
    setFlowData(logicalFlows.followerRandomList.id, { state })
    history.push({
      pathname: makeUrl({ route: ROUTES.PROFILE_FOLLOWER, routeParams: { followerNick } }),
      state: { backTo: ROUTES.PROFILE_RANDOM_LIST },
    })
  }

  const handleClickChat = (followerId) => {
    update(appConfig.storage.keys.backFromChats, location.pathname)
    setFlowData(logicalFlows.followerRandomList.id, { state })
    history.push(
      makeUrl({
        route: ROUTES.PROFILE_CHATS,
        routeParams: { userType: userType.toLowerCase(), interlocutorId: followerId },
      }),
    )
  }

  const fetchNextFollowersPage = useCallback(() => {
    if (data && data[data.length - 1]?.length === countPerPage) {
      const nextPage = page + 1
      fetchNextPage({ pageParam: nextPage })
      setPage(nextPage)
    }
  }, [fetchNextPage, page, data])

  useScroll({
    onReachedBottom: () => {
      if (!isFetching) {
        fetchNextFollowersPage()
      }
    },
  })

  useEffect(() => {
    if (data?.length && state.dateFrom && state.dateTo && state.minMonths && state.randomCount) {
      update(storageKey, {
        dateFrom: state.dateFrom,
        dateTo: state.dateTo,
        minMonths: state.minMonths,
        randomCount: state.randomCount,
      })
    }
  }, [data, state.dateFrom, state.dateTo, state.minMonths, state.randomCount])

  if (isLoading || (isFetching && !data)) {
    return <PageLoading hasFooter hasHeader />
  }
  const followers = (data || []).flat().filter((item) => item)

  return (
    <>
      <Header
        title="Подписчики"
        needRight={true}
        needLeft={true}
        onBackAction={handleBack}
        rightBlock={
          <div className="d-flex align-items-center">
            <div className="followers-count mr-2">{influencerData.followersCount || null}</div>
            <img src={`${appConfig.cdnPath.iconsSvg}/people-gray.svg`} alt="" />
          </div>
        }
      />
      <div className="follower-list-wrapper">
        <div className="subheader">
          <div className="content-padding">
            <Search
              value={state.searchText}
              onChange={(text) => setState({ searchText: text })}
              placeholder="Поиск по подписчикам"
              filterMode={false}
              onSearch={(search) => setState({ search })}
              onClear={() => setState({ search: '', searchText: '' })}
            />
            <div className="random-select mt-2">
              <span>
                Дата приобретения подписки
                <br /> с
                <span
                  onClick={() => setState({ isDatepickerShow: 'dateFrom' })}
                  className="text-color-green"
                >
                  {nbsp}
                  {state.dateFrom ? formatDate(state.dateFrom) : '___'}
                  {nbsp}
                </span>
                по
                <span
                  onClick={() => setState({ isDatepickerShow: 'dateTo' })}
                  className="text-color-green"
                >
                  {nbsp}
                  {state.dateTo ? formatDate(state.dateTo) : '___'}
                  {nbsp}
                </span>
              </span>
            </div>
            <div className="random-select justify-content-start">
              <div className="input-wrapper">
                <Input
                  value={state.minMonths}
                  placeholder=""
                  onChange={(count) => {
                    const clearCount = count?.replace(/([^\d])/, '')
                    setState({
                      minMonths: clearCount,
                      errorMonth: !clearCount,
                      isQueryActive: false,
                    })
                  }}
                  inputClassName={clsx('random-input', { error: state.errorMonth })}
                  hideDeleteIcon
                />
              </div>{' '}
              и более месяцев подписки
            </div>
            <RandomSelect
              initialValue={state.randomCount}
              onChange={(count) =>
                setState({ randomCount: count, errorCount: !count, isQueryActive: false })
              }
              afterInputText="случайных подписчиков"
              inputClassName={clsx({ error: state.errorCount })}
              onSelect={handleRandomSearch}
            />
          </div>
        </div>
        <div className="content content-padding influencer-follower-list">
          <div className="follower-list">
            {!followers.length && state.isQueryActive && (
              <div className="semiBold-14-20">По заданным условиям подписчиков не найдено</div>
            )}
            <List
              height={followers.length * 60}
              itemCount={followers.length}
              itemSize={60}
              width="100%"
            >
              {({ index, style }) => (
                <div style={style}>
                  <FollowerListItem
                    follower={followers[index]}
                    onClickChat={handleClickChat}
                    onClickFollower={handleClickFollower}
                  />
                </div>
              )}
            </List>
          </div>
          <DatePicker
            isPopup
            open={state.isDatepickerShow}
            onSelect={async (date) => {
              if (state.isDatepickerShow === 'dateFrom') {
                setState({ dateFrom: date, isDatepickerShow: false })
              } else {
                setState({ dateTo: date, isDatepickerShow: false })
              }
            }}
            onCancel={() => setState({ isDatepickerShow: false })}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default RandomFollowersPage

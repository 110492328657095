import React from 'react'
import AudioRecorder from 'audio-recorder-polyfill'
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
import { QueryClientProvider } from 'react-query'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { StompChatContext } from './stomp/StompChatContext'
import { queryClient } from './api/api'

AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder

ReactDOM.render(
  <React.StrictMode>
    <StompChatContext.Provider value={{ config: window.insider.chatConfig }}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </StompChatContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or onSend to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

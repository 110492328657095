import { api } from './api'

export const getInfluencerList = () => {
  return api.get({
    path: `/insider/influencer`,
  })
}

export const getInfluencerByNick = (nick) => {
  return api
    .get({
      path: `/insider/influencer/${nick}/profile`,
    })
    .then(
      ({
        id,
        avatarUrl,
        backgroundUrl,
        followersSubscribed,
        influencerFullName,
        influencerLongDescription,
        influencerShortDescription,
        influencerTitle,
        instagramLink,
        nickName,
        tikTokLink,
        twitchLink,
        youTubeLink,
        subscription,
        publishedPostsCount,
      }) => ({
        id,
        avatarUrl: avatarUrl ? `//${avatarUrl}` : avatarUrl,
        backgroundUrl: backgroundUrl ? `//${backgroundUrl}` : backgroundUrl,
        name: influencerFullName,
        nick: nickName,
        shortDescription: influencerShortDescription,
        about: {
          title: influencerTitle,
          text: influencerLongDescription || '',
        },
        followersCount: followersSubscribed,
        socialLinks: {
          instagramLink,
          tikTokLink,
          twitchLink,
          youTubeLink,
        },
        subscription,
        publishedPostsCount,
      }),
    )
}

export const getCurrentSubscribers = ({
  page = 0,
  perPage = 50,
  countOfRandomSubscribers,
  dateFrom,
  dateTo,
  minMonths,
  search,
}) => {
  return api.post({
    path: `/api/influencer/current/subscribers`,
    data: {
      page,
      perPage,
      ...(countOfRandomSubscribers && { countOfRandomSubscribers }),
      ...(dateFrom && { dateFrom }),
      ...(dateTo && { dateTo }),
      ...(minMonths && { minMonths }),
      ...(search && { search }),
    },
  })
}

export const getSubscriberByNick = (followerNickname) => {
  const params = new URLSearchParams({ followerNickname })
  return api.get({
    path: `/api/influencer/current/subscriber?${params.toString()}`,
  })
}

export const setInfluencerOptions = ({ isShowingFollowers, isShowingLikes }) => {
  return api.put({
    path: `/api/influencer/current/showfollowers`,
    data: {
      isShowingFollowers,
      isShowingLikes,
    },
    isDirect: true,
  })
}

export const getInfluencerPayments = ({ dateFrom, dateTo, page, perPage, paymentType }) => {
  return api.post({
    path: `/api/influencer/current/payments`,
    data: {
      ...(dateFrom && { dateFrom }),
      ...(dateTo && { dateTo }),
      ...(paymentType && { paymentType }),
      page,
      perPage,
    },
  })
}

export const getInfluencerBalance = () => {
  return api.get({
    path: `/api/influencer/current/balance`,
  })
}

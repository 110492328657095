import React from 'react'
import PropTypes from 'prop-types'
import { AuthorizedPost } from './AuthorizedPost'
import { BlurredPost } from './BlurredPost'

export function Post({
  post,
  avatar,
  author,
  influencerId,
  imageParams,
  isSubscribed = false,
  onPressActions = false,
}) {
  return post?.text !== undefined ? (
    <AuthorizedPost
      post={post}
      avatar={avatar}
      author={author}
      influencerId={influencerId}
      influencerNick={author}
      secureParams={imageParams}
      isSubscribed={isSubscribed}
      onPressActions={onPressActions}
    />
  ) : (
    <BlurredPost
      post={post}
      avatar={avatar}
      author={author}
      influencerNick={author}
      isSubscribed={isSubscribed}
    />
  )
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
  avatar: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  influencerId: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  imageParams: PropTypes.object,
  onPressActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

import React, { useContext, useMemo, useReducer, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { appConfig } from '../../../constants/appConfig'
import { ChatContext } from '../../../contexts/chatContext'
import { VIP_CHAT_TYPE } from '../../../constants'
import clsx from 'clsx'
import { simpleReducer } from '../../../helpers'
import { UploadMaterialModal } from '../../Upload'
import { handleFile } from '../../../api/upload'
import { uploadFileTypes } from '../../../constants/files'
import { VoiceRecorder } from '../../Recorders/VoiceRecorder'
import BottomTextArea from '../../Layout/TextArea/BottomTextArea'
import { UserContext } from '../../../contexts/userContext'

const plusIcon = `${appConfig.cdnPath.iconsSvg}/plus-green.svg`
const arrowIcon = `${appConfig.cdnPath.iconsSvg}/arrow-up-fill-green1.svg`
const initialFieldHeight = 36

function MessageForm({ onSend, chat, disabled = false }) {
  const initialState = {
    message: {},
    fieldHeight: initialFieldHeight,
    isSelectUploadTypeOpen: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { isConnected } = useContext(ChatContext)
  const { currentTypeId } = useContext(UserContext)

  const messageInput = useRef(null)

  const message = useMemo(() => state.message[chat.chatId] || '', [chat, state.message])

  const setMessage = (value) =>
    setState({
      message: {
        ...state.message,
        [chat.chatId]: value,
      },
    })

  const upload = async (file, callback) =>
    await handleFile({
      ...file,
      data: { mediaTypeDestination: appConfig.media.mediaTypeDestinations.chat },
      callback: (data) => {
        sendMedia(data)
        callback()
      },
    })

  const sendMessage = () => {
    if (isRecordStart) {
      voiceRecorderRef.current.stop()
      return true
    }
    if (!message?.trim().length) {
      return false
    }
    setMessage('')
    messageInput.current?.focus() // чтоб не скрывалась клавиатура после отправки
    onSend({
      chatId: chat.chatId,
      from: currentTypeId,
      to: chat.abonent?.idProfile,
      vip: chat.chatType === VIP_CHAT_TYPE,
      content: message?.trim(),
    })
  }

  const sendMedia = ({ mediaContentId, downloadUrl, contentType, axisXSize, axisYSize, path }) => {
    if (!mediaContentId) {
      return false
    }
    setState({ isSelectUploadTypeOpen: false })
    onSend({
      chatId: chat.chatId,
      from: currentTypeId,
      to: chat.abonent?.idProfile,
      vip: chat.chatType === VIP_CHAT_TYPE,
      content: '',
      mediaItems: [mediaContentId],
      mediaItemsData: [{ downloadUrl, contentType, axisXSize, axisYSize, path }],
    })
  }

  const voiceRecorderRef = useRef(null)

  const [isRecordStart, setRecordStart] = useState(false)

  const isChatDisabled = useMemo(() => disabled || !isConnected, [disabled, isConnected])
  const isSendButtonDisable = useMemo(
    () => isChatDisabled || (!isRecordStart && !message?.trim().length),
    [isRecordStart, message, isChatDisabled],
  )
  return (
    <div className="chat-user--footer" id="chat-user-footer">
      <div
        className={clsx(
          'messages-form d-flex align-items-center justify-content-between content-padding',
          {
            disabled: !isConnected,
          },
        )}
      >
        <Button
          color="link"
          disabled={isChatDisabled}
          className="messages-form--button d-flex align-items-center justify-content-center"
          onClick={() => setState({ isSelectUploadTypeOpen: true })}
        >
          <img src={plusIcon} alt="" />
        </Button>
        <div style={{ position: 'relative' }} className="d-flex flex-grow-1">
          <BottomTextArea
            value={message}
            onChange={setMessage}
            disabled={isChatDisabled}
            onEnter={sendMessage}
            placeholder="Сообщение..."
            ref={messageInput}
          />

          {message.length === 0 && (
            <VoiceRecorder
              uploadMethod={(file, callback) => upload({ file }, callback)}
              ref={voiceRecorderRef}
              setIsRecording={(isRecording) => setRecordStart(isRecording)}
              className="voice"
              disabled={isChatDisabled}
            />
          )}
        </div>
        <Button
          color="link"
          disabled={isSendButtonDisable}
          className="messages-form--button d-flex align-items-center justify-content-center"
          onClick={() => sendMessage()}
        >
          <img src={arrowIcon} alt="" />
        </Button>
      </div>
      <UploadMaterialModal
        uploadMethod={upload}
        open={state.isSelectUploadTypeOpen}
        toggle={() => setState({ isSelectUploadTypeOpen: false })}
        onCancel={() => setState({ isSelectUploadTypeOpen: false })}
        types={[uploadFileTypes.photo, uploadFileTypes.audio]}
        maxFileSize={10}
      />
    </div>
  )
}

export default MessageForm

MessageForm.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func.isRequired,
  chat: PropTypes.shape({
    chatId: PropTypes.string,
    chatType: PropTypes.string,
    abonent: PropTypes.shape({
      idProfile: PropTypes.string,
      nickName: PropTypes.string,
      avatarLink: PropTypes.string,
    }),
  }),
}

import * as React from 'react'
import { HeaderProgress } from '../components/Header/HeaderProgress'
import { ROUTES } from '../constants/routes'
import SetSubscriptionAmount from '../components/Profile/SetSubscriptionAmount'
import { useHistory } from 'react-router-dom'

const SetSubscriptionAmountPage = () => {
  const history = useHistory()
  const handleSave = () => history.push(ROUTES.PROFILE)
  return (
    <>
      <HeaderProgress all={2} progress={2} to={ROUTES.SELECT_CATEGORY} />
      <SetSubscriptionAmount onAfterSave={handleSave} />
    </>
  )
}

export default SetSubscriptionAmountPage

export const ROUTES = {
  ROOT: '/',
  PAGE_404: '/404',
  MAIN: '/main',
  AUTH: '/auth',
  ADD_NAME: '/addName',
  EDIT_NAME: '/editName',
  EMAIL: '/email',
  REGISTER_FINISH: '/registerFinish',
  PR: '/pr',
  PAY_FINISH: '/payFinish',
  SET_SUBSCRIPTION_AMOUNT: '/setSubscriptionAmount',
  SELECT_CATEGORY: '/selectCategory',
  SELECT_PROFILE: '/selectProfile',
  SET_PASSWORD_PAGE: '/setPassword',
  EDIT_PASSWORD_PAGE: '/editPassword',
  SUBSCRIBE: '/subscribe',
  AGREEMENT: '/agreement',
  CONFIDENTIALITY: '/confidentiality',
  OFFER_PAYMENT: '/offerPayment',
  ABOUT_APP: '/aboutApp',
  FAQ: '/faq',
  PROFILE: '/profile',
  PROFILE_SETTINGS: '/profile/settings',
  PROFILE_ABOUT: '/profile/about',
  PROFILE_ADD_POST: '/profile/addPost',
  PROFILE_SUBSCRIPTION_AMOUNT: '/profile/setSubscriptionAmount',
  PROFILE_CATEGORY: '/profile/category',
  PROFILE_SELECT_AVATAR: '/profile/selectAvatar',
  PROFILE_ADD_AVATAR: '/profile/addAvatar',
  PROFILE_ADD_BACKGROUND: '/profile/addBackground',
  PROFILE_COMMENTS: '/profile/comments',
  PROFILE_CHATS: '/profile/chats/:userType/:interlocutorId?',
  PROFILE_SOCIAL_LINKS: '/profile/socialLinks/:name?',
  PROFILE_NOTIFICATIONS: '/profile/notifications',
  PROFILE_INCOME: '/profile/income',
  PROFILE_HISTORY: '/profile/history',
  PROFILE_WITHDRAWAL: '/profile/withdrawal',
  PROFILE_TARGETS: '/profile/targets/:targetId?',
  PROFILE_FOLLOWER: '/profile/follower/:followerNick',
  PROFILE_FOLLOWER_LIST: '/profile/followerList',
  PROFILE_RANDOM_LIST: '/profile/randomList',
  PROFILE_SETTINGS_CHATS: '/profile/settingsChats',
  PROFILE_SETTINGS_PRIVACY: '/profile/settingsPrivacy',
  FOLLOWER: '/follower',
  FOLLOWER_SUBSCRIPTIONS: '/follower/subscriptions',
  FOLLOWER_SUBSCRIBE: '/follower/subscribe',
  FOLLOWER_RATING: '/follower/rating',
  FOLLOWER_RATING_INFO: '/follower/rating/info',
  FOLLOWER_RATING_SUBSCRIPTIONS: '/follower/rating/subscriptions',
  FOLLOWER_CHATS: '/follower/chats',
  FOLLOWER_PAYMENT_INFO: '/follower/paymentInfo',
  FOLLOWER_PAYMENT_HISTORY: '/follower/paymentHistory',
  FOLLOWER_PAYMENT_CARDS: '/follower/paymentCards',
  FOLLOWER_LIKED_POSTS: '/follower/likedPosts',
  FOLLOWER_SAVED_POSTS: '/follower/savedPosts',
  FOLLOWER_NOTIFICATIONS: '/follower/notifications',
  FOLLOWER_ACCOUNT: '/follower/account',
  INSIDERS_NICK: '/insiders/:nick',
  INSIDERS_NICK_COMMENTS: '/insiders/:nick/comments',
  INSIDERS_NICK_DONATE: '/insiders/:nick/donate',
  TEMPLATES_CHANGE_PASSWORD: '/templates/changePassword',
}

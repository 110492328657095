import React from 'react'
import { Header } from '../components/Header/Header'
import { appConfig } from '../constants/appConfig'

function FaqPage() {
  return (
    <>
      <Header title="Частые вопросы" needRight={false} to="back" />
      <div className="content-margin faq">
        <div className="sub-title">Не приходит письмо с подтверждением.</div>
        <ol>
          <li>Воспользуйся формой регистрации Яндекс или Google на странице авторизации.</li>
          <li>Запроси готовый аккаунт через Telegram-бота.</li>
        </ol>

        <div className="sub-title">Как отменить подписку?</div>
        <div>
          Воспользуйся разделом «Управление подпиской» в Личном кабинете. Для отмены подписки
          найдите иконку «Изменить» и выключите слайдер автопродления.
        </div>

        <div className="sub-title">Почему после отмены подписки мне не вернули деньги?</div>
        <div>
          При отмене подписки деньги не возвращаются. Отмена подписки означает отмену повторного
          списания после истечения пробного периода.
        </div>

        <div className="sub-title">Что происходит после отмены подписки?</div>
        <div>Ничего не меняется до конца периода подписки.</div>

        <div className="sub-title">Я недоволен премиум-чатом.</div>
        <div>
          Ответственность за добросоветный и качественный ответ лежит только на инфлюенсере. После
          использования премиум-чата рекомендуем ставить оценку. Это поможет нам блокировать
          премиум-чаты инфлюенсеров с низкой оценкой.
        </div>

        <div className="sub-title">Как инфлюенсер видит донаты?</div>
        <div>
          Донаты отправляются в обычном чате как сообщение, а также отображаются в отдельном окне
          уведомлений инфлюенсера.
        </div>

        <div className="sub-title">Как создать аккаунт инфлюенсера?</div>
        <div>Отправьте заявку, и администрация Insider рассмотрит её.</div>

        <div className="sub-title">Не нашёл ответ на свой вопрос?</div>
        <div>
          Напиши нам на почту{' '}
          <a href={`mailto:${appConfig.mailSupport}`} className="text-color-green">
            {appConfig.mailSupport}
          </a>
          .
        </div>
      </div>
    </>
  )
}

export default FaqPage

import { Modal } from 'reactstrap'
import PropTypes from 'prop-types'
import React from 'react'

export function CancelAddPostModal({ open, toggle, onClick }) {
  return (
    <Modal isOpen={open} toggle={toggle} centered={true} size="sm">
      <div className="pt-4">
        <div className="semiBold-17-24 text-center mb-4">
          Если вы закроете, изменения не будут сохранены!
        </div>
        <div className="mx-3 py-2 modalBottomButtonBlock">
          <div
            className="semiBold-15-24 text-color-error flex-grow-1 pb-1 pt-2"
            onClick={onClick}
            role="button"
          >
            Закрыть
          </div>
          <div
            className="semiBold-15-24 text-color-green flex-grow-1 pb-1 pt-2"
            onClick={toggle}
            role="button"
          >
            Отменить
          </div>
        </div>
      </div>
    </Modal>
  )
}

CancelAddPostModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onClick: PropTypes.func,
}

import React from 'react'
import PropTypes from 'prop-types'
import { LogoSpinner } from '../Layout'
import { Button } from '../Layout/Button/Button'
import { modes as authScreenModes } from '../../screens/Auth'

function Welcome({ onSelect }) {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <LogoSpinner isLoading withLogo isFullName size={40} />
        <div className="auth-welcome">
          <Button onClick={() => onSelect(authScreenModes.register)} className="mb-3 bold-14-20">
            Регистрация
          </Button>
          <Button onClick={() => onSelect(authScreenModes.auth)} className="bold-14-20">
            Авторизация
          </Button>
        </div>
      </div>
    </>
  )
}

Welcome.defaultProps = {
  onSelect: () => {},
}

Welcome.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default Welcome

import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useInfiniteQuery } from 'react-query'
import { FixedSizeList as List } from 'react-window'
import clsx from 'clsx'
import { Search } from '../../Search/Search'
import ChatItem from './ChatItem'
import ChatTab from './ChatTab'
import { getChats } from '../../../api/chat'
import { REGULAR_CHAT_TYPE, VIP_CHAT_TYPE } from '../../../constants'
import { PageLoading } from '../../Layout/PageLoading'
import EmptyChats from './EmptyChats'
import { ChatContext } from '../../../contexts/chatContext'
import { useScroll } from '../../../hooks/useScroll'
import { ROUTES } from '../../../constants/routes'
import { getChatKey, simpleReducer } from '../../../helpers'
import { appConfig } from '../../../constants/appConfig'
import ChatsFilterModal from './ChatsFilterModal'
import { LogoSpinner } from '../../Layout'
import { uniqBy } from 'lodash-es/array'
import { orderBy } from 'lodash-es/collection'
import { getDate } from '../../../helpers/time'
import { isEqual } from 'lodash-es'
import { UserContext } from '../../../contexts/userContext'
import { find, update } from '../../../api/storage'

function Chats() {
  const { currentTypeId, isFollower } = useContext(UserContext)
  const { userType } = useParams()
  const history = useHistory()
  const location = useLocation()

  const initialState = {
    chats: {},
    search: {},
    searchText: {},
    isFilterOpened: {},
    activeFilters: {},
    page: {},
    lastPageTs: {},
    loadedPages: {},
    firstRender: {},
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const chatType = useMemo(
    () => new URLSearchParams(location.search).get('type') || REGULAR_CHAT_TYPE,
    [location.search],
  )
  const activeFilters = useMemo(
    () => state.activeFilters[chatType] || [],
    [state.activeFilters, chatType],
  )
  const search = useMemo(() => state.search[chatType] || '', [state.search, chatType])
  const chatKey = useMemo(
    () => `${currentTypeId}-${userType}-${chatType}-${activeFilters?.join('-')}-${search}`,
    [currentTypeId, activeFilters, chatType, search, userType],
  )
  const chats = useMemo(() => state.chats[chatKey] || [], [state.chats, chatKey])
  const page = useMemo(() => state.page[chatKey] || 0, [state.page, chatKey])
  const lastPageTs = useMemo(() => state.lastPageTs[chatKey], [state.lastPageTs, chatKey])
  const loadedPages = useMemo(() => state.loadedPages[chatKey] || [], [state.loadedPages, chatKey])
  const firstRender = useMemo(
    () => (typeof state.firstRender[chatKey] === 'undefined' ? true : state.firstRender[chatKey]),
    [state.firstRender, chatKey],
  )

  const set = useCallback(
    (key, value) => {
      if (chatKey) {
        if (JSON.stringify(value) === JSON.stringify(state[key][chatKey])) {
          return null
        }
        setState({
          [key]: {
            ...state[key],
            [chatKey]: value,
          },
        })
      }
    },
    [chatKey, state],
  )

  const { lastMessage, lastReadMessageId } = useContext(ChatContext)

  const { isFetching, data, fetchNextPage, refetch } = useInfiniteQuery(
    `loadChats-${chatKey}`,
    (key, pageParam = page) => {
      return getChats({
        userType,
        activeFilters,
        page: pageParam,
        lastPageTs,
        isVip: chatType === VIP_CHAT_TYPE,
        search: state.search[chatType],
      })
    },
    {
      enabled: Boolean(userType && chatKey && !loadedPages.includes(page)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const fetchNextChatsPage = useCallback(() => {
    const nav = data ? data[0]?.nav : {}
    const maxPage = Math.ceil(nav?.total / nav?.perPage)
    if (page < maxPage - 1) {
      const nextPage = page + 1
      fetchNextPage({ pageParam: nextPage })
      set('page', nextPage)
    }
  }, [data, fetchNextPage, page, set])

  useScroll({
    onReachedBottom: () => {
      if (!isFetching) {
        fetchNextChatsPage()
      }
    },
  })

  useEffect(() => {
    if (![REGULAR_CHAT_TYPE, VIP_CHAT_TYPE].includes(chatType)) {
      history.push(ROUTES.PAGE_404)
    }
  }, [chatType, history])

  /**
   * Перезапрашиваем чаты при приходе на страницу, чтоб обновить последние сообщения
   */
  useEffect(() => {
    if (firstRender) {
      set('firstRender', false)
      refetch()
    }
  }, [firstRender, refetch, set])

  useEffect(() => {
    const newChats = (data || [])?.map((page) => page?.data).flat()
    if (!isEqual(chats, newChats)) {
      set('chats', uniqBy(newChats, 'chatId'))
      set('lastPageTs', data ? data[data?.length - 1]?.lastMessageDate || new Date() : new Date())
      set('loadedPages', [...loadedPages, page])
    }
  }, [data, page])

  const currentChats = useMemo(() => {
    const withUnread = orderBy(
      [...chats].filter((chat) => chat.unreadQty > 0),
      [(chat) => getDate(chat.lastMessage?.createDate)],
      ['desc'],
    )
    const withoutUnread = orderBy(
      [...chats].filter((chat) => chat.unreadQty === 0),
      [(chat) => getDate(chat.lastMessage?.createDate)],
      ['desc'],
    )
    const sorted = [...withUnread, ...withoutUnread]
    if (!isFollower) {
      return sorted
    }
    return sorted?.filter((chat) =>
      chat.abonent?.nickName
        ?.toLowerCase()
        ?.includes(state.searchText[chatType]?.toLowerCase() || ''),
    )
  }, [chats, isFollower, state.searchText, chatType])

  /**
   * При получении нового сообщения из кролика, обновляем соотв. чату lastMessage и кол-во непрочитанных
   */
  useEffect(() => {
    if (lastMessage) {
      const chatsCopy = [...chats]
      const chatIndex = chatsCopy.findIndex((chat) => getChatKey(chat) === lastMessage.chatKey)
      if (chatIndex > -1) {
        chatsCopy[chatIndex] = {
          ...chatsCopy[chatIndex],
          lastMessage,
          unreadQty: chatsCopy[chatIndex].unreadQty + 1,
        }
        set('chats', chatsCopy)
      } else {
        fetchNextChatsPage()
      }
    }
  }, [lastMessage])
  /**
   * При прочтении сообщения, обновляем соотв. чату lastMessage.isRead, чтоб отобразить галочки
   */
  useEffect(() => {
    if (lastReadMessageId) {
      const chatsCopy = [...chats]
      const chatIndex = chatsCopy.findIndex(
        (chat) => chat.lastMessage?.externalId === lastReadMessageId,
      )
      if (chatIndex > -1 && !chatsCopy[chatIndex].lastMessage?.isRead) {
        chatsCopy[chatIndex] = {
          ...chatsCopy[chatIndex],
          unreadQty: 0,
          lastMessage: {
            ...chatsCopy[chatIndex].lastMessage,
            isRead: true,
          },
        }
        set('chats', chatsCopy)
      }
    }
  }, [lastReadMessageId, chats, set])

  useEffect(() => {
    setState({ activeFilters: find(appConfig.chats.filterName, {}) })
  }, [])

  if (isFetching && !currentChats.length) {
    return (
      <div className="d-flex flex-column content chat footer-padding">
        <div className="chat-user--header">
          <div className="chat-title-wrapper d-flex justify-content-between align-items-center">
            <div className="chat-title">Чаты</div>
          </div>
        </div>
        <PageLoading hasFooter hasHeader />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column content chat footer-padding">
      <div className="chat-user--header">
        <div className="chat-title-wrapper d-flex justify-content-between align-items-center">
          <div className="chat-title">Чаты</div>
          <div
            className={clsx('chat-filters cursor-pointer', {
              'text-color-green': activeFilters?.length,
            })}
            onClick={() =>
              setState({ isFilterOpened: { ...state.isFilterOpened, [chatType]: true } })
            }
          >
            Фильтры
            <img
              src={`${appConfig.cdnPath.iconsSvg}/filter${
                activeFilters?.length ? '-green' : ''
              }.svg`}
              alt=""
              className="ml-2"
            />
          </div>
        </div>
        <div className="chat-search">
          <Search
            value={state.searchText[chatType] || ''}
            filterMode={isFollower}
            onClear={
              isFollower
                ? null
                : () =>
                    setState({
                      search: { ...state.search, [chatType]: '' },
                      searchText: { ...state.searchText, [chatType]: '' },
                    })
            }
            onSearch={(value) => setState({ search: { ...state.search, [chatType]: value } })}
            placeholder={`Поиск по ${chatType === VIP_CHAT_TYPE ? 'премиум' : 'обычным'}`}
            onChange={(value) =>
              setState({ searchText: { ...state.searchText, [chatType]: value } })
            }
          />
        </div>
        <div className="d-flex flex-row justify-content-between ">
          <ChatTab type={REGULAR_CHAT_TYPE} title="Обычные чаты" />
          <ChatTab type={VIP_CHAT_TYPE} title="Премиум чаты" />
        </div>
      </div>
      <div className="chat-content d-flex flex-column content-padding">
        <>
          {!Object.keys(currentChats).length ? (
            activeFilters.length > 0 || state.searchText[chatType]?.length > 0 ? (
              <div className="medium-15-24 text-center">Нет чатов, соответствующих фильтру</div>
            ) : (
              isFollower && <EmptyChats />
            )
          ) : (
            <List
              height={currentChats.length * 76}
              itemCount={currentChats.length}
              itemSize={76}
              width="100%"
            >
              {({ index, style }) => (
                <div style={style}>
                  <ChatItem chat={currentChats[index]} />
                </div>
              )}
            </List>
          )}
          {isFetching && currentChats?.length > 0 && (
            <div className="my-2">
              <LogoSpinner isLoading size={25} />
            </div>
          )}
        </>
      </div>
      {[REGULAR_CHAT_TYPE, VIP_CHAT_TYPE].map((type) => (
        <ChatsFilterModal
          key={type}
          isOpen={state.isFilterOpened[type]}
          onClose={() => setState({ isFilterOpened: { ...state.isFilterOpened, [type]: false } })}
          activeFilters={state.activeFilters[type] || []}
          onSetActiveFilters={(filters) => {
            update(appConfig.chats.filterName, { ...state.activeFilters, [type]: filters })
            setState({ activeFilters: { ...state.activeFilters, [type]: filters } })
            set('page', 0)
          }}
        />
      ))}
    </div>
  )
}

export default Chats

import * as React from 'react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Button } from '../components/Layout/Button/Button'
import InputPassword from '../components/Layout/InputPassword/InputPassword'
import { emailLogin } from '../api/auth'
import queryString from 'query-string'
import { Modal } from 'reactstrap'
import { authRedirects } from '../helpers'
import { ROUTES } from '../constants/routes'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { changePassword } from '../api/users'
import { Header } from '../components/Header/Header'
import { appConfig } from '../constants/appConfig'
import { UserContext } from '../contexts/userContext'

const { min: MIN_PASS_LENGTH, max: MAX_PASS_LENGTH } = appConfig.passwordLength

const SetPasswordPage = () => {
  const history = useHistory()
  const matchEditPass = useRouteMatch(ROUTES.EDIT_PASSWORD_PAGE)
  const isEdit = useMemo(() => {
    return Boolean(matchEditPass?.isExact)
  }, [matchEditPass])
  const { token } = queryString.parse(window.location.search)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDisableButton, setIsDisableButton] = useState(false)
  const { setUserType, setUser } = useContext(UserContext)

  useEffect(() => {
    if (!isEdit && !token) {
      history.push(ROUTES.PAGE_404)
    }
  }, [history, isEdit, token])

  const sendPass = async () => {
    const passValid =
      password.length >= MIN_PASS_LENGTH &&
      password.length <= MAX_PASS_LENGTH &&
      !/^ *$/.test(password)
    if (!passValid) {
      setPasswordError(
        `Пароль должен содержать от ${MIN_PASS_LENGTH} - ${MAX_PASS_LENGTH} символов`,
      )
      setIsDisableButton(false)
    } else {
      try {
        if (isEdit) {
          await changePassword(password)
          history.goBack()
        } else {
          const authData = await emailLogin(null, password, token)
          authRedirects({ authData, history, setUser, setUserType })
        }
      } catch (e) {
        setIsModalOpen(true)
        setIsDisableButton(false)
      }
    }
  }

  const handleToggleModal = () => {
    if (isEdit) {
      setIsModalOpen(false)
    } else {
      setIsModalOpen(false)
      history.push(ROUTES.AUTH)
    }
  }

  const handleButtonClick = (action) => {
    setIsDisableButton(true)
    action()
  }

  return (
    <>
      <Header
        title={isEdit ? 'Смена пароля' : 'Восстановление пароля'}
        needRight={false}
        to="back"
        needLeft={isEdit}
      />
      <div className="auth pt-4">
        {!isEdit && <h1 className="bold-24-32 mt-4">Новый пароль</h1>}
        <InputPassword
          value={password}
          onChange={(newPass) => {
            setPassword(newPass.target.value)
            setPasswordError('')
          }}
          inputClassName={`medium-14-20 ${passwordError?.length ? 'border-bottom-error' : ''}`}
        />
        <div className="text-color-error medium-12-16 mt-2">&nbsp;{passwordError}</div>
        <Button
          onClick={() => handleButtonClick(sendPass)}
          className="mt-5 semiBold-14-20"
          disable={isDisableButton}
        >
          Сохранить
        </Button>
      </div>
      <Modal isOpen={isModalOpen} centered={true} toggle={handleToggleModal} size="sm">
        <div className="py-4">
          <div className="medium-13-20 text-color-regular text-center mt-1">
            {isEdit
              ? 'Ошибка сохранения данных. Повторите попытку.'
              : 'Мы не смогли подтвердить ваш email по полученной ссылке. Повторите регистрацию.'}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SetPasswordPage

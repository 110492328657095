import queryString from 'query-string'
import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { PostContext, postModes as pageMode } from '../../../contexts/postContext'
import { simpleReducer } from '../../../helpers'
import { ROUTES } from '../../../constants/routes'
import { addPost, finishPost, getPostById } from '../../../api/blog'
import AddPost from './AddPost'
import PostSettings from './PostSettings'
import { appConfig } from '../../../constants/appConfig'
import { PageLoading } from '../../Layout/PageLoading'
import { useLogicalFlow, logicalFlows } from '../../../hooks/useLogicalFlow'
import ErrorModal from '../../Modal/ErrorModal'

function AddPostGeneral({ location }) {
  const history = useHistory()
  const { pageOption, id } = queryString.parse(location.search)
  const initialState = {
    mode: pageMode[pageOption] ? pageMode[pageOption] : pageMode.addPost,
    isOpenLoader: false,
    hasNoTeaser: false,
    focusTeaser: false,
    mainImageId: null,
    postEditCacheData: null,
    mediaIdToDelete: [],
    error: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { getFlowData, setFlowData } = useLogicalFlow()

  useEffect(() => {
    if (id) {
      getPostById(id).then((postData) => {
        if (postData) {
          setPost(postData)
          combineDesc(postData)
        }
      })
    }
  }, [id])

  useEffect(() => {
    getFlowData(
      logicalFlows.postEditData.id,
      (result) => {
        setState({
          postEditCacheData: Object.keys(result?.list || {}).length > 0 ? result.list : {},
        })
      },
      () => {
        setState({ postEditCacheData: {} })
      },
    )
  }, [])

  const emptyPost = {
    id: undefined,
    blogPostId: undefined,
    isFree: false,
    teaser: '',
    text: '',
    allowComments: true,
    images: [],
    videos: [],
    audios: [],
    files: [],
  }

  const [post, setPost] = useReducer(simpleReducer, emptyPost)
  const startPost = async () => {
    const data = await addPost({})
    setPost({
      ...data,
      ...(state.postEditCacheData?.[data.id] && { ...state.postEditCacheData?.[data.id] }),
    })
    combineDesc(data)
  }
  const { isLoading } = useQuery(['editedPost'], () => startPost(), {
    enabled: state.postEditCacheData !== null && !id,
  })
  console.log(state.postEditCacheData, id)
  const [descriptionList, setDescriptionList] = useReducer(simpleReducer, {})

  const updateDescription = (id, desc) => setDescriptionList({ ...descriptionList, [id]: desc })
  const combineDesc = (data) => {
    const { audios, files } = data
    const list = Object.fromEntries(
      [...audios, ...files].map(({ id, description }) => [id, description]),
    )
    setDescriptionList(list)
  }
  const changePostData = (data) => setPost({ ...post, ...data })

  const savePost = () => {
    setState({ isOpenLoader: true, hasNoTeaser: false })
    const { id: blogPostId, text, teaser, allowComments, isFree } = post

    delete state.postEditCacheData?.[blogPostId]
    setFlowData(logicalFlows.postEditData.id, { list: { ...state.postEditCacheData } })

    const mediaModels = Object.entries(descriptionList).map(([mediaContentId, description]) => ({
      mediaContentId,
      description,
    }))
    mutation.mutate({ blogPostId, text, teaser, allowComments, isFree, mediaModels })
  }

  const mutation = useMutation(
    ({ blogPostId, text, teaser, allowComments, isFree, mediaModels }) =>
      finishPost(blogPostId, state.mainImageId, {
        updateTitleModel: {
          title: '',
          text,
        },
        updateTeaserModel: {
          teaser,
          allowComments,
          isFree,
        },
        mediaModels,
        mediaIdToDelete: state.mediaIdToDelete,
        ...(state.mode === pageMode.editPost && { setPublishDate: post.publishDate }),
      }),
    {
      onError: () => {
        setState({ isOpenLoader: false, error: 'Не удалось сохранить пост. Попробуйте позже.' })
      },
    },
  )

  const handleSavePost = () => {
    const { teaser, isFree } = post
    if (!isFree && !teaser) {
      setState({ hasNoTeaser: true })
    } else {
      savePost()
    }
  }

  if (mutation.isSuccess) {
    history.push(`${ROUTES.PROFILE}?tabName=${appConfig.tabCodes.posts}`)
  }

  useEffect(() => {
    if (!state.mainImageId) {
      const media = [...(post?.images || []), ...(post?.videos || [])]
      const main = media.filter(({ isMain }) => isMain).pop() || media.pop()
      if (main) {
        setState({ mainImageId: main.id })
      }
    }
  }, [post?.images, post?.videos, state.mainImageId])

  const renderModalContent = () => {
    return state.hasNoTeaser ? (
      <div className="pt-4">
        <div className="pt-4">
          <div className="semiBold-17-24 px-3 text-center mb-4">
            Не заполнено поле тизер, все равно сохранить?
          </div>
          <div className="d-flex mx-3 py-2 modalBottomButtonBlock">
            <div
              className="semiBold-15-24 text-color-green flex-grow-1 pb-1 pt-2"
              onClick={savePost}
              role="button"
            >
              Сохранить
            </div>
            <div
              className="semiBold-15-24 text-color-regular flex-grow-1 pb-1 pt-2"
              onClick={() => {
                setState({
                  hasNoTeaser: false,
                  focusTeaser: true,
                  mode: pageMode.postSettings,
                })
              }}
              role="button"
            >
              Ввести тизер
            </div>
          </div>
        </div>
      </div>
    ) : (
      <PageLoading hasFooter hasHeader className="mt-5 mb-5" />
    )
  }

  const renderBody = () => {
    const isNewPost = state.mode === pageMode.addPost
    switch (state.mode) {
      case pageMode.addPost:
      case pageMode.editPost:
        return (
          <>
            <AddPost
              onReturnBack={() => setState({ focusTeaser: false, mode: pageMode.postSettings })}
              savePost={handleSavePost}
              changePostData={changePostData}
              post={post}
              mainImageId={state.mainImageId}
              setMainImageId={(id) => setState({ mainImageId: id })}
              descriptionList={descriptionList}
              updateDescription={updateDescription}
              combineAllDesc={combineDesc}
              isLoading={mutation.isLoading || isLoading}
              onDeleteMedia={() => setState({ isOpenLoader: true })}
              postEditCacheData={state.postEditCacheData}
              isNewPost={isNewPost}
            />
            <Modal
              isOpen={state.hasNoTeaser || state.isOpenLoader}
              toggle={() => setState({ hasNoTeaser: false })}
              centered={true}
              size="sm"
              contentClassName={state.isOpenLoader ? 'hide-background' : ''}
            >
              {renderModalContent()}
            </Modal>
            <ErrorModal
              toggle={() => setState({ error: '' })}
              isOpen={Boolean(state.error)}
              message={state.error}
            />
          </>
        )
      case pageMode.postSettings:
        return (
          <PostSettings
            onReturnBack={() => setState({ mode: pageMode[pageOption] || pageMode.addPost })}
            changePostData={changePostData}
            focusTeaser={state.focusTeaser}
            post={post}
            isNewPost={isNewPost}
          />
        )
      default:
        return null
    }
  }

  return (
    <PostContext.Provider
      value={{
        /**
         * TODO: перенести в контекст mainImageId и т.п., чтоб не тянуть через все компоненты
         */
        mode: state.mode,
        mediaIdToDelete: state.mediaIdToDelete,
        deleteMedia: (id) => setState({ mediaIdToDelete: [...state.mediaIdToDelete, id] }),
        cancelDeleteMedia: (id) =>
          setState({ mediaIdToDelete: state.mediaIdToDelete.filter((value) => value !== id) }),
      }}
    >
      {renderBody()}
    </PostContext.Provider>
  )
}

export default AddPostGeneral

AddPostGeneral.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getAvatars, getBackground, getInfluencerFullInfo } from '../../../api/users'
import { Link, useHistory } from 'react-router-dom'
import { Profile } from '../Profile'
import { Footer } from '../../Footer/Footer'
import { ROUTES } from '../../../constants/routes'
import { appConfig } from '../../../constants/appConfig'
import UserAvatar from '../UserAvatar'
import { useQuery } from 'react-query'
import { PageLoading } from '../../Layout/PageLoading'
import { UserContext } from '../../../contexts/userContext'

function EmptyProfile({ onAddDescription }) {
  const { influencerId, followerId, setUserType } = useContext(UserContext)
  const history = useHistory()
  const [followerAvatar, setFollowerAvatar] = useState(null)
  const [influencerAvatar, setInfluencerAvatar] = useState(null)

  const [background, setBackground] = useState(null)
  const [avatars, setAvatars] = useState([])

  useEffect(() => {
    if (!influencerId) {
      history.push(ROUTES.AUTH)
    }
  }, [history, influencerId])

  const loadAvatars = async () => {
    try {
      const { followerAvatarLink, influencerAvatarLink } = await getAvatars()
      try {
        const backgroundLink = await getBackground()
        setBackground(backgroundLink)
      } catch (e) {
        console.log(e)
      }
      if (followerAvatarLink) {
        setFollowerAvatar('https://' + followerAvatarLink)
      }
      setAvatars(influencerAvatarLink)
      const [{ influencerAvatarLink: defaultLink }] = Object.values(influencerAvatarLink).filter(
        ({ isDefault }) => isDefault,
      )
      if (defaultLink) {
        setInfluencerAvatar('https://' + defaultLink)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadAvatars()
  }, [])

  const { data, isLoading } = useQuery(
    ['influencer', influencerId],
    () => getInfluencerFullInfo(influencerId),
    {},
  )
  return (
    <>
      <header className="mb-3 justify-content-start">
        <div className="flex-grow-1 bold-24-32 text-left">Профиль</div>
        <div className="pr-2 d-flex align-items-center">
          <div className={clsx({ selected: !!followerId })}>
            <UserAvatar src={influencerAvatar} size={40} className="avatar" />
          </div>
          {!!followerId && (
            <Link
              style={{ width: '46px', height: '46px', padding: '3px' }}
              to={ROUTES.MAIN}
              onClick={() => {
                setUserType(appConfig.userTypes.follower)
              }}
            >
              <UserAvatar src={followerAvatar} size={40} className="avatar" />
            </Link>
          )}
        </div>
      </header>
      {!isLoading && (
        <>
          <div className="content-padding mb-2">
            <hr className="w-100" />
          </div>
          <Profile
            profile={data}
            onAddDescription={onAddDescription}
            influencerAvatar={influencerAvatar}
            avatars={avatars}
            background={background}
          />
        </>
      )}
      {isLoading && (
        <div className="content footer-padding">
          <PageLoading hasFooter hasHeader />{' '}
        </div>
      )}
      <Footer />
    </>
  )
}

EmptyProfile.defaultProps = {
  onAddDescription: () => {},
}

EmptyProfile.propTypes = {
  onAddDescription: PropTypes.func,
}

export default EmptyProfile

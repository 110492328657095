import { api } from './api'
import { appConfig } from '../constants/appConfig'

export const getChatsToken = () => {
  return api.get({
    path: `/insider/chats/token`,
  })
}

export const getChats = ({
  userType,
  activeFilters = [],
  interlocutorId,
  page = 0,
  lastPageTs,
  perPage = 50,
  isVip = null,
  search = null,
}) => {
  if (userType === appConfig.userTypes.follower.toLowerCase()) {
    return getFollowerChats({
      influencerId: interlocutorId,
      activeFilters,
      page,
      lastPageTs,
      perPage,
      isVip,
      search,
    })
  }
  if (userType === appConfig.userTypes.influencer.toLowerCase()) {
    return getInfluencerChats({
      followerId: interlocutorId,
      activeFilters,
      page,
      lastPageTs,
      perPage,
      isVip,
      search,
    })
  }
  throw new Error(
    `Неверный тип пользователя, ожидается: ${Object.keys(appConfig.userTypes).join(' | ')}`,
  )
}

const getActiveFiltersParam = (activeFilters) => {
  const filters = activeFilters.reduce(
    (result, filter) => ({
      ...result,
      [filter]: true,
    }),
    {},
  )
  if (filters.isNotRead && filters.isRead) {
    filters.isRead = null
  } else if (filters.isNotRead) {
    filters.isRead = false
  }
  delete filters?.isNotRead
  return filters
}

const getFollowerChats = ({
  influencerId,
  activeFilters,
  page,
  lastPageTs,
  perPage,
  isVip,
  search,
}) => {
  return api.post({
    path: `/insider/chats/influencer`,
    data: {
      ...getActiveFiltersParam(activeFilters),
      influencerId,
      page,
      lastPageTs,
      perPage,
      isVip,
      search,
    },
  })
}

const getInfluencerChats = ({
  followerId,
  activeFilters,
  page,
  lastPageTs,
  perPage,
  isVip,
  search,
}) => {
  return api.post({
    path: `/insider/chats/follower`,
    data: {
      ...getActiveFiltersParam(activeFilters),
      followerId,
      page,
      lastPageTs,
      perPage,
      isVip,
      search,
    },
  })
}

export const getChatMessages = ({
  chatId,
  page = 0,
  countPerPage = appConfig.chats.countPerPage,
}) => {
  return api.get({
    path: `/insider/chats/${chatId}/messages?page=${page}&countPerPage=${countPerPage}`,
  })
}

export const setChatPrice = (cost) => {
  return api.put({
    path: `/api/chats/current/price`,
    data: { newCost: cost },
    isDirect: true,
  })
}

export const getUnreadCount = () => {
  return api.get({
    path: `/api/chats/unread-count`,
  })
}

import React, { useContext, useEffect, useReducer } from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { Button } from '../../components/Layout/Button/Button'
import { clearProtocolHost, simpleReducer } from '../../helpers'
import { getCurrentUser, setAuthorInfo } from '../../api/users'
import { appConfig } from '../../constants/appConfig'
import { Input } from '../../components/Layout/Input/Input'
import { useHistory, useParams } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'

const { socialHosts } = appConfig

function ProfileSocialLinks() {
  const { influencerId } = useContext(UserContext)
  const history = useHistory()
  const { name: currentName } = useParams()

  const initialState = {
    user: null,
    instagram: null,
    youtube: null,
    tiktok: null,
    twitch: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const loadUserData = async () => {
    if (influencerId) {
      const user = await getCurrentUser()
      setState({
        user,
        instagram: user?.influencer?.instagramLink,
        youtube: user?.influencer?.youTubeLink,
        tiktok: user?.influencer?.tikTokLink,
        twitch: user?.influencer?.twitchLink,
      })
    }
  }

  useEffect(() => {
    loadUserData()
  }, [influencerId])

  const renderItem = (item) => {
    const prefix = socialHosts[item.key] || ''
    return (
      <div className="profile-about-item" key={item.key}>
        {prefix && <div className="label">{prefix.replace('https://', '')}</div>}
        <div className="edit">
          <Input
            autofocus={currentName === item.key}
            placeholder={item.placeholder}
            onChange={(value) => handleChange(item, value)}
            value={clearProtocolHost(item.value)}
          />
        </div>
      </div>
    )
  }

  const handleChange = (item, value) => {
    setState({
      [item.key]: value,
    })
  }

  const handleSave = async () => {
    if (state.user.influencer.id) {
      await setAuthorInfo(state.user.influencer.id, state)
      history.push(ROUTES.PROFILE_ABOUT)
    }
  }

  return (
    <>
      <Header title="Социальные сети" needRight={false} to={ROUTES.PROFILE_ABOUT} />
      <div className="content content-padding profile-about">
        {[
          {
            key: 'instagram',
            name: 'Instagram',
            type: 'link',
            value: state.instagram,
            placeholder: 'Добавить ссылку на профиль',
          },
          {
            key: 'youtube',
            name: 'YouTube',
            type: 'link',
            value: state.youtube,
            placeholder: 'Добавить ссылку на профиль',
          },
          {
            key: 'tiktok',
            name: 'TikTok',
            type: 'link',
            value: state.tiktok,
            placeholder: 'Добавить ссылку на профиль',
          },
          {
            key: 'twitch',
            name: 'Twitch',
            type: 'link',
            value: state.twitch,
            placeholder: 'Добавить ссылку на профиль',
          },
        ].map(renderItem)}
      </div>
      <div className="content-padding pb-4">
        <Button
          className="semiBold-15-24"
          onClick={handleSave}
          color="regular"
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          Сохранить
        </Button>
      </div>
    </>
  )
}

export default ProfileSocialLinks

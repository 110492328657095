import React, { useCallback, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Post } from '../Tabs/Post'
import { ROUTES } from '../../../constants/routes'
import { appConfig } from '../../../constants/appConfig'
import { useHistory } from 'react-router-dom'
import { getPrivateParamsByUser } from '../../../api/users'
import InfiniteScroll from 'react-infinite-scroll-component'
import { LogoSpinner } from '../../Layout'
import PostActionsModal from '../../Modal/PostActionsModal/PostActionsModal'
import { simpleReducer } from '../../../helpers'
import { UserContext } from '../../../contexts/userContext'
/* eslint-disable react/prop-types */

export function Posts({
  posts,
  avatar,
  author,
  influencerId,
  loadNextPosts,
  hasMorePosts,
  publishedPostsCount,
}) {
  const { isInfluencer, user } = useContext(UserContext)
  const history = useHistory()
  const imageParams = { ...getPrivateParamsByUser(influencerId, user), w: 480 }
  const postsData = (posts || []).flat()
  const initialState = {
    isOpenModal: false,
    modalPostData: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const onPressPostAction = (post) => setState({ isOpenModal: true, modalPostData: post })

  const getIfExistsPostAction = useCallback(
    (post) => {
      return isInfluencer ? () => onPressPostAction(post) : false
    },
    [isInfluencer],
  )

  return (
    <>
      <div
        className="d-flex flex-grow-1 content-padding align-items-center mb-3"
        onClick={() => {
          history.push(ROUTES.PROFILE_ADD_POST)
        }}
        role="button"
      >
        <img
          src={`${appConfig.cdnPath.iconsSvg}/plus-around-green.svg`}
          width="44px"
          height="44px"
          alt=""
        />
        <div className="text-color-green semiBold-15-24 ml-2">Добавить</div>
      </div>
      <div className="content-padding mb-5">
        <hr />
      </div>
      {publishedPostsCount === 0 && (
        <div className="text-center text-color-regular medium-15-24">0 записей</div>
      )}
      <InfiniteScroll
        dataLength={postsData.length} //This is important field to render the next data
        next={() => {
          loadNextPosts()
        }}
        scrollThreshold={0.4}
        scrollableTarget="scroller"
        hasMore={hasMorePosts}
        loader={<LogoSpinner isLoading />}
        endMessage={<p style={{ textAlign: 'center' }} />}
      >
        {postsData.map((post) => (
          <Post
            post={post}
            key={`post_${post.id}`}
            isSubscribed={true}
            avatar={avatar}
            author={author}
            influencerId={influencerId}
            imageParams={imageParams}
            onPressActions={getIfExistsPostAction(post)}
          />
        ))}
        <div className="hackyOffPageElement" />
      </InfiniteScroll>
      <PostActionsModal
        isOpen={state.isOpenModal}
        onClose={() => setState({ isOpenModal: false })}
        post={state.modalPostData}
      />
    </>
  )
}

Post.propTypes = {
  posts: PropTypes.array,
  author: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  influencerId: PropTypes.string.isRequired,
  influencerNick: PropTypes.string,
  isSubscribed: PropTypes.bool.isRequired,
  loadNextPosts: PropTypes.func,
  hasMorePosts: PropTypes.bool,
  publishedPostsCount: PropTypes.number,
}

import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { Header } from '../Header/Header'
import clsx from 'clsx'
import { Button } from '../Layout/Button/Button'
import CardList from '../Payment/CardList'
import {
  subscribe,
  getSubscriptions,
  getSubscriptionsFollower,
  changeSubscriptionType,
} from '../../api/subscribe'
import { update } from '../../api/storage'
import { appConfig } from '../../constants/appConfig'
import PropTypes from 'prop-types'
import { ROUTES } from '../../constants/routes'
import { isWrongAnyGetParam, makeUrl, simpleReducer } from '../../helpers'
import { isDateExpired } from '../../helpers/time'
import { rub } from '../../constants/typography'
import Checkbox from '../Layout/Checkbox/Checkbox'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import CheckboxAgreement from '../Layout/Checkbox/CheckboxAgreement'
import { logicalFlows, useLogicalFlow } from '../../hooks/useLogicalFlow'
import InfoModal from '../Modal/InfoModal'
import { errorsJsx } from '../../constants/errorsJsx'
import { apiCodes } from '../../constants/apiCodes'
import { UserContext } from '../../contexts/userContext'
import { getAuthIp, refresh } from '../../api/auth'

const { types: periodTypes, periodMonthValue } = appConfig.subscribe
export const modes = {
  edit: 'edit',
  create: 'create',
}

const periodTexts = {
  [periodTypes.oneMonthKey]: 'месяц',
  [periodTypes.threeMonthKey]: 'три месяца',
  [periodTypes.twelveMonthsKey]: 'двенадцать месяцев',
}

const Subscribe = ({ influencerId, mode }) => {
  const history = useHistory()
  const { user, setUser } = useContext(UserContext)
  const initialState = {
    subscriptionsInfluencer: [],
    currentSubscription: null,
    currentPeriod: periodTypes.oneMonthKey,
    currentCard: null,
    isRecurrent: false,
    isAgree: mode === modes.edit,
    isModalOpen: false,
    isModalBackendOpen: false,
    modalContent: '',
    isDisableButtonForce: false,
    existPromo: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { setFlowData } = useLogicalFlow()
  const followerSubscribe = logicalFlows.subscribe

  const currentDate = useMemo(() => {
    // testing data
    // const time = moment.utc('16.01.2022T00:00:00Z', 'DD.MM.YYYY').hours(23).minutes(59).seconds(59)
    // const localTime = time.clone().local()
    // const time2 = moment()
    // console.log('time', time.format('DD.MM.YYYY HH:mm:ss'))
    // console.log('time', localTime.format('DD.MM.YYYY HH:mm:ss'))
    // console.log('time2', time2.format('DD.MM.YYYY HH:mm:ss'))

    const now = moment()
    const countMonths = periodMonthValue[state.currentPeriod]
    if (mode === modes.create && state.existPromo) {
      const currentSubscription = user?.follower?.subscriptions?.filter(
        (item) => item.influencerId === influencerId,
      )[0]
      if (currentSubscription?.expirationDate) {
        const expDate = moment.utc(currentSubscription.expirationDate, 'DD.MM.YYYY')
        const localExpDate = expDate.clone().local()
        if (localExpDate.isAfter(now)) {
          return expDate.add(1, 'days').add(countMonths, 'months').format('DD.MM.YYYY')
        }
      }
    }
    if (mode === modes.edit) {
      if (state.currentSubscription?.expirationDate) {
        const expDate = moment.utc(state.currentSubscription.expirationDate, 'DD.MM.YYYY')
        const localExpDate = expDate.clone().local()
        if (localExpDate.isAfter(now)) {
          return expDate.format('DD.MM.YYYY')
        }
      }
    }
    return now.utc().add(1, 'days').add(countMonths, 'months').format('DD.MM.YYYY')
  }, [mode, state.currentPeriod, state.currentSubscription?.expirationDate, state.existPromo, user])

  const isDisableButton = useMemo(() => {
    if (mode === modes.create) {
      return !state.isAgree || state.isDisableButtonForce
    }
    if (mode === modes.edit) {
      return !state.isAgree || state.isDisableButtonForce
    }
    return false
  }, [state.isAgree, mode, state.isDisableButtonForce])

  const loadFollowerSubscriptions = async () => {
    const { subscriptions } = await getSubscriptionsFollower()
    const currentSubscription = subscriptions?.filter(
      (item) => item.influencerId === influencerId,
    )[0]
    if (currentSubscription) {
      setState({
        currentSubscription,
        currentPeriod: currentSubscription.subscriptionType,
        isRecurrent: currentSubscription.isRecurrent,
      })
    }
  }

  useEffect(() => {
    if (isWrongAnyGetParam({ influencerId })) {
      history.push(ROUTES.PAGE_404)
      return
    }

    const { follower, influencer, email } = user
    if (follower && !email) {
      setFlowData(followerSubscribe.id, { isActive: true, subscribe: influencerId })
      history.push(ROUTES.EMAIL)
      return
    } else {
      if (!follower && !influencer) {
        setFlowData(followerSubscribe.id, { isActive: true, subscribe: influencerId })
        history.push(ROUTES.AUTH)
        return
      }
      if (!follower?.nickName && !influencer?.nickName) {
        setFlowData(followerSubscribe.id, { isActive: true, subscribe: influencerId })
        history.push(ROUTES.ADD_NAME)
        return
      }
    }
    if (influencerId) {
      getSubscriptions({ userId: influencerId })
        .then((list) => {
          setState({
            subscriptionsInfluencer: Object.values(list),
            existPromo: list.some((item) => item.action),
          })
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            history.push(ROUTES.PAGE_404)
          }
        })
    }

    if (mode === modes.edit) {
      try {
        loadFollowerSubscriptions()
      } catch (e) {
        if (e?.response?.status === 400) {
          history.push(ROUTES.PAGE_404)
        }
      }
    }
  }, [influencerId])

  const checkBackend = () => {
    getAuthIp()
      .then(() => {
        handlePay()
      })
      .catch(() => {
        setState({ isModalBackendOpen: true })
      })
  }

  const handlePay = async () => {
    try {
      const result = await subscribe(
        influencerId,
        state.currentPeriod,
        state.currentCard?.id,
        state.isAgree,
      )
      if (result?.redirectUrl && !state.currentCard?.id) {
        const period = state.subscriptionsInfluencer?.find(
          (item) => item.key === state.currentPeriod,
        )
        window.ym(81892699, 'reachGoal', 'subscription_start', {
          order_price: period?.cost || 0,
          currency: 'RUB',
        })
        window.location = result?.redirectUrl
      }

      if (result?.paymentStatus === appConfig.payment.statuses.charged) {
        let { user } = await refresh()
        setUser(user)
        update(appConfig.storage.keys.expirationDate, result?.expirationDate)
      }
      if (result?.nickName) {
        update(appConfig.storage.keys.status, result?.paymentStatus)
        history.push(
          makeUrl({ route: ROUTES.INSIDERS_NICK, routeParams: { nick: result?.nickName } }),
        )
      } else {
        history.push(ROUTES.MAIN)
      }
    } catch (e) {
      let message = errorsJsx.payment.fail
      if (e.response?.status === 400 && e.response?.data === apiCodes.paymentFailAgreement) {
        message = errorsJsx.payment.failAgreement
      }
      setState({
        isDisableButtonForce: false,
        isModalOpen: true,
        modalContent: message,
      })
    }
  }

  const handleButtonClick = (action) => {
    setState({ isDisableButtonForce: true })
    action()
  }

  const handleEditSubscription = async () => {
    if (state.currentSubscription?.expirationDate) {
      const canPay = isDateExpired(state.currentSubscription.expirationDate) && state.isRecurrent
      if (state.currentSubscription?.subscriptionId) {
        try {
          await changeSubscriptionType(state.currentSubscription.subscriptionId, {
            bankCardId: state.currentCard?.id,
            newSubscriptionType: state.currentPeriod,
            isRecurrent: state.isRecurrent,
          })
          if (!canPay) {
            history.push(ROUTES.FOLLOWER_SUBSCRIPTIONS)
          }
        } catch (e) {
          console.log(e)
          setState({ isDisableButtonForce: false })
        }
      }
      if (canPay) {
        checkBackend()
      }
    } else {
      setState({ isDisableButtonForce: false })
    }
  }

  const handleSetCurrentCard = (card) => setState({ currentCard: card })

  const isNeedNewCard = useMemo(() => {
    if (mode === modes.edit) {
      if (state.currentSubscription && !state.currentSubscription?.pan) {
        return true
      }
      if (state.currentSubscription?.expirationDate) {
        if (!isDateExpired(state.currentSubscription.expirationDate)) {
          return false
        }
      }
    }
    return true
  }, [state.currentSubscription])

  const isPeriodDisable = (period) => {
    if (mode === modes.create) {
      const existSubscribe = user.follower.subscriptions.filter(
        (item) => item.influencerId === influencerId,
      )[0]
      if (existSubscribe && !isDateExpired(existSubscribe.expirationDate) && !period.action) {
        return true
      }
    }
    return false
  }

  const getPeriodUserCost = (key) =>
    state.subscriptionsInfluencer?.filter((item) => item.key === key)?.[0]?.cost || false

  const getPeriodOriginCost = (key) => {
    switch (key) {
      case periodTypes.oneMonthKey:
        return getPeriodUserCost(key)
      case periodTypes.threeMonthKey:
        return getPeriodUserCost(periodTypes.oneMonthKey) * 3
      case periodTypes.twelveMonthsKey:
        return getPeriodUserCost(periodTypes.threeMonthKey) * 4
      default:
        return false
    }
  }

  const hasDiscount = (period) => {
    if (period.key === periodTypes.oneMonthKey) {
      return false
    } else if (period.key === periodTypes.threeMonthKey) {
      const oneMonthCost = getPeriodUserCost(periodTypes.oneMonthKey)
      if (oneMonthCost !== false && oneMonthCost >= 0) {
        if (oneMonthCost * 3 > period.cost) {
          return true
        }
      }
      return false
    } else if (period.key === periodTypes.twelveMonthsKey) {
      const threeMonthCost = getPeriodUserCost(periodTypes.threeMonthKey)
      if (threeMonthCost !== false && threeMonthCost >= 0) {
        if (threeMonthCost * 4 > period.cost) {
          return true
        }
      }
      return false
    }
    return false
  }

  const renderPeriod = (period, index) => {
    return (
      <div
        key={index}
        onClick={isPeriodDisable(period) ? () => {} : () => setState({ currentPeriod: period.key })}
        className={clsx('subscribe-offers-item rounded ', {
          ' subscribe-offers-active': state.currentPeriod === period.key,
          ' subscribe-offers-disable ': isPeriodDisable(period),
        })}
        role="button"
        onKeyDown={() => {}}
        tabIndex={index}
      >
        <div>{period.desc} /</div>
        {hasDiscount(period) && (
          <div className="subscribe-offers-item-discount">
            {getPeriodOriginCost(period.key)} {rub}
          </div>
        )}
        <div>
          {period.cost} {rub}
        </div>
      </div>
    )
  }

  const getCheckboxAgree = () => {
    return (
      <CheckboxAgreement
        className="mb-3"
        onChange={(checked) => setState({ isAgree: checked })}
        checked={state.isAgree}
      />
    )
  }

  return (
    <div className="vh-100 d-flex flex-column">
      <Header
        title={mode === modes.edit ? 'Управление подписками' : 'Оформление подписки'}
        needRight={false}
        to={mode === modes.edit ? 'back' : makeUrl(ROUTES.MAIN)}
        onForwardAction={
          mode === modes.edit ? () => handleButtonClick(handleEditSubscription) : false
        }
        disableForwardButton={isDisableButton}
      />
      <div className="subscribe mt-1">
        <span className="bold-17-24">На какой период?</span>
        <div className="subscribe-offers mt-3">
          {state.subscriptionsInfluencer?.map((period, index) => renderPeriod(period, index))}
        </div>
        <div className="mt-2 medium-13-20 text-color-regular">
          Списание средств один раз в {`${periodTexts[state.currentPeriod]}`}, следующее списание{' '}
          <b className="text-color-sub-title">{currentDate}</b>.
        </div>
        <div className="mt-2 medium-13-20 text-color-regular">
          Конвертация осуществляется по курсу банка-эмитента на момент списания
        </div>
        <hr width="100%" size="2" className="mt-3" />
        {mode === modes.edit && (
          <Checkbox
            label="Автопродление подписки"
            onChange={(checked) => setState({ isRecurrent: checked })}
            checked={state.isRecurrent}
          />
        )}
      </div>
      <CardList
        topElement={getCheckboxAgree()}
        existsNewCard={isNeedNewCard}
        onSetCurrentCard={handleSetCurrentCard}
        defaultCardId={state.currentSubscription?.cardId}
        selectedNoneCard={state.currentSubscription && !state.currentSubscription?.pan}
      />
      <div className="mb-5 button">
        {mode === modes.edit ? (
          <Button
            onClick={() => handleButtonClick(handleEditSubscription)}
            disable={isDisableButton}
          >
            Готово
          </Button>
        ) : (
          <Button onClick={() => handleButtonClick(checkBackend)} disable={isDisableButton}>
            Оплатить
          </Button>
        )}
      </div>

      <InfoModal
        isOpen={state.isModalOpen}
        onClose={() => setState({ isModalOpen: false })}
        message={state.modalContent}
      />
      <InfoModal
        isOpen={state.isModalBackendOpen}
        onClose={() => setState({ isModalBackendOpen: false })}
        message={errorsJsx.backendPingError}
      />
    </div>
  )
}

Subscribe.defaultProps = {
  mode: modes.edit,
}

Subscribe.propTypes = {
  influencerId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(Object.values(modes)),
}

export default Subscribe

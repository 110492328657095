import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ChatContext } from '../contexts/chatContext'
import { useQuery } from 'react-query'
import { getChatsToken } from '../api/chat'
import { UserContext } from '../contexts/userContext'
import { useChat } from '../hooks/useChat'

function ChatProvider({ children }) {
  const [chatToken, setChatToken] = useState(null)
  const { user, userType, influencerId, followerId, isFollower, isInfluencer } =
    useContext(UserContext)
  const { data, isError } = useQuery(
    ['chatToken', user?.id, user?.follower?.subscriptions?.length || 0],
    () => getChatsToken(),
    {
      enabled: !!user?.id,
    },
  )
  const {
    isConnecting,
    isConnected,
    send,
    disconnect,
    markAsRead,
    markAllRead,
    newMessages,
    unreadMessagesCount,
    unreadMessagesCountVip,
    lastMessage,
    lastReadMessageId,
    reduceUnreadCount,
    updateIsRead,
    refreshHistory,
    requestNewSignature,
    md5,
    e,
    setRefreshed,
  } = useChat({
    ...(isInfluencer && { influencerId }),
    ...(isFollower && { followerId }),
    token: chatToken,
    userType: userType?.toLowerCase(),
  })

  useEffect(() => {
    window.addEventListener('beforeunload', () => disconnect())
    return () => disconnect()
  }, [disconnect])

  useEffect(() => {
    if (isError) {
      console.error('Ошибка получения токена!')
      setChatToken(null)
    }
    if (data?.token) {
      setChatToken(data.token)
    }
  }, [isError, data])

  return (
    <ChatContext.Provider
      value={{
        isConnecting,
        isConnected,
        send,
        disconnect,
        markAsRead,
        markAllRead,
        newMessages,
        unreadMessagesCount,
        unreadMessagesCountVip,
        lastMessage,
        lastReadMessageId,
        reduceUnreadCount,
        updateIsRead,
        refreshHistory,
        setRefreshed,
        requestNewSignature,
        md5,
        e,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export default ChatProvider

ChatProvider.propTypes = {
  children: PropTypes.node,
}

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { appConfig } from '../../constants/appConfig'
import { getPlaylistByVideoId } from '../../api/media'
import { useWindowSize } from '../../hooks/useWindowSize'
//import { LogoSpinner } from '../Layout'
import { isIOS } from '../../helpers'
import { formatSeconds } from '../../helpers/time'
// import WatermarkSvgPath from './WatermarkSvgPath'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { UserContext } from '../../contexts/userContext'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { MemoizedVideo } from './VideoPlayer/Video'
import { ChatContext } from '../../contexts/chatContext'

const SCREENSHOT_MODE = 0
const PRELOAD_MODE = 1
const VIDEO_MODE = 2

export const playerModes = {
  SCREENSHOT_MODE,
  PRELOAD_MODE,
  VIDEO_MODE,
}
/*
const numbers = {
  a: 10,
  b: 11,
  c: 12,
  d: 13,
  e: 14,
  f: 15,
}
*/

const speedList = [
  {
    speed: 0.5,
    title: '0.5x',
  },
  {
    speed: 1,
    title: 'Обычная',
  },
  {
    speed: 1.5,
    title: '1.5x',
  },
  {
    speed: 2,
    title: '2x',
  },
]
const TYPE_PAUSE = 'pause'
const TYPE_PLAY = 'play'

export function VideoPlayer({
  id,
  screenshotUrl,
  autoplay = false,
  isActiveSlide,
  securityParams,
  style,
  wrapperClass = '',
  influencerId,
  // size = {},
}) {
  const { user } = useContext(UserContext)
  const { requestNewSignature } = useContext(ChatContext)
  //Вычисляем точки, по которым будем двигать вотермарку - приводим socialId к 8-ричной системе счисления
  const socialId = user?.follower?.socialId
  /*const points = socialId
    ? socialId
        .toString(7)
        .split('')
        .map((symbol) => numbers[symbol] || parseInt(symbol))
    : []*/
  const isAutoPlay = autoplay === id
  const videoContainerRef = useRef(null)
  const videoRef = useRef(null)
  const progressBar = useRef(null)
  const iOS = isIOS()
  const [buttonType, setButtonType] = useState(TYPE_PLAY)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [percentage, setPercentage] = useState(0)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isSpeedOpen, setIsSpeedOpen] = useState(false)
  const [speed, setSpeed] = useState(1)
  const currentTime = useRef('00:00:00')
  const duration = useRef('00:00:00')
  const position = useRef(null)
  const pureDuration = useRef(0)
  const [src, setSrc] = useState('')
  // const [watermarkPoints, setWatermarkPoints] = useState([])
  // const [videoParams, setVideoParams] = useState({})
  // const [watermarkSvgPath, setWatermarkSvgPath] = useState(null)
  const timer = useRef(null)
  const { height, width } = useWindowSize()
  const vertical = height > width
  const playPauseVideo = async () => {
    if (!src && id) {
      await loadVideoPlaylist(id)
    }
    if (buttonType === TYPE_PLAY) {
      setButtonType(TYPE_PAUSE)
      videoRef.current.play()
    } else {
      setButtonType(TYPE_PLAY)
      videoRef.current.pause()
    }
  }

  const touchstart = (event) => {
    position.current = {
      startScreenY: event.changedTouches[0].screenY,
    }
  }

  const touchend = (event) => {
    const { screenY: stopScreenY } = event.changedTouches[0]
    if (Math.abs(stopScreenY - position.current.startScreenY) > 50 && isFullScreen) {
      toggleFullScreen()
      if (buttonType === TYPE_PAUSE) {
        playPauseVideo()
      }
    }
  }

  useEffect(() => {
    if (isFullScreen) {
      window.addEventListener('touchstart', touchstart, false)
      window.addEventListener('touchend', touchend, false)
    } else {
      window.removeEventListener('touchstart', touchstart, false)
      window.removeEventListener('touchend', touchend, false)
    }
  }, [isFullScreen])

  const loadVideoPlaylist = async (id) => {
    requestNewSignature({ influencerId })
    const result = await getPlaylistByVideoId(id)
    setSrc(`//${result.url}`)
  }

  useEffect(() => {
    if (isActiveSlide && isAutoPlay && id) {
      loadVideoPlaylist(id)
    }
  }, [id])

  useEffect(() => {
    if (!isActiveSlide) {
      if (videoRef.current) {
        setButtonType(TYPE_PLAY)
        videoRef.current.pause()
      }
    }
  }, [isActiveSlide])

  useEffect(() => {
    return () => {
      clearInterval(timer.current)
      timer.current = undefined
    }
  }, [])
  const videoScreen = useFullScreenHandle()
  const toggleFullScreen = () => {
    if (iOS) {
      setIsFullScreen(!isFullScreen)
      return
    }
    if (!isFullScreen) {
      videoScreen.enter()
    } else {
      videoScreen.exit()
    }
    setIsFullScreen(!isFullScreen)
  }
  /*const stepDuration = useMemo(
    () => (pureDuration.current > 26 ? 2 : pureDuration.current / 13),
    [pureDuration.current],
  )*/

  const timeouts = useRef([])

  /*useEffect(() => {
    if (watermarkPoints?.length > 0 && stepDuration > 0 && buttonType === TYPE_PAUSE) {
      let targetPointIndex = 0
      let oldPointIndex = 0
      let stepsCount = 0
      let params = []
      while ((stepsCount + 1) * stepDuration < pureDuration.current) {
        points.forEach((pointIndex) => {
          targetPointIndex = targetPointIndex + pointIndex + 1
          targetPointIndex %= 8
          stepsCount++
          params.push([stepsCount, targetPointIndex, oldPointIndex])
          oldPointIndex = targetPointIndex
        })
      }
      // для тестирования можно задать точки вручную
      // params = [
      //   [1, 5, 0],
      //   [2, 2, 5],
      //   [3, 0, 2],
      // ]
      setWatermarkSvgPath(params)
    }
  }, [watermarkPoints, stepDuration, pureDuration.current, buttonType])*/

  useEffect(() => {
    if (videoRef.current) {
      timeouts.current.map((timeout) => {
        clearTimeout(timeout)
      })
      // calcPoints()
    }
  }, [isFullScreen, vertical])

  useEffect(() => {
    if (videoRef.current && isFullScreen && buttonType === TYPE_PAUSE && iOS) {
      videoRef.current.play()
    }
  }, [isFullScreen, buttonType])

  /*function calcPoints() {
    let { clientWidth, clientHeight, offsetTop, offsetLeft, videoWidth, videoHeight } =
      videoRef.current
    if (isFullScreen) {
      videoWidth = videoWidth || size.axisXSize
      videoHeight = videoHeight || size.axisYSize
      if (videoWidth > videoHeight) {
        const height = (clientWidth * videoHeight) / videoWidth
        offsetTop = (clientHeight - height) / 2
        clientHeight = height
      } else {
        const width = (clientHeight * videoWidth) / videoHeight
        offsetLeft = (clientWidth - width) / 2
        clientWidth = width
      }
    }

    setVideoParams({
      width: clientWidth,
      height: clientHeight,
      top: offsetTop,
      left: offsetLeft,
    })

    //Вычисляем точки на прямоугольнике видео, разбив его на 8 точек (каждую сторону делим на 2 части)
    const widthIndex = clientWidth / 2
    const heightIndex = clientHeight / 2
    const targetPoints = []

    for (let xPoint = 0; xPoint < 2; xPoint++) {
      targetPoints.push({
        x: xPoint * widthIndex,
        y: 0,
      })
    }
    for (let yPoint = 0; yPoint < 2; yPoint++) {
      targetPoints.push({
        x: clientWidth,
        y: yPoint * heightIndex,
      })
    }
    for (let xPoint = 2; xPoint > 0; xPoint--) {
      targetPoints.push({
        x: xPoint * widthIndex,
        y: clientHeight,
      })
    }
    for (let yPoint = 2; yPoint > 0; yPoint--) {
      targetPoints.push({
        x: 0,
        y: yPoint * heightIndex,
      })
    }
    setWatermarkPoints(targetPoints)
  }*/

  const checkSpot = useCallback(() => {
    if (videoRef.current && !isFullScreen) {
      if (document.getElementById(`w-${socialId}`)) {
        const childs = videoRef.current?.parentNode?.childNodes
        const some = Array.prototype.some
        const onRightSpot = some.call(childs, (node) => node.id === `w-${socialId}`)
        if (!onRightSpot) {
          videoRef.current.pause()
        }
      }
    }
  }, [videoRef.current, isFullScreen])
  useEffect(() => {
    const interval = setInterval(() => checkSpot(), 1000)
    return () => clearInterval(interval)
  }, [videoRef.current, isFullScreen])

  const menuRef = useRef(null)
  useOutsideClick(menuRef, () => {
    setIsSpeedOpen(false)
    setIsSettingsOpen(false)
  })

  const onTimeUpdate = useCallback(() => {
    setPercentage(Math.floor((100 / videoRef.current?.duration) * videoRef.current?.currentTime))
    currentTime.current = formatSeconds(videoRef.current?.currentTime)
  }, [videoRef.current])

  const onLoadedData = useCallback(() => {
    let { duration: videoDuration } = videoRef.current
    // calcPoints()
    duration.current = formatSeconds(videoDuration)
    pureDuration.current = videoDuration
    if (isActiveSlide) {
      videoRef.current.play()
    }
  }, [isActiveSlide, isFullScreen])

  const onPause = useCallback(() => {
    setButtonType(TYPE_PLAY)
    timeouts.current.map((timeout) => {
      clearTimeout(timeout)
    })
    clearInterval(timer.current)
    timer.current = undefined
  }, [timeouts.current, timer.current])

  const onPlay = useCallback(() => {
    if (!timer.current) {
      requestNewSignature({ influencerId })
      timer.current = setInterval(() => {
        requestNewSignature({ influencerId })
      }, 30000)
    }
    setButtonType(TYPE_PAUSE)
  }, [influencerId, timer.current])

  const onEnded = useCallback(() => {
    // calcPoints()
    clearInterval(timer.current)
    timer.current = undefined
  }, [videoRef.current, isFullScreen])

  const content = (
    <FullScreen
      handle={videoScreen}
      onChange={() => {}}
      className={isFullScreen ? 'player-full' : wrapperClass}
    >
      <div ref={videoContainerRef} className="player" onClick={(e) => e.stopPropagation()}>
        <div className="player-video" onClick={playPauseVideo}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={!isFullScreen ? { ...(style && style) } : { height, width: '100%' }}
          >
            <foreignObject x="0" y="0" width="100%" height="100%">
              <MemoizedVideo
                path={screenshotUrl}
                securityParams={securityParams}
                playerRef={videoRef}
                onLoadedData={onLoadedData}
                onPause={onPause}
                onPlay={onPlay}
                onTimeUpdate={onTimeUpdate}
                onEnded={onEnded}
                fullScreen={isFullScreen}
                maxWidth={width}
                style={style}
                height={height}
                src={src}
                autoPlay={isAutoPlay}
              />
              {/*{buttonType === TYPE_PAUSE && watermarkPoints.length > 0 && (
                <WatermarkSvgPath
                  materialId={id}
                  pathPoints={watermarkSvgPath}
                  watermarkPoints={watermarkPoints}
                  videoParams={videoParams}
                  socialId={socialId}
                />
              )}*/}
            </foreignObject>
          </svg>
        </div>
        {(!isFullScreen || buttonType === TYPE_PLAY) && (
          <div className="player-controls">
            <div className="d-flex flex-column">
              <progress
                max="100"
                value={percentage || 0}
                onClick={(e) => {
                  if (videoRef.current) {
                    const { left: offsetLeft } = progressBar.current.getBoundingClientRect()
                    const x = e.pageX - offsetLeft
                    const percent = x / progressBar.current.offsetWidth
                    if (buttonType === TYPE_PAUSE || isFullScreen) {
                      videoRef.current.currentTime = percent * videoRef.current.duration
                      setPercentage(percent)
                    }
                  }
                }}
                ref={progressBar}
                id="progress-bar"
              >
                percentage% played
              </progress>
              <div className="d-flex flex-row justify-content-between text-color-white medium-10-16">
                <div>{currentTime.current}</div>
                <div>{duration.current}</div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div
                className="player-controls-button"
                onClick={() => {
                  setIsSettingsOpen(!isSettingsOpen)
                }}
              >
                <img
                  src={`${appConfig.cdnPath.iconsSvg}/settings-white.svg`}
                  width="24px"
                  height="24px"
                  alt=""
                />
              </div>
              {isSettingsOpen && (
                <div
                  className={!isSpeedOpen ? 'player-video-properties' : 'player-video-speed'}
                  ref={menuRef}
                >
                  <div className="player-controls-dropdown">
                    {!isSpeedOpen && (
                      <div onClick={() => setIsSpeedOpen(true)}>
                        <div className="d-flex flex-row bold-12-20 justify-content-between flex-grow-1">
                          <span>Скорость</span> <span className="text-right">{speed}</span>
                        </div>
                        <span style={{ color: '#959B9B' }} className="medium-8-12 text-left">
                          {speedList.find(({ speed: speedVal }) => speedVal === speed)?.title}
                        </span>
                      </div>
                    )}
                    {isSpeedOpen && (
                      <div>
                        {speedList.map(({ speed: speedVal, title }) => (
                          <div
                            key={speedVal}
                            className={clsx('medium-12-20', 'player-controls-dropdown-part')}
                            onClick={() => {
                              videoRef.current.playbackRate = speedVal
                              setSpeed(speedVal)
                              setIsSpeedOpen(false)
                            }}
                          >
                            {title}
                          </div>
                        ))}
                        <div
                          className="player-controls-back medium-12-20"
                          style={{ color: '#959B9B' }}
                          onClick={() => {
                            setIsSpeedOpen(false)
                          }}
                        >
                          Назад
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div style={{ flex: '0.5' }} className="d-flex justify-content-between">
                <img
                  src={`${appConfig.cdnPath.iconsSvg}/skip-left.svg`}
                  alt=""
                  onClick={() => {
                    videoRef.current.currentTime -= 15
                  }}
                />
                <img
                  className="play-icon"
                  src={`${appConfig.cdnPath.iconsSvg}/${
                    buttonType === TYPE_PLAY ? 'play-white' : 'pause-white'
                  }.svg`}
                  alt=""
                  role="button"
                  onClick={playPauseVideo}
                />
                <img
                  src={`${appConfig.cdnPath.iconsSvg}/skip-right.svg`}
                  alt=""
                  onClick={() => {
                    videoRef.current.currentTime += 15
                  }}
                />
              </div>
              <div
                id="btnFullScreen"
                className="player-controls-button ml-2"
                title="toggle full screen"
                accessKey="T"
                onClick={toggleFullScreen}
              >
                {!isFullScreen && (
                  <img
                    src={`${appConfig.cdnPath.iconsSvg}/maximize1.svg`}
                    width="24px"
                    height="24px"
                    alt=""
                  />
                )}
                {isFullScreen && (
                  <img
                    src={`${appConfig.cdnPath.iconsSvg}/minimize1.svg`}
                    width="24px"
                    height="24px"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </FullScreen>
  )

  return isFullScreen && iOS
    ? ReactDOM.createPortal(content, document.getElementById('fullscreen'))
    : content
}

VideoPlayer.defaultProps = {
  mode: SCREENSHOT_MODE,
}

VideoPlayer.propTypes = {
  autoplay: PropTypes.string,
  isActiveSlide: PropTypes.bool,
  id: PropTypes.string,
  screenshotUrl: PropTypes.string,
  playerMode: PropTypes.oneOf(Object.values(playerModes)),
  securityParams: PropTypes.object,
  style: PropTypes.any,
  wrapperClass: PropTypes.string,
  size: PropTypes.object,
  influencerId: PropTypes.string,
}

import React from 'react'

function DisableRegistration() {
  return (
    <>
      В данный момент регистрация через почту недоступна из-за проблем с сервисом рассылки. Работаем
      над устранением.
      <div className="text-color-sub-title mt-3">
        Для регистрации воспользуйся быстрым входом через социальные сети.
      </div>
    </>
  )
}

export default DisableRegistration

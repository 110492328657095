import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import { Footer } from '../../components/Footer/Footer'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { formatPrice, makeUrl, simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import FilterList from '../../components/Income/FilterList'
import FilterValuesModal from '../../components/Income/FilterValuesModal'
import { formatDate } from '../../helpers/time'
import DatePicker from '../../components/Layout/DatePicker/DatePicker'
import { useInfiniteQuery, useQuery } from 'react-query'
import { getInfluencerBalance, getInfluencerPayments } from '../../api/influencer'
import { useScroll } from '../../hooks/useScroll'
import { FixedSizeList as List } from 'react-window'
import { LogoSpinner } from '../../components/Layout'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'
import { update } from '../../api/storage'
import { VIP_CHAT_TYPE } from '../../constants'

const typeLabels = appConfig.income.labels

function IncomePage() {
  const history = useHistory()
  const location = useLocation()
  const { userType } = useContext(UserContext)

  const initialState = {
    page: 0,
    isOpenFilter: false,
    currentFilterValues: null,
    currentFilterValueElement: null,
    currentFilterTitle: '',
    currentFilterKey: null,
    valuesList: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data: balanceData } = useQuery('getInfluencerBalance', getInfluencerBalance)

  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    `getInfluencerPayments-${state.valuesList?.periodFrom?.value}-${state.valuesList?.periodTo?.value}-${state.valuesList?.income?.value}`,
    () => {
      return getInfluencerPayments({
        page: state.page,
        dateFrom: state.valuesList?.periodFrom?.value,
        dateTo: state.valuesList?.periodTo?.value,
        paymentType: state.valuesList?.income?.value,
        perPage: 20,
      })
    },
  )

  useEffect(() => {
    if (location.state) {
      const { filterInitList } = location.state
      const incomeLabels = appConfig.income.labels
      let valuesList = {}
      Object.keys(filterInitList).forEach((filterKey) => {
        const value = filterInitList[filterKey]
        const dateVal =
          filterKey !== appConfig.filters.values.income &&
          moment(value, appConfig.dateFormats.apiDateTime).format(appConfig.dateFormats.shortDate)
        valuesList = {
          ...valuesList,
          [filterKey]: {
            value,
            label: filterKey === appConfig.filters.values.income ? incomeLabels[value] : dateVal,
          },
        }
      })
      setState({ valuesList })
    }
  }, [location.state])

  const fetchNextPaymentsPage = useCallback(() => {
    const nav = data ? data[0]?.nav : {}
    const maxPage = Math.ceil(nav?.total / nav?.perPage)
    if (state.page < maxPage - 1) {
      const nextPage = state.page + 1
      fetchNextPage({ pageParam: nextPage })
      setState({ page: nextPage })
    }
  }, [data, fetchNextPage, state.page])

  useScroll({
    onReachedBottom: () => {
      if (!isFetching) {
        fetchNextPaymentsPage()
      }
    },
  })

  const getDatePicker = (filterKey) => {
    return (
      <div className="body mt-3">
        <DatePicker
          open={true}
          onSelect={(date) => {
            let value = moment(date)
            if (filterKey === 'periodFrom') {
              value = value.format(appConfig.dateFormats.apiDateTime)
            } else {
              value = value.format(appConfig.dateFormats.apiDateTimeAllDay)
            }
            setState({
              isOpenFilter: false,
              valuesList: {
                ...state.valuesList,
                [filterKey]: { label: formatDate(date), value: value },
              },
              page: 0,
            })
          }}
          onCancel={() => setState({ isOpenFilter: false })}
        />
      </div>
    )
  }

  const handleClickFilter = (filterKey) => {
    if (filterKey === appConfig.filters.values.income) {
      setState({
        currentFilterKey: filterKey,
        isOpenFilter: true,
        currentFilterTitle: appConfig.filters.labels[filterKey],
        currentFilterValues: typeLabels,
        currentFilterValueElement: null,
        page: 0,
      })
    } else if (filterKey === appConfig.filters.values.periodFrom) {
      setState({
        currentFilterKey: filterKey,
        isOpenFilter: true,
        currentFilterTitle: appConfig.filters.labels[filterKey],
        currentFilterValues: null,
        currentFilterValueElement: getDatePicker(filterKey),
      })
    } else if (filterKey === appConfig.filters.values.periodTo) {
      setState({
        currentFilterKey: filterKey,
        isOpenFilter: true,
        currentFilterTitle: appConfig.filters.labels[filterKey],
        currentFilterValues: null,
        currentFilterValueElement: getDatePicker(filterKey),
      })
    }
  }

  const handleClickValue = (value, label) => {
    setState({
      isOpenFilter: false,
      valuesList: {
        ...state.valuesList,
        [state.currentFilterKey]: { value, label },
      },
      page: 0,
    })
  }

  const handleClickClear = (filterKey) => {
    delete state.valuesList[filterKey]
    setState({ currentFilterKey: null, valuesList: state.valuesList })
  }

  const handleClickPayment = (payment) => {
    if (payment?.paymentType?.id === appConfig.income.types.donate) {
      update(appConfig.storage.keys.backFromChats, location.pathname)
      history.push(
        makeUrl({
          route: ROUTES.PROFILE_CHATS,
          routeParams: { userType: userType.toLowerCase(), interlocutorId: payment?.followerId },
        }),
      )
    } else if (payment?.paymentType?.id === appConfig.income.types.subscription) {
      handleClickFollower(payment)
    } else if (payment?.paymentType?.id === appConfig.income.types.premium) {
      update(appConfig.storage.keys.backFromChats, location.pathname)
      history.push(
        makeUrl({
          route: ROUTES.PROFILE_CHATS,
          routeParams: { userType: userType.toLowerCase(), interlocutorId: payment?.followerId },
          params: { type: VIP_CHAT_TYPE },
        }),
      )
    }
  }

  const handleClickFollower = (payment) => {
    history.push(
      makeUrl({
        route: ROUTES.PROFILE_FOLLOWER,
        routeParams: { followerNick: payment.followerNickName },
      }),
      { backTo: location.pathname },
    )
  }

  const handleCloseFilter = () => setState({ isOpenFilter: false })

  const payments = (data || [])?.map((page) => page?.data).flat()
  return (
    <>
      <Header needRight={false} title="Мои доходы" to={ROUTES.PROFILE_SETTINGS} />
      <div className="content income footer-padding">
        <div className="balance">
          <div className="top">
            <div>Доступный остаток:</div>
            <div>Выплаты:</div>
          </div>
          <div className="top">
            <div className="bold-24-32 text-color-green">
              {formatPrice(Math.ceil(balanceData?.balance))}
            </div>
            <div className="bold-24-32 text-color-green">
              {formatPrice(Math.ceil(balanceData?.payed))}
            </div>
          </div>
          <div className="top">
            <div>Продажи:</div>
            <div>Комиссия Insider:</div>
          </div>
          <div className="top">
            <div className="bold-24-32 text-color-green">
              {formatPrice(Math.ceil(balanceData?.income))}
            </div>
            <div className="bold-24-32 text-color-green">
              {formatPrice(Math.ceil(balanceData?.commission))}
            </div>
          </div>
        </div>
        <div className="title">Статистика доходов</div>
        <FilterList
          className="mb-3"
          onClick={handleClickFilter}
          values={state.valuesList}
          onClear={handleClickClear}
        />
        <div className="totals">
          Продажи: <span>{data ? formatPrice(data[0]?.sum) : 0}</span>
        </div>
        <div className="income-list">
          <List
            height={payments.length * 146}
            itemCount={payments.length}
            itemSize={146}
            width="100%"
          >
            {({ index, style }) => (
              <div style={style} key={index}>
                <div className="date-block mb-3">{formatDate(payments[index].createDate)}</div>
                <div key={index} className="income-list-item">
                  <div className="amount" onClick={() => handleClickPayment(payments[index])}>
                    + {formatPrice(payments[index].paymentSum)}
                  </div>
                  <div className="type">
                    {payments[index]?.paymentType?.name?.replace('Donate', 'Донат')}
                  </div>
                  <div className="buyer" onClick={() => handleClickFollower(payments[index])}>
                    Покупка от <b>{payments[index].followerNickName}</b>
                  </div>
                </div>
              </div>
            )}
          </List>
          {isFetching && payments?.length > 0 && (
            <div className="my-2">
              <LogoSpinner isLoading size={25} />
            </div>
          )}
        </div>
      </div>
      <Footer />
      <FilterValuesModal
        title={state.currentFilterTitle}
        values={state.currentFilterValues}
        valueElement={state.currentFilterValueElement}
        onClose={handleCloseFilter}
        onClickValue={handleClickValue}
        isOpen={state.isOpenFilter}
        hideClose={state.currentFilterKey === appConfig.filters.values.period}
      />
    </>
  )
}

export default IncomePage

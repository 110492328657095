import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button } from '../../Layout/Button/Button'
import { makeUrl } from '../../../helpers'
import { ROUTES } from '../../../constants/routes'
import { appConfig } from '../../../constants/appConfig'

function PostActions({ onClose, onActionDelete, onActionPinned, post, actions }) {
  const history = useHistory()
  return (
    <>
      <div className="modal-white-panel mb-2">
        {actions.includes(appConfig.postActions.pinn) && (
          <>
            <div className="semiBold-15-24 cursor-pointer" onClick={onActionPinned}>
              {post?.isPinned ? 'Открепить' : 'Закрепить'}
            </div>
            <div className="content-padding">
              <hr />
            </div>
          </>
        )}
        {actions.includes(appConfig.postActions.edit) && (
          <>
            <div
              className="semiBold-15-24 cursor-pointer"
              onClick={() =>
                history.push(
                  makeUrl({
                    route: ROUTES.PROFILE_ADD_POST,
                    params: {
                      pageOption: 'editPost',
                      id: post.id,
                    },
                  }),
                )
              }
            >
              Редактировать
            </div>
            <div className="content-padding">
              <hr />
            </div>
          </>
        )}
        {actions.includes(appConfig.postActions.delete) && (
          <div className="semiBold-15-24 cursor-pointer text-color-error" onClick={onActionDelete}>
            Удалить
          </div>
        )}
      </div>
      <div className="modal-white-panel mb-2">
        <Button className="semiBold-15-24 cursor-pointer" onClick={onClose}>
          Отмена
        </Button>
      </div>
    </>
  )
}

PostActions.defaultProps = {
  onClose: () => {},
  onActionDelete: () => {},
  onActionPinned: () => {},
}

PostActions.propTypes = {
  post: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onActionDelete: PropTypes.func.isRequired,
  onActionPinned: PropTypes.func.isRequired,
  actions: PropTypes.array,
}

export default PostActions

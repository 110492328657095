import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { AudioPlayer } from '../../../Player/AudioPlayer'
import { appConfig } from '../../../../constants/appConfig'
import DeleteMediaIcon from './DeleteMediaIcon'
import { PostContext } from '../../../../contexts/postContext'

export function AudioLine({
  id,
  description,
  path,
  contentType,
  updateDescription,
  onDelete,
  secureParams,
}) {
  const { mediaIdToDelete } = useContext(PostContext)

  return (
    <div className={clsx('my-3 file-line', { disabled: mediaIdToDelete.includes(id) })}>
      <div className="d-flex justify-content-between">
        <AudioPlayer
          name={description}
          src={`${appConfig.cdnPath.insider}${path}`}
          type={contentType}
          onChangeName={(text) => {
            updateDescription(text)
          }}
          className="flex-grow-1 mr-1"
          secureParams={secureParams}
        />
        <DeleteMediaIcon id={id} onDelete={onDelete} />
      </div>
    </div>
  )
}

AudioLine.defaultProps = {
  onDelete: () => {},
}

AudioLine.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  path: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  updateDescription: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  secureParams: PropTypes.object,
}

import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Link } from 'react-router-dom'
import { appConfig } from '../../constants/appConfig'

export function HeaderProgress({ progress, all, to }) {
  const blackArrowLeft = `${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`

  return (
    <header className="mb-4 align-items-start">
      {to && to !== 'back' && (
        <Link className="pr-2" to={to} style={{ paddingTop: '3px' }}>
          <img src={blackArrowLeft} width="16px" height="14px" alt="" />
        </Link>
      )}
      {to === 'back' && (
        <a
          className="pr-2"
          onClick={() => {
            navigate(-1)
          }}
        >
          {' '}
          <img src={blackArrowLeft} width="16px" height="14px" alt="" />
        </a>
      )}
      <div className="flex-grow-1 bold-17-24">
        <progress max={all} value={progress} onClick={() => {}} id="progress-bar" />
        <div className="text-right text-color-regular medium-13-20">
          {progress} из {all}
        </div>
      </div>
    </header>
  )
}

HeaderProgress.propTypes = {
  all: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  to: PropTypes.string,
}

HeaderProgress.defaultProps = {
  to: undefined,
}

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { LogoSpinner } from '../Layout'
import ErrorModal from '../Modal/ErrorModal'
import { appConfig } from '../../constants/appConfig'
import { RemoveButton } from './VoiceRecorder/RemoveButton'

const RECORDER_MODES = {
  play: 'play',
  stop: 'stop',
}

// eslint-disable-next-line react/display-name
export const VoiceRecorder = forwardRef(
  ({ uploadMethod, setIsRecording, className, disabled }, ref) => {
    const [inProgress, setInProgress] = useState(false)
    const [mode, setMode] = useState(RECORDER_MODES.stop)
    const [error, setError] = useState('')

    const stop = () => {
      if (recorder.current) {
        recorder.current.stop()
        recorder.current.stream.getTracks().forEach((i) => i.stop())
      }
    }

    const stopRecording = () => {
      stop()
      setIsRecording(false)
      setMode(RECORDER_MODES.stop)
    }
    const recordReset = () => {
      reset.current = true
      stopRecording()
    }

    useImperativeHandle(ref, () => ({
      stop,
    }))

    const changeHandler = async (file) => {
      setInProgress(true)
      try {
        await uploadMethod(file, () => {
          setInProgress(false)
        })
      } catch (e) {
        setError('Произошла ошибка загрузки файла. Попробуйте ещё раз.')
        setInProgress(false)
      }
      setMode(RECORDER_MODES.stop)
    }

    useEffect(() => {
      return () => {
        recordReset()
      }
    }, [])

    const recorder = useRef(null)
    const reset = useRef(null)

    if (inProgress) {
      return (
        <div className="d-flex justify-content-center mr-2">
          <LogoSpinner isLoading />
        </div>
      )
    }

    const record = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          recorder.current = new MediaRecorder(stream)

          // Set record to <audio> when recording will be finished
          recorder.current.addEventListener('dataavailable', (e) => {
            if (!reset.current) {
              const blob = e.data
              blob.name = 'voice'
              changeHandler(blob)
            } else {
              reset.current = false
            }
          })
          setIsRecording(true)
          recorder.current.start()
          setMode(RECORDER_MODES.play)
        })
        .catch((e) => {
          if (e.message?.toLowerCase() === 'permission denied') {
            setError('Для записи голосового сообщения разрешите приложению доступ к микрофону')
          } else {
            setError('Не удается подключиться к микрофону')
          }
        })
    }

    return (
      <div className={className}>
        <Button color="link" onClick={record} disabled={disabled}>
          {mode === RECORDER_MODES.stop && (
            <img className="play-icon" src={`${appConfig.cdnPath.iconsSvg}/voice.svg`} alt="" />
          )}
        </Button>
        <>
          {mode === RECORDER_MODES.play && <RemoveButton onClick={recordReset} />}
          <ErrorModal toggle={() => setError('')} isOpen={Boolean(error)} message={error} />
        </>
      </div>
    )
  },
)

VoiceRecorder.propTypes = {
  uploadMethod: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  ref: PropTypes.object,
  disabled: PropTypes.bool,
  setIsRecording: PropTypes.func,
}

import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { Footer } from '../../components/Footer/Footer'
import { simpleReducer } from '../../helpers'
import { getLikedPosts, getFavoritePosts, getPosts, getFreePosts } from '../../api/blog'
import { Post } from '../../components/Profile/Tabs/Post'
import TabContentEmpty, {
  types as tabContentTypes,
} from '../../components/Profile/Tabs/TabContentEmpty'
import { useMutation, useQuery } from 'react-query'
import { PageLoading } from '../../components/Layout/PageLoading'
import { appConfig } from '../../constants/appConfig'
import { hasSubscribe } from '../../api/auth'
import { useRouteMatch } from 'react-router-dom'
import { UserContext } from '../../contexts/userContext'
import { getPrivateParamsByUser } from '../../api/users'

function FollowerFavoritePostsPage() {
  const matchLiked = useRouteMatch(ROUTES.FOLLOWER_LIKED_POSTS)
  const isLiked = useMemo(() => matchLiked?.isExact, [matchLiked])
  const { user } = useContext(UserContext)
  const initialState = {
    pageTitle: isLiked ? 'Понравилось' : 'Сохраненное',
    coordinateList: null,
    posts: null,
    influencerData: {},
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const makeCoordinateIndexes = (list) => {
    let influencerData = {}
    list?.forEach((item) => {
      influencerData = {
        ...influencerData,
        [item.influencer]: {
          posts: [...(influencerData[item.influencer]?.posts || []), ...(item.posts || [])],
          nickName: item.nickName,
          avatar: item.avatar,
        },
      }
    })
    return influencerData
  }

  const {
    isLoading,
    isSuccess,
    data: coordinateList,
  } = useQuery(isLiked ? 'liked' : 'saved', () => getPostsData(), {
    keepPreviousData: true,
  })

  const filterByCoordinate = (data) => {
    return data?.filter((post) => state.influencerData[post.influencerId]?.posts?.includes(post.id))
  }

  useEffect(() => {
    if (isSuccess) {
      setState({
        coordinateList: coordinateList || [],
        influencerData: makeCoordinateIndexes(coordinateList),
      })
    }
  }, [isSuccess, coordinateList])

  useEffect(() => {
    if (state.coordinateList?.length) {
      let allPosts = []
      coordinateList.forEach((item) => {
        getInfluencerData({
          influencerId: item.influencer,
          page: item.page,
          countPerPage: appConfig.posts.countPerPage,
          nickName: item.nickName,
        })
          .then((data) => {
            allPosts = [...allPosts, ...filterByCoordinate(data)]
            setState({ posts: allPosts })
          })
          .catch((e) => {
            console.log('error', e)
          })
      })
    } else if (state.coordinateList?.length === 0) {
      setState({ posts: [] })
    }
  }, [state.coordinateList])

  const getInfluencerData = ({ influencerId, page, countPerPage, nickName }) => {
    return hasSubscribe(nickName)
      ? mutatePrivateData({ influencerId, page, countPerPage })
      : mutateFreeData({ influencerId, page, countPerPage })
  }

  const { mutateAsync: mutateFreeData } = useMutation(({ influencerId, page, countPerPage }) =>
    getFreePosts({ influencerId, page, countPerPage, ignorePins: true }),
  )

  const { mutateAsync: mutatePrivateData } = useMutation(({ influencerId, page, countPerPage }) =>
    getPosts({ influencerId, page, countPerPage, user, ignorePins: true }),
  )

  const getPostsData = async () => {
    const method = isLiked ? getLikedPosts : getFavoritePosts
    try {
      return await method()
    } catch (e) {
      console.log(e)
    }
  }

  const renderList = () => {
    return state.posts?.length > 0 ? (
      state.posts.map((post) => {
        const isSubscribed = hasSubscribe(state.influencerData[post.influencerId]?.nickName)
        const imageParams = isSubscribed
          ? { ...getPrivateParamsByUser(post.influencerId, user), w: 480 }
          : { w: 480 }
        return (
          <Post
            post={post}
            key={`post_${post.id}`}
            isSubscribed={hasSubscribe(state.influencerData[post.influencerId]?.nickName)}
            avatar={state.influencerData[post.influencerId]?.avatar}
            author={state.influencerData[post.influencerId]?.nickName}
            influencerId={post.influencerId}
            imageParams={imageParams}
            influencerNick={state.influencerData[post.influencerId]?.nickName}
          />
        )
      })
    ) : (
      <TabContentEmpty type={tabContentTypes.post} />
    )
  }

  return (
    <>
      <Header title={state.pageTitle} needRight={false} to={ROUTES.FOLLOWER} />
      <div className={`content follower-favorite-posts ${isLoading ? '' : 'footer-padding'}`}>
        {isLoading ||
        state.coordinateList === null ||
        state.coordinateList === undefined ||
        state.posts === null ? (
          <PageLoading hasFooter hasHeader />
        ) : (
          renderList()
        )}
      </div>
      <Footer />
    </>
  )
}

export default FollowerFavoritePostsPage

import React, { useEffect, useReducer } from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { Footer } from '../../components/Footer/Footer'
import { appConfig } from '../../constants/appConfig'
import { nbsp } from '../../constants/typography'
import { formatPrice, groupBy, simpleReducer } from '../../helpers'
import { formatDate } from '../../helpers/time'

const cardTypes = appConfig.payment.cardTypeValues

const mock = [
  {
    id: 1,
    title: 'Рекламный макет, пренебрегая деталями, притягивает медийный канал',
    author: 'Иванов Иван',
    cardType: cardTypes.visa,
    cardPan: '564654654',
    amount: 123,
    date: '27.08.2020T00:11:22',
  },
  {
    id: 2,
    title: 'Promotion-кампания, пренебрегая деталями',
    author: 'Фамилий Именович',
    cardType: cardTypes.visa,
    cardPan: '564654654',
    amount: 1230,
    date: '27.08.2020T00:11:22',
  },
  {
    id: 3,
    title: 'Отсюда естественно следует',
    author: 'Иванов Петр',
    cardType: cardTypes.visa,
    cardPan: '897987987',
    amount: 123000,
    date: '27.08.2020T00:11:22',
  },
  {
    id: 4,
    title: 'Нет такого задания за которое',
    author: 'Света с того',
    cardType: cardTypes.visa,
    cardPan: '897987987',
    amount: 6000,
    date: '27.08.2020T00:11:22',
  },
  {
    id: 5,
    title: 'Есть такое задание которое',
    author: 'Безымянный автор',
    cardType: cardTypes.visa,
    cardPan: '897987987',
    amount: '5600000',
    date: '28.08.2020T00:11:22',
  },
]

function FollowerPaymentHistoryPage() {
  const formatData = (data) => {
    const list = data?.map((item) => ({
      ...item,
      ...(item.date && {
        dateString: formatDate(item.date),
      }),
    }))
    return groupBy(list, 'dateString')
  }

  const initialState = {
    paymentList: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    setState({ paymentList: formatData(mock) })
  }, [])

  const renderItem = (paymentItem) => {
    return (
      <div key={paymentItem.id} className="follower-payment-history-item">
        <div className="amount">{formatPrice(paymentItem.amount)}</div>
        <div className="title">{paymentItem.title}</div>
        <div className="from">
          Продукт от <b>{paymentItem.author}</b>
        </div>
        <div className="cardInfo">
          <b>Карта {paymentItem.cardType},</b> последние цифры{nbsp}
          <b>{`${paymentItem.cardPan?.substring(paymentItem.cardPan?.length - 4) || ''}`}</b>
        </div>
        <div className="btnChange">Чек об оплате</div>
      </div>
    )
  }

  const renderDateBlock = (date) => {
    return (
      <>
        <div className="date-block mt-3 mb-3">{date}</div>
        {state.paymentList[date]?.map(renderItem)}
      </>
    )
  }

  return (
    <>
      <Header title="История платежей" needRight={false} to={ROUTES.FOLLOWER_PAYMENT_INFO} />
      <div className="content content-padding follower-payment-history footer-padding">
        {Object.keys(state.paymentList || {}).map(renderDateBlock)}
      </div>
      <Footer />
    </>
  )
}

export default FollowerPaymentHistoryPage

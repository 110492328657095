import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { GoogleLogin } from 'react-google-login'
import { useLocation } from 'react-router-dom'

function Google({ authHandler }) {
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.hash?.replace('#', ''))
    if (params.has('id_token')) {
      authHandler({
        token: params.get('id_token'),
        provider: 'Google',
      })
    }
  }, [location])

  return (
    <GoogleLogin
      clientId="937912081314-6retv9fuippcut9qnec4olcgecg1apui.apps.googleusercontent.com"
      onSuccess={() => {}}
      onFailure={() => {}}
      uxMode="redirect"
      redirectUri={window.location.origin + '/auth/'}
      render={(props) => (
        <button {...props} className="auth-line-google semiBold-15-24" rel="opener">
          Google
        </button>
      )}
    />
  )
}

export default Google

Google.propTypes = {
  authHandler: PropTypes.func.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import Vk from './Vk'
import Facebook from './Facebook'
import Yandex from './Yandex'
import Google from './Google'
import { Button } from '../Layout/Button/Button'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'
import { Header } from '../Header/Header'
import { modes as authScreenMode } from '../../screens/Auth'
import { LogoSpinner } from '../Layout'

function SelectButtons({ authHandler, mode, backAction }) {
  const history = useHistory()
  const handleAgreement = () => history.push(ROUTES.AGREEMENT)
  const handleConfidentiality = () => history.push(ROUTES.CONFIDENTIALITY)

  return (
    <>
      <Header
        needRight={false}
        title={mode === authScreenMode.register ? 'Регистрация' : 'Авторизация'}
        onBackAction={backAction}
      />
      <div className="h-100">
        <div className="auth-header d-flex align-items-end justify-content-center" />
        <div className="auth-content">
          <div className="mb-5">
            <LogoSpinner isLoading withLogo isFullName size={30} />
          </div>
          <div className="semiBold-14-20 text-color-regular mb-4">Через социальные сети</div>
          <div className="auth-line">
            <Vk authHandler={authHandler} />
            <Facebook authHandler={authHandler} />
          </div>
          <div className="auth-line mt-4">
            <Yandex authHandler={authHandler} />
            <Google authHandler={authHandler} />
          </div>
          <div className="semiBold-14-20 text-color-regular mt-4">или</div>
          <div className="d-flex justify-content-center">
            <Button
              className="mt-3 w-100 semiBold-14-20"
              onClick={async () => {
                history.push(ROUTES.EMAIL)
              }}
            >
              Через почту
            </Button>
          </div>
        </div>
        <div className="auth-footer">
          <span>
            Регистрируясь, вы подтверждаете, что согласны <br />с{' '}
            <span className="text-color-green cursor-pointer" onClick={handleAgreement}>
              Пользовательским соглашением
            </span>
            , а также <br />с{' '}
            <span className="text-color-green cursor-pointer" onClick={handleConfidentiality}>
              Политикой конфиденциальности
            </span>
            .
          </span>
        </div>
      </div>
    </>
  )
}

SelectButtons.defaultProps = {
  authHandler: () => {},
  backAction: () => {},
}

SelectButtons.propTypes = {
  authHandler: PropTypes.func.isRequired,
  mode: PropTypes.string,
  backAction: PropTypes.func.isRequired,
}

export default SelectButtons

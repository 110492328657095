import React, { useContext } from 'react'
import * as PropTypes from 'prop-types'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { VIP_CHAT_TYPE } from '../../../constants'
import { ROUTES } from '../../../constants/routes'
import { nbsp } from '../../../constants/typography'
import { formatDateTimeLastDays } from '../../../helpers/time'
import { UserContext } from '../../../contexts/userContext'

function ChatExpiredMessage({ chat }) {
  const { isFollower } = useContext(UserContext)
  return (
    <div className="d-flex justify-content-center mt-3">
      <span className="medium-15-20 text-color-regular">
        {chat.chatType === VIP_CHAT_TYPE ? (
          isFollower ? (
            <span>Доступ к чату приостановлен</span>
          ) : (
            <span>
              Оплата чата истекла{nbsp}
              <span className="text-color-green">
                {formatDateTimeLastDays(chat.expirationDate).toLowerCase()}
              </span>
            </span>
          )
        ) : isFollower ? (
          <span>
            Подписка истекла.{nbsp}
            <Link
              to={`${ROUTES.SUBSCRIBE}?${queryString.stringify({
                influencerId: chat.abonent?.idProfile,
              })}`}
              className="text-color-green"
            >
              Оформить подписку.
            </Link>
          </span>
        ) : (
          <span>
            Подписка истекла{nbsp}
            <span className="text-color-green">
              {formatDateTimeLastDays(chat.expirationDate).toLowerCase()}
            </span>
          </span>
        )}
      </span>
    </div>
  )
}
export default ChatExpiredMessage

ChatExpiredMessage.propTypes = {
  chat: PropTypes.any,
}

import React, { useReducer } from 'react'
import { ROUTES } from '../../constants/routes'
import { appConfig } from '../../constants/appConfig'
import CheckboxAgreement from '../Layout/Checkbox/CheckboxAgreement'
import { makeUrl, simpleReducer } from '../../helpers'
import { pay } from '../../api/payment'
import { Modal } from 'reactstrap'
import { Button } from '../Layout/Button/Button'
import { getAuthIp } from '../../api/auth'
import InfoModal from '../Modal/InfoModal'
import { errorsJsx } from '../../constants/errorsJsx'

function ButtonAddWithAgree() {
  const initialState = {
    isAgree: false,
    isOpenModal: false,
    isModalBackendOpen: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const checkBackend = () => {
    getAuthIp()
      .then(() => {
        handlePay()
      })
      .catch(() => {
        setState({ ...initialState, isModalBackendOpen: true })
      })
  }

  const handlePay = async () => {
    const result = await pay({
      paymentType: appConfig.payment.types.link,
      returnUrl: makeUrl({ route: ROUTES.PAY_FINISH, isAbsolute: true }),
      isAgree: state.isAgree,
    })

    if (result?.redirectUrl) {
      window.location = result?.redirectUrl
    }
  }

  return (
    <>
      <div
        className="d-flex flex-grow-1 align-items-center mb-3"
        onClick={() => setState({ isOpenModal: true })}
        role="button"
      >
        <img
          src={`${appConfig.cdnPath.iconsSvg}/plus-around-green.svg`}
          width="44px"
          height="44px"
          alt=""
        />
        <div className="text-color-green semiBold-15-24 ml-2">Добавить карту</div>
      </div>
      <hr />
      <Modal isOpen={state.isOpenModal} toggle={() => {}} centered={true} size="sm">
        <div className="py-4">
          <div className="medium-15-20 text-center mt-1 p-2">Добавление новой карты</div>
          <div className="m-3">
            <CheckboxAgreement
              onChange={(checked) => setState({ isAgree: checked })}
              checked={state.isAgree}
            />
          </div>
          <div className="d-flex justify-content-between mt-4 ml-3 mr-3">
            <Button className="mr-3" onClick={checkBackend} disable={!state.isAgree}>
              Продолжить
            </Button>
            <Button color="grey" className="mr-3" onClick={() => setState({ isOpenModal: false })}>
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
      <InfoModal
        isOpen={state.isModalBackendOpen}
        onClose={() => setState({ isModalBackendOpen: false })}
        message={errorsJsx.backendPingError}
      />
    </>
  )
}

export default ButtonAddWithAgree

import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { Modal } from 'reactstrap'
import { simpleReducer } from '../../helpers'

const linksData = {
  instagramLink: { label: 'Instagram', icon: 'instagram-green.svg' },
  youTubeLink: { label: 'YouTube', icon: 'youtube-green.svg' },
  tikTokLink: { label: 'TikTok', icon: 'tik-tok-green.svg' },
  twitchLink: { label: 'Twitch', icon: 'twitch-green.svg' },
}

function SocialLinks({ links, influencerNick }) {
  const initialState = {
    isModalOpen: false,
    modalLabel: '',
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleEmptyClick = (keyLink) => {
    setState({ isModalOpen: true, modalLabel: linksData[keyLink].label })
  }

  const renderItem = (keyLink, index) => {
    const item = linksData[keyLink]
    const sourceLink = links?.[keyLink]
    let params
    if (sourceLink) {
      params = { target: '_blank', rel: 'noreferrer', href: sourceLink }
    } else {
      params = { onClick: () => handleEmptyClick(keyLink) }
    }
    return (
      <a className="socialLinks-item" key={index} {...params}>
        <div className="center-block">
          <div className="image">
            <img
              src={`${appConfig.cdnPath.iconsSocialSvg}/${item.icon}`}
              alt=""
              width="28px"
              height="28px"
            />
          </div>
          <span>{item.label}</span>
        </div>
      </a>
    )
  }

  return (
    <>
      <div className="content-margin mt-5 socialLinks mb-4">
        <div className="socialLinks-list">{Object.keys(linksData).map(renderItem)}</div>
      </div>
      <Modal
        isOpen={state.isModalOpen}
        centered
        toggle={() => setState({ isModalOpen: false })}
        size="sm"
      >
        <div className="py-4">
          <div className="medium-13-20 text-color-regular text-center mt-1 p-2">
            Пользователь {influencerNick} не добавил ссылку на {state.modalLabel}
          </div>
        </div>
      </Modal>
    </>
  )
}

SocialLinks.defaultProps = {
  links: null,
}

SocialLinks.propTypes = {
  links: PropTypes.any,
  influencerNick: PropTypes.string,
}

export default SocialLinks

import { Modal } from 'reactstrap'
import { Uploader } from './Uploader'
import { Button } from '../Layout/Button/Button'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { uploadFileTypes, uploadFileTypesInfo } from '../../constants/files'

export function UploadMaterialModal({
  open,
  toggle,
  uploadMethod,
  onCancel,
  types = Object.keys(uploadFileTypes),
  maxFileSize = false,
  onFinishUpload = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false)
  const currentTypes = useMemo(
    () => uploadFileTypesInfo.filter((type) => types.includes(type.id)),
    [types],
  )
  return (
    <Modal isOpen={open} toggle={toggle} centered={true} size="sm" modalClassName="modal-bottom">
      <div className="py-4">
        <div className="modal-white-panel mb-2">
          {currentTypes.map((type, index) => (
            <div key={type.id}>
              <Uploader
                type={type.id}
                accept={type.accept}
                title={type.name}
                className="semiBold-15-24 cursor-pointer"
                uploadMethod={uploadMethod}
                onUploading={() => setIsLoading(true)}
                onFinishUpload={(data) => {
                  setIsLoading(false)
                  onFinishUpload(data)
                }}
                disabled={isLoading}
                maxFileSize={maxFileSize}
              />
              {index < currentTypes.length - 1 && (
                <div className="content-padding">
                  <hr />
                </div>
              )}
            </div>
          ))}
        </div>
        <Button className="semiBold-15-24 cursor-pointer" onClick={onCancel}>
          Закрыть
        </Button>
      </div>
    </Modal>
  )
}

UploadMaterialModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  uploadMethod: PropTypes.func,
  onCancel: PropTypes.func,
  types: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(uploadFileTypes))),
  maxFileSize: PropTypes.number,
  onFinishUpload: PropTypes.func,
}

import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import { getCards } from '../../api/payment'
import { UserContext } from '../../contexts/userContext'

const cardTypes = appConfig.payment.cardTypes
const someCardIcon = 'some-card.svg'

const cardNew = { cardType: 'Новая карта', label: '+ Добавить способ оплаты' }
const cardNone = { cardType: 'Не выбрана', label: 'Не выбрана' }

function CardList({
  existsNewCard = false,
  onSetCurrentCard,
  defaultCardId,
  topElement,
  selectedNoneCard = false,
}) {
  const initialState = {
    isVisibleList: false,
    cardList: [],
    defaultCard: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { user } = useContext(UserContext)
  useEffect(() => {
    const { follower, influencer, email } = user
    if (email && (follower?.nickName || influencer?.nickName)) {
      getCards().then((data) => {
        if (data) {
          const cardList = Object.values(data || {}).filter((item) => item.id && item.pan)
          let defaultCard
          if (selectedNoneCard) {
            defaultCard = cardNone
          } else {
            defaultCard = cardList.filter((item) => item.id === defaultCardId)[0]
            if (!defaultCard?.id) {
              defaultCard = cardList.filter((item) => item.isDefault)[0]
              if (!defaultCard?.id) {
                defaultCard = cardList[0]
              }
            }
          }

          setState({ cardList, defaultCard })
          onSetCurrentCard(defaultCard)
        }
      })
    }
  }, [defaultCardId, user])

  const handleToggleList = () => {
    setState({ isVisibleList: !state.isVisibleList })
  }

  const getToggleIcon = () => {
    return state.isVisibleList
      ? `${appConfig.cdnPath.iconsSvg}/arrow-up-black.svg`
      : `${appConfig.cdnPath.iconsSvg}/arrow-down-black.svg`
  }

  const getIconUrl = (icon) => `${appConfig.cdnPath.iconsSvg}/payment/${icon}`

  const handleSelectCard = (card) => {
    setState({ defaultCard: card, isVisibleList: false })
    onSetCurrentCard(card)
  }

  const handleAddCard = () => {
    handleSelectCard(selectedNoneCard ? cardNone : cardNew)
  }

  const renderItem = (card) => {
    return (
      <div key={card.id} className="card-item" onClick={() => handleSelectCard(card)}>
        <img
          className="icon"
          src={getIconUrl(cardTypes[card.cardType]?.icon || someCardIcon)}
          alt=""
        />
        <span className="type">{card.cardType}</span>
        <span>{card.pan?.substr(-4)}</span>
      </div>
    )
  }

  return (
    <div className="cards">
      {topElement}
      {state.isVisibleList && state.cardList.length && (
        <div className="background-fill">
          <div className="cards-list">
            {state.cardList.map(renderItem)}
            {existsNewCard && (
              <div key="addCard" className="addCard" onClick={handleAddCard}>
                {selectedNoneCard ? cardNone.label : cardNew.label}
              </div>
            )}
          </div>
        </div>
      )}

      {state.cardList.length > 0 && state.defaultCard && (
        <div className="card-current">
          {!state.isVisibleList && <span>Способ оплаты:</span>}
          <div onClick={handleToggleList} className="card-current-body">
            <img
              alt=""
              className="icon"
              src={getIconUrl(cardTypes[state.defaultCard.cardType]?.icon || someCardIcon)}
            />
            <span className="type">{state.defaultCard.cardType}</span>
            <span>{state.defaultCard.pan?.substr(-4)}</span>
            <img className="arrow" src={getToggleIcon()} alt="" />
          </div>
        </div>
      )}
      <hr width="100%" size="2" className="mt-3" />
    </div>
  )
}

CardList.propTypes = {
  existsNewCard: PropTypes.bool,
  onSetCurrentCard: PropTypes.func,
  defaultCardId: PropTypes.string,
  topElement: PropTypes.node,
  selectedNoneCard: PropTypes.bool,
}

export default CardList

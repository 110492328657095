import { find, remove, update } from './storage'
import { api } from './api'
import Cookies from 'js-cookie'
import { appConfig } from '../constants/appConfig'
const CryptoJS = require('crypto-js')

const tokenKey = 'token'
const usernameKey = 'username'
const useridKey = 'userid'

const TOKEN_KEY = 'App.Dat'
const authDataKey = 'App.Cu'

const subscribers = []
const notify = () => {
  subscribers.forEach((callback) => {
    callback()
  })
}

// const getAuth = () => {
//   const token = find(tokenKey)
//   const username = find(usernameKey)
//   const userId = find(useridKey)
//   return {
//     isUserLogged: Boolean(token),
//     loginError: false,
//     token,
//     username,
//     userId,
//   }
// }

// export const useAuth = () => getAuth()

export const updateAuth = ({ token, username, user_id }) => {
  update(tokenKey, token)
  update(usernameKey, username)
  update(useridKey, user_id)
  notify()
}

const clearAuth = () => {
  remove(tokenKey)
  remove(usernameKey)
  notify()
}

export const subscribe = (callback) => {
  subscribers.push(callback)
}

export const socialAuth = async ({ token, socialId, provider, redirectUrl }) => {
  return await api
    .post({
      path: '/insider/auth/login/social',
      data: {
        token,
        socialId,
        provider,
        redirectUrl,
      },
    })
    .then(async (data) => {
      if (data?.user?.accessId && data?.state?.id !== 'NoEmailReceived') {
        await directAuth(data?.user?.accessId)
      }
      return data
    })
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const getSubscriptions = () => {
  if (typeof document !== 'undefined') {
    const token = Cookies.get(TOKEN_KEY)
    let decodedHeader = {}
    if (token) {
      decodedHeader = parseJwt(token)
      const subscriptions = decodedHeader[appConfig.tokenKeys.subscriptions]
      return subscriptions?.length ? subscriptions.split(';') : []
    }
  }
  return []
}

export const emailLogin = (email, password = null, token = null, register = null) => {
  return api
    .post({
      path: `/insider/auth/login/email`,
      data: {
        email,
        password,
        token,
        register,
      },
      validateStatus: (status) => status === 200,
    })
    .then(async (data) => {
      if (data?.user?.accessId) {
        await directAuth(data?.user?.accessId)
      }
      return data
    })
}

export const logout = () => {
  return api.post({
    path: `/insider/auth/logout`,
  })
}

export const loginReset = (email) => {
  return api.post({
    path: `/insider/auth/login/reset`,
    data: { email },
    validateStatus: (status) => status === 200,
  })
}

export const hasSubscribe = (influencerNickname) => {
  const subscriptions = getSubscriptions()
  return subscriptions.includes(influencerNickname)
}

export const hasSubscriptions = () => {
  const subscriptions = getSubscriptions()
  return subscriptions.length > 0
}

export const refresh = () => {
  return api
    .post({
      path: '/insider/auth/refresh',
    })
    .then(async (data) => {
      if (data?.user?.accessId) {
        await directAuth(data?.user?.accessId)
      }
      return data
    })
}

export const directAuth = (accessId) => {
  return api.get({
    path: `/insider/auth/access/cookie?tempId=${accessId}`,
    isDirect: true,
  })
}

export const getAuthIp = () => {
  if (appConfig.isPingBackend) {
    return api.get({
      path: `/insider/auth/ip`,
      isDirect: true,
    })
  } else {
    return Promise.resolve('alwaysSuccess')
  }
}

export const ensureAuth = () => {
  clearAuth()
  return refresh()
}

export const signOut = () => {
  clearAuth()
  window.location.reload()
}

export const hasToken = () => Cookies.get(TOKEN_KEY)

export const isTokenExpired = () => !hasToken()

export const removeToken = () => Cookies.remove(TOKEN_KEY)

export const getAuthData = () => {
  const cipher = find(authDataKey)
  if (!cipher) {
    return {}
  }
  const bytes = CryptoJS.AES.decrypt(cipher, appConfig.cryptoSecret)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}

export const setAuthData = (data) => {
  //При логине нового пользователя данные старого обнуляем
  remove(authDataKey)
  //Если пользователь нажал чекбокс "Запомнить меня", сохраняем его данные
  if (data.rememberMe) {
    const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), appConfig.cryptoSecret).toString()
    update(authDataKey, cipher)
  }
}

import React, { useEffect, useReducer } from 'react'
import { useInfluencerTargets } from '../../api/targets'
import PropTypes from 'prop-types'
import TargetItem, { targetModes } from './TargetItem'
import { simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'

const defaultTargetItem = {
  targetName: 'Не выбрана',
}

function TargetSelect({ influencerId, onSelect }) {
  const initialState = {
    targetList: null,
    isVisibleList: false,
    currentTarget: defaultTargetItem,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data: dataTargets, isSuccess: isSuccessTargets } = useInfluencerTargets(
    { influencerId },
    {
      enabled: Boolean(influencerId),
    },
  )

  useEffect(() => {
    if (isSuccessTargets && dataTargets) {
      setState({
        targetList: dataTargets,
        currentTarget: dataTargets[0] || defaultTargetItem,
      })
      onSelect(dataTargets[0] || defaultTargetItem)
    }
  }, [dataTargets, isSuccessTargets])

  const getToggleIcon = () => {
    return state.isVisibleList
      ? `${appConfig.cdnPath.iconsSvg}/arrow-up-black.svg`
      : `${appConfig.cdnPath.iconsSvg}/arrow-down-black.svg`
  }

  const handleToggleList = () => {
    setState({ isVisibleList: !state.isVisibleList })
  }

  const handleSelectItem = (target) => {
    setState({
      currentTarget: target,
      isVisibleList: false,
    })
    onSelect(target)
  }

  if (!state.targetList?.length) {
    return null
  }

  return (
    <div className="target-select mt-4">
      <div className="target-select-current">
        <span>Цель:</span>
        <div onClick={handleToggleList} className="target-select-current-body">
          <span>{state.currentTarget?.targetName}</span>
          <img className="arrow" src={getToggleIcon()} alt="" />
        </div>
      </div>
      {state.isVisibleList && (
        <div className="background-fill">
          <div className="target-select-list">
            <div
              key="defaultTarget"
              className="defaultTarget"
              onClick={() => handleSelectItem(defaultTargetItem)}
            >
              {defaultTargetItem.targetName}
            </div>
            {state.targetList?.map((item) => (
              <TargetItem
                key={item.id}
                targetData={item}
                mode={targetModes.show}
                onSelect={handleSelectItem}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

TargetSelect.defaultProps = {
  onSelect: () => {},
}

TargetSelect.propTypes = {
  influencerId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
}

export default TargetSelect

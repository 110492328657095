import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../../../constants/appConfig'
import { Button } from '../../../Layout/Button/Button'
import { PostContext, postModes } from '../../../../contexts/postContext'

function DeleteMediaIcon({ id, onDelete }) {
  const { mode, deleteMedia, cancelDeleteMedia, mediaIdToDelete } = useContext(PostContext)

  return mediaIdToDelete.includes(id) ? (
    <Button
      color="link"
      className="text-color-green d-flex align-items-center "
      onClick={() => {
        cancelDeleteMedia(id)
      }}
    >
      К удалению <span className="semiBold-17-24 ml-2">&times;</span>
    </Button>
  ) : (
    <img
      src={`${appConfig.cdnPath.iconsSvg}/basket-grey.svg`}
      onClick={() => {
        mode === postModes.editPost ? deleteMedia(id) : onDelete()
      }}
      alt=""
    />
  )
}

export default DeleteMediaIcon

DeleteMediaIcon.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

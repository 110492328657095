import React from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { PostPreview } from './PostPreview'
import clsx from 'clsx'
import { appConfig } from '../../../../constants/appConfig'

export function Vertical({
  main,
  secondaryImages,
  count,
  split,
  openGallery,
  imageParams = { w: 480 },
  influencerId,
}) {
  const maxWidth = count === 1 ? 439 : (432 + (count > 2 ? 8 : 0)) / (1 + split)
  const size = useWindowSize()
  const currWidth = (size.width * 0.916 - 8 + (count > 2 ? 8 : 0)) / (1 + split)
  const width = Math.min(maxWidth, currWidth)
  let reductionFactor = 1
  const style = {
    width: `${width * reductionFactor}px`,
  }

  const countShownSecondary = count - 1 // Minus main image
  const countHiddenSecondary = secondaryImages.length - countShownSecondary
  return (
    <>
      {main && (
        <div className="list-post-content-vertical content-padding">
          <div
            className="justify-content-center"
            style={{ width: secondaryImages.length === 0 && main.isVideo ? '100%' : 'auto' }}
          >
            <PostPreview
              path={
                main.isVideo
                  ? main.screenshotUrl
                  : `${appConfig.cdnPath.insiderWithoutProtocol}${main.path}`
              }
              style={style}
              isMain={true}
              isVideo={main.isVideo}
              params={imageParams}
              image={main}
              hasSecondary={secondaryImages.length > 0}
              onClick={() => openGallery(main.path)}
              influencerId={influencerId}
            />
          </div>
          {secondaryImages.length > 0 && (
            <div className="list-post-content-vertical-carousel">
              {secondaryImages.map(({ id, path, screenshotUrl, isVideo }, index) => {
                return index < countShownSecondary ? (
                  <div
                    key={id}
                    className={clsx({
                      additional: index === countShownSecondary - 1 && countHiddenSecondary > 0,
                    })}
                  >
                    <PostPreview
                      path={
                        isVideo
                          ? screenshotUrl
                          : `${appConfig.cdnPath.insiderWithoutProtocol}${path}`
                      }
                      imageClassName="w-100"
                      isVideo={isVideo}
                      params={imageParams}
                      onClick={() => openGallery(path)}
                      influencerId={influencerId}
                    />
                    {index === countShownSecondary - 1 && countHiddenSecondary > 0 && (
                      <div>+{countHiddenSecondary}</div>
                    )}
                  </div>
                ) : null
              })}
            </div>
          )}
        </div>
      )}
    </>
  )
}

Vertical.propTypes = {
  main: PropTypes.shape({
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    axisXSize: PropTypes.number,
    axisYSize: PropTypes.number,
    screenshotUrl: PropTypes.string,
    isVideo: PropTypes.bool,
  }).isRequired,
  secondaryImages: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, path: PropTypes.string.isRequired }),
  ).isRequired,
  count: PropTypes.number.isRequired,
  split: PropTypes.number.isRequired,
  openGallery: PropTypes.func.isRequired,
  imageParams: PropTypes.object,
  influencerId: PropTypes.string,
}

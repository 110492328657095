import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'

function TextArea({
  value,
  className,
  textareaClassName,
  onChange,
  autofocus,
  placeholder,
  showCounterLimit,
  maxlength,
  style = {},
  onFocus,
  onBlur,
}) {
  const handleChange = (e) => {
    const value = e.target.value
    if (maxlength >= 0 && showCounterLimit && value) {
      onChange(value.substring(0, maxlength))
    } else {
      onChange(value)
    }
  }

  const getCounterLimit = (value) => {
    if (maxlength >= 0 && showCounterLimit) {
      return maxlength - value?.length > 0 ? maxlength - value?.length : 0
    }
    return ''
  }

  const textareaRef = useRef()

  useEffect(() => {
    if (style.maxHeight) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight
    }
  }, [style.maxHeight])

  return (
    <div className={`countLimitWrap ${className}`}>
      <TextareaAutosize
        ref={textareaRef}
        className={`form-item ${textareaClassName}`}
        onChange={handleChange}
        autoFocus={autofocus}
        placeholder={placeholder}
        value={value}
        style={style}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {showCounterLimit && <span className="countLimitVal">{getCounterLimit(value)}</span>}
    </div>
  )
}

TextArea.defaultProps = {
  className: '',
  textareaClassName: '',
  autofocus: false,
  showCounterLimit: false,
  style: {},
  onFocus: () => {},
  onBlur: () => {},
}

TextArea.propTypes = {
  className: PropTypes.string,
  textareaClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
  showCounterLimit: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  maxlength: PropTypes.number,
  style: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default TextArea

import React from 'react'
import { Header } from '../../Header/Header'
import { nbsp } from '../../../constants/typography'
import { appConfig } from '../../../constants/appConfig'
import { Footer } from '../../Footer/Footer'
import { useHistory } from 'react-router-dom'

export function RatingInfo() {
  const history = useHistory()
  return (
    <>
      <div className="footer-padding">
        <Header
          needRight={false}
          needLeft={true}
          title="Что такое рейтинг?"
          onBackAction={() => history.goBack()}
        />
        <div className="content-padding">
          <div>
            <div className="bold-16-20 mb-2" id="rating">
              Рейтинг
            </div>
            <div className="medium-14-20">
              <div className="mb-1">
                Совершая покупки на <span className="text-color-green">Insider.space</span>, Вы
                накапливаете баллы.
              </div>
              Баллы даются за следующие действия:
              <ul className="list-black-circle">
                <li>Покупка подписки</li>
                <li>Продление подписки</li>
                <li>Донат</li>
                <li>Премиум чат</li>
              </ul>
              <div>
                Платформа сравнивает Ваши баллы с баллами других пользователей и составляет рейтинг
                подписчиков, который Вы можете увидеть в профиле у инфлюенсера.
              </div>
              <div className="mb-2">Пример:</div>
              <ul className="list-black-circle">
                <li className="text-color-green">
                  Top 5%{nbsp}
                  <span className="text-color-sub-title medium-14-20">
                    означает, что Вы входите в первые 5% самых активных подписчиков.
                  </span>
                </li>
                <li className="text-color-green">
                  Top 90%{nbsp}
                  <span className="text-color-sub-title medium-14-20">
                    означает, что 90% подписчиков инфлюенсера активнее Вас.
                  </span>
                </li>
              </ul>
              Просматривая своих подписчиков, инфлюенсер видит сначала подписчиков с наивысшим
              рейтингом.
              <br className="mb-1" />
              Инфлюенсер может проводить конкурсы, в которых рейтинг будет иметь значение.
              <hr className="my-3" />
            </div>
            <div className="bold-16-20 mb-2">Система рангов</div>
            <div className="medium-14-20 badges">
              Также на платформе реализована система рангов, которая позволяет классифицировать всех
              подписчиков определенного инфлюенсера.
              <br className="mb-1" />
              Система устанавливает иерархию 7 ступеней подписки на инфлюенсера:
              <div className="d-flex flex-row align-items-center mb-1">
                <img src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/1.svg`} alt="" />
                {nbsp}1 месяц
              </div>
              <div className="d-flex flex-row align-items-center mb-1">
                <img src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/2.svg`} alt="" />
                {nbsp}2 месяца
              </div>
              <div className="d-flex flex-row align-items-center mb-1">
                <img src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/3.svg`} alt="" />
                {nbsp}3 месяца
              </div>
              <div className="d-flex flex-row align-items-center mb-1">
                <img
                  src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/6.svg`}
                  alt=""
                  width="14px"
                />
                {nbsp}6 месяцев
              </div>
              <div className="d-flex flex-row align-items-center mb-1">
                <img src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/9.svg`} alt="" />
                {nbsp}9 месяцев
              </div>
              <div className="d-flex flex-row align-items-center mb-1">
                <img src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/12.svg`} alt="" />
                {nbsp}12 месяцев и более
              </div>
              <div className="d-flex flex-row align-items-start mb-1" id="rank-system">
                <img src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/top.svg`} alt="" />
                <span>
                  {nbsp}Попадание в <span className="text-color-green">Top 25</span> подписчиков
                  инфлюенсера
                </span>
              </div>
              <br />
              Ники подписчиков, которые приобрели Премиум чат с инфлюенсером, будут перманентно
              подсвечиваться фирменным цветом Insider.space в комментариях и личных сообщениях с
              этим инфлюенсером.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { WithStore, Slider, Slide } from 'pure-react-carousel'
import { appConfig } from '../../constants/appConfig'
import { VideoPlayer } from '../Player/VideoPlayer'
import { makeSecureLink } from '../../helpers'
import { usePrevious } from '../../hooks/usePrevious'
import { useWindowSize } from '../../hooks/useWindowSize'

function Slides({
  data,
  currentSlideIndex,
  imageParams,
  isFirstOpened,
  setIsFirstOpened,
  setFullScreenSlide,
  influencerId,
}) {
  const currentId = data[currentSlideIndex]?.id
  const prevCurrentSlideIndex = usePrevious(currentSlideIndex)
  const { height, width } = useWindowSize()
  useEffect(() => {
    if (prevCurrentSlideIndex >= 0) {
      setIsFirstOpened(false)
    }
  }, [currentSlideIndex])
  const renderSlide = ({ id, screenshotUrl, path, isVideo, axisXSize, axisYSize }, index) => {
    let img = path
    let style = {}
    if (!isVideo) {
      const params = { ...imageParams }
      img = makeSecureLink({ path, params: { ...params, w: 1440 } })
      img = `//${appConfig.cdnPath.insiderWithoutProtocol}${img}`
    } else {
      if (axisYSize > axisXSize) {
        style.height = 0.9 * height
        style.width = (axisXSize * style.height) / axisYSize
      } else {
        style.width = 0.9 * width
        style.height = (axisYSize * style.width) / axisXSize
      }
    }
    return (
      <Slide index={index + 1} key={index + 1}>
        {isVideo ? (
          <VideoPlayer
            id={id}
            style={style}
            isActiveSlide={currentSlideIndex === index}
            autoplay={isFirstOpened ? currentId : undefined}
            screenshotUrl={screenshotUrl}
            securityParams={imageParams}
            setFullScreenSlide={setFullScreenSlide}
            wrapperClass="galleryVideoWrapper"
            influencerId={influencerId}
            size={{ axisXSize, axisYSize }}
          />
        ) : (
          <img src={img} alt="" onClick={(e) => e.stopPropagation()} />
        )}
      </Slide>
    )
  }
  return <Slider>{data.map((element, index) => renderSlide(element, index))}</Slider>
}

export default WithStore(Slides, (state) => ({
  currentSlideIndex: state.currentSlide,
}))

Slides.propTypes = {
  data: PropTypes.array,
  currentSlideIndex: PropTypes.number,
  imageParams: PropTypes.object,
  isFirstOpened: PropTypes.bool,
  setIsFirstOpened: PropTypes.func,
  setFullScreenSlide: PropTypes.func,
  influencerId: PropTypes.string,
}

import { createContext } from 'react'
import PropTypes from 'prop-types'

export const ChatContext = createContext({
  send: () => {},
  disconnect: () => {},
  markAsRead: () => {},
  markAllRead: () => {},
  isConnecting: true,
  isConnected: false,
  newMessages: {},
  unreadMessagesCount: 0,
  unreadMessagesCountVip: 0,
  lastMessage: null,
  lastReadMessageId: null,
  reduceUnreadCount: () => {},
  updateIsRead: () => {},
  refreshHistory: false,
  setRefreshed: () => {},
})

ChatContext.Provider.propTypes = {
  send: PropTypes.func,
  disconnect: PropTypes.func,
  markAsRead: PropTypes.func,
  markAllRead: PropTypes.func,
  isConnecting: PropTypes.bool,
  isConnected: PropTypes.bool,
  newMessages: PropTypes.object,
  unreadMessagesCount: PropTypes.number,
  unreadMessagesCountVip: PropTypes.number,
  lastMessage: PropTypes.object,
  lastReadMessageId: PropTypes.string,
  reduceUnreadCount: PropTypes.func,
  updateIsRead: PropTypes.func,
  refreshHistory: PropTypes.bool,
  setRefreshed: PropTypes.func,
}

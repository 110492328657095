import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import LogoWelcome from './LogoWelcome'

const insider = `${appConfig.cdnPath.iconsSvg}/logo/insider.svg`
const space = `${appConfig.cdnPath.iconsSvg}/logo/space.svg`
const spinner = `${appConfig.cdnPath.iconsSvg}/logo/spinner.svg`

function LogoSpinner({
  isLoading = false,
  withLogo = false,
  size = 'auto',
  isFullName = false,
  isWelcome = false,
}) {
  return (
    <div className="logo-spinner d-flex align-items-center justify-content-center">
      <div
        className={`spinner ${isLoading ? 'loading' : ''} ${withLogo ? '' : 'bg-none'} ${
          isWelcome && 'welcome'
        }`}
      >
        {isWelcome ? <LogoWelcome /> : <img src={spinner} alt="" height={size} />}
      </div>
      {withLogo && (
        <>
          <img
            src={insider}
            height={size}
            alt=""
            style={{ marginLeft: parseInt(size) ? (size / 100) * 33 : 10 }}
          />
          {isFullName && <img src={space} alt="" style={{ marginBottom: -5 }} />}
        </>
      )}
    </div>
  )
}
export { LogoSpinner }

LogoSpinner.propTypes = {
  isLoading: PropTypes.bool,
  withLogo: PropTypes.bool,
  size: PropTypes.any,
  isFullName: PropTypes.bool,
  isWelcome: PropTypes.bool,
}

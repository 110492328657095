import React from 'react'
import PropTypes from 'prop-types'
import { PostPreview } from './PostPreview'
import { appConfig } from '../../../../constants/appConfig'

export function Horizontal({
  main,
  secondaryImages,
  count,
  split,
  openGallery,
  imageParams = { w: 480 },
  influencerId,
}) {
  const mainRatio = main.axisXSize / main.axisYSize

  const style = {
    height: `calc(((91.6vw - 8px * (${count} - 2))/ ${mainRatio}) * ${split})`,
    maxHeight: `calc(((440px - 8px * (${count} - 2))/ ${mainRatio}) * ${split})`,
  }

  const countShownSecondary = count - 1 // Minus main image
  const countHiddenSecondary = secondaryImages.length - countShownSecondary

  return (
    <>
      {main && (
        <div className="list-post-content-horizontal content-padding">
          <div className="list-post-content-horizontal-main">
            <PostPreview
              path={
                main.isVideo
                  ? main.screenshotUrl
                  : `${appConfig.cdnPath.insiderWithoutProtocol}${main.path}`
              }
              style={{ width: '100%' }}
              isVideo={main.isVideo}
              params={imageParams}
              image={main}
              hasSecondary={secondaryImages.length > 0}
              onClick={() => openGallery(main.path)}
              influencerId={influencerId}
            />
          </div>
          {secondaryImages.length > 0 && (
            <div className="list-post-content-horizontal-carousel">
              {secondaryImages.map(({ id, screenshotUrl, path, isVideo }, index) => {
                return index < countShownSecondary ? (
                  <div
                    key={id}
                    className={
                      index === countShownSecondary - 1 && countHiddenSecondary > 0
                        ? 'additional'
                        : ''
                    }
                  >
                    <PostPreview
                      path={
                        isVideo
                          ? screenshotUrl
                          : `${appConfig.cdnPath.insiderWithoutProtocol}${path}`
                      }
                      style={style}
                      isVideo={isVideo}
                      params={imageParams}
                      onClick={() => openGallery(path)}
                      influencerId={influencerId}
                    />
                    {index === countShownSecondary - 1 && countHiddenSecondary > 0 && (
                      <div>+{countHiddenSecondary}</div>
                    )}
                  </div>
                ) : null
              })}
            </div>
          )}
        </div>
      )}
    </>
  )
}

Horizontal.propTypes = {
  main: PropTypes.shape({
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    axisXSize: PropTypes.number.isRequired,
    axisYSize: PropTypes.number.isRequired,
    screenshotUrl: PropTypes.string,
    isVideo: PropTypes.bool,
  }).isRequired,
  secondaryImages: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, path: PropTypes.string.isRequired }),
  ).isRequired,
  count: PropTypes.number.isRequired,
  split: PropTypes.number.isRequired,
  openGallery: PropTypes.func.isRequired,
  imageParams: PropTypes.object,
  influencerId: PropTypes.string,
}

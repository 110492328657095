import React, { useContext, useEffect, useReducer } from 'react'
import Comments from '../../../components/Comments/Comments'
import { makeUrl, simpleReducer } from '../../../helpers'
import { ROUTES } from '../../../constants/routes'
import { appConfig } from '../../../constants/appConfig'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../../contexts/userContext'

function InsiderCommentsPage() {
  const { isInfluencer, setUserType, user, isAuthorized } = useContext(UserContext)
  const history = useHistory()
  const initialState = {
    follower: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (!isAuthorized) {
      history.push(makeUrl({ route: ROUTES.PAGE_404 }))
      return
    }

    if (isInfluencer) {
      setUserType(appConfig.userTypes.follower)
    }

    if (user?.follower?.id) {
      setState({ follower: user.follower })
    }
  }, [history, isAuthorized, isInfluencer, setUserType, user.follower])

  return state.follower ? <Comments followerViewer={state.follower} /> : null
}

export default InsiderCommentsPage

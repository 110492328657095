import React, { useEffect, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Footer } from '../Footer/Footer'
import { appConfig } from '../../constants/appConfig'
import TargetItem from './TargetItem'
import { useHistory, useLocation } from 'react-router-dom'
import { makeUrl, simpleReducer } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { useMutateTarget } from '../../api/targets'

function TargetList({ data }) {
  const history = useHistory()
  const location = useLocation()

  const activeList = useMemo(() => {
    return data?.filter((item) => !item.closed)
  }, [data])

  const closedList = useMemo(() => {
    return data?.filter((item) => item.closed)
  }, [data])

  const initialState = {
    showClosed: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (location.state?.showClosed) {
      setState({ showClosed: location.state?.showClosed })
    }
  }, [location.state])

  const { mutate: mutateTarget } = useMutateTarget()

  const handleClickCreate = () => {
    history.push(
      makeUrl({
        route: ROUTES.PROFILE_TARGETS,
        routeParams: { targetId: appConfig.targets.createId },
      }),
    )
  }

  const handleClickShowClosed = () => {
    setState({ showClosed: true })
  }

  const handleClose = (target) => {
    mutateTarget({
      data: {
        id: target.id,
        closed: true,
      },
    })
  }

  return (
    <>
      <div className="content content-padding footer-padding targets">
        <div className="description mt-3 mb-4">
          С помощью целей вы легко можете сообщить вашим подписчикам, на что вы хотите накопить.
        </div>
        <hr />
        {activeList?.length >= appConfig.targets.activeLimit ? (
          <div className="text-color-regular">У вас уже есть пять активных целей</div>
        ) : (
          <div
            className="d-flex flex-grow-1 align-items-center mb-4 mt-3"
            onClick={handleClickCreate}
            role="button"
          >
            <img
              src={`${appConfig.cdnPath.iconsSvg}/plus-around-green.svg`}
              width="44px"
              height="44px"
              alt=""
            />
            <div className="text-color-green semiBold-15-24 ml-2">Добавить цель</div>
          </div>
        )}
        <div className="targets-list">
          {activeList?.length > 0 ? (
            activeList?.map((item) => (
              <TargetItem key={item.id} targetData={item} onClose={handleClose} />
            ))
          ) : (
            <div className="text-color-regular medium-15-20">Нет активных целей</div>
          )}
          {closedList?.length > 0 && state.showClosed ? (
            <>
              <div className="subTitle mt-5">Закрытые</div>
              {closedList.map((item) => (
                <TargetItem key={item.id} targetData={item} />
              ))}
            </>
          ) : closedList?.length > 0 && !state.showClosed ? (
            <div className="text-color-regular text-center my-3" onClick={handleClickShowClosed}>
              Показать закрытые цели
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </>
  )
}

TargetList.propTypes = {
  data: PropTypes.object,
}

export default TargetList

import React, { useContext, useEffect, useMemo } from 'react'
import { Footer } from '../components/Footer/Footer'
import Notifications from '../components/Notifications/Notifications'
import { appConfig } from '../constants/appConfig'
import { UserContext } from '../contexts/userContext'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

function NotificationsPage() {
  const history = useHistory()
  const matchProfile = useRouteMatch(ROUTES.PROFILE_NOTIFICATIONS)
  const matchFollower = useRouteMatch(ROUTES.FOLLOWER_NOTIFICATIONS)
  const isInfluencerPage = useMemo(() => {
    return matchProfile?.isExact
  }, [matchProfile])
  const isFollowerPage = useMemo(() => {
    return matchFollower?.isExact
  }, [matchFollower])

  const { user, setUserType, isFollower, isInfluencer, isAuthorized } = useContext(UserContext)

  useEffect(() => {
    if (!isAuthorized) {
      history.push(ROUTES.AUTH)
    } else if (isFollower && isInfluencerPage) {
      if (user?.influencer) {
        setUserType(appConfig.userTypes.influencer)
      } else {
        history.push(ROUTES.PAGE_404)
      }
    } else if (isInfluencer && isFollowerPage) {
      setUserType(appConfig.userTypes.follower)
    }
  }, [isAuthorized, setUserType, isFollower, isFollowerPage, isInfluencer, isInfluencerPage])

  return (
    <>
      <header className="mb-4 notifications-title">Уведомления</header>
      <Notifications />
      <Footer />
    </>
  )
}

export default NotificationsPage

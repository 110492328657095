import React from 'react'
import PropTypes from 'prop-types'
import TabContentEmpty, { types as tabContentTypes } from '../Tabs/TabContentEmpty'

export function Marketplace() {
  return <TabContentEmpty type={tabContentTypes.marketplace} />
}

Marketplace.propTypes = {
  products: PropTypes.array.isRequired,
}

import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import { UploadMaterialModal } from '../Upload'
import { uploadChatFileTypes } from '../../constants/files'
import { handleFile } from '../../api/upload'
import { deleteWelcomeMedia } from '../../api/media'
import { LogoSpinner } from '../Layout'

function WelcomeMediaBlock({ className, user }) {
  const initialState = {
    mediaFile: null,
    isOpenUploadModal: false,
    loading: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (user?.influencer?.welcomeMediaUrl) {
      setState({ mediaFile: `https://${user?.influencer?.welcomeMediaUrl}` })
    }
  }, [user])

  const handleAddClick = () => setState({ isOpenUploadModal: true })

  const upload = async ({ file, callback = () => {}, onProgress = () => {}, type = null }) =>
    await handleFile({
      file,
      data: { mediaTypeDestination: appConfig.media.mediaTypeDestinations.welcomeMedia },
      callback,
      onProgress,
      type,
    })

  const handleFinishUpload = ({ downloadUrl }) => {
    setState({ mediaFile: `https://${downloadUrl}`, isOpenUploadModal: false })
  }

  const handleDeleteClick = async () => {
    try {
      setState({ loading: true })
      await deleteWelcomeMedia()
      setState({ mediaFile: null, loading: false })
    } catch (e) {
      console.log('e', e)
      setState({ loading: false })
    }
  }

  if (state.loading) {
    return (
      <div className="my-2">
        <LogoSpinner isLoading size={25} />
      </div>
    )
  }

  return (
    <>
      {state.mediaFile ? (
        <div className={`${className} uploadedWelcomeMedia`}>
          <img
            src={`${appConfig.cdnPath.iconsSvg}/cross-green.svg`}
            className="close"
            onClick={handleDeleteClick}
            alt=""
          />
          <img src={state.mediaFile} alt="" className="welcomeImage" />
        </div>
      ) : (
        <div className={`uploadWelcomeBlock ${className}`}>
          <div className="frame">
            <div className="title">Фото-приветствие</div>
            <div className="addButton" onClick={handleAddClick}>
              + Добавить
            </div>
          </div>
        </div>
      )}
      <UploadMaterialModal
        open={state.isOpenUploadModal}
        toggle={() => setState({ isOpenUploadModal: false })}
        uploadMethod={upload}
        onCancel={() => setState({ isOpenUploadModal: false })}
        types={Object.keys(uploadChatFileTypes)}
        onFinishUpload={handleFinishUpload}
      />
    </>
  )
}

WelcomeMediaBlock.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
}

export default WelcomeMediaBlock

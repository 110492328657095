import React, { useEffect, useReducer } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { Footer } from '../../components/Footer/Footer'
import { deleteCard, useCardList } from '../../api/payment'
import { simpleReducer } from '../../helpers'
import CardsItem from '../../components/Payment/CardsItem'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import { getSubscriptionsFollower } from '../../api/subscribe'
import ErrorModal from '../../components/Modal/ErrorModal'
import ButtonAddWithAgree from '../../components/Follower/ButtonAddWithAgree'

function FollowerPaymentCardsPage() {
  const location = useLocation()

  const initialState = {
    cardList: [],
    subscriptions: null,
    activeCard: null,
    isDeleteModalOpen: false,
    error: false,
    confirmModalMessage: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data: cardList, isSuccess: isSuccessCards, refetch: refetchCardList } = useCardList()

  useEffect(() => {
    if (isSuccessCards && cardList) {
      setState({ cardList })
    }
  }, [isSuccessCards, cardList])

  const getCardInfo = (item) => `${item?.cardType} ${item?.pan}`

  const deleteActiveCard = async () => {
    try {
      await deleteCard(state.activeCard?.id)
      const cardListCopy = [...state.cardList]
      const index = cardListCopy.findIndex((card) => card.id === state.activeCard.id)
      cardListCopy.splice(index, 1)
      setState({
        isDeleteModalOpen: false,
        confirmModalMessage: null,
        error: false,
        cardList: cardListCopy,
      })
      refetchCardList()
    } catch (e) {
      console.log(e)
      setState({
        isDeleteModalOpen: false,
        confirmModalMessage: null,
        error: true,
      })
    }
  }

  const getConfirmModalMessage = (cardSubscriptions, hasMoreCards) => (
    <div>
      При удалении у данных подписок будет удалена карта и выключен рекуррентный платеж
      <ul className="follower-payment-cards-message mt-2 mb-0">
        {cardSubscriptions.map((subscription) => (
          <li key={subscription.influencerId}>{subscription.influencerNickname}</li>
        ))}
      </ul>
      {hasMoreCards && (
        <div className="mt-2">
          Вы можете{' '}
          <Link className="text-color-green" to={ROUTES.FOLLOWER_SUBSCRIPTIONS}>
            привязать другую карту
          </Link>{' '}
          в управлении подписками
        </div>
      )}
    </div>
  )

  const handleCheckSubscriptions = async () => {
    const subscriptions =
      state.subscriptions || (await getSubscriptionsFollower())?.subscriptions || []
    if (!state.subscriptions?.length) {
      setState({ subscriptions })
    }
    const cardSubscriptions = subscriptions.filter(
      (subscription) => subscription.cardId === state.activeCard?.id,
    )

    //Если нет подписок, привязанных к карте - удаляем её сразу
    if (!cardSubscriptions?.length) {
      await deleteActiveCard()
    } else {
      //Есть подписки - проверяем, есть ли у человека ещё карты и показываем диалог
      setState({
        isDeleteModalOpen: false,
        confirmModalMessage: getConfirmModalMessage(cardSubscriptions, state.cardList?.length > 1),
      })
    }
  }

  return (
    <>
      <Header
        title="Способы оплаты"
        needRight={false}
        to={location.state?.backTo || ROUTES.FOLLOWER}
      />
      <div className="content content-padding follower-payment-cards footer-padding">
        <div className="title mb-3 mt-3">Привязанные карты</div>
        <ButtonAddWithAgree />
        {state.cardList.map((item) => (
          <CardsItem
            item={item}
            key={item.id}
            onDelete={() => {
              setState({
                activeCard: item,
                isDeleteModalOpen: true,
              })
            }}
          />
        ))}
      </div>
      <Footer />
      <ConfirmModal
        message={`Вы действительно хотите удалить карту ${getCardInfo(state.activeCard)}?`}
        isOpen={state.isDeleteModalOpen}
        onCancel={() => setState({ isDeleteModalOpen: false })}
        okLabel="Удалить"
        onOk={handleCheckSubscriptions}
        cancelLabel="Отменить"
      />
      <ConfirmModal
        isOpen={state.confirmModalMessage}
        message={state.confirmModalMessage}
        onCancel={() => {
          setState({ confirmModalMessage: null })
        }}
        okLabel="Удалить"
        onOk={deleteActiveCard}
        cancelLabel="Отменить"
      />
      <ErrorModal
        isOpen={state.error}
        toggle={() => setState({ error: false })}
        message="Не удалось удалить карту"
      />
    </>
  )
}

export default FollowerPaymentCardsPage

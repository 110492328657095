import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { getCurrentUser } from '../../api/users'
import { changeSubscriptionPrice } from '../../api/subscribe'

import clsx from 'clsx'
import { Input } from '../Layout/Input/Input'
import { appConfig } from '../../constants/appConfig'
import { nbsp, rub } from '../../constants/typography'
import { simpleReducer } from '../../helpers'
import { Button } from '../Layout/Button/Button'

const { types: periodTypes, periodLabels, periodMonthValue } = appConfig.subscribe
const periodNotices = {
  [periodTypes.oneMonthKey]: 'стоимость для одного месяца подписки',
  [periodTypes.threeMonthKey]: 'стоимость для трех месяцев подписки',
  [periodTypes.twelveMonthsKey]: 'стоимость для двенадцати месяцев подписки',
}
const ERROR_COST = 'Стоимость подписки может быть от 0 до '

const SetSubscriptionAmount = ({ onAfterSave }) => {
  const initialState = {
    user: null,
    currentPeriod: periodTypes.oneMonthKey,
    currentCost: 0,
    costList: {
      [periodTypes.oneMonthKey]: 0,
      [periodTypes.threeMonthKey]: 0,
      [periodTypes.twelveMonthsKey]: 0,
    },
    error: null,
    isSaving: false,
    agentCommission: 0,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const user = await getCurrentUser()
    if (user?.influencer?.id) {
      setState({
        user,
        currentCost: user.influencer.subscription?.oneMonthCost || 0,
        costList: {
          [periodTypes.oneMonthKey]: user.influencer.subscription?.oneMonthCost || 0,
          [periodTypes.threeMonthKey]: user.influencer.subscription?.threeMonthCost || 0,
          [periodTypes.twelveMonthsKey]: user.influencer.subscription?.twelveMonthCost || 0,
        },
        agentCommission: (user.influencer.subscription?.agentCommission || 0) / 100,
      })
    }
  }

  const getPeriodLimit = (periodKey) => {
    if (periodKey === periodTypes.oneMonthKey) {
      return appConfig.subscribe.firstLimit
    } else if (periodKey === periodTypes.threeMonthKey) {
      return state.costList[periodTypes.oneMonthKey] * 3
    } else if (periodKey === periodTypes.twelveMonthsKey) {
      return state.costList[periodTypes.threeMonthKey] * 4
    }
    return 0
  }

  const isValidCost = (value, periodKey) => {
    const cost = parseInt(value)
    return 0 <= cost && cost <= getPeriodLimit(periodKey)
  }

  const getErrorMessage = (maxCost) => `${ERROR_COST} ${maxCost} ${rub}`

  const handleChangeInput = (value) => {
    const digits = value?.replace(rub, '').replace(/([^\d])/, '')
    setState({
      currentCost: digits ? parseInt(digits) : digits,
      error: isValidCost(digits, state.currentPeriod)
        ? null
        : getErrorMessage(getPeriodLimit(state.currentPeriod)),
    })
  }

  const getProfit = (value, periodKey) => {
    const cost = parseInt(value)
    if (cost >= 0) {
      return `${Math.floor(
        (cost - cost * state.agentCommission) / periodMonthValue[periodKey],
      )} ${rub}`
    }
    return ''
  }

  const handlePeriodClick = (periodKey) => {
    if (state.error === null) {
      setState({
        costList: {
          ...state.costList,
          [state.currentPeriod]: state.currentCost,
        },
        currentPeriod: periodKey,
        currentCost: state.costList[periodKey],
        error: null,
        isSaving: false,
      })
    }
  }

  useEffect(() => {
    setState({
      error: isValidCost(state.currentCost, state.currentPeriod)
        ? null
        : getErrorMessage(getPeriodLimit(state.currentPeriod)),
    })
  }, [state.currentPeriod, state.currentCost])

  useEffect(() => {
    if (state.currentPeriod === periodTypes.twelveMonthsKey && state.isSaving) {
      if (!isValidCost(state.costList[periodTypes.oneMonthKey], periodTypes.oneMonthKey)) {
        handlePeriodClick(periodTypes.oneMonthKey)
      } else if (
        !isValidCost(state.costList[periodTypes.threeMonthKey], periodTypes.threeMonthKey)
      ) {
        handlePeriodClick(periodTypes.threeMonthKey)
      } else {
        saveData()
      }
    }
  }, [state.costList])

  const handleSave = () => {
    switch (state.currentPeriod) {
      case periodTypes.oneMonthKey:
        handlePeriodClick(periodTypes.threeMonthKey)
        break
      case periodTypes.threeMonthKey:
        handlePeriodClick(periodTypes.twelveMonthsKey)
        break
      case periodTypes.twelveMonthsKey:
        if (state.error === null) {
          setState({
            costList: {
              ...state.costList,
              [state.currentPeriod]: state.currentCost,
            },
            isSaving: true,
          })
        }
        break
    }
  }

  const saveData = async () => {
    let data = []
    Object.keys(state.costList).forEach((periodKey) => {
      data.push({
        subscriptionTypeKey: periodKey,
        subscriptionPrice: state.costList[periodKey],
      })
    })
    await changeSubscriptionPrice(data)
    onAfterSave()
  }

  const periodHasDiscount = (periodKey) => {
    if (periodKey === periodTypes.oneMonthKey) {
      return false
    }
    return getPeriodLimit(periodKey) > state.costList[periodKey]
  }

  const renderPeriod = (periodKey, index) => {
    return (
      <div
        key={periodKey}
        onClick={() => handlePeriodClick(periodKey)}
        className={clsx('subscribe-offers-item rounded ', {
          ' subscribe-offers-active': state.currentPeriod === periodKey,
        })}
        role="button"
        tabIndex={index}
      >
        <div>{periodLabels[periodKey]} /</div>
        {periodHasDiscount(periodKey) && (
          <div className="subscribe-offers-item-discount">
            {getPeriodLimit(periodKey)} {rub}
          </div>
        )}
        <div>
          {state.costList[periodKey]} {rub}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="content content-padding footer-padding">
        <div className="subscribe-offers mt-3">{Object.values(periodTypes).map(renderPeriod)}</div>
        <div className="bold-17-24 my-3">Установите стоимость подписки</div>
        <div className="medium-13-20 mb-2 text-color-regular">
          Старайтесь объективно оценивать стоимость, чтобы не отпугнуть своих фанатов.
        </div>
        <div className="medium-13-20 mb-4 text-color-regular">
          Вы всегда можете изменить стоимость подписки в настройках профиля или в настройках
          приложения.
        </div>

        <hr width="100%" size="2" className="mb-3" />

        <div className="semiBold-15-24 my-3 text-left">
          Стоимость, которую видят твои подписчики
        </div>
        <Input
          type="number"
          onChange={handleChangeInput}
          value={state.currentCost}
          autofocus={true}
          inputClassName="pr-3"
          placeholder="Введите стоимость"
          prefix={rub}
        />
        {state.error && <div className="text-danger">{state.error}</div>}

        <div className="medium-13-20 mt-1 mb-4 text-color-regular">
          Установите{nbsp}
          <span className="medium-15-20 text-color-sub-title">
            {periodNotices[state.currentPeriod]}
          </span>
          {nbsp}на ваш профиль.
        </div>

        <div className="semiBold-15-24 my-3 text-left">Твой ежемесячный доход с подпичика</div>
        <div className="inputMark semiBold-15-24 text-left">
          {getProfit(state.currentCost, state.currentPeriod)}
        </div>
        <Button
          onClick={handleSave}
          className="my-2 semiBold-15-24"
          disable={state.currentPeriod === periodTypes.twelveMonthsKey && state.isSaving}
        >
          {state.currentPeriod === periodTypes.twelveMonthsKey ? 'Сохранить' : 'Далее'}
        </Button>
      </div>
    </>
  )
}

SetSubscriptionAmount.defaultProps = {
  onAfterSave: () => {},
}

SetSubscriptionAmount.propTypes = {
  onAfterSave: PropTypes.func.isRequired,
}

export default SetSubscriptionAmount

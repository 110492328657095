import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { LayoutContext } from '../contexts/layoutContext'
import { appConfig } from '../constants/appConfig'
import { useWindowSize } from '../hooks/useWindowSize'
import { useScroll } from '../hooks/useScroll'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

function LayoutProvider({ children }) {
  const location = useLocation()
  const matchChats = useRouteMatch(ROUTES.PROFILE_CHATS)
  const [helmetPreloadLinks, setHelmetPreloadLinks] = useState([])
  const { height } = useWindowSize()
  const { scrollToTop } = useScroll({})

  useLayoutEffect(() => {
    if (!matchChats?.params?.interlocutorId) {
      scrollToTop()
    }
  }, [scrollToTop, location.pathname, matchChats])

  useEffect(() => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', '${height}px')
    return () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', '100vh')
    }
  }, [height])

  return (
    <LayoutContext.Provider value={{ helmetPreloadLinks, setHelmetPreloadLinks }}>
      <Helmet htmlAttributes={{ lang: 'ru' }}>
        <meta charSet="utf-8" />
        <meta name="description" content="Insider" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <title>{appConfig.title}</title>
        <link rel="preconnect" href={`${appConfig.cdnPath.root}`} crossOrigin />
        <link
          rel="preload"
          as="image"
          href={`${appConfig.cdnPath.iconsSvg}/black-arrow-left.svg`}
        />
        {helmetPreloadLinks &&
          helmetPreloadLinks.map((href, index) => (
            <link rel="preload" as="image" href={href} key={`preload-${index}`} />
          ))}
      </Helmet>
      <main className="h-100 d-flex flex-column">{children}</main>
    </LayoutContext.Provider>
  )
}

export default LayoutProvider

LayoutProvider.propTypes = {
  children: PropTypes.node,
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { TabsEditable } from './TabsEditable'
import { Button } from '../Layout/Button/Button'
import { Modal } from 'reactstrap'
import { ROUTES } from '../../constants/routes'
import { nbsp } from '../../constants/typography'
import { makeUrl, numberDeclension } from '../../helpers'
import queryString from 'query-string'
import { InfluencerAvatar } from './InfluencerAvatar'
import { logicalFlows, useLogicalFlow } from '../../hooks/useLogicalFlow'
import { appConfig } from '../../constants/appConfig'
import { remove } from '../../api/storage'
import { storageKey as followersListKey } from '../../screens/profile/followerList'
import { storageKey as randomFollowerKey } from '../../screens/profile/randomFollowers'

export function Profile({ profile, onAddDescription, influencerAvatar, avatars, background }) {
  const {
    about,
    products,
    shortDescription,
    influencerFullName,
    followersCount,
    influencerNick,
    publishedPostsCount,
    socialLinks,
    id: influencerId,
  } = profile
  const history = useHistory()
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false)
  const [isBackModalOpen, setIsBackModalOpen] = useState(false)
  const { tabName } = queryString.parse(location.search)
  const [defaultTabName, setDefaultTabName] = useState(tabName)
  const { getFlowData, delFlowData } = useLogicalFlow()

  useEffect(() => {
    getFlowData(logicalFlows.profileTabName.id, (result) => {
      if (Object.values(appConfig.tabCodes || {}).includes(result?.tabName)) {
        setDefaultTabName(result.tabName)
        delFlowData(logicalFlows.profileTabName.id)
      }
    })
  }, [])

  const handleClickFollowers = () => {
    remove(followersListKey)
    remove(randomFollowerKey)
    history.push(makeUrl({ route: ROUTES.PROFILE_FOLLOWER_LIST }))
  }

  return (
    <div className="content footer-padding">
      <InfluencerAvatar
        bgUrl={background}
        photoUrl={influencerAvatar}
        onEditAvatar={() => setIsAvatarModalOpen(true)}
        onEditBackground={() => setIsBackModalOpen(true)}
        influencerId={influencerId}
      />
      <div className="d-flex justify-content-between content-margin">
        <span className="d-flex bold-17-24">{influencerFullName}</span>
        <span className="d-flex medium-13-20 text-color-regular" onClick={handleClickFollowers}>
          <b className="text-color-sub-title">{followersCount}</b>
          {nbsp}
          {numberDeclension(followersCount, ['подписчик', 'подписчика', 'подписчиков'])}
        </span>
      </div>
      {!shortDescription && (
        <div
          className="my-3 semiBold-13-20 text-color-regular content-margin text-color-green pointer"
          onClick={onAddDescription}
        >
          Добавить описание
        </div>
      )}
      {shortDescription && (
        <>
          <div className="mt-2 medium-13-20 text-color-regular content-margin">
            {shortDescription}
          </div>
          <div
            className="mb-3 semiBold-13-20 text-color-regular content-margin text-color-green pointer"
            onClick={onAddDescription}
          >
            Редактировать
          </div>
        </>
      )}
      <Button
        className="content-margin semiBold-15-24"
        onClick={() => history.push(ROUTES.PROFILE_SETTINGS)}
        color="dark"
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
      >
        Настройки профиля
      </Button>
      <TabsEditable
        defaultTab={defaultTabName}
        products={products}
        about={about}
        socialLinks={socialLinks}
        author={influencerNick}
        avatar={influencerAvatar}
        influencerId={influencerId}
        publishedPostsCount={publishedPostsCount}
      />
      <Modal
        isOpen={isAvatarModalOpen}
        centered={true}
        size="sm"
        modalClassName="modal-bottom"
        toggle={() => {
          setIsAvatarModalOpen(false)
        }}
      >
        <div className="py-4">
          <div className="modal-white-panel mb-2">
            {avatars.length > 0 && (
              <>
                <div
                  className="semiBold-15-24 cursor-pointer"
                  onClick={() => history.push(ROUTES.PROFILE_SELECT_AVATAR)}
                >
                  Посмотреть фото
                </div>
                <div className="content-padding">
                  <hr />
                </div>
              </>
            )}
            <div
              className="semiBold-15-24 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: ROUTES.PROFILE_ADD_AVATAR,
                  state: { backTo: ROUTES.PROFILE },
                })
              }
            >
              Новое фото
            </div>
          </div>
          <Button
            className="semiBold-15-24 cursor-pointer"
            onClick={() => {
              setIsAvatarModalOpen(false)
            }}
          >
            Отмена
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isBackModalOpen}
        centered={true}
        size="sm"
        modalClassName="modal-bottom"
        toggle={() => {
          setIsBackModalOpen(false)
        }}
      >
        <div className="py-4">
          <div className="modal-white-panel mb-2">
            <div
              className="semiBold-15-24 cursor-pointer"
              onClick={() =>
                history.push({
                  pathname: ROUTES.PROFILE_ADD_BACKGROUND,
                  state: { backTo: ROUTES.PROFILE },
                })
              }
            >
              Новое фото
            </div>
          </div>
          <Button
            className="semiBold-15-24 cursor-pointer"
            onClick={() => {
              setIsBackModalOpen(false)
            }}
          >
            Отмена
          </Button>
        </div>
      </Modal>
    </div>
  )
}

Profile.defaultProps = {
  onAddDescription: () => {},
  avatars: [],
}

Profile.propTypes = {
  onAddDescription: PropTypes.func,
  profile: PropTypes.object.isRequired,
  influencerAvatar: PropTypes.string,
  avatars: PropTypes.array,
  background: PropTypes.string,
}

import { appConfig } from '../../../constants/appConfig'
import React from 'react'
import PropTypes from 'prop-types'

export function RatingProgress({ item: { subscribedMonths, userHadVip, userScoreTop } }) {
  return (
    <div className="d-flex flex-row align-items-center badges">
      <img
        src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/1.svg`}
        alt=""
        className={subscribedMonths < 1 ? 'disabled' : ''}
        width="16px"
      />
      <img
        src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/2.svg`}
        alt=""
        className={subscribedMonths < 2 ? 'disabled ml-3' : 'ml-3'}
        width="16px"
      />
      <img
        src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/3.svg`}
        alt=""
        className={subscribedMonths < 3 ? 'disabled ml-3' : 'ml-3'}
        width="16px"
      />
      <img
        src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/6.svg`}
        alt=""
        className={subscribedMonths < 6 ? 'disabled ml-3' : 'ml-3'}
        width="16px"
      />
      <img
        src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/9.svg`}
        alt=""
        className={subscribedMonths < 9 ? 'disabled ml-3' : 'ml-3'}
        width="16px"
      />
      <img
        src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/12.svg`}
        alt=""
        className={subscribedMonths < 12 ? 'disabled ml-3' : 'ml-3'}
        width="16px"
      />
      <div className="mx-3 delimiter d-flex align-items-center badges" style={{ height: '12px' }}>
        <img
          src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/crown.svg`}
          alt=""
          className={!userHadVip ? 'disabled ml-3' : 'ml-3'}
          width="16px"
        />
        <img
          src={`${appConfig.cdnPath.iconsSvg}/profileicons/20x20/top.svg`}
          alt=""
          className={!userScoreTop ? 'disabled ml-3' : 'ml-3'}
          width="16px"
        />
      </div>
    </div>
  )
}

RatingProgress.propTypes = {
  item: PropTypes.shape({
    subscribedMonths: PropTypes.number,
    userHadVip: PropTypes.bool,
    userScoreTop: PropTypes.number,
  }),
}

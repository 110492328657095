import React from 'react'
import { Header } from '../../components/Header/Header'
import { ROUTES } from '../../constants/routes'
import { Footer } from '../../components/Footer/Footer'
import LinkWithArrow from '../../components/Layout/LinkWithArrow/LinkWithArrow'
import { useHistory } from 'react-router-dom'

function FollowerPaymentInfoPage() {
  const history = useHistory()
  return (
    <>
      <Header title="Платежная информация" needRight={false} to={ROUTES.FOLLOWER} />
      <div className="content content-padding follower-payment-info">
        <LinkWithArrow
          onClick={() => history.push(ROUTES.FOLLOWER_PAYMENT_HISTORY)}
          title="История платежей"
          className="follower-payment-info-item"
        />
        <LinkWithArrow
          onClick={() => history.push(ROUTES.FOLLOWER_PAYMENT_CARDS)}
          title="Карты оплаты"
        />
      </div>
      <Footer />
    </>
  )
}

export default FollowerPaymentInfoPage

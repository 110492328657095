import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Radio from '../../../Layout/Radio/Radio'
import DeleteMediaIcon from './DeleteMediaIcon'
import { PostContext } from '../../../../contexts/postContext'
import { useWebpLinks } from '../../../../hooks/useWebpLinks'
import { appConfig } from '../../../../constants/appConfig'

export function ImageLine({ value, mainImageId, onChange, path, onDelete, secureParams }) {
  const { mediaIdToDelete } = useContext(PostContext)
  const { link: img, oldLink } = useWebpLinks({
    path: `//${appConfig.cdnPath.insiderWithoutProtocol}${path}`,
    params: secureParams,
  })
  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between mb-2">
        <Radio
          name="media"
          value={value}
          label={value === mainImageId ? 'Основной материал' : 'Сделать основным'}
          checked={value === mainImageId}
          onChange={onChange}
        />
        <DeleteMediaIcon id={value} onDelete={onDelete} />
      </div>
      <picture>
        <source srcSet={img} type="image/webp" />
        <source srcSet={oldLink} />
        <img
          src={oldLink}
          alt=""
          width="100%"
          className={clsx('w-100-image', { disabled: mediaIdToDelete.includes(value) })}
        />
      </picture>
      <hr />
    </div>
  )
}

ImageLine.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
}

ImageLine.propTypes = {
  value: PropTypes.any,
  mainImageId: PropTypes.any,
  path: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  secureParams: PropTypes.object,
}

import React, { useEffect, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Header } from '../../Header/Header'
import CropImage from '../CropImage'
import { simpleReducer } from '../../../helpers'
import { dataURLtoFile } from '../../../helpers/file'
import { Modal } from 'reactstrap'
import { PageLoading } from '../../Layout/PageLoading'

function AddImage({ upload, title = '', isAvatar = false, isLoading = false }) {
  const location = useLocation()
  const uploadRef = useRef()
  const initialState = {
    content: '',
    file: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const setImage = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (event) => {
      setState({
        content: event.target.result,
        file,
      })
    }
    reader.readAsDataURL(file)
  }

  const setCroppedImage = (croppedContent) => {
    const file = dataURLtoFile(croppedContent, state.file?.name)
    setState({ file })
  }

  const openFileGallery = () => {
    if (uploadRef.current) {
      uploadRef.current.click()
    }
  }

  const uploadFile = () => {
    if (!state.file) {
      openFileGallery()
    } else {
      upload(state.file)
    }
  }

  useEffect(() => {
    setTimeout(() => openFileGallery(), 100)
  }, [uploadRef])

  return (
    <>
      <input
        ref={uploadRef}
        type="file"
        name="file"
        id="upload-avatar-button"
        accept=".png, .jpg, .jpeg"
        onChange={setImage}
        hidden
      />

      <div className="layout3">
        <Header
          title={title}
          needRight={false}
          to={location?.state?.backTo}
          isWhiteTheme
          needLeft={false}
        />
        <CropImage isAvatar={isAvatar} image={state.content} onCrop={setCroppedImage} />
        <footer className="layout3-footer">
          {!isLoading && (
            <div className="upload-button w-100" onClick={uploadFile}>
              {state.file ? 'Сохранить' : 'Добавить'}
            </div>
          )}
        </footer>
        <Modal
          isOpen={isLoading}
          toggle={() => {}}
          centered={true}
          size="sm"
          contentClassName={'hide-background'}
        >
          <PageLoading hasFooter hasHeader className="mt-5 mb-5" />
        </Modal>
      </div>
    </>
  )
}
AddImage.propTypes = {
  upload: PropTypes.func.isRequired,
  title: PropTypes.string,
  isAvatar: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default AddImage

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const colors = {
  regular: 'regular',
  dark: 'dark',
  grey: 'grey',
}

const stylesColor = {
  [colors.regular]: 'button-regular',
  [colors.dark]: 'button-dark',
  [colors.grey]: 'button-grey',
}
const stylesDisableColor = {
  [colors.regular]: 'button-regular-disable',
  [colors.dark]: 'button-dark-disable',
  [colors.grey]: 'button-grey',
}

export function Button({ children, disable, color, className, onClick }) {
  return (
    <div
      onClick={disable ? () => {} : onClick}
      className={clsx(stylesColor[color], className, disable && (stylesDisableColor[color] || ''))}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  )
}

Button.defaultProps = {
  disable: false,
  color: colors.regular,
  onClick: () => {},
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(colors)),
  onClick: PropTypes.func,
  className: PropTypes.string,
  disable: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

export function Input({
  type = 'text',
  value,
  onChange,
  placeholder = 'Введите имя',
  autofocus = false,
  inputClassName = '',
  iconClassName = '',
  hideDeleteIcon = false,
  onKeyPress = () => {},
  disabled,
  showCounterLimit = false,
  maxlength,
  prefix = '',
}) {
  const handleChange = (e) => {
    const value = e.target.value
    if (maxlength >= 0 && showCounterLimit && value) {
      onChange(value.substring(0, maxlength))
    } else {
      onChange(value)
    }
  }

  const isEmptyValue = value === null || value === undefined || value === ''

  const getCounterLimit = (value) => {
    if (maxlength >= 0 && showCounterLimit) {
      return maxlength - value?.length > 0 ? maxlength - value?.length : 0
    }
    return ''
  }

  return (
    <div>
      <span className={`deleteicon countLimitWrap ${iconClassName}`}>
        <input
          value={value}
          type={type}
          className={clsx('auth-name', prefix && 'pl-4', inputClassName)}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          autoFocus={autofocus}
          disabled={disabled}
        />
        {!isEmptyValue && !hideDeleteIcon && !disabled && (
          <span className="iconButton" onClick={() => onChange('')} />
        )}
        {showCounterLimit && <span className="countLimitVal">{getCounterLimit(value)}</span>}
        {prefix && <span className="prefix">{prefix}</span>}
      </span>
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  autofocus: PropTypes.bool,
  showCounterLimit: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  hideDeleteIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  maxlength: PropTypes.number,
  prefix: PropTypes.string,
}

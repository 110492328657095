import React from 'react'
import { RatingMain } from '../../components/Follower/Rating/RatingMain'
import { RatingInfo } from '../../components/Follower/Rating/RatingInfo'
import { Footer } from '../../components/Footer/Footer'
import { RatingSubscriptions } from '../../components/Follower/Rating/RatingSubscriptions'
import { Switch, Route } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

function RatingPage() {
  return (
    <>
      <Switch>
        <Route path={ROUTES.FOLLOWER_RATING_INFO} component={RatingInfo} />
        <Route path={ROUTES.FOLLOWER_RATING_SUBSCRIPTIONS} component={RatingSubscriptions} />
        <Route path="/" component={RatingMain} />
      </Switch>
      <Footer />
    </>
  )
}

export default RatingPage

import React from 'react'
import PropTypes from 'prop-types'

function Register({ email }) {
  return (
    <>
      <div className="bold-16-24 mb-3 text-color-sub-title">Завершение регистрации</div>
      Вам на почту <span className="text-color-green">{email}</span> было отправлено письмо. Для
      завершения регистрации перейдите по ссылке из письма.
      <div className="text-color-sub-title mt-3">Пожалуйста, проверяйте папку спам!</div>
    </>
  )
}

Register.propTypes = {
  email: PropTypes.string,
}

export default Register

import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '../contexts/userContext'
import {
  getUser,
  getUserType,
  setUser as setUserInStorage,
  setUserType as setUserTypeInStorage,
} from '../api/users'
import { hasToken, refresh } from '../api/auth'
import { appConfig } from '../constants/appConfig'
import { simpleReducer } from '../helpers'

function UserProvider({ children }) {
  const [user, setUser] = useState(getUser())
  const [userType, setUserType] = useState(getUserType())
  const [idsHashState, setIdsHashState] = useReducer(simpleReducer, { idsHashData: null })

  useEffect(() => {
    setUserInStorage(user)
    setUserTypeInStorage(userType)
  }, [user, userType])

  /**
   * При обновлении страницы, если уже есть данные авторизации, дергаем рефреш токена
   * Нужно для случаев, когда пользователь изменил свой токен на другом устройстве и он стал не актуальный
   */
  useEffect(() => {
    const referer = document.referrer
    const { email } = getUser()
    if ((!referer || referer.includes('insider')) && hasToken() && email) {
      refresh().then((result) => {
        if (result?.user) {
          setUser(result.user)
        }
      })
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        userType,
        setUser,
        setUserType,
        isAuthorized: !!user?.id,
        currentTypeId: user?.[userType?.toLowerCase()]?.id,
        influencerId: user?.influencer?.id,
        followerId: user?.follower?.id,
        isFollower: userType === appConfig.userTypes.follower,
        isInfluencer: userType === appConfig.userTypes.influencer,
        isAdmin: user?.role === 'Administrator',
        idsHash: idsHashState,
        setIdsHash: setIdsHashState,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider

UserProvider.propTypes = {
  children: PropTypes.node,
}

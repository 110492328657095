import React from 'react'

import { ROUTES } from '../../constants/routes'
import SetSubscriptionAmount from '../../components/Profile/SetSubscriptionAmount'
import { Header } from '../../components/Header/Header'
import { useHistory } from 'react-router-dom'

// markup
const SetSubscriptionAmountPage = () => {
  const handleSave = () => history.push(ROUTES.PROFILE_SETTINGS)
  const history = useHistory()
  return (
    <>
      <Header title="Управление подписками" needRight={false} to={ROUTES.PROFILE_SETTINGS} />
      <SetSubscriptionAmount onAfterSave={handleSave} />
    </>
  )
}

export default SetSubscriptionAmountPage

import React, { useContext, useEffect, useState } from 'react'
import { find, remove } from '../../api/storage'
import { getAvatars } from '../../api/users'
import { appConfig } from '../../constants/appConfig'
import { ROUTES } from '../../constants/routes'
import clsx from 'clsx'
import { Tabs } from '../Main/Tabs'
import { Footer } from '../Footer/Footer'
import { Modal } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getInfluencerList } from '../../api/influencer'
import { PageLoading } from '../Layout/PageLoading'
import UserAvatar from '../Profile/UserAvatar'
import { UserContext } from '../../contexts/userContext'
import SubscriptionNotifyModal from '../Modal/SubscriptionNotifyModal'

export function Main() {
  const history = useHistory()
  const { isLoading, data } = useQuery(['influencerList'], () => getInfluencerList())
  const { influencerId, isAuthorized, setUserType } = useContext(UserContext)

  const [followerAvatar, setFollowerAvatar] = useState(null)
  const [influencerAvatar, setInfluencerAvatar] = useState(null)

  useEffect(() => {
    const loadAvatars = async () => {
      try {
        if (isAuthorized) {
          const { followerAvatarLink, influencerAvatarLink } = await getAvatars()
          if (followerAvatarLink) {
            setFollowerAvatar('https://' + followerAvatarLink)
          }
          if (influencerAvatarLink && influencerAvatarLink.length > 0) {
            const [{ influencerAvatarLink: defaultLink }] = Object.values(
              influencerAvatarLink,
            )?.filter(({ isDefault }) => isDefault)
            if (defaultLink) {
              setInfluencerAvatar('https://' + defaultLink)
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    loadAvatars()
  }, [isAuthorized])

  const [isNoEmailReceivedShow, setIsNoEmailReceivedShow] = useState(false)
  useEffect(() => {
    const message = find(appConfig.storage.keys.message)
    if (message === appConfig.userState.noEmailReceived) {
      setIsNoEmailReceivedShow(true)
      remove(appConfig.storage.keys.message)
    }
  }, [])

  return (
    <>
      <header className="mb-4">
        <div className="flex-grow-1 bold-24-32">Главная</div>
        <div className="pr-2 d-flex align-items-center">
          {!!influencerId && (
            <div
              style={{ width: '46px', height: '46px', padding: '3px' }}
              onClick={() => {
                setUserType(appConfig.userTypes.influencer)
                history.push(ROUTES.PROFILE)
              }}
            >
              <UserAvatar src={influencerAvatar} size={40} className="avatar" />
            </div>
          )}
          <div className={clsx({ selected: !!influencerId })}>
            <UserAvatar src={followerAvatar} size={40} className="avatar" />
          </div>
        </div>
      </header>
      <div className="content footer-padding">
        {!isLoading && <Tabs influencers={data?.data} />}
        {isLoading && <PageLoading hasHeader />}
      </div>
      <Footer />
      <SubscriptionNotifyModal />
      <Modal
        isOpen={isNoEmailReceivedShow}
        toggle={() => setIsNoEmailReceivedShow(false)}
        centered={true}
        size="sm"
      >
        <div className="py-4">
          <div className="pt-4">
            <div className="semiBold-17-24 px-3 text-center">
              Для создания аккаунта привяжите email
            </div>
            <hr />
            <div className="d-flex px-3 justify-content-between">
              <div
                className="semiBold-15-24 text-color-green flex-grow-1"
                onClick={() => {
                  history.push(ROUTES.EMAIL)
                }}
                role="button"
              >
                Привязать
              </div>
              <div
                className="semiBold-15-24 text-color-regular flex-grow-1"
                onClick={() => setIsNoEmailReceivedShow(false)}
                role="button"
              >
                Продолжить
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { useWebpLinks } from '../../hooks/useWebpLinks'

const emptyAvatar = `${appConfig.cdnPath.iconsSvg}/emptyavatar.svg`

function UserAvatar({
  src = null,
  size,
  className,
  round = true,
  showEmptyOnLoad = true,
  onClick = () => {},
}) {
  const img = useRef(null)
  let avatarSrc = src
  if (!avatarSrc?.includes('//')) {
    avatarSrc = `//${avatarSrc}`
  }
  const { link } = useWebpLinks({
    path: avatarSrc,
    params: { w: size * appConfig.cdnImagesSizeRange },
    isOriginal: true,
  })
  if (!src && !showEmptyOnLoad) {
    return null
  }

  return (
    <img
      ref={img}
      className={className}
      src={src ? link : emptyAvatar}
      alt=""
      width={`${size}px`}
      height={`${size}px`}
      style={{
        height: size,
        width: size,
        ...(round && { borderRadius: size / 2 }),
      }}
      onError={() => (img.current.src = emptyAvatar)}
      onClick={onClick}
    />
  )
}

export default UserAvatar

UserAvatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
  round: PropTypes.bool,
  showEmptyOnLoad: PropTypes.bool,
  onClick: PropTypes.func,
}

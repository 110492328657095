import React from 'react'
import PropTypes from 'prop-types'
import AddPostGeneral from '../../components/Profile/Post/AddPostGeneral'

function AddPostPage({ location }) {
  return <AddPostGeneral location={location} />
}

export default AddPostPage

AddPostPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

import * as React from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import Subscribe, { modes as subscribeModes } from '../components/Follower/Subscribe'

const SubscribePage = ({ location }) => {
  const { influencerId } = queryString.parse(location.search)
  return <Subscribe influencerId={influencerId} mode={subscribeModes.create} />
}
export default SubscribePage

SubscribePage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
}

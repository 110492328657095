import React from 'react'
import PropTypes from 'prop-types'
import { Footer } from '../../Footer/Footer'
import LinkWithArrow from '../../Layout/LinkWithArrow/LinkWithArrow'
import { ROUTES } from '../../../constants/routes'
import { Header } from '../../Header/Header'
import { useHistory } from 'react-router-dom'
import { makeUrl } from '../../../helpers'

function ProfileSettings({ onSelectAbout }) {
  const history = useHistory()
  return (
    <>
      <Header title="Настройки профиля" needRight={false} to={ROUTES.PROFILE} />
      <div className="content content-padding profile-settings footer-padding">
        <LinkWithArrow
          title="Об авторе"
          onClick={onSelectAbout}
          className="profile-settings-item"
        />
        <hr className="w-100 profile-settings-item" />

        {/* Скрыть пока не реализован функционал самой страницы */}
        {/*<LinkWithArrow title="Донаты и цели" onClick={() => {}} className="profile-settings-item" />*/}
        {/*<hr className="w-100 profile-settings-item" />*/}
        <LinkWithArrow
          title="Мои доходы"
          onClick={() => history.push(ROUTES.PROFILE_INCOME)}
          className="profile-settings-item"
        />
        <LinkWithArrow
          title="Мои цели"
          onClick={() => history.push(makeUrl({ route: ROUTES.PROFILE_TARGETS }))}
          className="profile-settings-item"
        />
        <hr className="w-100 profile-settings-item" />
        <LinkWithArrow
          title="Управление подписками"
          onClick={() => history.push(ROUTES.PROFILE_SUBSCRIPTION_AMOUNT)}
          className="profile-settings-item"
        />
        <LinkWithArrow
          title="Управление чатами"
          onClick={() => history.push(ROUTES.PROFILE_SETTINGS_CHATS)}
          className="profile-settings-item"
        />
        <LinkWithArrow
          title="Управление категориями"
          onClick={() => history.push(ROUTES.PROFILE_CATEGORY)}
          className="profile-settings-item"
        />
        <hr className="w-100 profile-settings-item" />
        <LinkWithArrow
          title="Приватность"
          onClick={() => history.push(ROUTES.PROFILE_SETTINGS_PRIVACY)}
          className="profile-settings-item"
        />
        <hr className="w-100 profile-settings-item" />
        <LinkWithArrow
          title="Об Insider"
          onClick={() => history.push(ROUTES.ABOUT_APP)}
          className="profile-settings-item"
        />
      </div>
      <Footer />
    </>
  )
}

ProfileSettings.defaultProps = {
  onSelectAbout: () => {},
}

ProfileSettings.propTypes = {
  onSelectAbout: PropTypes.func,
}

export default ProfileSettings

import { useContext, useEffect } from 'react'
import { arrayUniqValues } from '../helpers'
import { logicalFlows, useLogicalFlow } from './useLogicalFlow'
import { getFavoritePostsList, getLikedCommentsList, getLikedPostsList } from '../api/blog'
import { UserContext } from '../contexts/userContext'

const CryptoJS = require('crypto-js')
const idsHashTypes = logicalFlows.idsHash.types

export function useIdsHash() {
  const { getFlowData, setFlowData } = useLogicalFlow()
  const { user, idsHash, setIdsHash } = useContext(UserContext)

  useEffect(() => {
    if (user?.id) {
      getFlowData(logicalFlows.idsHash.id, (data) => {
        setIdsHash({ idsHashData: data?.[user?.id] })
      })
    }
  }, [user])

  const getMd5ByIds = (ids) => {
    const idsString = ids?.sort()?.join(' ')
    return idsString ? CryptoJS.MD5(idsString).toString() : ''
  }

  const isEqualHashes = (hash1, hash2) => hash1?.toLowerCase() === hash2?.toLowerCase()

  const checkHashData = async ({
    likedPostsHash = null,
    savedPostsHash = null,
    likedCommentsHash = null,
    userId,
  }) => {
    let allData = {}
    const localLikedPostsHash = getMd5ByIds(
      idsHash.idsHashData?.[idsHashTypes.likedPostsList] || [],
    )
    if (
      !likedPostsHash ||
      !localLikedPostsHash ||
      !isEqualHashes(likedPostsHash, localLikedPostsHash)
    ) {
      try {
        const likedPostsData = await getLikedPostsList()
        allData[idsHashTypes.likedPostsList] = likedPostsData
      } catch (e) {
        console.log('getLikedPostsList error', e)
      }
    }
    const localFavoritePostsHash = getMd5ByIds(
      idsHash.idsHashData?.[idsHashTypes.favoritePostsList] || [],
    )
    if (
      !savedPostsHash ||
      !localFavoritePostsHash ||
      !isEqualHashes(savedPostsHash, localFavoritePostsHash)
    ) {
      try {
        const favoritePostsData = await getFavoritePostsList()
        allData[idsHashTypes.favoritePostsList] = favoritePostsData
      } catch (e) {
        console.log('getFavoritePostsList error', e)
      }
    }
    const localLikedCommentsHash = getMd5ByIds(
      idsHash.idsHashData?.[idsHashTypes.likedCommentsList] || [],
    )
    if (
      !likedCommentsHash ||
      !localLikedCommentsHash ||
      !isEqualHashes(likedCommentsHash, localLikedCommentsHash)
    ) {
      try {
        const likedCommentsData = await getLikedCommentsList()
        allData[idsHashTypes.likedCommentsList] = likedCommentsData
      } catch (e) {
        console.log('getLikedCommentsList error', e)
      }
    }
    if (Object.keys(allData).length) {
      updateAllStorages(allData, userId)
    }
  }

  const hasPostLike = (postId) =>
    idsHash.idsHashData?.[idsHashTypes.likedPostsList]?.includes(postId)

  const hasPostFavorite = (postId) =>
    idsHash.idsHashData?.[idsHashTypes.favoritePostsList]?.includes(postId)

  const hasCommentLike = (postId) =>
    idsHash.idsHashData?.[idsHashTypes.likedCommentsList]?.includes(postId)

  const toggleLikedPosts = (toggle, id) =>
    toggle
      ? addIdToList(idsHashTypes.likedPostsList, id)
      : removeIdFromList(idsHashTypes.likedPostsList, id)

  const toggleFavoritePosts = (toggle, id) =>
    toggle
      ? addIdToList(idsHashTypes.favoritePostsList, id)
      : removeIdFromList(idsHashTypes.favoritePostsList, id)

  const toggleLikedComments = (toggle, id) =>
    toggle
      ? addIdToList(idsHashTypes.likedCommentsList, id)
      : removeIdFromList(idsHashTypes.likedCommentsList, id)

  const updateAllStorages = (data, userId) => {
    const id = userId ? userId : user?.id
    setIdsHash({
      idsHashData: {
        ...idsHash.idsHashData,
        ...data,
      },
    })
    setFlowData(logicalFlows.idsHash.id, {
      [id]: {
        ...idsHash.idsHashData,
        ...data,
      },
    })
  }

  const addIdToList = (listName, id) => {
    let list = idsHash.idsHashData?.[listName] || []
    list.push(id)
    list = arrayUniqValues(list)
    updateAllStorages({ [listName]: list })
  }

  const removeIdFromList = (listName, id) => {
    let list = idsHash.idsHashData?.[listName] || []
    list = list.filter((item) => item !== id)
    updateAllStorages({ [listName]: list })
  }

  return {
    idsHashData: idsHash.idsHashData,
    checkHashData,
    hasPostLike,
    hasPostFavorite,
    hasCommentLike,
    toggleLikedPosts,
    toggleFavoritePosts,
    toggleLikedComments,
  }
}

import React from 'react'
import { Header } from '../components/Header/Header'
import { ROUTES } from '../constants/routes'

// markup
const selectProfilePage = () => {
  return (
    <>
      <div className="auth">
        <div style={{ marginLeft: '-4.2%' }}>
          <Header title="Учетная запись" needRight={false} />
        </div>
        <span className="text-color-regular medium-13-20">
          Мы нашли учетные записи с таким email
        </span>
        <div className="auth-profile-line mt-3">
          <div
            className={'list-influencer'}
            onClick={() => {
              window.location.href = ROUTES.MAIN
            }}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <img
              src="https://insider-static.cdnvideo.ru/insider/images/avatar.webp"
              alt=""
              width="64px"
              height="64px"
            />
            <div className="list-influencer-content" style={{ paddingTop: '16px' }}>
              <div className="bold-17-24">Андрей</div>
              <div className="list-influencer-teaser">На insider c 12 ноября 2020</div>
            </div>
          </div>
        </div>
        <div className="auth-profile-line mt-2">
          <div
            className={'list-influencer'}
            onClick={() => {
              window.location.href = ROUTES.MAIN
            }}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <img
              src="https://insider-static.cdnvideo.ru/insider/images/avatar.webp"
              alt=""
              width="64px"
              height="64px"
            />
            <div className="list-influencer-content" style={{ paddingTop: '16px' }}>
              <div className="bold-17-24">Федор</div>
              <div className="list-influencer-teaser">На insider c 12 января 2021</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default selectProfilePage

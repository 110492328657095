import React from 'react'
import SelectCategory from '../../components/Category/SelectCategory'
import { ROUTES } from '../../constants/routes'
import { useHistory } from 'react-router-dom'

const ProfileCategoryPage = () => {
  const history = useHistory()
  const handleSave = () => {
    history.push(ROUTES.PROFILE_SETTINGS)
  }
  return <SelectCategory buttonName="Сохранить" onAfterSave={handleSave} />
}

export default ProfileCategoryPage

import { api } from './api'

export const getPlaylistByVideoId = async (objectId) => {
  return api.get({
    path: `/insider/media/playlist/?objectId=${objectId}`,
  })
}

export const deleteWelcomeMedia = () => {
  return api.del({
    path: `/insider/media/welcomemedia`,
  })
}
